import styles from "./FloatingTray.module.css";

function FloatingTray({ style, className, children, subtitle, offset = 0 }) {
    return (
        <div
            className={[styles.floating_tray, className].join(" ")}
            style={{ top: offset, marginTop: -offset, ...style }}
        >
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            {children}
        </div>
    );
}

export default FloatingTray;
