import styles from "./PeerConnectMobile.module.css";
import FloatingTray from "../../../components/FloatingTray/FloatingTray";
import Header from "../../../components/Header/Header";
import ArrowSVG from "../../../components/SVGs/ArrowSVG";
import Footer from "../../../components/Footer/Footer";
import MembersList from "../../../components/MembersList/MembersList";
import MemberModal from "../../../components/MemberModal/MemberModal";
import { useState } from "react";
import OrgLink from "../../../components/OrgLink/OrgLink";
import { useOrgObject } from "../../../hooks/useOrgObject";

function PeerConnectMobile({ viewportName = "mobile" }) {
    const [modalMember, setModalMember] = useState({});
    const { reels } = useOrgObject();

    return (
        <main className={viewportName}>
            <section id={styles.index_section}>
                <MemberModal member={modalMember} setMember={setModalMember} />
                <div id={styles.index_container}>
                    <div id={styles.index_background}></div>
                    <div id={styles.header_container}>
                        <Header viewportName={viewportName} />
                    </div>
                    <h1 className="mobile-cap">
                        Building strong connections with your members.
                    </h1>
                    <FloatingTray offset={350} className={"mobile-cap"}>
                        <MembersList
                            className={"mobile-cap"}
                            data={reels}
                            setModalMember={setModalMember}
                        />
                    </FloatingTray>
                </div>
                <div id={styles.bottom_link_container} className="mobile-cap">
                    <OrgLink
                        className="link-button"
                        id={styles.back_home_link}
                        to="/"
                    >
                        <ArrowSVG style={{ stroke: "white" }} /> Back Home
                    </OrgLink>
                </div>
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default PeerConnectMobile;
