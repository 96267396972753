import { createContext, useState } from "react";

export const SignUpModalContext = createContext();

function SignUpModalContextProvider({ children }) {
    const [showModal, setShowModal] = useState(false);
    const [signUpOption, setSignUpOption] = useState("Peer Member");

    const contextObject = {
        showModal,
        setShowModal,
        signUpOption,
        setSignUpOption,
    };

    return (
        <SignUpModalContext.Provider value={contextObject}>
            {children}
        </SignUpModalContext.Provider>
    );
}

export default SignUpModalContextProvider;
