import FloatingTray from "../../../components/FloatingTray/FloatingTray";
import Header from "../../../components/Header/Header";
import StarterTile from "../../../components/StarterTile/StarterTile";
import styles from "./SignInMobile.module.css";
import ArrowSVG from "../../../components/SVGs/ArrowSVG";
import Footer from "../../../components/Footer/Footer";
import VideoCallSVG from "../../../components/SVGs/VideoCallSVG";
import LaptopBubbleSVG from "../../../components/SVGs/LaptopBubbleSVG";
import OrgLink from "../../../components/OrgLink/OrgLink";
import { useOrgObject } from "../../../hooks/useOrgObject";

function SignInMobile({ viewportName = "mobile" }) {
    const { region } = useOrgObject();
    const orgObject = useOrgObject();
    return (
        <main className={viewportName}>
            <section id={styles.index_section}>
                <div id={styles.index_container}>
                    <div id={styles.index_background}></div>
                    <div id={styles.header_container}>
                        <Header viewportName={viewportName} />
                    </div>
                    <h1 className="mobile-cap">
                        Help Our First Responder Family.
                    </h1>
                    <FloatingTray offset={80} className={"mobile-cap"}>
                        <div id={styles.starter_tiles_grid}>
                            <StarterTile
                                Graphic={VideoCallSVG}
                                href={`https://app.${orgObject.region}.geta-head.com/app/gah/`}
                                label={"I Have An Account"}
                                subtitle={"Meet with a Peer"}
                                viewportName={viewportName}
                            />
                            <StarterTile
                                Graphic={LaptopBubbleSVG}
                                href={`https://app.${orgObject.region}.geta-head.com/app/gah/`}
                                label={"New Members"}
                                subtitle={"Join Here"}
                                viewportName={viewportName}
                            />
                        </div>
                    </FloatingTray>
                </div>
                <div id={styles.join_tile_container}>
                    <StarterTile
                        href={"/"}
                        style={{ width: "100%" }}
                        src={"/images/global/join-tile-image.jpg"}
                        Graphic={() => (
                            <>
                                {region === "us" && (
                                    <img
                                        src="/images/global/us-footer-logo.jpg"
                                        alt="monochromatic United States flag"
                                        style={{ height: 17 }}
                                    />
                                )}
                                {region === "ca" && (
                                    <img
                                        src="/images/global/canada-footer-logos.jpg"
                                        alt="Logos for Government of Canada, Employment Ontario, and Government of Ontario"
                                        style={{ height: 17 }}
                                    />
                                )}
                            </>
                        )}
                        label={"Join the National\nPeer Support Initiative"}
                        subtitle={"Making a difference across our nations"}
                        viewportName={viewportName}
                    />
                </div>
                <div id={styles.bottom_link_container} className="mobile-cap">
                    <OrgLink
                        className="link-button"
                        id={styles.back_home_link}
                        to="/"
                    >
                        <ArrowSVG style={{ stroke: "white" }} /> Back Home
                    </OrgLink>
                </div>
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default SignInMobile;
