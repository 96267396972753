import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function LocationChangeHelper() {
    const location = useLocation();
    useEffect(() => {
        // if (location.pathname.match(/\/sign-in$"/)) {
        // setTimeout(() =>
        window.scrollTo(0, 0);
        // , 100);
        // }
    }, [location]);

    return null;
}

export default LocationChangeHelper;
