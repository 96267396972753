import SignInMobile from "./SignInMobile/SignInMobile";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import HomeDesktop from "../Home/HomeDesktop/HomeDesktop";

function SignIn({ viewportName = "mobile" }) {
    return (
        <>
            <OrgThemeHelper />
            <HomeDesktop defaultShowModal={true} viewportName="desktop" />
            <SignInMobile viewportName="mobile" />
        </>
    );
}

export default SignIn;
