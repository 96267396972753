function PauseButtonSVG({ className }) {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="17.178"
                cy="16.8929"
                r="16.8929"
                fill="var(--secondary-color)"
            />
            <path
                d="M18.1667 21.0834V12.9167H20.5V21.0834H18.1667ZM13.5 21.0834V12.9167H15.8333V21.0834H13.5Z"
                fill="white"
            />
        </svg>
    );
}

export default PauseButtonSVG;
