import OrgLink from "../OrgLink/OrgLink";
import styles from "./BodyGraphic.module.css";
import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";

function BodyGraphic({
    id,
    className,
    arrowText,
    darkMode,
    target,
    rel,
    headingText,
    children,
    linkText,
    href,
    action,
    Graphic = () => {},
    Overlays = () => {},
    reverse,
}) {
    return (
        <div
            id={id}
            className={[
                styles.body_graphic,
                darkMode ? styles.dark_mode : undefined,
                reverse ? styles.reverse : undefined,
                className,
            ].join(" ")}
        >
            <div className={styles.text}>
                {arrowText && (
                    <ArrowSubtitle text={arrowText} darkMode={darkMode} />
                )}
                <h2>{headingText}</h2>
                {children}
                {linkText &&
                    ((href && (
                        <OrgLink
                            target={target}
                            rel={rel}
                            className={[styles.link, "link-button"].join(" ")}
                            to={href}
                        >
                            {linkText}
                        </OrgLink>
                    )) ||
                        (action && (
                            <button
                                onClick={action}
                                className="link-button"
                                id={styles.action_button}
                            >
                                {linkText}
                            </button>
                        )))}
            </div>
            <div className={styles.graphic_container}>
                <Graphic />
                <Overlays />
            </div>
        </div>
    );
}

export default BodyGraphic;
