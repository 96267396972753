import styles from "./IntroReelPill.module.css";
import PlayButtonSVG from "../SVGs/PlayButtonSVG";
function IntroReelPill({ disabledSrc, x = 0, y = 0, headshot, name, orgName }) {
    return disabledSrc ? (
        <img
            className={styles.disabled_intro_reel_pill}
            alt=""
            src={disabledSrc}
            style={{
                translate: `calc(-50% + ${x}px) calc(-50% + ${y}px)`,
            }}
        />
    ) : (
        <div
            className={[styles.intro_reel_pill]}
            style={{
                translate: `calc(-50% + ${x}px) calc(-50% + ${y}px)`,
            }}
        >
            <img
                src={headshot}
                alt={`${name} headshot`}
                className={styles.headshot}
            />
            <div className={styles.text}>
                <strong>{name}</strong>
                <div className={styles.org_name}>{orgName}</div>
            </div>
            <div className={styles.outer_circle}>
                <PlayButtonSVG className={styles.play_button} />
            </div>
        </div>
    );
}

export default IntroReelPill;
