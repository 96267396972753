import { useParams } from "react-router-dom";
import styles from "./WellnessHubArticle.module.css";
import { useOrgObject } from "../../hooks/useOrgObject";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import WellnessHubCarousel from "../../components/WellnessHubCarousel/WellnessHubCarousel";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";

function WellnessHubArticle({ viewportName = "desktop" }) {
    const { id } = useParams();
    const { placeholderArticles } = useOrgObject();

    const { title, src, content } = placeholderArticles[id];

    return (
        <main id={styles.main}>
            <OrgThemeHelper />
            <section id={styles.content_section}>
                <div
                    className="section-content"
                    id={styles.content_section_content}
                >
                    <Header viewportName={viewportName} darkMode={true} />
                    <div id={styles.hero}>
                        <img id={styles.hero_image} src={src} alt="" />
                        <div id={styles.hero_image_cover}></div>
                        <h1 id={styles.title}>{title}</h1>
                    </div>
                    <div
                        id={styles.content_container}
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </div>
            </section>
            <section id={styles.wellness_carousel_section}>
                <div className="section-content">
                    <WellnessHubCarousel data={placeholderArticles} />
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default WellnessHubArticle;
