import styles from "./SignUpOption.module.css";

function SignUpOption({
    name,
    subtitle,
    checks = [],
    boldChecks = [],
    selectedOption,
    setSelectedOption,
}) {
    return (
        <button
            className={[
                styles.sign_up_option,
                selectedOption === name ? styles.selected : undefined,
            ].join(" ")}
            onClick={() => setSelectedOption(name)}
        >
            <div className={styles.top}>
                <strong className={styles.title}>{name}</strong>
                <div
                    className={styles.selected_check}
                    style={{
                        backgroundImage:
                            "url(/images/global/checkmark-white.png)",
                    }}
                ></div>
            </div>
            <strong className={styles.subtitle}>{subtitle}</strong>
            <ul className={styles.checks_container}>
                {checks.map((text, index) => (
                    <li key={index} className={styles.check}>
                        {text}
                    </li>
                ))}
                {boldChecks.map((text, index) => (
                    <li key={index} className={styles.check}>
                        <strong>{text}</strong>
                    </li>
                ))}
            </ul>
        </button>
    );
}

export default SignUpOption;
