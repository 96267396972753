import styles from "./MembersList.module.css";

function MembersList({ className, data, setModalMember }) {
    return (
        <div id={styles.members_list} className={className}>
            <div id={styles.top}>
                <strong>
                    Matching with your fellow first responders has never been so
                    intelligent.
                </strong>
                <div className={styles.light_text}>
                    We get it, we've got you! You're not alone in your
                    experiences on or off the job. Learn more about your fellow
                    peers below.
                </div>
            </div>
            <div id={styles.bottom}>
                {data.map(({ name, subtitle, headshotSrc, bio }) => (
                    <button
                        className={styles.member}
                        key={name}
                        aria-label={"Open details modal for " + name}
                        onClick={() =>
                            setModalMember({ name, subtitle, headshotSrc, bio })
                        }
                    >
                        <div className={styles.member_info}>
                            <img
                                src={headshotSrc}
                                alt={name + " headshot"}
                                className={styles.member_headshot}
                            />
                            <div className={styles.member_text}>
                                <strong>{name}</strong>
                                <div className={styles.light_text}>
                                    {subtitle}
                                </div>
                            </div>
                        </div>
                        <div className={styles.member_right}>
                            <img
                                src="/images/global/right-arrow-blue.svg"
                                alt=""
                                className={styles.member_arrow}
                            />
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default MembersList;
