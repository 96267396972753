import "./Wavesurfer_fix.css";
import { useEffect, useRef } from "react";
import styles from "./Waveform.module.css";
import { useWavesurfer } from "@wavesurfer/react";
import PlayButtonSVG from "../SVGs/PlayButtonSVG";
import PauseButtonSVG from "../SVGs/PauseButtonSVG";
import { useOrgObject } from "../../hooks/useOrgObject";

function Waveform({
    data: { name, subtitle, audioSrc, headshotSrc },
    className,
}) {
    const { secondaryColor } = useOrgObject();
    const containerRef = useRef(null);
    const { wavesurfer, isPlaying, isReady } = useWavesurfer({
        container: containerRef,
        url: audioSrc,
        waveColor: "#1D3160",
        progressColor: secondaryColor,
        height: 20,

        // minPxPerSec: 1,
    });

    useEffect(() => {
        if (isReady) {
            // const shadowRoot = containerRef
            const shadowRoot = containerRef.current.firstChild.shadowRoot;
            shadowRoot.querySelector("style")?.remove();
        }
    }, [isReady]);

    // useEffect()

    function handlePlayPause() {
        wavesurfer && wavesurfer.playPause();
    }

    return (
        <div className={className}>
            <img
                src={headshotSrc}
                alt={name + " headshot"}
                className={styles.headshot}
            />
            <button className={styles.play_button} onClick={handlePlayPause}>
                {isPlaying ? <PauseButtonSVG /> : <PlayButtonSVG />}
            </button>
            <div className={styles.details}>
                <strong>{name}</strong>
                <div className={styles.subtitle}>{subtitle}</div>
                <div
                    className={[
                        styles.waveform_container,
                        "waveform-container",
                    ].join(" ")}
                    ref={containerRef}
                ></div>
            </div>
        </div>
    );
}

export default Waveform;
