import ArrowSubtitle from "../../../components/ArrowSubtitle/ArrowSubtitle";
import BodyGraphic from "../../../components/BodyGraphic/BodyGraphic";
import FloatingTray from "../../../components/FloatingTray/FloatingTray";
import StarterTile from "../../../components/StarterTile/StarterTile";
import InterfaceTargetSVG from "../../../components/SVGs/InterfaceTargetSVG";
import LaptopBubbleSVG from "../../../components/SVGs/LaptopBubbleSVG";
import LaptopListSVG from "../../../components/SVGs/LaptopListSVG";
import ToolCard from "../../../components/ToolCard/ToolCard";
import styles from "./HomeDesktop.module.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import IntroReelPill from "../../../components/IntroReelPill/IntroReelPill";
import { useContext, useEffect, useRef } from "react";
import GraphicOverlay from "../../../components/GraphicOverlay/GraphicOverlay";
import PlayButtonSVG from "../../../components/SVGs/PlayButtonSVG";
import WellnessHubCarousel from "../../../components/WellnessHubCarousel/WellnessHubCarousel";
import { useOrgObject } from "../../../hooks/useOrgObject";
import OrgLink from "../../../components/OrgLink/OrgLink";
import { SignUpModalContext } from "../../../components/SignUpModalContextProvider/SignUpModalContextProvider";

function HomeDesktop({ viewportName = "desktop", defaultShowModal = false }) {
    const joinSectionRef = useRef(null);
    const wellnessSectionRef = useRef(null);
    const orgObject = useOrgObject();
    const { setShowModal, setSignUpOption } = useContext(SignUpModalContext);

    function handleScrollArrowsClick() {
        joinSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        setShowModal(defaultShowModal);
    }, [setShowModal, defaultShowModal]);

    return (
        <main className={viewportName}>
            <section id={styles.hero}>
                <div className="section-content" id={styles.hero_content}>
                    <Header viewportName={viewportName} />
                    <div id={styles.hero_text}>
                        <ArrowSubtitle
                            text={
                                "Welcome to the " +
                                orgObject.name +
                                " Peer Support Program"
                            }
                            darkMode={true}
                            style={{ fontSize: 18 }}
                        />
                        <h1>
                            Supporting Your Well-Being <span>Every Step</span>{" "}
                            of the Way.
                        </h1>
                    </div>

                    <FloatingTray
                        offset={70}
                        subtitle={
                            "Get started here or scroll for more information"
                        }
                        style={{ width: "100%" }}
                    >
                        <div id={styles.starter_tiles_container}>
                            <div className={styles.starter_tiles_group}>
                                <StarterTile
                                    src={
                                        "/images/" +
                                        orgObject.type +
                                        "/starter-tile-1.jpg"
                                    }
                                    href={`https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=dev.peersupport.keelmind.com`}
                                    label={"Readiness Check"}
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                    subtitle={"Quick & Confidential"}
                                    viewportName={viewportName}
                                />
                                <StarterTile
                                    src={
                                        "/images/" +
                                        orgObject.type +
                                        "/starter-tile-2.jpg"
                                    }
                                    href={"/peer-connect"}
                                    label={"Connect with a Peer Supporter"}
                                    subtitle={"You're not alone"}
                                    viewportName={viewportName}
                                />
                            </div>
                            <div className={styles.starter_tiles_group}>
                                <StarterTile
                                    src={
                                        "/images/" +
                                        orgObject.type +
                                        "/starter-tile-3.jpg"
                                    }
                                    action={() => {
                                        wellnessSectionRef.current.scrollIntoView(
                                            {
                                                behavior: "smooth",
                                            }
                                        );
                                    }}
                                    label={"Wellness Hub"}
                                    subtitle={"Articles & Resources"}
                                    viewportName={viewportName}
                                />
                                <StarterTile
                                    src={
                                        "/images/" +
                                        orgObject.type +
                                        "/starter-tile-4.jpg"
                                    }
                                    action={() => {
                                        setShowModal(true);
                                        setSignUpOption("Peer Supporter");
                                    }}
                                    label={"For Peer Supporters"}
                                    subtitle={"We're making a difference"}
                                    viewportName={viewportName}
                                />
                            </div>
                        </div>
                    </FloatingTray>
                </div>
            </section>
            <section id={styles.scroll_arrows_section}>
                <button
                    id={styles.scroll_arrows_button}
                    aria-label="Click to scroll to content below"
                    onClick={handleScrollArrowsClick}
                >
                    <img src="/images/global/down-arrows.png" alt="" />
                </button>
            </section>
            <section
                ref={joinSectionRef}
                id={styles.join_program_section}
                className={styles.section}
            >
                <BodyGraphic
                    className={"section-content"}
                    headingText={
                        "We're committed to fostering resilience and well-being for our members."
                    }
                    linkText={"Join Program"}
                    action={() => {
                        setShowModal(true);
                    }}
                    Graphic={() => (
                        <img
                            alt=""
                            src={
                                "/images/" +
                                orgObject.type +
                                "/home-body-image-1.jpg"
                            }
                        />
                    )}
                >
                    <div className={styles.body_graphic_content}>
                        <p>
                            We are excited to announce an enhancement to our
                            Peer Support Program through our partnership with
                            Keel Mind.
                        </p>
                        <br />
                        <p>
                            By integrating Keel Mind's cutting-edge tools and
                            technology, we are supporting you with the resources
                            you may need to navigate your mental wellness.
                        </p>
                    </div>
                </BodyGraphic>
            </section>
            <section
                id={styles.browse_supporters_section}
                className={[styles.section, styles.dark_section].join(" ")}
            >
                <BodyGraphic
                    reverse={true}
                    arrowText={"Peer Support"}
                    headingText={"Connect with a Peer Supporter"}
                    className={"section-content"}
                    darkMode={true}
                    linkText={"Browse Peer Supporters"}
                    href={"/peer-connect"}
                    Graphic={() => (
                        <img
                            src={
                                "/images/" +
                                orgObject.type +
                                "/home-body-image-2.jpg"
                            }
                            alt=""
                        />
                    )}
                    Overlays={() => (
                        <>
                            <GraphicOverlay
                                id={styles.play_button_overlay}
                                top={25}
                                left={-20}
                            >
                                <PlayButtonSVG />
                                <img
                                    src="/images/global/overlays/waveform.png"
                                    alt="audio waveform"
                                />
                            </GraphicOverlay>
                            <GraphicOverlay
                                id={styles.your_peer_supporter_overlay}
                                bottom={-30}
                                right={-10}
                            >
                                <img
                                    src="/images/global/overlays/Susan_Robinson_headshot.png"
                                    alt="Susan Robinson headshot"
                                />
                                <div id={styles.your_peer_supporter_text}>
                                    <strong>Your Peer Supporter</strong>
                                    <p>Susan Robinson</p>
                                </div>
                            </GraphicOverlay>
                        </>
                    )}
                >
                    <div className={styles.body_graphic_content}>
                        <p>
                            We understand the immense stress and trauma that our
                            members often face, and our goal is to provide you
                            with a safe, confidential space to discuss your
                            experiences.
                        </p>
                        <br />
                        <p>
                            <strong>
                                Browse the network of peer supporters that have
                                walked in your shoes and share your struggles
                                and triumphs.
                            </strong>
                        </p>
                    </div>
                </BodyGraphic>
            </section>
            <section id={styles.tools_section} className={styles.section}>
                <div
                    id={styles.tools_section_content}
                    className="section-content"
                >
                    <div className={styles.center_heading}>
                        <ArrowSubtitle text={"Support Tools"} />
                        <h2>Your Strength, Your Care, Your Tools.</h2>
                        <p>
                            Empowering your mental and physical health with
                            custom wellness tools - all in a secure, anonymous
                            space
                        </p>
                    </div>
                    <div id={styles.tool_cards_container}>
                        <ToolCard
                            heading={"Shared Experiences"}
                            linkText={"Let's Listen"}
                            href={"/peer-connect"}
                            Graphic={LaptopBubbleSVG}
                        >
                            A virtual interview that improves rapport between
                            peer supporters and peers based on story-telling and
                            lived experience.
                        </ToolCard>
                        <ToolCard
                            heading={"Readiness Checks"}
                            linkText={"Try a Self Assessment"}
                            target={"_blank"}
                            rel={"noreferrer"}
                            href={`https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=dev.peersupport.keelmind.com`}
                            Graphic={LaptopListSVG}
                        >
                            An anonymous tool that allows you to gain quick
                            insights into your overall mental readiness and
                            decide when it’s the right time to seek resources.
                        </ToolCard>
                        <ToolCard
                            heading={"Wellness Hub"}
                            linkText={"Browse Articles"}
                            action={() => {
                                wellnessSectionRef.current.scrollIntoView({
                                    behavior: "smooth",
                                });
                            }}
                            Graphic={InterfaceTargetSVG}
                        >
                            A secure space offering therapeutic and
                            wellness-related resources specifically designed for
                            first responders.
                        </ToolCard>
                    </div>
                    <div
                        id={styles.reels_intro_container}
                        style={{
                            backgroundImage:
                                "url(/images/global/circles-background.svg)",
                        }}
                    >
                        <IntroReelPill
                            disabledSrc={"/images/global/disabled-reel-1.png"}
                            x={-80}
                            y={-290}
                        />
                        <IntroReelPill
                            disabledSrc={"/images/global/disabled-reel-2.png"}
                            x={-220}
                            y={200}
                        />
                        <IntroReelPill
                            disabledSrc={"/images/global/disabled-reel-3.png"}
                            x={250}
                            y={120}
                        />
                        <IntroReelPill
                            name={"Ethan Collins"}
                            headshot={
                                "/images/generic/Ethan-Collins-headshot.jpg"
                            }
                            orgName={"First Responder"}
                            x={-240}
                            y={-180}
                        />
                        <IntroReelPill
                            name={"Lucas Anderson"}
                            headshot={
                                "/images/generic/Lucas-Anderson-headshot.jpg"
                            }
                            orgName={"First Responder"}
                            x={295}
                            y={-270}
                        />
                        <IntroReelPill
                            name={"Sophia Harris"}
                            orgName={"First Responder"}
                            headshot={
                                "/images/generic/Sophia-Harris-headshot.jpg"
                            }
                            x={87}
                            y={254}
                        />
                        <div className={styles.center_heading}>
                            <ArrowSubtitle
                                text={"Interviews To Build Rapport"}
                            />
                            <h2>
                                Your First Responder Voice,
                                <br />
                                Their Connection.
                            </h2>
                            <p>Complete your Peer Supporter Interview Now!</p>
                            <OrgLink
                                id={styles.create_reel_link}
                                className="link-button"
                                to="/peer-connect"
                            >
                                Create My Own Keel Reel
                            </OrgLink>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id={styles.self_assessment_section}
                className={[styles.section, styles.grey_section].join(" ")}
            >
                <BodyGraphic
                    id={styles.anonymous_tools_body_graphic}
                    className="section-content"
                    headingText={"Anonymous Self Assessment Tools"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    arrowText={"Support Tools Introduction"}
                    href={`https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=dev.peersupport.keelmind.com`}
                    linkText={"Start A Self Assessment"}
                    Graphic={() => (
                        <img
                            alt=""
                            src={
                                "/images/" +
                                orgObject.type +
                                "/home-body-image-3.jpg"
                            }
                        />
                    )}
                    Overlays={() => (
                        <>
                            <GraphicOverlay
                                bottom={-40}
                                right={115}
                                style={{
                                    padding: 16,
                                    paddingTop: 14,
                                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                                }}
                                id={styles.stress_level_overlay}
                            >
                                <div
                                    style={{
                                        paddingInline: 4,
                                        display: "grid",
                                        gridTemplateColumns: "repeat(2, 1fr)",
                                        marginBottom: 20,
                                    }}
                                >
                                    <strong style={{ alignSelf: "end" }}>
                                        Stress level
                                    </strong>
                                    <strong
                                        style={{
                                            fontSize: 22,
                                            textAlign: "right",
                                        }}
                                    >
                                        42
                                    </strong>
                                    <div style={{ fontWeight: 300 }}>
                                        Wednesday, June 21st
                                    </div>
                                    <strong
                                        style={{
                                            lineHeight: "22px",
                                            textAlign: "right",
                                        }}
                                    >
                                        Medium
                                    </strong>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        height: 6,
                                        position: "relative",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            opacity: 0.3,
                                            backgroundColor:
                                                "var(--secondary-color)",
                                            borderRadius: 6,
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            width: "42%",
                                            height: "100%",
                                            backgroundColor:
                                                "var(--secondary-color)",
                                            position: "absolute",
                                            top: 0,
                                            borderRadius: 6,
                                        }}
                                    ></div>
                                </div>
                            </GraphicOverlay>
                        </>
                    )}
                >
                    <div className={styles.body_graphic_content}>
                        <strong>AI-Driven Assessments:</strong> Identify
                        potential wellness concerns such as fatigue, burnout,
                        and stress. It’s confidential and anonymous.
                        <br />
                        <br />
                        <strong>First Responders:</strong> can evaluate their
                        mental health without fear of judgment or breaches of
                        privacy.
                    </div>
                </BodyGraphic>
            </section>
            <section
                className={styles.section}
                id={styles.wellness_section}
                ref={wellnessSectionRef}
            >
                <WellnessHubCarousel
                    data={orgObject.placeholderArticles}
                    arrowText={"Support Tools Introduction"}
                />
            </section>
            <section id={styles.stats_section} className={styles.section}>
                <div id={styles.stats_card} className="section-content">
                    <h2>You Are Not Alone</h2>
                    <div id={styles.stats_container}>
                        <div className={styles.stat}>
                            <strong>5x</strong>
                            <p>
                                First Responders are 5 times more likely to
                                experience PTSD.
                            </p>
                        </div>
                        <div className={styles.stat}>
                            <strong>39%</strong>
                            <p>
                                Of First Responders say there are negative
                                repercussions for seeking help at work.
                            </p>
                        </div>
                        <div className={styles.stat}>
                            <strong>25%</strong>
                            <p>
                                Of First Responders experience signs of
                                depression.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}

export default HomeDesktop;
