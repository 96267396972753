function ArrowSVG({ style, className, id }) {
    return (
        <svg
            id={id}
            className={className}
            stroke="var(--secondary-color)"
            style={style}
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 7H17M17 7L11 1M17 7L11 13"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ArrowSVG;
