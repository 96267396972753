import { headerLinks } from "../../data/navigation";
import styles from "./Header.module.css";
import { useOrgObject } from "../../hooks/useOrgObject";
import OrgLink from "../OrgLink/OrgLink";
import SignUpModal from "../SignUpModal/SignUpModal";
import { useContext } from "react";
import { SignUpModalContext } from "../SignUpModalContextProvider/SignUpModalContextProvider";

function Header({ viewportName, darkMode }) {
    const { setShowModal } = useContext(SignUpModalContext);
    const orgObject = useOrgObject();

    return (
        <header
            id={styles.header}
            className={[
                styles[viewportName],
                darkMode ? styles.dark_mode : undefined,
            ].join(" ")}
        >
            <SignUpModal />
            <div id={styles.left}>
                <img
                    id={styles.logo}
                    src={orgObject.logoSrc}
                    alt={orgObject.name + " logo"}
                />
            </div>
            <nav id={styles.middle} className="desktop">
                {headerLinks.map(({ name, href, ...rest }) => (
                    <OrgLink
                        className={styles.link}
                        key={name}
                        to={href}
                        {...rest}
                    >
                        {name}
                    </OrgLink>
                ))}
            </nav>
            <div id={styles.right} className="desktop">
                <OrgLink
                    className={
                        "link-button outline " +
                        (darkMode ? "dark-mode" : undefined)
                    }
                    to={`https://app.${orgObject.region}.geta-head.com/app/gah/`}
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    Login
                </OrgLink>
                <button
                    onClick={() => setShowModal(true)}
                    id={styles.join_button}
                    className="link-button"
                    to={"/"}
                >
                    Join Program
                </button>
            </div>
        </header>
    );
}

export default Header;
