import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import OrgLink from "../OrgLink/OrgLink";
import styles from "./ProgramBanner.module.css";

function ProgramBanner({
    backgroundImageSrc = "/images/global/join-program-banner.jpg",
    className,
    heading = "Join the International Peer Support Program",
    linkHref = "/",
    linkText = "Sign up to this program",
    arrowText = "New Program",
}) {
    return (
        <section
            className={[styles.program_banner_section, className].join(" ")}
            style={{ backgroundImage: `url(${backgroundImageSrc})` }}
        >
            <div
                className={[styles.program_banner, "section-content"].join(" ")}
            >
                <div className={styles.cover}></div>
                <ArrowSubtitle darkMode={true} text={arrowText} />
                <h2 className={styles.heading}>{heading}</h2>
                <OrgLink className="link-button" to={linkHref}>
                    {linkText}
                </OrgLink>
            </div>
        </section>
    );
}

export default ProgramBanner;
