import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import OrgLink from "../OrgLink/OrgLink";
import styles from "./StarterTile.module.css";

function StarterTile({
    Graphic = () => {},
    style,
    label,
    subtitle,
    href,
    action,
    src,
    viewportName,
    target,
    rel,
}) {
    function TileComponent({ ...props }) {
        return href ? <OrgLink {...props} /> : <button {...props} />;
    }
    return (
        <TileComponent
            target={target}
            rel={rel}
            style={style}
            className={[
                styles.starter_tile,
                styles[viewportName],
                src ? undefined : styles.white_tile,
            ].join(" ")}
            to={href}
            onClick={action}
        >
            <img className={styles.image} src={src} alt="" />
            <div className={styles.cover} />
            <div className={styles.graphic_container}>
                <Graphic className={styles.graphic} />
            </div>
            <div className={styles.text}>
                <strong className={styles.label}>{label}</strong>
                <ArrowSubtitle
                    arrowClassName={"desktop"}
                    darkMode={!!src}
                    text={subtitle}
                    style={{
                        fontSize: 14,
                    }}
                />
            </div>
        </TileComponent>
    );
}

export default StarterTile;
