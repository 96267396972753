import { useParams } from "react-router-dom";
import orgs from "../data/orgs.json";
import {
    defaultPlaceholderArticles,
    defaultReels,
} from "../data/placeholderData";

export function useOrgObject() {
    const { org } = useParams();

    const output = orgs?.[org] || orgs["keelmind"];

    const key = orgs?.[org] ? org : "";

    const placeholderArticles =
        output.placeholderArticles || defaultPlaceholderArticles;

    const reels = output.reels || defaultReels;

    return { key, placeholderArticles, reels, ...output };
}
