import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import "./fonts.css";
import Home from "./pages/Home/Home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import PeerConnect from "./pages/PeerConnect/PeerConnect";
import LocationChangeHelper from "./components/LocationChangeHelper/LocationChangeHelper";
import SignUpModalContextProvider from "./components/SignUpModalContextProvider/SignUpModalContextProvider";
import WellnessHubArticle from "./pages/WellnessHubArticle/WellnessHubArticle";
import CrisisSupport from "./pages/CrisisSupport/CrisisSupport";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <LocationChangeHelper />
            <SignUpModalContextProvider>
                <Routes>
                    {/* unbranded routes */}
                    <Route path="*" element={<Home />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/peer-connect" element={<PeerConnect />} />
                    <Route
                        path="/wellness-hub/:id"
                        element={<WellnessHubArticle />}
                    />
                    <Route path="/crisis-support" element={<CrisisSupport />} />

                    {/* org routes  */}
                    <Route path=":org/*" element={<Home />} />
                    <Route path=":org/sign-in" element={<SignIn />} />
                    <Route path=":org/peer-connect" element={<PeerConnect />} />
                    <Route
                        path=":org/wellness-hub/:id"
                        element={<WellnessHubArticle />}
                    />
                    <Route
                        path=":org/crisis-support"
                        element={<CrisisSupport />}
                    />
                </Routes>
            </SignUpModalContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
