import FloatingTray from "../../../components/FloatingTray/FloatingTray";
import StarterTile from "../../../components/StarterTile/StarterTile";
import styles from "./HomeMobile.module.css";
import Header from "../../../components/Header/Header";
import ArrowSVG from "../../../components/SVGs/ArrowSVG";
import Footer from "../../../components/Footer/Footer";
import OrgLink from "../../../components/OrgLink/OrgLink";
import { useOrgObject } from "../../../hooks/useOrgObject";

function HomeMobile({ viewportName = "mobile" }) {
    const orgObject = useOrgObject();
    return (
        <main className={viewportName}>
            <section id={styles.index_section}>
                <div id={styles.index_container}>
                    <div id={styles.index_background}></div>
                    <div id={styles.header_container}>
                        <Header viewportName={viewportName} />
                    </div>
                    <h1 className="mobile-cap">
                        Supporting Your Well-Being <span>Every Step</span> of
                        the Way.
                    </h1>
                    <FloatingTray offset={80} className={"mobile-cap"}>
                        <div id={styles.starter_tiles_grid}>
                            <StarterTile
                                src={
                                    "/images/" +
                                    orgObject.type +
                                    "/starter-tile-1.jpg"
                                }
                                href={`https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=dev.peersupport.keelmind.com`}
                                label={"Readiness Check"}
                                subtitle={"Quick & Confidential"}
                                target={"_blank"}
                                rel={"noreferrer"}
                                viewportName={viewportName}
                            />
                            <StarterTile
                                src={
                                    "/images/" +
                                    orgObject.type +
                                    "/starter-tile-2.jpg"
                                }
                                href={"/peer-connect"}
                                label={"Connect with a Peer Supporter"}
                                subtitle={"You're not alone"}
                                viewportName={viewportName}
                            />
                            <StarterTile
                                src={
                                    "/images/" +
                                    orgObject.type +
                                    "/starter-tile-3.jpg"
                                }
                                href={"/wellness-hub/0"}
                                label={"Wellness Hub"}
                                subtitle={"Articles & Resources"}
                                viewportName={viewportName}
                            />
                            <StarterTile
                                src={
                                    "/images/" +
                                    orgObject.type +
                                    "/starter-tile-4.jpg"
                                }
                                href={"/"}
                                label={"For Peer Supporters"}
                                subtitle={"We're making a difference"}
                                viewportName={viewportName}
                            />
                        </div>
                    </FloatingTray>
                </div>
                <div id={styles.bottom_link_button} className="mobile-cap">
                    <OrgLink
                        className="link-button"
                        id={styles.sign_in_link}
                        to={"/sign-in"}
                    >
                        <ArrowSVG style={{ stroke: "white" }} /> Sign in
                    </OrgLink>
                </div>
            </section>
            <Footer viewportName={viewportName} />
        </main>
    );
}

export default HomeMobile;
