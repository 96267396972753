export const headerLinks = [
    {
        name: "Home",
        href: "/",
    },
    {
        name: "Security & Privacy",
        href: "/building-trust",
    },
    {
        name: "Readiness Check",
        href: `https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=dev.peersupport.keelmind.com`,
        target: "_blank",
        rel: "noreferrer",
    },
    {
        name: "Connect to a Peer Supporter",
        href: "/peer-connect",
    },
    // {
    //     name: "Crisis Support",
    //     href: "/crisis-support",
    // },
];

export const footerLinks = {
    "Need Help?": [
        { name: "Crisis Support", href: "/crisis-support" },
        // { name: "Anonymous Options", href: "/anonymous-options" },
    ],
    Legal: [
        {
            name: "Privacy Policy",
            href: "https://www.keel.digital/privacy-policy",
        },
        {
            name: "Terms of Use",
            href: "https://www.keel.digital/terms-of-use",
        },
        {
            name: "Terms & Conditions",
            href: "https://www.keel.digital/terms-and-conditions",
        },
    ],
};
