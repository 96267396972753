function LaptopBubbleSVG({ className }) {
    return (
        <svg
            width="92"
            height="96"
            viewBox="0 0 92 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g opacity="0.5">
                <mask
                    id="mask0_6587_35280"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="2"
                    y="3"
                    width="32"
                    height="19"
                >
                    <path
                        d="M19.8487 20.5109C28.4382 18.9127 34.7103 13.9031 33.8579 9.32155C33.0054 4.74003 25.3512 2.32155 16.7617 3.91972C8.17216 5.5179 1.90003 10.5275 2.75247 15.1091C3.60492 19.6906 11.2591 22.1091 19.8487 20.5109Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_6587_35280)">
                    <mask
                        id="mask1_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="28"
                        y="3"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M41.5414 3.17181H28.7793V13.2767H41.5414V3.17181Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask1_6587_35280)">
                        <path
                            d="M34.8276 10.7478L34.0973 11.1687L33.3669 10.7478H34.8276Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M33.3669 13.2732L34.0973 13.6982L34.8276 13.2732L34.0973 12.8523L33.3669 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.7028 10.7478L31.9684 11.1687L31.238 10.7478H32.7028Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.238 13.2732L31.9684 13.6982L32.6987 13.2732L31.9684 12.8523L31.238 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3025 12.0095L33.0328 12.4304L33.7631 12.0095L33.0328 11.5886L32.3025 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5737 10.7478L29.8434 11.1687L29.113 10.7478H30.5737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.1091 13.2732L29.8436 13.6982L30.5739 13.2732L29.8436 12.8523L29.1091 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1736 12.0095L30.9039 12.4304L31.6384 12.0095L30.9039 11.5886L30.1736 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7791 12.4304L29.5094 12.0095L28.7791 11.5886V12.4304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8276 5.69702L34.0973 6.11789L33.3669 5.69702H34.8276Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M33.3669 8.22238L34.0973 8.64325L34.8276 8.22238L34.0973 7.80151L33.3669 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8276 10.7468H33.3669L34.0973 10.3259L34.8276 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.7028 5.69702L31.9684 6.11789L31.238 5.69702H32.7028Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.238 8.22238L31.9684 8.64325L32.6987 8.22238L31.9684 7.80151L31.238 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3025 6.95991L33.0328 7.38077L33.7631 6.95991L33.0328 6.53491L32.3025 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.6987 10.7468H31.238L31.9684 10.3259L32.6987 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3025 9.48507L33.0328 9.90594L33.7631 9.48507L33.0328 9.06421L32.3025 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5737 5.69702L29.8434 6.11789L29.113 5.69702H30.5737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.1091 8.22238L29.8436 8.64325L30.5739 8.22238L29.8436 7.80151L29.1091 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1738 6.95985L30.9042 7.38071L31.6386 6.95985L30.9042 6.53485L30.1738 6.95985Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7791 7.38071L29.5094 6.95985L28.7791 6.53485V7.38071Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7791 9.90594L29.5094 9.48507L28.7791 9.06421V9.90594Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5739 10.7468H29.1091L29.8436 10.3259L30.5739 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1736 9.48507L30.9039 9.90594L31.6384 9.48507L30.9039 9.06421L30.1736 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M33.3669 3.16769L34.0973 3.59268L34.8276 3.16769L34.0973 2.74683L33.3669 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8276 5.69721H33.3669L34.0973 5.27222L34.8276 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.238 3.16769L31.9684 3.59268L32.6987 3.16769L31.9684 2.74683L31.238 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.6987 5.69703H31.238L31.9684 5.27203L32.6987 5.69703Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3025 4.43451L33.0328 4.85538L33.7631 4.43451L33.0328 4.00952L32.3025 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.1091 3.16769L29.8436 3.59268L30.5739 3.16769L29.8436 2.74683L29.1091 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7791 4.8555L29.5094 4.43464L28.7791 4.00964V4.8555Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5739 5.69703H29.1091L29.8436 5.27203L30.5739 5.69703Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1738 4.43464L30.9042 4.8555L31.6386 4.43464L30.9042 4.00964L30.1738 4.43464Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask2_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="28"
                        y="13"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M41.5412 13.2722H28.7791V23.3771H41.5412V13.2722Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask2_6587_35280)">
                        <path
                            d="M34.8274 20.8525L34.097 21.2733L33.3667 20.8525H34.8274Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.7026 20.8525L31.9681 21.2733L31.2378 20.8525H32.7026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5734 20.8525L29.8431 21.2733L29.1128 20.8525H30.5734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8274 15.8017L34.097 16.2226L33.3667 15.8017H34.8274Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M33.3667 18.3273L34.097 18.7481L34.8274 18.3273L34.097 17.9023L33.3667 18.3273Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8274 20.8515H33.3667L34.097 20.4306L34.8274 20.8515Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.7026 15.8015L31.9681 16.2223L31.2378 15.8015H32.7026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.2378 18.3273L31.9681 18.7481L32.6984 18.3273L31.9681 17.9023L31.2378 18.3273Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3022 17.0646L33.0326 17.4854L33.7629 17.0646L33.0326 16.6396L32.3022 17.0646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.6984 20.8515H31.2378L31.9681 20.4306L32.6984 20.8515Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3022 19.589L33.0326 20.0099L33.7629 19.589L33.0326 19.164L32.3022 19.589Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5734 15.8015L29.8431 16.2223L29.1128 15.8015H30.5734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.1089 18.3273L29.8433 18.7481L30.5737 18.3273L29.8433 17.9023L29.1089 18.3273Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1736 17.0646L30.9039 17.4854L31.6384 17.0646L30.9039 16.6396L30.1736 17.0646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7788 17.4854L29.5091 17.0646L28.7788 16.6396V17.4854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7788 20.0099L29.5091 19.589L28.7788 19.164V20.0099Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5737 20.8515H29.1089L29.8433 20.4306L30.5737 20.8515Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1733 19.589L30.9037 20.0099L31.6381 19.589L30.9037 19.164L30.1733 19.589Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M33.3667 13.2724L34.097 13.6974L34.8274 13.2724L34.097 12.8515L33.3667 13.2724Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8274 15.8019H33.3667L34.097 15.3769L34.8274 15.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.2378 13.2724L31.9681 13.6974L32.6984 13.2724L31.9681 12.8516L31.2378 13.2724Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.6984 15.8018H31.2378L31.9681 15.3768L32.6984 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.3022 14.5351L33.0326 14.9601L33.7629 14.5351L33.0326 14.1142L32.3022 14.5351Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.1089 13.2724L29.8433 13.6974L30.5737 13.2724L29.8433 12.8516L29.1089 13.2724Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7788 14.9602L29.5091 14.5352L28.7788 14.1144V14.9602Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.5737 15.8018H29.1089L29.8433 15.3768L30.5737 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.1736 14.5352L30.9039 14.9602L31.6384 14.5352L30.9039 14.1144L30.1736 14.5352Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask3_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="16"
                        y="3"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M28.7739 3.17181H16.0117V13.2767H28.7739V3.17181Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask3_6587_35280)">
                        <path
                            d="M28.445 10.7478L27.7147 11.1687L26.9844 10.7478H28.445Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9844 13.2732L27.7147 13.6982L28.445 13.2732L27.7147 12.8523L26.9844 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7772 11.5886V12.4345L28.0469 12.0095L28.7772 11.5886Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3183 10.7478L25.588 11.1687L24.8535 10.7478H26.3183Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.8535 13.2732L25.5838 13.6982L26.3183 13.2732L25.5838 12.8523L24.8535 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.918 12.0095L26.6483 12.4304L27.3786 12.0095L26.6483 11.5886L25.918 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1892 10.7478L23.4588 11.1687L22.7285 10.7478H24.1892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7285 13.2732L23.4588 13.6982L24.1892 13.2732L23.4588 12.8523L22.7285 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.7891 12.0095L24.5235 12.4304L25.2538 12.0095L24.5235 11.5886L23.7891 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0644 10.7478L21.3299 11.1687L20.5996 10.7478H22.0644Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.5996 13.2732L21.3299 13.6982L22.0644 13.2732L21.3299 12.8523L20.5996 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.6641 12.0095L22.3944 12.4304L23.1247 12.0095L22.3944 11.5886L21.6641 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9353 10.7478L19.2049 11.1687L18.4746 10.7478H19.9353Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4746 13.2732L19.2049 13.6982L19.9353 13.2732L19.2049 12.8523L18.4746 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5352 12.0095L20.2696 12.4304L20.9999 12.0095L20.2696 11.5886L19.5352 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8105 10.7478L17.076 11.1687L16.3457 10.7478H17.8105Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.3457 13.2732L17.076 13.6982L17.8064 13.2732L17.076 12.8523L16.3457 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4102 12.0095L18.1405 12.4304L18.8708 12.0095L18.1405 11.5886L17.4102 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0117 12.4304L16.7462 12.0095L16.0117 11.5886V12.4304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.445 5.69702L27.7147 6.11789L26.9844 5.69702H28.445Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9844 8.22238L27.7147 8.64325L28.445 8.22238L27.7147 7.80151L26.9844 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7772 6.53882V7.38055L28.0469 6.95969L28.7772 6.53882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.445 10.7468H26.9844L27.7147 10.3259L28.445 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7772 9.06421V9.90594L28.0469 9.48507L28.7772 9.06421Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3183 5.69702L25.588 6.11789L24.8535 5.69702H26.3183Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.8535 8.22238L25.5838 8.64325L26.3183 8.22238L25.5838 7.80151L24.8535 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.918 6.95991L26.6483 7.38077L27.3786 6.95991L26.6483 6.53491L25.918 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3183 10.7468H24.8535L25.5838 10.3259L26.3183 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.918 9.48507L26.6483 9.90594L27.3786 9.48507L26.6483 9.06421L25.918 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1892 5.69702L23.4588 6.11789L22.7285 5.69702H24.1892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7285 8.22238L23.4588 8.64325L24.1892 8.22238L23.4588 7.80151L22.7285 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.7891 6.95991L24.5235 7.38077L25.2538 6.95991L24.5235 6.53491L23.7891 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1892 10.7468H22.7285L23.4588 10.3259L24.1892 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.7891 9.48507L24.5235 9.90594L25.2538 9.48507L24.5235 9.06421L23.7891 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0644 5.69702L21.3299 6.11789L20.5996 5.69702H22.0644Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.5996 8.22238L21.3299 8.64325L22.0644 8.22238L21.3299 7.80151L20.5996 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.6641 6.95991L22.3944 7.38077L23.1247 6.95991L22.3944 6.53491L21.6641 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0644 10.7468H20.5996L21.3299 10.3259L22.0644 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.6641 9.48507L22.3944 9.90594L23.1247 9.48507L22.3944 9.06421L21.6641 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9353 5.69702L19.2049 6.11789L18.4746 5.69702H19.9353Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4746 8.22238L19.2049 8.64325L19.9353 8.22238L19.2049 7.80151L18.4746 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5352 6.95991L20.2696 7.38077L20.9999 6.95991L20.2696 6.53491L19.5352 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9353 10.7468H18.4746L19.2049 10.3259L19.9353 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5352 9.48507L20.2696 9.90594L20.9999 9.48507L20.2696 9.06421L19.5352 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8105 5.69702L17.076 6.11789L16.3457 5.69702H17.8105Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.3457 8.22238L17.076 8.64325L17.8064 8.22238L17.076 7.80151L16.3457 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4102 6.95991L18.1405 7.38077L18.8708 6.95991L18.1405 6.53491L17.4102 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0117 7.38071L16.7462 6.95985L16.0117 6.53485V7.38071Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0117 9.90594L16.7462 9.48507L16.0117 9.06421V9.90594Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8064 10.7468H16.3457L17.076 10.3259L17.8064 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4102 9.48507L18.1405 9.90594L18.8708 9.48507L18.1405 9.06421L17.4102 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9824 3.16769L27.7128 3.59268L28.4431 3.16769L27.7128 2.74683L26.9824 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.4431 5.69696H26.9824L27.7128 5.27197L28.4431 5.69696Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7772 4.00952V4.85538L28.0469 4.43451L28.7772 4.00952Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.8555 3.16769L25.5858 3.59268L26.3202 3.16769L25.5858 2.74683L24.8555 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3202 5.69721H24.8555L25.5858 5.27222L26.3202 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.918 4.43451L26.6483 4.85538L27.3786 4.43451L26.6483 4.00952L25.918 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7305 3.16769L23.4608 3.59268L24.1911 3.16769L23.4608 2.74683L22.7305 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1911 5.69721H22.7305L23.4608 5.27222L24.1911 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.7891 4.43451L24.5235 4.85538L25.2538 4.43451L24.5235 4.00952L23.7891 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.5996 3.16769L21.3299 3.59268L22.0644 3.16769L21.3299 2.74683L20.5996 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0644 5.69721H20.5996L21.3299 5.27222L22.0644 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.6641 4.43451L22.3944 4.85538L23.1247 4.43451L22.3944 4.00952L21.6641 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4746 3.16769L19.2049 3.59268L19.9353 3.16769L19.2049 2.74683L18.4746 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9353 5.69721H18.4746L19.2049 5.27222L19.9353 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5352 4.43451L20.2696 4.85538L20.9999 4.43451L20.2696 4.00952L19.5352 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.3457 3.16769L17.076 3.59268L17.8064 3.16769L17.076 2.74683L16.3457 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0117 4.8555L16.7462 4.43464L16.0117 4.00964V4.8555Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8064 5.69703H16.3457L17.076 5.27203L17.8064 5.69703Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4102 4.43451L18.1405 4.85538L18.8708 4.43451L18.1405 4.00952L17.4102 4.43451Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask4_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="16"
                        y="13"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M28.7758 13.2722H16.0137V23.3771H28.7758V13.2722Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask4_6587_35280)">
                        <path
                            d="M28.445 20.8524L27.7147 21.2733L26.9844 20.8524H28.445Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3202 20.8524L25.5899 21.2733L24.8555 20.8524H26.3202Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1911 20.8524L23.4608 21.2733L22.7305 20.8524H24.1911Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0663 20.8524L21.3319 21.2733L20.6016 20.8524H22.0663Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9372 20.8524L19.2069 21.2733L18.4766 20.8524H19.9372Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8124 20.8524L17.078 21.2733L16.3477 20.8524H17.8124Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.445 15.8014L27.7147 16.2223L26.9844 15.8014H28.445Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9844 18.3272L27.7147 18.7481L28.445 18.3272L27.7147 17.9022L26.9844 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7811 16.6395V17.4854L28.0508 17.0645L28.7811 16.6395Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.445 20.8514H26.9844L27.7147 20.4305L28.445 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7811 19.1688V20.0106L28.0508 19.5897L28.7811 19.1688Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3202 15.8016L25.5899 16.2225L24.8555 15.8016H26.3202Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.8555 18.3272L25.5858 18.7481L26.3202 18.3272L25.5858 17.9022L24.8555 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9219 17.0645L26.6522 17.4854L27.3825 17.0645L26.6522 16.6395L25.9219 17.0645Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3202 20.8514H24.8555L25.5858 20.4305L26.3202 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9199 19.5889L26.6503 20.0098L27.3806 19.5889L26.6503 19.1639L25.9199 19.5889Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1911 15.8016L23.4608 16.2225L22.7305 15.8016H24.1911Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7305 18.3272L23.4608 18.7481L24.1911 18.3272L23.4608 17.9022L22.7305 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.793 17.0645L24.5274 17.4854L25.2577 17.0645L24.5274 16.6395L23.793 17.0645Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1911 20.8514H22.7305L23.4608 20.4305L24.1911 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.791 19.5889L24.5255 20.0098L25.2558 19.5889L24.5255 19.1639L23.791 19.5889Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0663 15.8016L21.3319 16.2225L20.6016 15.8016H22.0663Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.6016 18.3272L21.3319 18.7481L22.0663 18.3272L21.3319 17.9022L20.6016 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.666 17.0645L22.3963 17.4854L23.1267 17.0645L22.3963 16.6395L21.666 17.0645Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0663 20.8514H20.6016L21.3319 20.4305L22.0663 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.666 19.5889L22.3963 20.0098L23.1267 19.5889L22.3963 19.1639L21.666 19.5889Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9372 15.8016L19.2069 16.2225L18.4766 15.8016H19.9372Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4766 18.3272L19.2069 18.7481L19.9372 18.3272L19.2069 17.9022L18.4766 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5371 17.0645L20.2716 17.4854L21.0019 17.0645L20.2716 16.6395L19.5371 17.0645Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9372 20.8514H18.4766L19.2069 20.4305L19.9372 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5371 19.5889L20.2716 20.0098L21.0019 19.5889L20.2716 19.1639L19.5371 19.5889Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8124 15.8014L17.078 16.2223L16.3477 15.8014H17.8124Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.3477 18.3272L17.078 18.7481L17.8083 18.3272L17.078 17.9022L16.3477 18.3272Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4121 17.0645L18.1424 17.4854L18.8728 17.0645L18.1424 16.6395L17.4121 17.0645Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0137 17.4854L16.7481 17.0645L16.0137 16.6395V17.4854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0137 20.0098L16.7481 19.5889L16.0137 19.1639V20.0098Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8083 20.8514H16.3477L17.078 20.4305L17.8083 20.8514Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4121 19.5889L18.1424 20.0098L18.8728 19.5889L18.1424 19.1639L17.4121 19.5889Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9844 13.2723L27.7147 13.6973L28.445 13.2723L27.7147 12.8514L26.9844 13.2723Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.445 15.8017H26.9844L27.7147 15.3767L28.445 15.8017Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.7811 14.1141V14.96L28.0508 14.535L28.7811 14.1141Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.8555 13.2723L25.5858 13.6973L26.3202 13.2723L25.5858 12.8514L24.8555 13.2723Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.3202 15.8018H24.8555L25.5858 15.3768L26.3202 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9219 14.535L26.6522 14.96L27.3825 14.535L26.6522 14.1141L25.9219 14.535Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7305 13.2723L23.4608 13.6973L24.1911 13.2723L23.4608 12.8514L22.7305 13.2723Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.1911 15.8018H22.7305L23.4608 15.3768L24.1911 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.793 14.535L24.5274 14.96L25.2577 14.535L24.5274 14.1141L23.793 14.535Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.6016 13.2723L21.3319 13.6973L22.0663 13.2723L21.3319 12.8514L20.6016 13.2723Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.0663 15.8018H20.6016L21.3319 15.3768L22.0663 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.666 14.535L22.3963 14.96L23.1267 14.535L22.3963 14.1141L21.666 14.535Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4766 13.2723L19.2069 13.6973L19.9372 13.2723L19.2069 12.8514L18.4766 13.2723Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.9372 15.8018H18.4766L19.2069 15.3768L19.9372 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.5371 14.535L20.2716 14.96L21.0019 14.535L20.2716 14.1141L19.5371 14.535Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.3477 13.2724L17.078 13.6974L17.8083 13.2724L17.078 12.8515L16.3477 13.2724Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0137 14.9602L16.7481 14.5352L16.0137 14.1143V14.9602Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.8083 15.8017H16.3477L17.078 15.3767L17.8083 15.8017Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.4121 14.535L18.1424 14.96L18.8728 14.535L18.1424 14.1141L17.4121 14.535Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask5_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="3"
                        y="3"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M16.0121 3.17181H3.25V13.2767H16.0121V3.17181Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask5_6587_35280)">
                        <path
                            d="M15.6816 10.7478L14.9512 11.1687L14.2168 10.7478H15.6816Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.2168 13.2732L14.9512 13.6982L15.6816 13.2732L14.9512 12.8523L14.2168 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 11.5886V12.4345L15.2812 12.0095L16.0116 11.5886Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5525 10.7478L12.8221 11.1687L12.0918 10.7478H13.5525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.0918 13.2732L12.8221 13.6982L13.5525 13.2732L12.8221 12.8523L12.0918 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 12.0095L13.8866 12.4304L14.6169 12.0095L13.8866 11.5886L13.1562 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 10.7478L10.6973 11.1687L9.96289 10.7478H11.4277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.96289 13.2732L10.6932 13.6982L11.4277 13.2732L10.6932 12.8523L9.96289 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 12.0095L11.7577 12.4304L12.488 12.0095L11.7577 11.5886L11.0273 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 10.7478L8.56822 11.1687L7.83789 10.7478H9.29854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.83789 13.2732L8.56822 13.6982L9.29854 13.2732L8.56822 12.8523L7.83789 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 12.0095L9.63289 12.4304L10.3632 12.0095L9.63289 11.5886L8.89844 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.17376 10.7478L6.43931 11.1687L5.70898 10.7478H7.17376Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.70898 13.2732L6.43931 13.6982L7.16964 13.2732L6.43931 12.8523L5.70898 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 12.0095L7.50376 12.4304L8.23409 12.0095L7.50376 11.5886L6.77344 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04464 10.7478L4.31431 11.1687L3.58398 10.7478H5.04464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.58008 13.2732L4.31453 13.6982L5.04486 13.2732L4.31453 12.8523L3.58008 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64453 12.0095L5.37486 12.4304L6.10931 12.0095L5.37486 11.5886L4.64453 12.0095Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 12.4304L3.98033 12.0095L3.25 11.5886V12.4304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 5.69702L14.9512 6.11789L14.2168 5.69702H15.6816Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.2168 8.22238L14.9512 8.64325L15.6816 8.22238L14.9512 7.80151L14.2168 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 6.53882V7.38055L15.2812 6.95969L16.0116 6.53882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 10.7468H14.2168L14.9512 10.3259L15.6816 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 9.06421V9.90594L15.2812 9.48507L16.0116 9.06421Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5525 5.69702L12.8221 6.11789L12.0918 5.69702H13.5525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.0918 8.22238L12.8221 8.64325L13.5525 8.22238L12.8221 7.80151L12.0918 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 6.95991L13.8866 7.38077L14.6169 6.95991L13.8866 6.53491L13.1562 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5525 10.7468H12.0918L12.8221 10.3259L13.5525 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 9.48507L13.8866 9.90594L14.6169 9.48507L13.8866 9.06421L13.1562 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 5.69702L10.6973 6.11789L9.96289 5.69702H11.4277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.96289 8.22238L10.6932 8.64325L11.4277 8.22238L10.6932 7.80151L9.96289 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 6.95991L11.7577 7.38077L12.488 6.95991L11.7577 6.53491L11.0273 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 10.7468H9.96289L10.6932 10.3259L11.4277 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 9.48507L11.7577 9.90594L12.488 9.48507L11.7577 9.06421L11.0273 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 5.69702L8.56822 6.11789L7.83789 5.69702H9.29854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.83789 8.22238L8.56822 8.64325L9.29854 8.22238L8.56822 7.80151L7.83789 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 6.95991L9.63289 7.38077L10.3632 6.95991L9.63289 6.53491L8.89844 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 10.7468H7.83789L8.56822 10.3259L9.29854 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 9.48507L9.63289 9.90594L10.3632 9.48507L9.63289 9.06421L8.89844 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.17376 5.69702L6.43931 6.11789L5.70898 5.69702H7.17376Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.70898 8.22238L6.43931 8.64325L7.16964 8.22238L6.43931 7.80151L5.70898 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 6.95991L7.50376 7.38077L8.23409 6.95991L7.50376 6.53491L6.77344 6.95991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.16964 10.7468H5.70898L6.43931 10.3259L7.16964 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 9.48507L7.50376 9.90594L8.23409 9.48507L7.50376 9.06421L6.77344 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04464 5.69702L4.31431 6.11789L3.58398 5.69702H5.04464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.58008 8.22238L4.31453 8.64325L5.04486 8.22238L4.31453 7.80151L3.58008 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64478 6.95985L5.3751 7.38071L6.10955 6.95985L5.3751 6.53485L4.64478 6.95985Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 7.38071L3.98033 6.95985L3.25 6.53485V7.38071Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 9.90594L3.98033 9.48507L3.25 9.06421V9.90594Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04486 10.7468H3.58008L4.31453 10.3259L5.04486 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64453 9.48507L5.37486 9.90594L6.10931 9.48507L5.37486 9.06421L4.64453 9.48507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.2168 3.16769L14.9512 3.59268L15.6816 3.16769L14.9512 2.74683L14.2168 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 5.69721H14.2168L14.9512 5.27222L15.6816 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 4.00952V4.85538L15.2812 4.43451L16.0116 4.00952Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.0938 3.16769L12.8241 3.59268L13.5544 3.16769L12.8241 2.74683L12.0938 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5544 5.69721H12.0938L12.8241 5.27222L13.5544 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 4.43451L13.8866 4.85538L14.6169 4.43451L13.8866 4.00952L13.1562 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.96289 3.16769L10.6932 3.59268L11.4277 3.16769L10.6932 2.74683L9.96289 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 5.69721H9.96289L10.6932 5.27222L11.4277 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 4.43451L11.7577 4.85538L12.488 4.43451L11.7577 4.00952L11.0273 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.83789 3.16769L8.56822 3.59268L9.29854 3.16769L8.56822 2.74683L7.83789 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 5.69721H7.83789L8.56822 5.27222L9.29854 5.69721Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 4.43451L9.63289 4.85538L10.3632 4.43451L9.63289 4.00952L8.89844 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.70923 3.16769L6.43956 3.59268L7.16988 3.16769L6.43956 2.74683L5.70923 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.16988 5.69703H5.70923L6.43956 5.27203L7.16988 5.69703Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 4.43451L7.50376 4.85538L8.23409 4.43451L7.50376 4.00952L6.77344 4.43451Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.58008 3.16769L4.31453 3.59268L5.04486 3.16769L4.31453 2.74683L3.58008 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 4.8555L3.98033 4.43464L3.25 4.00964V4.8555Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04486 5.69703H3.58008L4.31453 5.27203L5.04486 5.69703Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64478 4.43464L5.3751 4.8555L6.10955 4.43464L5.3751 4.00964L4.64478 4.43464Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask6_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="3"
                        y="13"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M16.0121 13.2724H3.25V23.3773H16.0121V13.2724Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask6_6587_35280)">
                        <path
                            d="M15.6816 20.8527L14.9512 21.2735L14.2168 20.8527H15.6816Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5525 20.8527L12.8221 21.2735L12.0918 20.8527H13.5525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 20.8527L10.6973 21.2735L9.96289 20.8527H11.4277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 20.8527L8.56822 21.2735L7.83789 20.8527H9.29854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.17376 20.8527L6.43931 21.2735L5.70898 20.8527H7.17376Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04464 20.8527L4.31431 21.2735L3.58398 20.8527H5.04464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 15.8019L14.9512 16.2227L14.2168 15.8019H15.6816Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.2168 18.3275L14.9512 18.7483L15.6816 18.3275L14.9512 17.9025L14.2168 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 16.6398V17.4856L15.2812 17.0648L16.0116 16.6398Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 20.8517H14.2168L14.9512 20.4308L15.6816 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 19.1691V20.0108L15.2812 19.5899L16.0116 19.1691Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5544 15.8019L12.8241 16.2227L12.0938 15.8019H13.5544Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.0918 18.3275L12.8221 18.7483L13.5525 18.3275L12.8221 17.9025L12.0918 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 17.0648L13.8866 17.4856L14.6169 17.0648L13.8866 16.6398L13.1562 17.0648Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5525 20.8517H12.0918L12.8221 20.4308L13.5525 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 19.5892L13.8866 20.01L14.6169 19.5892L13.8866 19.1642L13.1562 19.5892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 15.8019L10.6973 16.2227L9.96289 15.8019H11.4277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.96289 18.3275L10.6932 18.7483L11.4277 18.3275L10.6932 17.9025L9.96289 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 17.0648L11.7577 17.4856L12.488 17.0648L11.7577 16.6398L11.0273 17.0648Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 20.8517H9.96289L10.6932 20.4308L11.4277 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 19.5892L11.7577 20.01L12.488 19.5892L11.7577 19.1642L11.0273 19.5892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 15.8019L8.56822 16.2227L7.83789 15.8019H9.29854Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.83789 18.3275L8.56822 18.7483L9.29854 18.3275L8.56822 17.9025L7.83789 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 17.0648L9.63289 17.4856L10.3632 17.0648L9.63289 16.6398L8.89844 17.0648Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 20.8517H7.83789L8.56822 20.4308L9.29854 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 19.5892L9.63289 20.01L10.3632 19.5892L9.63289 19.1642L8.89844 19.5892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.17401 15.8016L6.43956 16.2225L5.70923 15.8016H7.17401Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.70898 18.3275L6.43931 18.7483L7.16964 18.3275L6.43931 17.9025L5.70898 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 17.0648L7.50376 17.4856L8.23409 17.0648L7.50376 16.6398L6.77344 17.0648Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.16964 20.8517H5.70898L6.43931 20.4308L7.16964 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 19.5892L7.50376 20.01L8.23409 19.5892L7.50376 19.1642L6.77344 19.5892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04464 15.8016L4.31431 16.2225L3.58398 15.8016H5.04464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.58008 18.3275L4.31453 18.7483L5.04486 18.3275L4.31453 17.9025L3.58008 18.3275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64478 17.0648L5.3751 17.4856L6.10955 17.0648L5.3751 16.6398L4.64478 17.0648Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 17.4856L3.98033 17.0648L3.25 16.6398V17.4856Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 20.01L3.98033 19.5892L3.25 19.1642V20.01Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04486 20.8517H3.58008L4.31453 20.4308L5.04486 20.8517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64453 19.5892L5.37486 20.01L6.10931 19.5892L5.37486 19.1642L4.64453 19.5892Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.2168 13.2725L14.9512 13.6975L15.6816 13.2725L14.9512 12.8517L14.2168 13.2725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.6816 15.8021H14.2168L14.9512 15.3771L15.6816 15.8021Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.0116 14.1144V14.9602L15.2812 14.5352L16.0116 14.1144Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.0938 13.2725L12.8241 13.6975L13.5544 13.2725L12.8241 12.8517L12.0938 13.2725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.5544 15.8021H12.0938L12.8241 15.3771L13.5544 15.8021Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1562 14.5352L13.8866 14.9602L14.6169 14.5352L13.8866 14.1144L13.1562 14.5352Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.96289 13.2725L10.6932 13.6975L11.4277 13.2725L10.6932 12.8517L9.96289 13.2725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.4277 15.8021H9.96289L10.6932 15.3771L11.4277 15.8021Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.0273 14.5352L11.7577 14.9602L12.488 14.5352L11.7577 14.1144L11.0273 14.5352Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.83789 13.2725L8.56822 13.6975L9.29854 13.2725L8.56822 12.8517L7.83789 13.2725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.29854 15.8021H7.83789L8.56822 15.3771L9.29854 15.8021Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.89844 14.5352L9.63289 14.9602L10.3632 14.5352L9.63289 14.1144L8.89844 14.5352Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.70923 13.2726L6.43956 13.6976L7.16988 13.2726L6.43956 12.8517L5.70923 13.2726Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.16988 15.8019H5.70923L6.43956 15.377L7.16988 15.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M6.77344 14.5352L7.50376 14.9602L8.23409 14.5352L7.50376 14.1144L6.77344 14.5352Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.58008 13.2726L4.31453 13.6976L5.04486 13.2726L4.31453 12.8517L3.58008 13.2726Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.25 14.9604L3.98033 14.5354L3.25 14.1146V14.9604Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M5.04486 15.8019H3.58008L4.31453 15.377L5.04486 15.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M4.64478 14.5354L5.3751 14.9604L6.10955 14.5354L5.3751 14.1146L4.64478 14.5354Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask7_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="-10"
                        y="3"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M3.24652 3.17181H-9.51562V13.2767H3.24652V3.17181Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask7_6587_35280)">
                        <path
                            d="M2.91768 10.7478L2.18736 11.1687L1.45703 10.7478H2.91768Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M1.45703 13.2732L2.18736 13.6982L2.91768 13.2732L2.18736 12.8523L1.45703 13.2732Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 11.5886V12.4345L2.51953 12.0095L3.24986 11.5886Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91768 5.69702L2.18736 6.11789L1.45703 5.69702H2.91768Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M1.45703 8.22238L2.18736 8.64325L2.91768 8.22238L2.18736 7.80151L1.45703 8.22238Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 6.53882V7.38055L2.51953 6.95969L3.24986 6.53882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91768 10.7468H1.45703L2.18736 10.3259L2.91768 10.7468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 9.06421V9.90594L2.51953 9.48507L3.24986 9.06421Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M1.45508 3.16769L2.1854 3.59268L2.91573 3.16769L2.1854 2.74683L1.45508 3.16769Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91573 5.69696H1.45508L2.1854 5.27197L2.91573 5.69696Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 4.00952V4.85538L2.51953 4.43451L3.24986 4.00952Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask8_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="-10"
                        y="13"
                        width="14"
                        height="11"
                    >
                        <path
                            d="M3.24652 13.2722H-9.51562V23.3771H3.24652V13.2722Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask8_6587_35280)">
                        <path
                            d="M2.91768 20.8525L2.18736 21.2733L1.45703 20.8525H2.91768Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91573 15.8015L2.1854 16.2223L1.45508 15.8015H2.91573Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M1.45703 18.3273L2.18736 18.7481L2.91768 18.3273L2.18736 17.9023L1.45703 18.3273Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 16.6396V17.4854L2.51953 17.0646L3.24986 16.6396Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91768 20.8515H1.45703L2.18736 20.4306L2.91768 20.8515Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 19.1689V20.0106L2.51953 19.5898L3.24986 19.1689Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M1.45508 13.2724L2.1854 13.6974L2.91573 13.2724L2.1854 12.8515L1.45508 13.2724Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M2.91573 15.8018H1.45508L2.1854 15.3768L2.91573 15.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M3.24986 14.1142V14.9601L2.51953 14.5351L3.24986 14.1142Z"
                            fill="#1D3260"
                        />
                    </g>
                </g>
            </g>
            <path
                d="M26.2041 17.6194C24.8227 17.2161 23.3816 17.437 21.5759 18.7308C21.2183 19.4952 20.78 20.3332 20.2891 21.1571L20.1384 19.4987L20.1033 18.857C20.0227 17.4546 18.9848 16.9321 17.7296 17.6579L14.7458 19.3795V18.1768C15.5557 17.1635 16.1027 15.8312 16.1027 14.6671V12.9771C16.1027 11.238 14.879 10.5298 13.3714 11.3993C13.1014 11.5501 12.8454 11.7464 12.6 11.9708C12.5334 11.0768 11.8602 10.7331 11.0362 11.21C10.1702 11.7114 9.46545 12.928 9.46545 13.9308C9.46545 14.8389 10.0475 15.2597 10.8048 14.9511C10.6961 15.3508 10.6365 15.7505 10.6365 16.1327V17.8192C10.6365 18.9833 11.18 19.6845 11.9934 19.7617V20.9643L8.9325 22.7314C7.65273 23.4712 6.60087 25.2384 6.53425 26.7495L6.38699 29.2179C6.34492 29.1969 6.30284 29.1723 6.26427 29.1478L6.23272 29.1303C5.64718 28.7867 5.12826 28.3098 4.68998 27.6962C1.90605 23.7868 3.51891 15.7751 8.30138 9.8005C12.302 4.79713 17.2528 2.8091 20.3943 4.59377L20.4259 4.6113C21.0219 4.95491 21.5514 5.43876 22.0002 6.06287C23.4833 8.14556 23.7182 11.3888 22.8943 14.867C25.5905 14.5654 26.2076 17.6053 26.2111 17.6194H26.2181H26.2041Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.0962 3.27208L20.3893 4.59392C17.2512 2.81276 12.3004 4.80079 8.29633 9.80065C3.51735 15.7752 1.90099 23.7869 4.68492 27.6964C5.1232 28.31 5.64212 28.7868 6.22766 29.1304L3.9381 27.8086C3.34204 27.465 2.81261 26.9811 2.36381 26.357C-0.420124 22.4476 1.19273 14.4359 5.97521 8.46127C9.98983 3.44037 14.9581 1.45936 18.0962 3.26857V3.27208Z"
                fill="varI--light-blue);"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.14 19.4984L20.2908 21.1569C19.7018 22.1526 19.0496 23.1309 18.3834 23.9619C14.4214 28.9161 9.53024 30.9147 6.38867 29.2247L6.53593 26.7563C6.60255 25.2416 7.65441 23.4745 8.93418 22.7382L10.3437 21.9247L10.3647 21.9598C10.5225 23.6218 11.8093 24.2494 13.3731 23.3483C14.9298 22.4507 16.2131 20.3505 16.3744 18.5097L16.3463 18.4606L17.7313 17.6612C18.9865 16.9389 20.0243 17.4648 20.105 18.8603L20.14 19.502V19.4984Z"
                fill="var(--secondary-color)"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3464 18.4571L16.3744 18.5062C16.2061 20.3504 14.9264 22.4471 13.3731 23.3447C11.8129 24.2458 10.5226 23.6217 10.3648 21.9563L10.3438 21.9212L11.9952 20.9675V19.7649C11.1852 19.6842 10.6383 18.9865 10.6383 17.8224V16.9284C11.613 15.9256 12.4229 14.7861 13.012 13.8534C13.2364 13.4993 13.5274 13.2223 13.8254 13.0926C14.1199 12.9628 14.3864 12.9874 14.5582 13.1662C14.8563 13.4677 15.3331 13.7412 16.101 13.8394V14.6739C16.101 15.8379 15.5575 17.1668 14.7441 18.1836V19.3862L16.3464 18.4641V18.4571Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1006 12.9808V13.8328C15.3327 13.7346 14.8559 13.4611 14.5578 13.1596C14.386 12.9808 14.116 12.9562 13.825 13.086C13.5305 13.2157 13.236 13.4927 13.0116 13.8468C12.4225 14.7795 11.6126 15.9155 10.6379 16.9218V16.1293C10.6379 15.7472 10.6975 15.351 10.8062 14.9478C10.0488 15.2563 9.4668 14.8391 9.4668 13.9274C9.4668 12.9282 10.1715 11.708 11.0376 11.2066C11.8615 10.7333 12.5382 11.0734 12.6014 11.9675C12.8433 11.7431 13.1027 11.5502 13.3727 11.396C14.8804 10.5264 16.1041 11.2312 16.1041 12.9738L16.1006 12.9808Z"
                fill="#1D3260"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.2839 1V6.48372L20.895 12.4793V10.6281L19.0156 10.8244L20.895 8.85041V6.99913L31.2839 1ZM27.8969 6.1892C28.1283 6.05596 28.3176 5.72988 28.3176 5.46341C28.3176 5.19694 28.1283 5.08825 27.8969 5.22148C27.6655 5.35472 27.4761 5.6808 27.4761 5.94727C27.4761 6.21374 27.6655 6.32243 27.8969 6.1892ZM25.9579 7.30768C26.1893 7.17094 26.3787 6.84837 26.3787 6.57839C26.3787 6.30841 26.1893 6.20322 25.9579 6.33646C25.7265 6.4732 25.5407 6.79577 25.5407 7.06575C25.5407 7.33573 25.7265 7.44092 25.9579 7.30768ZM24.0225 8.42266C24.2539 8.28942 24.4432 7.96334 24.4432 7.69687C24.4432 7.4304 24.2539 7.32171 24.0225 7.45494C23.7911 7.59168 23.6018 7.91426 23.6018 8.18073C23.6018 8.4472 23.7911 8.55589 24.0225 8.42266Z"
                fill="#D9E1E8"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g opacity="0.51">
                <mask
                    id="mask9_6587_35280"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="7"
                    y="79"
                    width="46"
                    height="17"
                >
                    <path
                        d="M8.26056 79.2765C7.66451 79.5465 7.61191 80.374 8.17291 80.7176L32.8812 95.8749C33.1302 96.0292 33.4422 96.0362 33.6982 95.896C36.4365 94.3988 51.8674 85.9313 51.9901 85.2862C52.0918 84.7462 51.4957 82.2287 51.2047 81.0577C51.117 80.7035 50.8015 80.4511 50.4368 80.4406L8.62521 79.2064C8.49899 79.2029 8.37627 79.2274 8.26407 79.28L8.26056 79.2765Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask9_6587_35280)">
                    <mask
                        id="mask10_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M58.1386 71.8154H46.3262V81.1665H58.1386V71.8154Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask10_6587_35280)">
                        <path
                            d="M51.9254 78.8279L51.2487 79.2171L50.572 78.8279H51.9254Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.572 81.1636L51.2487 81.5563L51.9254 81.1636L51.2487 80.7744L50.572 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5564 79.9962L52.2331 80.3854L52.9098 79.9962L52.2331 79.6035L51.5564 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.9586 78.8281L49.2819 79.2173L48.6052 78.8281H49.9586Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6013 81.1636L49.278 81.5563L49.9547 81.1636L49.278 80.7744L48.6013 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5876 79.9962L50.2643 80.3854L50.941 79.9962L50.2643 79.6035L49.5876 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9879 78.8281L47.3112 79.2173L46.6345 78.8281H47.9879Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6345 81.1636L47.3112 81.5563L47.9879 81.1636L47.3112 80.7744L46.6345 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6189 79.9962L48.2956 80.3854L48.9723 79.9962L48.2956 79.6035L47.6189 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3259 80.3854L47.0026 79.9962L46.3259 79.6035V80.3854Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask11_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 81.1631H46.3262V90.5142H58.1386V81.1631Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask11_6587_35280)">
                        <path
                            d="M51.9257 88.1749L51.249 88.5676L50.5723 88.1749H51.9257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.5723 90.5137L51.249 90.9029L51.9257 90.5137L51.249 90.121L50.5723 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5566 89.3433L52.2333 89.736L52.91 89.3433L52.2333 88.9541L51.5566 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.9589 88.1748L49.2822 88.5675L48.6055 88.1748H49.9589Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6016 90.5138L49.2783 90.903L49.955 90.5138L49.2783 90.1211L48.6016 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 89.3433L50.2646 89.736L50.9413 89.3433L50.2646 88.9541L49.5879 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9882 88.1748L47.3115 88.5675L46.6348 88.1748H47.9882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 90.5138L47.3115 90.903L47.9882 90.5138L47.3115 90.1211L46.6348 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 89.3433L48.2958 89.736L48.9725 89.3433L48.2958 88.9541L47.6191 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 89.736L47.0029 89.3433L46.3262 88.9541V89.736Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.9257 83.501L51.249 83.8902L50.5723 83.501H51.9257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.5723 85.8363L51.249 86.229L51.9257 85.8363L51.249 85.4471L50.5723 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5566 84.6695L52.2333 85.0587L52.91 84.6695L52.2333 84.2803L51.5566 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.9257 88.1753H50.5723L51.249 87.7861L51.9257 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5566 87.007L52.2333 87.3961L52.91 87.007L52.2333 86.6143L51.5566 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.9589 83.501L49.2822 83.8902L48.6055 83.501H49.9589Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6016 85.8365L49.2783 86.2292L49.955 85.8365L49.2783 85.4473L48.6016 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 84.6695L50.2646 85.0587L50.9413 84.6695L50.2646 84.2803L49.5879 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.955 88.1753H48.6016L49.2783 87.7861L49.955 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 87.007L50.2646 87.3961L50.9413 87.007L50.2646 86.6143L49.5879 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9882 83.501L47.3115 83.8902L46.6348 83.501H47.9882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 85.8365L47.3115 86.2292L47.9882 85.8365L47.3115 85.4473L46.6348 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 84.6695L48.2958 85.0587L48.9725 84.6695L48.2958 84.2803L47.6191 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 85.0587L47.0029 84.6695L46.3262 84.2803V85.0587Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 87.3961L47.0029 87.007L46.3262 86.6143V87.3961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9882 88.1753H46.6348L47.3115 87.7861L47.9882 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 87.007L48.2958 87.3961L48.9725 87.007L48.2958 86.6143L47.6191 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.5723 81.1626L51.249 81.5553L51.9257 81.1626L51.249 80.7734L50.5723 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.9257 83.5013H50.5723L51.249 83.1086L51.9257 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5566 82.3296L52.2333 82.7223L52.91 82.3296L52.2333 81.9404L51.5566 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6016 81.1626L49.2783 81.5553L49.955 81.1626L49.2783 80.7734L48.6016 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.955 83.5011H48.6016L49.2783 83.1084L49.955 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 82.3296L50.2646 82.7223L50.9413 82.3296L50.2646 81.9404L49.5879 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 81.1626L47.3115 81.5553L47.9882 81.1626L47.3115 80.7734L46.6348 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 82.7223L47.0029 82.3296L46.3262 81.9404V82.7223Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9882 83.5011H46.6348L47.3115 83.1084L47.9882 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 82.3296L48.2958 82.7223L48.9725 82.3296L48.2958 81.9404L47.6191 82.3296Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask12_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 90.5137H46.3262V99.8647H58.1386V90.5137Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask12_6587_35280)">
                        <path
                            d="M51.9254 92.8525L51.2487 93.2417L50.572 92.8525H51.9254Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.572 95.187L51.2487 95.5762L51.9254 95.187L51.2487 94.7978L50.572 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5564 94.0206L52.2331 94.4097L52.9098 94.0206L52.2331 93.6279L51.5564 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5564 96.355L52.2331 96.7476L52.9098 96.355L52.2331 95.9658L51.5564 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.9586 92.8525L49.2819 93.2417L48.6052 92.8525H49.9586Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6013 95.187L49.278 95.5762L49.9547 95.187L49.278 94.7978L48.6013 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5876 94.0206L50.2643 94.4097L50.941 94.0206L50.2643 93.6279L49.5876 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5876 96.3552L50.2643 96.7479L50.941 96.3552L50.2643 95.966L49.5876 96.3552Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9879 92.8525L47.3112 93.2417L46.6345 92.8525H47.9879Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6345 95.187L47.3112 95.5762L47.9879 95.187L47.3112 94.7978L46.6345 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6189 94.0206L48.2956 94.4097L48.9723 94.0206L48.2956 93.6279L47.6189 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3259 94.4097L47.0026 94.0206L46.3259 93.6279V94.4097Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3259 96.7479L47.0026 96.3552L46.3259 95.966V96.7479Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6189 96.355L48.2956 96.7476L48.9723 96.355L48.2956 95.9658L47.6189 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M50.572 90.5138L51.2487 90.903L51.9254 90.5138L51.2487 90.1211L50.572 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.9254 92.8526H50.572L51.2487 92.4599L51.9254 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M51.5564 91.6821L52.2331 92.0713L52.9098 91.6821L52.2331 91.2929L51.5564 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6013 90.5137L49.278 90.9029L49.9547 90.5137L49.278 90.121L48.6013 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.9547 92.8526H48.6013L49.278 92.4599L49.9547 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5876 91.6821L50.2643 92.0713L50.941 91.6821L50.2643 91.2929L49.5876 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6345 90.5137L47.3112 90.9029L47.9879 90.5137L47.3112 90.121L46.6345 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3259 92.0748L47.0026 91.6821L46.3259 91.2929V92.0748Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9879 92.8526H46.6345L47.3112 92.4599L47.9879 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6189 91.6821L48.2956 92.0713L48.9723 91.6821L48.2956 91.2929L47.6189 91.6821Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask13_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M46.33 71.8154H34.5176V81.1665H46.33V71.8154Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask13_6587_35280)">
                        <path
                            d="M46.0214 78.8279L45.3447 79.2171L44.668 78.8279H46.0214Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 81.1636L45.3447 81.5563L46.0179 81.1636L45.3447 80.7744L44.668 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 79.6035V80.3854L45.6504 79.9962L46.3271 79.6035Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 78.8279L43.374 79.2171L42.6973 78.8279H44.0507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6953 81.1636L43.372 81.5563L44.0487 81.1636L43.372 80.7744L42.6953 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 79.9962L44.3603 80.3854L45.037 79.9962L44.3603 79.6035L43.6836 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 78.8281L41.4072 79.2173L40.7305 78.8281H42.0839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 81.1636L41.4072 81.5563L42.0839 81.1636L41.4072 80.7744L40.7305 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 79.9962L42.3876 80.3854L43.0643 79.9962L42.3876 79.6035L41.7109 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 78.8281L39.4384 79.2173L38.7617 78.8281H40.1151Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 81.1636L39.4384 81.5563L40.1151 81.1636L39.4384 80.7744L38.7617 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 79.9962L40.4228 80.3854L41.0995 79.9962L40.4228 79.6035L39.7461 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 78.8281L37.4697 79.2173L36.793 78.8281H38.1464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 81.1636L37.4697 81.5563L38.1464 81.1636L37.4697 80.7744L36.793 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 79.9962L38.456 80.3854L39.1327 79.9962L38.456 79.6035L37.7793 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1796 78.8281L35.5029 79.2173L34.8262 78.8281H36.1796Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 81.1636L35.5009 81.5563L36.1776 81.1636L35.5009 80.7744L34.8242 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 79.9962L36.4853 80.3854L37.162 79.9962L36.4853 79.6035L35.8086 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 80.3854L35.1943 79.9962L34.5176 79.6035V80.3854Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask14_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M46.33 81.1631H34.5176V90.5142H46.33V81.1631Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask14_6587_35280)">
                        <path
                            d="M46.0214 88.1749L45.3447 88.5676L44.668 88.1749H46.0214Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 90.5137L45.3447 90.9029L46.0179 90.5137L45.3447 90.121L44.668 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 88.9541V89.736L45.6504 89.3433L46.3271 88.9541Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 88.1749L43.374 88.5676L42.6973 88.1749H44.0507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 90.5138L43.374 90.903L44.0507 90.5138L43.374 90.1211L42.6973 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 89.3433L44.3603 89.736L45.037 89.3433L44.3603 88.9541L43.6836 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 88.1748L41.4072 88.5675L40.7305 88.1748H42.0839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 90.5138L41.4072 90.903L42.0839 90.5138L41.4072 90.1211L40.7305 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 89.3433L42.3876 89.736L43.0643 89.3433L42.3876 88.9541L41.7109 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 88.1748L39.4384 88.5675L38.7617 88.1748H40.1151Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 90.5138L39.4384 90.903L40.1151 90.5138L39.4384 90.1211L38.7617 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 89.3433L40.4228 89.736L41.0995 89.3433L40.4228 88.9541L39.7461 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 88.1748L37.4697 88.5675L36.793 88.1748H38.1464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 90.5138L37.4697 90.903L38.1464 90.5138L37.4697 90.1211L36.793 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 89.3433L38.456 89.736L39.1327 89.3433L38.456 88.9541L37.7793 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1796 88.1748L35.5029 88.5675L34.8262 88.1748H36.1796Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 90.5138L35.5009 90.903L36.1776 90.5138L35.5009 90.1211L34.8242 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 89.3433L36.4853 89.736L37.162 89.3433L36.4853 88.9541L35.8086 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 89.736L35.1943 89.3433L34.5176 88.9541V89.736Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.0214 83.501L45.3447 83.8902L44.668 83.501H46.0214Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 85.8363L45.3447 86.229L46.0179 85.8363L45.3447 85.4471L44.668 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 84.2803V85.0622L45.6504 84.6695L46.3271 84.2803Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.0214 88.1753H44.668L45.3447 87.7861L46.0214 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 86.6143V87.3961L45.6504 87.007L46.3271 86.6143Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0487 83.501L43.372 83.8902L42.6953 83.501H44.0487Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 85.8362L43.374 86.2289L44.0507 85.8362L43.374 85.447L42.6973 85.8362Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 84.6695L44.3603 85.0587L45.037 84.6695L44.3603 84.2803L43.6836 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0487 88.1753H42.6953L43.372 87.7861L44.0487 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 87.007L44.3603 87.3961L45.037 87.007L44.3603 86.6143L43.6836 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 83.501L41.4072 83.8902L40.7305 83.501H42.0839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 85.8365L41.4072 86.2292L42.0839 85.8365L41.4072 85.4473L40.7305 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 84.6695L42.3876 85.0587L43.0643 84.6695L42.3876 84.2803L41.7109 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 88.1753H40.7305L41.4072 87.7861L42.0839 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 87.007L42.3876 87.3961L43.0643 87.007L42.3876 86.6143L41.7109 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 83.501L39.4384 83.8902L38.7617 83.501H40.1151Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 85.8365L39.4384 86.2292L40.1151 85.8365L39.4384 85.4473L38.7617 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 84.6695L40.4228 85.0587L41.0995 84.6695L40.4228 84.2803L39.7461 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 88.1753H38.7617L39.4384 87.7861L40.1151 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 87.007L40.4228 87.3961L41.0995 87.007L40.4228 86.6143L39.7461 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 83.501L37.4697 83.8902L36.793 83.501H38.1464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 85.8365L37.4697 86.2292L38.1464 85.8365L37.4697 85.4473L36.793 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 84.6695L38.456 85.0587L39.1327 84.6695L38.456 84.2803L37.7793 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 88.1753H36.793L37.4697 87.7861L38.1464 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 87.007L38.456 87.3961L39.1327 87.007L38.456 86.6143L37.7793 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1796 83.501L35.5029 83.8902L34.8262 83.501H36.1796Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 85.8362L35.5009 86.2289L36.1776 85.8362L35.5009 85.447L34.8242 85.8362Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 84.6695L36.4853 85.0587L37.162 84.6695L36.4853 84.2803L35.8086 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 85.0587L35.1943 84.6695L34.5176 84.2803V85.0587Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 87.3961L35.1943 87.007L34.5176 86.6143V87.3961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1776 88.1753H34.8242L35.5009 87.7861L36.1776 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 87.007L36.4853 87.3961L37.162 87.007L36.4853 86.6143L35.8086 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 81.1626L45.3447 81.5553L46.0179 81.1626L45.3447 80.7734L44.668 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.0214 83.5013H44.668L45.3447 83.1086L46.0214 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 81.9404V82.7223L45.6504 82.3331L46.3271 81.9404Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 81.1626L43.374 81.5553L44.0507 81.1626L43.374 80.7734L42.6973 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 83.5012H42.6973L43.374 83.1085L44.0507 83.5012Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 82.3296L44.3603 82.7223L45.037 82.3296L44.3603 81.9404L43.6836 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 81.1626L41.4072 81.5553L42.0839 81.1626L41.4072 80.7734L40.7305 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 83.5011H40.7305L41.4072 83.1084L42.0839 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 82.3296L42.3876 82.7223L43.0643 82.3296L42.3876 81.9404L41.7109 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 81.1626L39.4384 81.5553L40.1151 81.1626L39.4384 80.7734L38.7617 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 83.5011H38.7617L39.4384 83.1084L40.1151 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 82.3296L40.4228 82.7223L41.0995 82.3296L40.4228 81.9404L39.7461 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 81.1626L37.4697 81.5553L38.1464 81.1626L37.4697 80.7734L36.793 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 83.5011H36.793L37.4697 83.1084L38.1464 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 82.3296L38.456 82.7223L39.1327 82.3296L38.456 81.9404L37.7793 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 81.1626L35.5009 81.5553L36.1776 81.1626L35.5009 80.7734L34.8242 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 82.7223L35.1943 82.3296L34.5176 81.9404V82.7223Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1776 83.5012H34.8242L35.5009 83.1085L36.1776 83.5012Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 82.3296L36.4853 82.7223L37.162 82.3296L36.4853 81.9404L35.8086 82.3296Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask15_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M46.33 90.5137H34.5176V99.8647H46.33V90.5137Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask15_6587_35280)">
                        <path
                            d="M46.0214 92.8526L45.3447 93.2418L44.668 92.8526H46.0214Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 95.187L45.3447 95.5762L46.0179 95.187L45.3447 94.7979L44.668 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 93.6279V94.4098L45.6504 94.0206L46.3271 93.6279Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 95.9658V96.7477L45.6504 96.355L46.3271 95.9658Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 92.8527L43.374 93.2419L42.6973 92.8527H44.0507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6953 95.187L43.372 95.5762L44.0487 95.187L43.372 94.7979L42.6953 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 94.0206L44.3603 94.4098L45.037 94.0206L44.3603 93.6279L43.6836 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 96.3553L44.3603 96.748L45.037 96.3553L44.3603 95.9661L43.6836 96.3553Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 92.8525L41.4072 93.2417L40.7305 92.8525H42.0839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 95.187L41.4072 95.5762L42.0839 95.187L41.4072 94.7979L40.7305 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 94.0206L42.3876 94.4098L43.0643 94.0206L42.3876 93.6279L41.7109 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7129 96.3553L42.3896 96.748L43.0663 96.3553L42.3896 95.9661L41.7129 96.3553Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 92.8525L39.4384 93.2417L38.7617 92.8525H40.1151Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 95.187L39.4384 95.5762L40.1151 95.187L39.4384 94.7979L38.7617 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 94.0206L40.4228 94.4098L41.0995 94.0206L40.4228 93.6279L39.7461 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 96.355L40.4228 96.7477L41.0995 96.355L40.4228 95.9658L39.7461 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 92.8525L37.4697 93.2417L36.793 92.8525H38.1464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 95.187L37.4697 95.5762L38.1464 95.187L37.4697 94.7979L36.793 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 94.0206L38.456 94.4098L39.1327 94.0206L38.456 93.6279L37.7793 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 96.3553L38.456 96.748L39.1327 96.3553L38.456 95.9661L37.7793 96.3553Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1796 92.8525L35.5029 93.2417L34.8262 92.8525H36.1796Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 95.187L35.5009 95.5762L36.1776 95.187L35.5009 94.7979L34.8242 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 94.0206L36.4853 94.4098L37.162 94.0206L36.4853 93.6279L35.8086 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 94.4098L35.1943 94.0206L34.5176 93.6279V94.4098Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 96.748L35.1943 96.3553L34.5176 95.9661V96.748Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 96.3553L36.4853 96.748L37.162 96.3553L36.4853 95.9661L35.8086 96.3553Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 90.5138L45.3447 90.903L46.0179 90.5138L45.3447 90.1212L44.668 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.0214 92.8527H44.668L45.3447 92.46L46.0214 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3271 91.293V92.0749L45.6504 91.6822L46.3271 91.293Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 90.5138L43.374 90.903L44.0507 90.5138L43.374 90.1211L42.6973 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 92.8527H42.6973L43.374 92.46L44.0507 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 91.6822L44.3603 92.0713L45.037 91.6822L44.3603 91.293L43.6836 91.6822Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 90.5138L41.4072 90.903L42.0839 90.5138L41.4072 90.1211L40.7305 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 92.8527H40.7305L41.4072 92.46L42.0839 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 91.6822L42.3876 92.0713L43.0643 91.6822L42.3876 91.293L41.7109 91.6822Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 90.5138L39.4384 90.903L40.1151 90.5138L39.4384 90.1211L38.7617 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 92.8527H38.7617L39.4384 92.46L40.1151 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 91.6822L40.4228 92.0713L41.0995 91.6822L40.4228 91.293L39.7461 91.6822Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.793 90.5138L37.4697 90.903L38.1464 90.5138L37.4697 90.1211L36.793 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.1464 92.8527H36.793L37.4697 92.46L38.1464 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 91.6822L38.456 92.0713L39.1327 91.6822L38.456 91.293L37.7793 91.6822Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.8242 90.5138L35.5009 90.903L36.1776 90.5138L35.5009 90.1211L34.8242 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5176 92.0749L35.1943 91.6822L34.5176 91.293V92.0749Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M36.1776 92.8527H34.8242L35.5009 92.46L36.1776 92.8527Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M35.8086 91.6822L36.4853 92.0713L37.162 91.6822L36.4853 91.293L35.8086 91.6822Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask16_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="22"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M34.5156 71.8154H22.7031V81.1665H34.5156V71.8154Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask16_6587_35280)">
                        <path
                            d="M34.2069 78.8279L33.5302 79.2171L32.8535 78.8279H34.2069Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 81.1636L33.5302 81.5563L34.2069 81.1636L33.5302 80.7744L32.8535 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 79.6035V80.3854L33.8379 79.9962L34.5146 79.6035Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2382 78.8279L31.5615 79.2171L30.8848 78.8279H32.2382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 81.1636L31.5595 81.5563L32.2362 81.1636L31.5595 80.7744L30.8828 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 79.9962L32.5458 80.3854L33.2225 79.9962L32.5458 79.6035L31.8691 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2692 78.8279L29.5925 79.2171L28.9158 78.8279H30.2692Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.916 81.1636L29.5927 81.5563L30.2694 81.1636L29.5927 80.7744L28.916 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 79.9962L30.579 80.3854L31.2557 79.9962L30.579 79.6035L29.9023 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 78.8279L27.6259 79.2171L26.9492 78.8279H28.3026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 81.1636L27.6259 81.5563L28.3026 81.1636L27.6259 80.7744L26.9492 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 79.9962L28.6083 80.3854L29.285 79.9962L28.6083 79.6035L27.9316 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 78.8281L25.6533 79.2173L24.9766 78.8281H26.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 81.1636L25.6533 81.5563L26.33 81.1636L25.6533 80.7744L24.9766 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 79.9962L26.6415 80.3854L27.3182 79.9962L26.6415 79.6035L25.9648 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 78.8281L23.6884 79.2173L23.0117 78.8281H24.3651Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 81.1636L23.6884 81.5563L24.3651 81.1636L23.6884 80.7744L23.0117 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 79.9962L24.6689 80.3854L25.3456 79.9962L24.6689 79.6035L23.9922 79.9962Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 80.3854L23.3798 79.9962L22.7031 79.6035V80.3854Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask17_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="22"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M34.5156 81.1631H22.7031V90.5142H34.5156V81.1631Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask17_6587_35280)">
                        <path
                            d="M34.2069 88.1749L33.5302 88.5676L32.8535 88.1749H34.2069Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 90.5137L33.5302 90.9029L34.2069 90.5137L33.5302 90.121L32.8535 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 88.9541V89.736L33.8379 89.3433L34.5146 88.9541Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2382 88.1749L31.5615 88.5676L30.8848 88.1749H32.2382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 90.5138L31.5595 90.903L32.2362 90.5138L31.5595 90.1211L30.8828 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 89.3433L32.5458 89.736L33.2225 89.3433L32.5458 88.9541L31.8691 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2692 88.1749L29.5925 88.5676L28.9158 88.1749H30.2692Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.9158 90.5137L29.5925 90.9029L30.2692 90.5137L29.5925 90.121L28.9158 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 89.3433L30.579 89.736L31.2557 89.3433L30.579 88.9541L29.9023 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 88.1749L27.6259 88.5676L26.9492 88.1749H28.3026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 90.5137L27.6259 90.9029L28.3026 90.5137L27.6259 90.121L26.9492 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 89.3433L28.6083 89.736L29.285 89.3433L28.6083 88.9541L27.9316 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 88.1748L25.6533 88.5675L24.9766 88.1748H26.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 90.5138L25.6533 90.903L26.33 90.5138L25.6533 90.1211L24.9766 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 89.3433L26.6415 89.736L27.3182 89.3433L26.6415 88.9541L25.9648 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 88.1748L23.6884 88.5675L23.0117 88.1748H24.3651Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 90.5138L23.6884 90.903L24.3651 90.5138L23.6884 90.1211L23.0117 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 89.3433L24.6689 89.736L25.3456 89.3433L24.6689 88.9541L23.9922 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 89.736L23.3798 89.3433L22.7031 88.9541V89.736Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.2069 83.501L33.5302 83.8902L32.8535 83.501H34.2069Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 85.8363L33.5302 86.229L34.2069 85.8363L33.5302 85.4471L32.8535 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 84.2803V85.0622L33.8379 84.6695L34.5146 84.2803Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.2069 88.1753H32.8535L33.5302 87.7861L34.2069 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 86.6143V87.3961L33.8379 87.007L34.5146 86.6143Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2382 83.501L31.5615 83.8902L30.8848 83.501H32.2382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 85.8365L31.5595 86.2292L32.2362 85.8365L31.5595 85.4473L30.8828 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 84.6695L32.5458 85.0587L33.2225 84.6695L32.5458 84.2803L31.8691 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2362 88.1753H30.8828L31.5595 87.7861L32.2362 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 87.007L32.5458 87.3961L33.2225 87.007L32.5458 86.6143L31.8691 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2694 83.501L29.5927 83.8902L28.916 83.501H30.2694Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.9158 85.8363L29.5925 86.229L30.2692 85.8363L29.5925 85.4471L28.9158 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 84.6695L30.579 85.0587L31.2557 84.6695L30.579 84.2803L29.9023 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2694 88.1753H28.916L29.5927 87.7861L30.2694 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 87.007L30.579 87.3961L31.2557 87.007L30.579 86.6143L29.9023 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 83.501L27.6259 83.8902L26.9492 83.501H28.3026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 85.8363L27.6259 86.229L28.3026 85.8363L27.6259 85.4471L26.9492 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 84.6695L28.6083 85.0587L29.285 84.6695L28.6083 84.2803L27.9316 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 88.1753H26.9492L27.6259 87.7861L28.3026 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 87.007L28.6083 87.3961L29.285 87.007L28.6083 86.6143L27.9316 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 83.501L25.6533 83.8902L24.9766 83.501H26.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 85.8365L25.6533 86.2292L26.33 85.8365L25.6533 85.4473L24.9766 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 84.6695L26.6415 85.0587L27.3182 84.6695L26.6415 84.2803L25.9648 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 88.1753H24.9766L25.6533 87.7861L26.33 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 87.007L26.6415 87.3961L27.3182 87.007L26.6415 86.6143L25.9648 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 83.501L23.6884 83.8902L23.0117 83.501H24.3651Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 85.8365L23.6884 86.2292L24.3651 85.8365L23.6884 85.4473L23.0117 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 84.6695L24.6689 85.0587L25.3456 84.6695L24.6689 84.2803L23.9922 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 85.0587L23.3798 84.6695L22.7031 84.2803V85.0587Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 87.3961L23.3798 87.007L22.7031 86.6143V87.3961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 88.1753H23.0117L23.6884 87.7861L24.3651 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 87.007L24.6689 87.3961L25.3456 87.007L24.6689 86.6143L23.9922 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 81.1626L33.5302 81.5553L34.2069 81.1626L33.5302 80.7734L32.8535 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.2069 83.5013H32.8535L33.5302 83.1086L34.2069 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 81.9404V82.7223L33.8379 82.3331L34.5146 81.9404Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 81.1626L31.5595 81.5553L32.2362 81.1626L31.5595 80.7734L30.8828 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2362 83.5011H30.8828L31.5595 83.1084L32.2362 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 82.3296L32.5458 82.7223L33.2225 82.3296L32.5458 81.9404L31.8691 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.9158 81.1626L29.5925 81.5553L30.2692 81.1626L29.5925 80.7734L28.9158 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2692 83.5013H28.9158L29.5925 83.1086L30.2692 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 82.3296L30.579 82.7223L31.2557 82.3296L30.579 81.9404L29.9023 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 81.1626L27.6259 81.5553L28.3026 81.1626L27.6259 80.7734L26.9492 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 83.5013H26.9492L27.6259 83.1086L28.3026 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 82.3296L28.6083 82.7223L29.285 82.3296L28.6083 81.9404L27.9316 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 81.1626L25.6533 81.5553L26.33 81.1626L25.6533 80.7734L24.9766 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 83.5011H24.9766L25.6533 83.1084L26.33 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 82.3296L26.6415 82.7223L27.3182 82.3296L26.6415 81.9404L25.9648 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 81.1626L23.6884 81.5553L24.3651 81.1626L23.6884 80.7734L23.0117 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 82.7223L23.3798 82.3296L22.7031 81.9404V82.7223Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 83.5011H23.0117L23.6884 83.1084L24.3651 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 82.3296L24.6689 82.7223L25.3456 82.3296L24.6689 81.9404L23.9922 82.3296Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask18_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="22"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M34.5156 90.5137H22.7031V99.8647H34.5156V90.5137Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask18_6587_35280)">
                        <path
                            d="M34.2069 92.8525L33.5302 93.2417L32.8535 92.8525H34.2069Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 95.1869L33.5302 95.5761L34.2069 95.1869L33.5302 94.7977L32.8535 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 93.6278V94.4097L33.8379 94.0205L34.5146 93.6278Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5148 95.9659V96.7478L33.8381 96.3551L34.5148 95.9659Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2382 92.8525L31.5615 93.2417L30.8848 92.8525H32.2382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 95.1869L31.5595 95.5761L32.2362 95.1869L31.5595 94.7977L30.8828 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 94.0205L32.5458 94.4097L33.2225 94.0205L32.5458 93.6278L31.8691 94.0205Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 96.3551L32.5458 96.7478L33.2225 96.3551L32.5458 95.9659L31.8691 96.3551Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2692 92.8525L29.5925 93.2417L28.9158 92.8525H30.2692Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.916 95.1869L29.5927 95.5761L30.2694 95.1869L29.5927 94.7977L28.916 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 94.0205L30.579 94.4097L31.2557 94.0205L30.579 93.6278L29.9023 94.0205Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 96.3549L30.579 96.7476L31.2557 96.3549L30.579 95.9657L29.9023 96.3549Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 92.8525L27.6259 93.2417L26.9492 92.8525H28.3026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 95.1869L27.6259 95.5761L28.3026 95.1869L27.6259 94.7977L26.9492 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 94.0205L28.6083 94.4097L29.285 94.0205L28.6083 93.6278L27.9316 94.0205Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 96.3549L28.6083 96.7476L29.285 96.3549L28.6083 95.9657L27.9316 96.3549Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 92.8524L25.6533 93.2416L24.9766 92.8524H26.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 95.1869L25.6533 95.5761L26.33 95.1869L25.6533 94.7977L24.9766 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 94.0205L26.6415 94.4097L27.3182 94.0205L26.6415 93.6278L25.9648 94.0205Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 96.3551L26.6415 96.7478L27.3182 96.3551L26.6415 95.9659L25.9648 96.3551Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 92.8524L23.6884 93.2416L23.0117 92.8524H24.3651Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 95.1869L23.6884 95.5761L24.3651 95.1869L23.6884 94.7977L23.0117 95.1869Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 94.0205L24.6689 94.4097L25.3456 94.0205L24.6689 93.6278L23.9922 94.0205Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 94.4097L23.3798 94.0205L22.7031 93.6278V94.4097Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 96.7478L23.3798 96.3551L22.7031 95.9659V96.7478Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9941 96.3551L24.6708 96.7478L25.3475 96.3551L24.6708 95.9659L23.9941 96.3551Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.8535 90.5137L33.5302 90.9029L34.2069 90.5137L33.5302 90.121L32.8535 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.2069 92.8525H32.8535L33.5302 92.4598L34.2069 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M34.5146 91.2928V92.0747L33.8379 91.682L34.5146 91.2928Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.8828 90.5137L31.5595 90.9029L32.2362 90.5137L31.5595 90.121L30.8828 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M32.2362 92.8525H30.8828L31.5595 92.4598L32.2362 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M31.8691 91.682L32.5458 92.0712L33.2225 91.682L32.5458 91.2928L31.8691 91.682Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.9158 90.5137L29.5925 90.9029L30.2692 90.5137L29.5925 90.121L28.9158 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M30.2692 92.8525H28.9158L29.5925 92.4598L30.2692 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M29.9023 91.682L30.579 92.0712L31.2557 91.682L30.579 91.2928L29.9023 91.682Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.9492 90.5137L27.6259 90.9029L28.3026 90.5137L27.6259 90.121L26.9492 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M28.3026 92.8525H26.9492L27.6259 92.4598L28.3026 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M27.9316 91.682L28.6083 92.0712L29.285 91.682L28.6083 91.2928L27.9316 91.682Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.9766 90.5137L25.6533 90.9029L26.33 90.5137L25.6533 90.121L24.9766 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M26.33 92.8525H24.9766L25.6533 92.4598L26.33 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M25.9648 91.682L26.6415 92.0712L27.3182 91.682L26.6415 91.2928L25.9648 91.682Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.0117 90.5137L23.6884 90.9029L24.3651 90.5137L23.6884 90.121L23.0117 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7031 92.0747L23.3798 91.682L22.7031 91.2928V92.0747Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M24.3651 92.8525H23.0117L23.6884 92.4598L24.3651 92.8525Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M23.9922 91.682L24.6689 92.0712L25.3456 91.682L24.6689 91.2928L23.9922 91.682Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask19_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="10"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M22.7072 71.8154H10.8948V81.1664H22.7072V71.8154Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask19_6587_35280)">
                        <path
                            d="M22.3986 78.8278L21.7219 79.217L21.0452 78.8278H22.3986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0452 81.1635L21.7219 81.5562L22.3986 81.1635L21.7219 80.7744L21.0452 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7062 79.6035V80.3853L22.0295 79.9961L22.7062 79.6035Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4298 78.8278L19.7531 79.217L19.0764 78.8278H20.4298Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0764 81.1635L19.7531 81.5562L20.4298 81.1635L19.7531 80.7744L19.0764 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0627 79.9961L20.7394 80.3853L21.4161 79.9961L20.7394 79.6035L20.0627 79.9961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.465 78.8278L17.7883 79.217L17.1116 78.8278H18.465Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1077 81.1635L17.7844 81.5562L18.4611 81.1635L17.7844 80.7744L17.1077 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.094 79.9961L18.7707 80.3853L19.4474 79.9961L18.7707 79.6035L18.094 79.9961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.4943 78.8278L15.8176 79.217L15.1409 78.8278H16.4943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1409 81.1635L15.8176 81.5562L16.4943 81.1635L15.8176 80.7744L15.1409 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 79.9961L16.8019 80.3853L17.4786 79.9961L16.8019 79.6035L16.1252 79.9961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5294 78.8281L13.8527 79.2173L13.176 78.8281H14.5294Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1741 81.1635L13.8508 81.5562L14.5275 81.1635L13.8508 80.7744L13.1741 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1565 79.9961L14.8332 80.3853L15.5099 79.9961L14.8332 79.6035L14.1565 79.9961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5568 78.8281L11.8801 79.2173L11.2034 78.8281H12.5568Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2034 81.1635L11.8801 81.5562L12.5568 81.1635L11.8801 80.7744L11.2034 81.1635Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1877 79.9961L12.8644 80.3853L13.5411 79.9961L12.8644 79.6035L12.1877 79.9961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8948 80.3853L11.5715 79.9961L10.8948 79.6035V80.3853Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask20_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="10"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M22.7072 81.1631H10.8948V90.5142H22.7072V81.1631Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask20_6587_35280)">
                        <path
                            d="M22.3986 88.1749L21.7219 88.5676L21.0452 88.1749H22.3986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0452 90.5137L21.7219 90.9029L22.3986 90.5137L21.7219 90.121L21.0452 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7062 88.9541V89.736L22.0295 89.3433L22.7062 88.9541Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4298 88.1749L19.7531 88.5676L19.0764 88.1749H20.4298Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0764 90.5137L19.7531 90.9029L20.4298 90.5137L19.7531 90.121L19.0764 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0627 89.3433L20.7394 89.736L21.4161 89.3433L20.7394 88.9541L20.0627 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.465 88.1749L17.7883 88.5676L17.1116 88.1749H18.465Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1077 90.5138L17.7844 90.903L18.4611 90.5138L17.7844 90.1211L17.1077 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.094 89.3433L18.7707 89.736L19.4474 89.3433L18.7707 88.9541L18.094 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.4943 88.1749L15.8176 88.5676L15.1409 88.1749H16.4943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1409 90.5137L15.8176 90.9029L16.4943 90.5137L15.8176 90.121L15.1409 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 89.3433L16.8019 89.736L17.4786 89.3433L16.8019 88.9541L16.1252 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5294 88.1748L13.8527 88.5675L13.176 88.1748H14.5294Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.176 90.5138L13.8527 90.903L14.5294 90.5138L13.8527 90.1211L13.176 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1565 89.3433L14.8332 89.736L15.5099 89.3433L14.8332 88.9541L14.1565 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5568 88.1748L11.8801 88.5675L11.2034 88.1748H12.5568Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2034 90.5138L11.8801 90.903L12.5568 90.5138L11.8801 90.1211L11.2034 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1877 89.3433L12.8644 89.736L13.5411 89.3433L12.8644 88.9541L12.1877 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8948 89.736L11.5715 89.3433L10.8948 88.9541V89.736Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.3986 83.501L21.7219 83.8902L21.0452 83.501H22.3986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0452 85.8363L21.7219 86.229L22.3986 85.8363L21.7219 85.4471L21.0452 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7062 84.2803V85.0622L22.0295 84.6695L22.7062 84.2803Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.3986 88.1753H21.0452L21.7219 87.7861L22.3986 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7062 86.6143V87.3961L22.0295 87.007L22.7062 86.6143Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4298 83.501L19.7531 83.8902L19.0764 83.501H20.4298Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0764 85.8363L19.7531 86.229L20.4298 85.8363L19.7531 85.4471L19.0764 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0627 84.6695L20.7394 85.0587L21.4161 84.6695L20.7394 84.2803L20.0627 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4298 88.1753H19.0764L19.7531 87.7861L20.4298 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0627 87.007L20.7394 87.3961L21.4161 87.007L20.7394 86.6143L20.0627 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4669 83.501L17.7902 83.8902L17.1135 83.501H18.4669Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1077 85.8365L17.7844 86.2292L18.4611 85.8365L17.7844 85.4473L17.1077 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.094 84.6695L18.7707 85.0587L19.4474 84.6695L18.7707 84.2803L18.094 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4611 88.1753H17.1077L17.7844 87.7861L18.4611 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.094 87.007L18.7707 87.3961L19.4474 87.007L18.7707 86.6143L18.094 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.4943 83.501L15.8176 83.8902L15.1409 83.501H16.4943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1409 85.8363L15.8176 86.229L16.4943 85.8363L15.8176 85.4471L15.1409 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 84.6695L16.8019 85.0587L17.4786 84.6695L16.8019 84.2803L16.1252 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.4943 88.1753H15.1409L15.8176 87.7861L16.4943 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 87.007L16.8019 87.3961L17.4786 87.007L16.8019 86.6143L16.1252 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5275 83.501L13.8508 83.8902L13.1741 83.501H14.5275Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.176 85.8365L13.8527 86.2292L14.5294 85.8365L13.8527 85.4473L13.176 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1565 84.6695L14.8332 85.0587L15.5099 84.6695L14.8332 84.2803L14.1565 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5275 88.1753H13.1741L13.8508 87.7861L14.5275 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1565 87.007L14.8332 87.3961L15.5099 87.007L14.8332 86.6143L14.1565 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5568 83.501L11.8801 83.8902L11.2034 83.501H12.5568Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2034 85.8365L11.8801 86.2292L12.5568 85.8365L11.8801 85.4473L11.2034 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1877 84.6695L12.8644 85.0587L13.5411 84.6695L12.8644 84.2803L12.1877 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8948 85.0587L11.5715 84.6695L10.8948 84.2803V85.0587Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8948 87.3961L11.5715 87.007L10.8948 86.6143V87.3961Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5568 88.1753H11.2034L11.8801 87.7861L12.5568 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1877 87.007L12.8644 87.3961L13.5411 87.007L12.8644 86.6143L12.1877 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0452 81.1626L21.7219 81.5553L22.3986 81.1626L21.7219 80.7734L21.0452 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.3986 83.5013H21.0452L21.7219 83.1086L22.3986 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.7062 81.9404V82.7223L22.0295 82.3331L22.7062 81.9404Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0764 81.1626L19.7531 81.5553L20.4298 81.1626L19.7531 80.7734L19.0764 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4298 83.5013H19.0764L19.7531 83.1086L20.4298 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0627 82.3296L20.7394 82.7223L21.4161 82.3296L20.7394 81.9404L20.0627 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1077 81.1626L17.7844 81.5553L18.4611 81.1626L17.7844 80.7734L17.1077 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4611 83.5011H17.1077L17.7844 83.1084L18.4611 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.094 82.3296L18.7707 82.7223L19.4474 82.3296L18.7707 81.9404L18.094 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1409 81.1626L15.8176 81.5553L16.4943 81.1626L15.8176 80.7734L15.1409 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.4943 83.5013H15.1409L15.8176 83.1086L16.4943 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 82.3296L16.8019 82.7223L17.4786 82.3296L16.8019 81.9404L16.1252 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.176 81.1626L13.8527 81.5553L14.5294 81.1626L13.8527 80.7734L13.176 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5294 83.5011H13.176L13.8527 83.1084L14.5294 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1565 82.3296L14.8332 82.7223L15.5099 82.3296L14.8332 81.9404L14.1565 82.3296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2034 81.1626L11.8801 81.5553L12.5568 81.1626L11.8801 80.7734L11.2034 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8948 82.7223L11.5715 82.3296L10.8948 81.9404V82.7223Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5568 83.5011H11.2034L11.8801 83.1084L12.5568 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1877 82.3296L12.8644 82.7223L13.5411 82.3296L12.8644 81.9404L12.1877 82.3296Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask21_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="10"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M22.707 90.5137H10.8945V99.8647H22.707V90.5137Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask21_6587_35280)">
                        <path
                            d="M22.3983 92.8525L21.7216 93.2417L21.0449 92.8525H22.3983Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0449 95.187L21.7216 95.5762L22.3983 95.187L21.7216 94.7978L21.0449 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.706 93.6279V94.4097L22.0293 94.0206L22.706 93.6279Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.706 95.966V96.7479L22.0293 96.3552L22.706 95.966Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4296 92.8525L19.7529 93.2417L19.0762 92.8525H20.4296Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0762 95.187L19.7529 95.5762L20.4296 95.187L19.7529 94.7978L19.0762 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0625 94.0206L20.7392 94.4097L21.4159 94.0206L20.7392 93.6279L20.0625 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0625 96.355L20.7392 96.7476L21.4159 96.355L20.7392 95.9658L20.0625 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4647 92.8526L17.788 93.2418L17.1113 92.8526H18.4647Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1074 95.187L17.7841 95.5762L18.4608 95.187L17.7841 94.7978L17.1074 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.0938 94.0206L18.7704 94.4097L19.4471 94.0206L18.7704 93.6279L18.0938 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.0938 96.355L18.7704 96.7476L19.4471 96.355L18.7704 95.9658L18.0938 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.494 92.8525L15.8173 93.2417L15.1406 92.8525H16.494Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1406 95.187L15.8173 95.5762L16.494 95.187L15.8173 94.7978L15.1406 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.125 94.0206L16.8017 94.4097L17.4784 94.0206L16.8017 93.6279L16.125 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.1252 96.3552L16.8019 96.7479L17.4786 96.3552L16.8019 95.966L16.1252 96.3552Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5292 92.8525L13.8525 93.2417L13.1758 92.8525H14.5292Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1738 95.187L13.8505 95.5762L14.5272 95.187L13.8505 94.7978L13.1738 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1562 94.0206L14.8329 94.4097L15.5096 94.0206L14.8329 93.6279L14.1562 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1562 96.3552L14.8329 96.7479L15.5096 96.3552L14.8329 95.966L14.1562 96.3552Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5565 92.8525L11.8798 93.2417L11.2031 92.8525H12.5565Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2031 95.187L11.8798 95.5762L12.5565 95.187L11.8798 94.7978L11.2031 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1875 94.0206L12.8642 94.4097L13.5409 94.0206L12.8642 93.6279L12.1875 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8945 94.4097L11.5712 94.0206L10.8945 93.6279V94.4097Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8945 96.7479L11.5712 96.3552L10.8945 95.966V96.7479Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1875 96.355L12.8642 96.7476L13.5409 96.355L12.8642 95.9658L12.1875 96.355Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M21.0449 90.5138L21.7216 90.903L22.3983 90.5138L21.7216 90.1211L21.0449 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.3983 92.8526H21.0449L21.7216 92.4599L22.3983 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M22.706 91.2929V92.0748L22.0293 91.6821L22.706 91.2929Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M19.0762 90.5138L19.7529 90.903L20.4296 90.5138L19.7529 90.1211L19.0762 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.4296 92.8526H19.0762L19.7529 92.4599L20.4296 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M20.0625 91.6821L20.7392 92.0713L21.4159 91.6821L20.7392 91.2929L20.0625 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M17.1074 90.5137L17.7841 90.9029L18.4608 90.5137L17.7841 90.121L17.1074 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.4608 92.8526H17.1074L17.7841 92.4599L18.4608 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M18.0938 91.6821L18.7704 92.0713L19.4471 91.6821L18.7704 91.2929L18.0938 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M15.1406 90.5138L15.8173 90.903L16.494 90.5138L15.8173 90.1211L15.1406 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.494 92.8526H15.1406L15.8173 92.4599L16.494 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M16.125 91.6821L16.8017 92.0713L17.4784 91.6821L16.8017 91.2929L16.125 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M13.1758 90.5137L13.8525 90.9029L14.5292 90.5137L13.8525 90.121L13.1758 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.5292 92.8526H13.1758L13.8525 92.4599L14.5292 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M14.1562 91.6821L14.8329 92.0713L15.5096 91.6821L14.8329 91.2929L14.1562 91.6821Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M11.2031 90.5137L11.8798 90.9029L12.5565 90.5137L11.8798 90.121L11.2031 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8945 92.0748L11.5712 91.6821L10.8945 91.2929V92.0748Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.5565 92.8526H11.2031L11.8798 92.4599L12.5565 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M12.1875 91.6821L12.8642 92.0713L13.5409 91.6821L12.8642 91.2929L12.1875 91.6821Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask22_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="71"
                        width="12"
                        height="11"
                    >
                        <path
                            d="M10.8925 71.8154H-0.919922V81.1665H10.8925V71.8154Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask22_6587_35280)">
                        <path
                            d="M10.5841 78.8279L9.90741 79.2171L9.23071 78.8279H10.5841Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23071 81.1636L9.90741 81.5563L10.5841 81.1636L9.90741 80.7744L9.23071 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8898 79.6035V80.3854L10.2131 79.9962L10.8898 79.6035Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61145 78.8281L7.93475 79.2173L7.25806 78.8281H8.61145Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25806 81.1636L7.93475 81.5563L8.61145 81.1636L7.93475 80.7744L7.25806 81.1636Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24634 79.9962L8.92304 80.3854L9.59974 79.9962L8.92304 79.6035L8.24634 79.9962Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask23_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="81"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M10.8925 81.1631H-0.919922V90.5142H10.8925V81.1631Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask23_6587_35280)">
                        <path
                            d="M10.5839 88.1749L9.90717 88.5676L9.23047 88.1749H10.5839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23047 90.5137L9.90717 90.9029L10.5839 90.5137L9.90717 90.121L9.23047 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8896 88.9541V89.736L10.2129 89.3433L10.8896 88.9541Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61121 88.1748L7.93451 88.5675L7.25781 88.1748H8.61121Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25781 90.5138L7.93451 90.903L8.61121 90.5138L7.93451 90.1211L7.25781 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24609 89.3433L8.92279 89.736L9.59949 89.3433L8.92279 88.9541L8.24609 89.3433Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.5839 83.501L9.90717 83.8902L9.23047 83.501H10.5839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23047 85.8363L9.90717 86.229L10.5839 85.8363L9.90717 85.4471L9.23047 85.8363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8896 84.2803V85.0622L10.2129 84.6695L10.8896 84.2803Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.5839 88.1753H9.23047L9.90717 87.7861L10.5839 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8896 86.6143V87.3961L10.2129 87.007L10.8896 86.6143Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61121 83.501L7.93451 83.8902L7.25781 83.501H8.61121Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25781 85.8365L7.93451 86.2292L8.61121 85.8365L7.93451 85.4473L7.25781 85.8365Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24609 84.6695L8.92279 85.0587L9.59949 84.6695L8.92279 84.2803L8.24609 84.6695Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61121 88.1753H7.25781L7.93451 87.7861L8.61121 88.1753Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24609 87.007L8.92279 87.3961L9.59949 87.007L8.92279 86.6143L8.24609 87.007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23047 81.1626L9.90717 81.5553L10.5839 81.1626L9.90717 80.7734L9.23047 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.5839 83.5013H9.23047L9.90717 83.1086L10.5839 83.5013Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8896 81.9414V82.7233L10.2129 82.3341L10.8896 81.9414Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25781 81.1626L7.93451 81.5553L8.61121 81.1626L7.93451 80.7734L7.25781 81.1626Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61121 83.5011H7.25781L7.93451 83.1084L8.61121 83.5011Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24609 82.3306L8.92279 82.7233L9.59949 82.3306L8.92279 81.9414L8.24609 82.3306Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask24_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="90"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M10.8925 90.5137H-0.919922V99.8647H10.8925V90.5137Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask24_6587_35280)">
                        <path
                            d="M10.5841 92.8525L9.90741 93.2417L9.23071 92.8525H10.5841Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23071 95.187L9.90741 95.5762L10.5841 95.187L9.90741 94.7978L9.23071 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8898 93.6279V94.4097L10.2131 94.0206L10.8898 93.6279Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8898 95.9658V96.7476L10.2131 96.355L10.8898 95.9658Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61145 92.8525L7.93475 93.2417L7.25806 92.8525H8.61145Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25806 95.187L7.93475 95.5762L8.61145 95.187L7.93475 94.7978L7.25806 95.187Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24634 94.0206L8.92304 94.4097L9.59974 94.0206L8.92304 93.6279L8.24634 94.0206Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24658 96.3552L8.92328 96.7479L9.59998 96.3552L8.92328 95.966L8.24658 96.3552Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M9.23071 90.5138L9.90741 90.903L10.5841 90.5138L9.90741 90.1211L9.23071 90.5138Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.5841 92.8526H9.23071L9.90741 92.4599L10.5841 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M10.8898 91.2929V92.0748L10.2131 91.6821L10.8898 91.2929Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M7.25806 90.5137L7.93475 90.9029L8.61145 90.5137L7.93475 90.121L7.25806 90.5137Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.61145 92.8526H7.25806L7.93475 92.4599L8.61145 92.8526Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M8.24634 91.6821L8.92304 92.0713L9.59974 91.6821L8.92304 91.2929L8.24634 91.6821Z"
                            fill="#1D3260"
                        />
                    </g>
                </g>
            </g>
            <g opacity="0.51">
                <mask
                    id="mask25_6587_35280"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="38"
                    y="57"
                    width="12"
                    height="7"
                >
                    <path
                        d="M44.1955 63.6912C47.2957 63.6912 49.8089 62.269 49.8089 60.5146C49.8089 58.7602 47.2957 57.338 44.1955 57.338C41.0953 57.338 38.582 58.7602 38.582 60.5146C38.582 62.269 41.0953 63.6912 44.1955 63.6912Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask25_6587_35280)">
                    <mask
                        id="mask26_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="53"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 53.1122H46.3262V62.4633H58.1386V53.1122Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask26_6587_35280)">
                        <path
                            d="M49.9589 60.1249L49.2822 60.5176L48.6055 60.1249H49.9589Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6016 62.4639L49.2783 62.8531L49.955 62.4639L49.2783 62.0712L48.6016 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 61.2934L50.2646 61.6861L50.9413 61.2934L50.2646 60.9042L49.5879 61.2934Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9882 60.1249L47.3115 60.5176L46.6348 60.1249H47.9882Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 62.4639L47.3115 62.8531L47.9882 62.4639L47.3115 62.0712L46.6348 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 61.2934L48.2958 61.6861L48.9725 61.2934L48.2958 60.9042L47.6191 61.2934Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 61.6861L47.0029 61.2934L46.3262 60.9042V61.6861Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M48.6016 57.7901L49.2783 58.1793L49.955 57.7901L49.2783 57.3974L48.6016 57.7901Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.955 60.1255H48.6016L49.2783 59.7363L49.955 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 58.9571L50.2646 59.3463L50.9413 58.9571L50.2646 58.5644L49.5879 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 57.7901L47.3115 58.1793L47.9882 57.7901L47.3115 57.3974L46.6348 57.7901Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 59.3463L47.0029 58.9571L46.3262 58.5644V59.3463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.9862 60.1255H46.6328L47.3095 59.7363L47.9862 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 58.9571L48.2958 59.3463L48.9725 58.9571L48.2958 58.5644L47.6191 58.9571Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask27_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="62"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 62.4638H46.3262V71.8149H58.1386V62.4638Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask27_6587_35280)">
                        <path
                            d="M48.6016 62.4639L49.2783 62.8531L49.955 62.4639L49.2783 62.0712L48.6016 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M49.5879 63.6313L50.2646 64.024L50.9413 63.6313L50.2646 63.2421L49.5879 63.6313Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.6348 62.4639L47.3115 62.8531L47.9882 62.4639L47.3115 62.0712L46.6348 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3262 64.024L47.0029 63.6313L46.3262 63.2421V64.024Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M47.6191 63.6313L48.2958 64.024L48.9725 63.6313L48.2958 63.2421L47.6191 63.6313Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask28_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="53"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M46.33 53.1123H34.5176V62.4634H46.33V53.1123Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask28_6587_35280)">
                        <path
                            d="M46.0214 60.1252L45.3447 60.5179L44.668 60.1252H46.0214Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 62.464L45.3447 62.8532L46.0179 62.464L45.3447 62.0714L44.668 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3251 60.9043V61.6862L45.6484 61.297L46.3251 60.9043Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0507 60.1252L43.374 60.5179L42.6973 60.1252H44.0507Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 62.464L43.374 62.8532L44.0507 62.464L43.374 62.0713L42.6973 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 61.2935L44.3603 61.6862L45.037 61.2935L44.3603 60.9043L43.6836 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 60.125L41.4072 60.5177L40.7305 60.125H42.0839Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 62.464L41.4072 62.8532L42.0839 62.464L41.4072 62.0713L40.7305 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 61.2935L42.3876 61.6862L43.0643 61.2935L42.3876 60.9043L41.7109 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 60.125L39.4384 60.5177L38.7617 60.125H40.1151Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 62.464L39.4384 62.8532L40.1151 62.464L39.4384 62.0713L38.7617 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 61.2935L40.4228 61.6862L41.0995 61.2935L40.4228 60.9043L39.7461 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 61.2935L38.456 61.6862L39.1327 61.2935L38.456 60.9043L37.7793 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.668 57.79L45.3447 58.1792L46.0179 57.79L45.3447 57.3973L44.668 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.0214 60.1255H44.668L45.3447 59.7363L46.0214 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3232 58.5686V59.347L45.6465 58.9578L46.3232 58.5686Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6973 57.79L43.374 58.1792L44.0507 57.79L43.374 57.3973L42.6973 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M44.0487 60.1255H42.6953L43.372 59.7363L44.0487 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 58.9581L44.3603 59.3473L45.037 58.9581L44.3603 58.5654L43.6836 58.9581Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 57.7902L41.4072 58.1793L42.0839 57.7902L41.4072 57.3975L40.7305 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.0839 60.1255H40.7305L41.4072 59.7363L42.0839 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 58.9581L42.3876 59.3473L43.0643 58.9581L42.3876 58.5654L41.7109 58.9581Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 57.7902L39.4384 58.1793L40.1151 57.7902L39.4384 57.3975L38.7617 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.1151 60.1255H38.7617L39.4384 59.7363L40.1151 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 58.9581L40.4228 59.3473L41.0995 58.9581L40.4228 58.5654L39.7461 58.9581Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 58.9581L38.456 59.3473L39.1327 58.9581L38.456 58.5654L37.7793 58.9581Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask29_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="34"
                        y="62"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M46.33 62.4638H34.5176V71.8149H46.33V62.4638Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask29_6587_35280)">
                        <path
                            d="M44.668 62.4639L45.3447 62.8531L46.0179 62.4639L45.3447 62.0712L44.668 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M46.3251 63.2421V64.024L45.6484 63.6313L46.3251 63.2421Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M42.6953 62.4639L43.372 62.8531L44.0487 62.4639L43.372 62.0712L42.6953 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M43.6836 63.6313L44.3603 64.024L45.037 63.6313L44.3603 63.2421L43.6836 63.6313Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M40.7305 62.4639L41.4072 62.8531L42.0839 62.4639L41.4072 62.0712L40.7305 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M41.7109 63.6313L42.3876 64.024L43.0643 63.6313L42.3876 63.2421L41.7109 63.6313Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M38.7617 62.4639L39.4384 62.8531L40.1151 62.4639L39.4384 62.0712L38.7617 62.4639Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M39.7461 63.6313L40.4228 64.024L41.0995 63.6313L40.4228 63.2421L39.7461 63.6313Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M37.7793 63.6313L38.456 64.024L39.1327 63.6313L38.456 63.2421L37.7793 63.6313Z"
                            fill="#1D3260"
                        />
                    </g>
                </g>
            </g>
            <g opacity="0.49">
                <mask
                    id="mask30_6587_35280"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="57"
                    y="37"
                    width="36"
                    height="55"
                >
                    <path
                        d="M90.794 65.1563C90.4258 68.6731 86.4813 72.7578 86.4813 72.7578C86.4813 72.7578 78.6905 81.7863 67.0569 88.3254C55.4233 94.861 57.2009 89.1388 57.2009 89.1388L66.545 81.6776C66.545 81.6776 72.9824 74.276 79.2305 68.617C78.0944 68.638 76.9374 68.6345 75.7733 68.6345C66.8079 68.6345 59.5431 63.554 59.5431 57.2849C59.5431 52.1974 64.3326 47.8917 70.9313 46.4507C70.8962 46.1877 70.8787 45.9212 70.8787 45.6548C70.8787 41.3702 75.4648 37.8955 81.1238 37.8955C86.7828 37.8955 91.369 41.3702 91.369 45.6548C91.369 46.9941 91.39 49.9394 90.6046 51.0368C90.4118 51.3068 90.1909 51.5452 89.9454 51.7556C91.2568 53.393 92.0001 55.2758 92.0001 57.2814C92.0001 59.2869 92.0387 63.5435 90.7904 65.1563H90.794Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask30_6587_35280)">
                    <mask
                        id="mask31_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="34"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M93.5761 34.4131H81.7637V43.7642H93.5761V34.4131Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask31_6587_35280)">
                        <path
                            d="M93.2675 41.4254L92.5908 41.8146L91.9141 41.4254H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 43.7646L92.5908 44.1538L93.2675 43.7646L92.5908 43.3719L91.9141 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 41.4254L90.622 41.8146L89.9453 41.4254H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 43.7646L90.622 44.1538L91.2987 43.7646L90.622 43.3719L89.9453 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 42.5933L91.6061 42.986L92.2828 42.5933L91.6061 42.2041L90.9294 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 41.4257L88.6533 41.8148L87.9766 41.4257H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 43.7646L88.6533 44.1538L89.33 43.7646L88.6533 43.3719L87.9766 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 42.5932L89.6396 42.9859L90.3163 42.5932L89.6396 42.204L88.9629 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 41.4254L86.6865 41.8146L86.0098 41.4254H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 43.7646L86.6865 44.1538L87.3632 43.7646L86.6865 43.3719L86.0098 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 42.5932L87.6689 42.9859L88.3456 42.5932L87.6689 42.204L86.9922 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 41.4257L84.7197 41.8148L84.043 41.4257H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 43.7646L84.7158 44.1538L85.3925 43.7646L84.7158 43.3719L84.0391 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 42.5933L85.7021 42.986L86.3788 42.5933L85.7021 42.2041L85.0254 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 41.4257L82.749 41.8148L82.0723 41.4257H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 43.7646L82.749 44.1538L83.4257 43.7646L82.749 43.3719L82.0723 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 42.5932L83.7333 42.9859L84.41 42.5932L83.7333 42.204L83.0566 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 42.986L82.4404 42.5933L81.7637 42.2041V42.986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 39.0863L92.5908 39.479L93.2675 39.0863L92.5908 38.6971L91.9141 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 41.4252H91.9141L92.5908 41.036L93.2675 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 39.0863L90.622 39.479L91.2987 39.0863L90.622 38.6971L89.9453 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 37.9199L91.6064 38.3091L92.2831 37.9199L91.6064 37.5272L90.9297 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 41.4252H89.9453L90.622 41.036L91.2987 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 40.258L91.6061 40.6472L92.2828 40.258L91.6061 39.8653L90.9294 40.258Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 39.0863L88.6533 39.479L89.33 39.0863L88.6533 38.6971L87.9766 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 37.9199L89.6396 38.3091L90.3163 37.9199L89.6396 37.5272L88.9629 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 41.4252H87.9766L88.6533 41.036L89.33 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 40.2578L89.6396 40.647L90.3163 40.2578L89.6396 39.8651L88.9629 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 39.0863L86.6865 39.479L87.3632 39.0863L86.6865 38.6971L86.0098 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 37.9199L87.6689 38.3091L88.3456 37.9199L87.6689 37.5272L86.9922 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 41.4252H86.0098L86.6865 41.036L87.3632 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 40.2578L87.6689 40.647L88.3456 40.2578L87.6689 39.8651L86.9922 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 39.0863L84.7158 39.479L85.3925 39.0863L84.7158 38.6971L84.0391 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 37.9199L85.7021 38.3091L86.3788 37.9199L85.7021 37.5272L85.0254 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 41.4252H84.0391L84.7158 41.036L85.3925 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 40.258L85.7021 40.6472L86.3788 40.258L85.7021 39.8653L85.0254 40.258Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 39.0863L82.749 39.479L83.4257 39.0863L82.749 38.6971L82.0723 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 37.9199L83.7333 38.3091L84.41 37.9199L83.7333 37.5272L83.0566 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 38.3091L82.4404 37.9199L81.7637 37.5272V38.3091Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 40.6472L82.4404 40.258L81.7637 39.8653V40.6472Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 41.4252H82.0723L82.749 41.036L83.4257 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 40.2578L83.7333 40.647L84.41 40.2578L83.7333 39.8651L83.0566 40.2578Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask32_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="43"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M93.5761 43.7646H81.7637V53.1157H93.5761V43.7646Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask32_6587_35280)">
                        <path
                            d="M93.2675 50.7772L92.5908 51.1664L91.9141 50.7772H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 53.1127L92.5908 53.5054L93.2675 53.1127L92.5908 52.7235L91.9141 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 50.7772L90.622 51.1664L89.9453 50.7772H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 53.1127L90.622 53.5054L91.2987 53.1127L90.622 52.7235L89.9453 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 51.9453L91.6064 52.3345L92.2831 51.9453L91.6064 51.5526L90.9297 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 50.7772L88.6533 51.1664L87.9766 50.7772H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 53.1127L88.6533 53.5054L89.33 53.1127L88.6533 52.7235L87.9766 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 51.9453L89.6396 52.3345L90.3163 51.9453L89.6396 51.5526L88.9629 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 50.7772L86.6865 51.1664L86.0098 50.7772H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 53.1127L86.6865 53.5054L87.3632 53.1127L86.6865 52.7235L86.0098 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 51.9453L87.6689 52.3345L88.3456 51.9453L87.6689 51.5526L86.9922 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 50.7772L84.7197 51.1664L84.043 50.7772H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 53.1127L84.7158 53.5054L85.3925 53.1127L84.7158 52.7235L84.0391 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 51.9453L85.7021 52.3345L86.3788 51.9453L85.7021 51.5526L85.0254 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 50.7772L82.749 51.1664L82.0723 50.7772H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 53.1127L82.749 53.5054L83.4257 53.1127L82.749 52.7235L82.0723 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 51.9453L83.7333 52.3345L84.41 51.9453L83.7333 51.5526L83.0566 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 52.3345L82.4404 51.9453L81.7637 51.5526V52.3345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 46.0995L92.5908 46.4922L91.9141 46.0995H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 48.4383L92.5908 48.8275L93.2675 48.4383L92.5908 48.0456L91.9141 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 50.7773H91.9141L92.5908 50.3846L93.2675 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 46.0995L90.622 46.4922L89.9453 46.0995H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 48.4383L90.622 48.8275L91.2987 48.4383L90.622 48.0456L89.9453 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 47.2715L91.6064 47.6607L92.2831 47.2715L91.6064 46.8788L90.9297 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 50.7773H89.9453L90.622 50.3846L91.2987 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 49.606L91.6061 49.9987L92.2828 49.606L91.6061 49.2169L90.9294 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 46.0995L88.6533 46.4922L87.9766 46.0995H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 48.4385L88.6533 48.8277L89.33 48.4385L88.6533 48.0458L87.9766 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 47.2715L89.6396 47.6607L90.3163 47.2715L89.6396 46.8788L88.9629 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 50.7773H87.9766L88.6533 50.3846L89.33 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 49.6059L89.6396 49.9986L90.3163 49.6059L89.6396 49.2167L88.9629 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 46.0995L86.6865 46.4922L86.0098 46.0995H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 48.4383L86.6865 48.8275L87.3632 48.4383L86.6865 48.0456L86.0098 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 47.2715L87.6689 47.6607L88.3456 47.2715L87.6689 46.8788L86.9922 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 50.7773H86.0098L86.6865 50.3846L87.3632 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 49.6059L87.6689 49.9986L88.3456 49.6059L87.6689 49.2167L86.9922 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 46.0995L84.7197 46.4922L84.043 46.0995H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 48.4385L84.7158 48.8277L85.3925 48.4385L84.7158 48.0458L84.0391 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 47.2715L85.7021 47.6607L86.3788 47.2715L85.7021 46.8788L85.0254 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 50.7773H84.0391L84.7158 50.3846L85.3925 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 49.606L85.7021 49.9987L86.3788 49.606L85.7021 49.2169L85.0254 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 46.0995L82.749 46.4922L82.0723 46.0995H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 48.4385L82.749 48.8277L83.4257 48.4385L82.749 48.0458L82.0723 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 47.2715L83.7333 47.6607L84.41 47.2715L83.7333 46.8788L83.0566 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 47.6607L82.4404 47.2715L81.7637 46.8788V47.6607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 49.9987L82.4404 49.606L81.7637 49.2169V49.9987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 50.7773H82.0723L82.749 50.3846L83.4257 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 49.6059L83.7333 49.9986L84.41 49.6059L83.7333 49.2167L83.0566 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 43.7646L92.5908 44.1538L93.2675 43.7646L92.5908 43.3719L91.9141 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 46.0999H91.9141L92.5908 45.7108L93.2675 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 43.7646L90.622 44.1538L91.2987 43.7646L90.622 43.3719L89.9453 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 46.0999H89.9453L90.622 45.7108L91.2987 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 44.9316L91.6064 45.3208L92.2831 44.9316L91.6064 44.5389L90.9297 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 43.7646L88.6533 44.1538L89.33 43.7646L88.6533 43.3719L87.9766 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 46.1H87.9766L88.6533 45.7108L89.33 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 44.9316L89.6396 45.3208L90.3163 44.9316L89.6396 44.5389L88.9629 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 43.7646L86.6865 44.1538L87.3632 43.7646L86.6865 43.3719L86.0098 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 46.0999H86.0098L86.6865 45.7108L87.3632 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 44.9316L87.6689 45.3208L88.3456 44.9316L87.6689 44.5389L86.9922 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 43.7646L84.7158 44.1538L85.3925 43.7646L84.7158 43.3719L84.0391 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 46.1H84.0391L84.7158 45.7108L85.3925 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 44.9316L85.7021 45.3208L86.3788 44.9316L85.7021 44.5389L85.0254 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 43.7646L82.749 44.1538L83.4257 43.7646L82.749 43.3719L82.0723 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 45.3208L82.4404 44.9316L81.7637 44.5389V45.3208Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 46.1H82.0723L82.749 45.7108L83.4257 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 44.9316L83.7333 45.3208L84.41 44.9316L83.7333 44.5389L83.0566 44.9316Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask33_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="53"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M93.5761 53.1123H81.7637V62.4634H93.5761V53.1123Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask33_6587_35280)">
                        <path
                            d="M93.2675 60.1251L92.5908 60.5178L91.9141 60.1251H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 62.464L92.5908 62.8532L93.2675 62.464L92.5908 62.0713L91.9141 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 60.1251L90.622 60.5178L89.9453 60.1251H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 62.464L90.622 62.8532L91.2987 62.464L90.622 62.0713L89.9453 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 61.2935L91.6064 61.6862L92.2831 61.2935L91.6064 60.9043L90.9297 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 60.125L88.6533 60.5177L87.9766 60.125H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 62.464L88.6533 62.8532L89.33 62.464L88.6533 62.0713L87.9766 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 61.2935L89.6396 61.6862L90.3163 61.2935L89.6396 60.9043L88.9629 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 60.1251L86.6865 60.5178L86.0098 60.1251H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 62.464L86.6865 62.8532L87.3632 62.464L86.6865 62.0713L86.0098 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 61.2935L87.6689 61.6862L88.3456 61.2935L87.6689 60.9043L86.9922 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 60.125L84.7197 60.5177L84.043 60.125H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 62.464L84.7158 62.8532L85.3925 62.464L84.7158 62.0713L84.0391 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 61.2935L85.7021 61.6862L86.3788 61.2935L85.7021 60.9043L85.0254 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 60.125L82.749 60.5177L82.0723 60.125H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 62.464L82.749 62.8532L83.4257 62.464L82.749 62.0713L82.0723 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 61.2935L83.7333 61.6862L84.41 61.2935L83.7333 60.9043L83.0566 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 61.6862L82.4404 61.2935L81.7637 60.9043V61.6862Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 55.4512L92.5908 55.8404L91.9141 55.4512H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 57.79L92.5908 58.1792L93.2675 57.79L92.5908 57.3973L91.9141 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 60.1255H91.9141L92.5908 59.7363L93.2675 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 55.4512L90.622 55.8404L89.9453 55.4512H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 57.79L90.622 58.1792L91.2987 57.79L90.622 57.3973L89.9453 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 56.6197L91.6064 57.0124L92.2831 56.6197L91.6064 56.2305L90.9297 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 60.1255H89.9453L90.622 59.7363L91.2987 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 58.9571L91.6064 59.3463L92.2831 58.9571L91.6064 58.5645L90.9297 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 55.4512L88.6533 55.8404L87.9766 55.4512H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 57.7902L88.6533 58.1793L89.33 57.7902L88.6533 57.3975L87.9766 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 56.6197L89.6396 57.0124L90.3163 56.6197L89.6396 56.2305L88.9629 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 60.1255H87.9766L88.6533 59.7363L89.33 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 58.9571L89.6396 59.3463L90.3163 58.9571L89.6396 58.5645L88.9629 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 55.4512L86.6865 55.8404L86.0098 55.4512H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 57.79L86.6865 58.1792L87.3632 57.79L86.6865 57.3973L86.0098 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 56.6197L87.6689 57.0124L88.3456 56.6197L87.6689 56.2305L86.9922 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 60.1255H86.0098L86.6865 59.7363L87.3632 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 58.9571L87.6689 59.3463L88.3456 58.9571L87.6689 58.5645L86.9922 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 55.4512L84.7197 55.8404L84.043 55.4512H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 57.7902L84.7158 58.1793L85.3925 57.7902L84.7158 57.3975L84.0391 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 56.6197L85.7021 57.0124L86.3788 56.6197L85.7021 56.2305L85.0254 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 60.1255H84.0391L84.7158 59.7363L85.3925 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 58.9571L85.7021 59.3463L86.3788 58.9571L85.7021 58.5645L85.0254 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 55.4512L82.749 55.8404L82.0723 55.4512H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 57.7902L82.749 58.1793L83.4257 57.7902L82.749 57.3975L82.0723 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 56.6197L83.7333 57.0124L84.41 56.6197L83.7333 56.2305L83.0566 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 57.0124L82.4404 56.6197L81.7637 56.2305V57.0124Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 59.3463L82.4404 58.9571L81.7637 58.5645V59.3463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 60.1255H82.0723L82.749 59.7363L83.4257 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 58.9571L83.7333 59.3463L84.41 58.9571L83.7333 58.5645L83.0566 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 53.1128L92.5908 53.5055L93.2675 53.1128L92.5908 52.7236L91.9141 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 55.4516H91.9141L92.5908 55.0624L93.2675 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 53.1128L90.622 53.5055L91.2987 53.1128L90.622 52.7236L89.9453 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 55.4516H89.9453L90.622 55.0624L91.2987 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 54.2833L91.6064 54.6725L92.2831 54.2833L91.6064 53.8906L90.9297 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 53.1128L88.6533 53.5055L89.33 53.1128L88.6533 52.7236L87.9766 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 55.4517H87.9766L88.6533 55.0625L89.33 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 54.2833L89.6396 54.6725L90.3163 54.2833L89.6396 53.8906L88.9629 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 53.1128L86.6865 53.5055L87.3632 53.1128L86.6865 52.7236L86.0098 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 55.4516H86.0098L86.6865 55.0624L87.3632 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 54.2833L87.6689 54.6725L88.3456 54.2833L87.6689 53.8906L86.9922 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 53.1128L84.7158 53.5055L85.3925 53.1128L84.7158 52.7236L84.0391 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 55.4517H84.0391L84.7158 55.0625L85.3925 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 54.2833L85.7021 54.6725L86.3788 54.2833L85.7021 53.8906L85.0254 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 53.1128L82.749 53.5055L83.4257 53.1128L82.749 52.7236L82.0723 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 54.6725L82.4404 54.2833L81.7637 53.8906V54.6725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 55.4517H82.0723L82.749 55.0625L83.4257 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 54.2833L83.7333 54.6725L84.41 54.2833L83.7333 53.8906L83.0566 54.2833Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask34_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="62"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M93.5761 62.4629H81.7637V71.814H93.5761V62.4629Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask34_6587_35280)">
                        <path
                            d="M93.2675 69.4756L92.5908 69.8648L91.9141 69.4756H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 71.8111L92.5908 72.2038L93.2675 71.8111L92.5908 71.4219L91.9141 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 69.4756L90.622 69.8648L89.9453 69.4756H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 71.8111L90.622 72.2038L91.2987 71.8111L90.622 71.4219L89.9453 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 70.6432L91.6061 71.0359L92.2828 70.6432L91.6061 70.254L90.9294 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 69.4756L88.6533 69.8648L87.9766 69.4756H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 71.8111L88.6533 72.2038L89.33 71.8111L88.6533 71.4219L87.9766 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 70.6431L89.6396 71.0358L90.3163 70.6431L89.6396 70.2539L88.9629 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 69.4756L86.6865 69.8648L86.0098 69.4756H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 71.8111L86.6865 72.2038L87.3632 71.8111L86.6865 71.4219L86.0098 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 70.6431L87.6689 71.0358L88.3456 70.6431L87.6689 70.2539L86.9922 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 69.4756L84.7197 69.8648L84.043 69.4756H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 71.8111L84.7158 72.2038L85.3925 71.8111L84.7158 71.4219L84.0391 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 70.6432L85.7021 71.0359L86.3788 70.6432L85.7021 70.254L85.0254 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 69.4756L82.749 69.8648L82.0723 69.4756H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 71.8111L82.749 72.2038L83.4257 71.8111L82.749 71.4219L82.0723 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 70.6431L83.7333 71.0358L84.41 70.6431L83.7333 70.2539L83.0566 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 71.0359L82.4404 70.6432L81.7637 70.254V71.0359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 64.8018L92.5908 65.1909L91.9141 64.8018H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 67.1363L92.5908 67.529L93.2675 67.1363L92.5908 66.7471L91.9141 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 69.4751H91.9141L92.5908 69.0859L93.2675 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 64.8018L90.622 65.1909L89.9453 64.8018H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 67.1363L90.622 67.529L91.2987 67.1363L90.622 66.7471L89.9453 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 65.9698L91.6064 66.359L92.2831 65.9698L91.6064 65.5771L90.9297 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 69.4751H89.9453L90.622 69.0859L91.2987 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 68.3044L91.6061 68.6971L92.2828 68.3044L91.6061 67.9152L90.9294 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 64.8018L88.6533 65.1909L87.9766 64.8018H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 67.1363L88.6533 67.529L89.33 67.1363L88.6533 66.7471L87.9766 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 65.9698L89.6396 66.359L90.3163 65.9698L89.6396 65.5771L88.9629 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 69.4751H87.9766L88.6533 69.0859L89.33 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 68.3042L89.6396 68.6969L90.3163 68.3042L89.6396 67.915L88.9629 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 64.8018L86.6865 65.1909L86.0098 64.8018H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 67.1363L86.6865 67.529L87.3632 67.1363L86.6865 66.7471L86.0098 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 65.9698L87.6689 66.359L88.3456 65.9698L87.6689 65.5771L86.9922 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 69.4751H86.0098L86.6865 69.0859L87.3632 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 68.3042L87.6689 68.6969L88.3456 68.3042L87.6689 67.915L86.9922 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 64.8018L84.7197 65.1909L84.043 64.8018H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 67.1363L84.7158 67.529L85.3925 67.1363L84.7158 66.7471L84.0391 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 65.9698L85.7021 66.359L86.3788 65.9698L85.7021 65.5771L85.0254 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 69.4751H84.0391L84.7158 69.0859L85.3925 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 68.3044L85.7021 68.6971L86.3788 68.3044L85.7021 67.9152L85.0254 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 64.8018L82.749 65.1909L82.0723 64.8018H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 67.1363L82.749 67.529L83.4257 67.1363L82.749 66.7471L82.0723 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 65.9698L83.7333 66.359L84.41 65.9698L83.7333 65.5771L83.0566 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 66.359L82.4404 65.9698L81.7637 65.5771V66.359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 68.6971L82.4404 68.3044L81.7637 67.9152V68.6971Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 69.4751H82.0723L82.749 69.0859L83.4257 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 68.3042L83.7333 68.6969L84.41 68.3042L83.7333 67.915L83.0566 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 62.463L92.5908 62.8522L93.2675 62.463L92.5908 62.0703L91.9141 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 64.8018H91.9141L92.5908 64.4091L93.2675 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 62.463L90.622 62.8522L91.2987 62.463L90.622 62.0703L89.9453 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 64.8018H89.9453L90.622 64.4091L91.2987 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 63.6304L91.6064 64.0231L92.2831 63.6304L91.6064 63.2412L90.9297 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 62.463L88.6533 62.8522L89.33 62.463L88.6533 62.0703L87.9766 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 64.8019H87.9766L88.6533 64.4092L89.33 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 63.6304L89.6396 64.0231L90.3163 63.6304L89.6396 63.2412L88.9629 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 62.463L86.6865 62.8522L87.3632 62.463L86.6865 62.0703L86.0098 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 64.8018H86.0098L86.6865 64.4091L87.3632 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 63.6304L87.6689 64.0231L88.3456 63.6304L87.6689 63.2412L86.9922 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 62.463L84.7158 62.8522L85.3925 62.463L84.7158 62.0703L84.0391 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 64.8019H84.0391L84.7158 64.4092L85.3925 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 63.6304L85.7021 64.0231L86.3788 63.6304L85.7021 63.2412L85.0254 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 62.463L82.749 62.8522L83.4257 62.463L82.749 62.0703L82.0723 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 64.0231L82.4404 63.6304L81.7637 63.2412V64.0231Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 64.8019H82.0723L82.749 64.4092L83.4257 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 63.6304L83.7333 64.0231L84.41 63.6304L83.7333 63.2412L83.0566 63.6304Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask35_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M93.5761 71.8135H81.7637V81.1646H93.5761V71.8135Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask35_6587_35280)">
                        <path
                            d="M93.2675 78.8262L92.5908 79.2154L91.9141 78.8262H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 81.1617L92.5908 81.5543L93.2675 81.1617L92.5908 80.7725L91.9141 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 78.8262L90.622 79.2154L89.9453 78.8262H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 81.1617L90.622 81.5543L91.2987 81.1617L90.622 80.7725L89.9453 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 79.9943L91.6064 80.3834L92.2831 79.9943L91.6064 79.6016L90.9297 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 78.8262L88.6533 79.2154L87.9766 78.8262H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 81.1617L88.6533 81.5543L89.33 81.1617L88.6533 80.7725L87.9766 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 79.9943L89.6396 80.3834L90.3163 79.9943L89.6396 79.6016L88.9629 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 78.8262L86.6865 79.2154L86.0098 78.8262H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 81.1617L86.6865 81.5543L87.3632 81.1617L86.6865 80.7725L86.0098 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 79.9943L87.6689 80.3834L88.3456 79.9943L87.6689 79.6016L86.9922 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 78.8262L84.7197 79.2154L84.043 78.8262H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 81.1617L84.7158 81.5543L85.3925 81.1617L84.7158 80.7725L84.0391 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 79.9943L85.7021 80.3834L86.3788 79.9943L85.7021 79.6016L85.0254 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 78.8262L82.749 79.2154L82.0723 78.8262H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 81.1617L82.749 81.5543L83.4257 81.1617L82.749 80.7725L82.0723 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 79.9943L83.7333 80.3834L84.41 79.9943L83.7333 79.6016L83.0566 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 80.3834L82.4404 79.9943L81.7637 79.6016V80.3834Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 74.1484L92.5908 74.5411L91.9141 74.1484H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 76.4873L92.5908 76.8765L93.2675 76.4873L92.5908 76.0946L91.9141 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 78.8263H91.9141L92.5908 78.4336L93.2675 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 74.1484L90.622 74.5411L89.9453 74.1484H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 76.4873L90.622 76.8765L91.2987 76.4873L90.622 76.0946L89.9453 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 75.3169L91.6064 75.7096L92.2831 75.3169L91.6064 74.9277L90.9297 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 78.8263H89.9453L90.622 78.4336L91.2987 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9294 77.655L91.6061 78.0477L92.2828 77.655L91.6061 77.2658L90.9294 77.655Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 74.1484L88.6533 74.5411L87.9766 74.1484H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 76.4874L88.6533 76.8766L89.33 76.4874L88.6533 76.0947L87.9766 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 75.3169L89.6396 75.7096L90.3163 75.3169L89.6396 74.9277L88.9629 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 78.8263H87.9766L88.6533 78.4336L89.33 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 77.6548L89.6396 78.0475L90.3163 77.6548L89.6396 77.2656L88.9629 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 74.1484L86.6865 74.5411L86.0098 74.1484H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 76.4873L86.6865 76.8765L87.3632 76.4873L86.6865 76.0946L86.0098 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 75.3169L87.6689 75.7096L88.3456 75.3169L87.6689 74.9277L86.9922 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 78.8263H86.0098L86.6865 78.4336L87.3632 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 77.6548L87.6689 78.0475L88.3456 77.6548L87.6689 77.2656L86.9922 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 74.1484L84.7197 74.5411L84.043 74.1484H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 76.4874L84.7158 76.8766L85.3925 76.4874L84.7158 76.0947L84.0391 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 75.3169L85.7021 75.7096L86.3788 75.3169L85.7021 74.9277L85.0254 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 78.8263H84.0391L84.7158 78.4336L85.3925 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 77.655L85.7021 78.0477L86.3788 77.655L85.7021 77.2658L85.0254 77.655Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 74.1484L82.749 74.5411L82.0723 74.1484H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 76.4874L82.749 76.8766L83.4257 76.4874L82.749 76.0947L82.0723 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 75.3169L83.7333 75.7096L84.41 75.3169L83.7333 74.9277L83.0566 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 75.7096L82.4404 75.3169L81.7637 74.9277V75.7096Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 78.0477L82.4404 77.655L81.7637 77.2658V78.0477Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 78.8263H82.0723L82.749 78.4336L83.4257 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 77.6548L83.7333 78.0475L84.41 77.6548L83.7333 77.2656L83.0566 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 71.8101L92.5908 72.2028L93.2675 71.8101L92.5908 71.4209L91.9141 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 74.1489H91.9141L92.5908 73.7597L93.2675 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 71.8101L90.622 72.2028L91.2987 71.8101L90.622 71.4209L89.9453 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 74.1489H89.9453L90.622 73.7597L91.2987 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 72.9806L91.6064 73.3698L92.2831 72.9806L91.6064 72.5879L90.9297 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 71.8101L88.6533 72.2028L89.33 71.8101L88.6533 71.4209L87.9766 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 74.149H87.9766L88.6533 73.7598L89.33 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 72.9806L89.6396 73.3698L90.3163 72.9806L89.6396 72.5879L88.9629 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 71.8101L86.6865 72.2028L87.3632 71.8101L86.6865 71.4209L86.0098 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 74.1489H86.0098L86.6865 73.7597L87.3632 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 72.9806L87.6689 73.3698L88.3456 72.9806L87.6689 72.5879L86.9922 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 71.8101L84.7158 72.2028L85.3925 71.8101L84.7158 71.4209L84.0391 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 74.149H84.0391L84.7158 73.7598L85.3925 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 72.9806L85.7021 73.3698L86.3788 72.9806L85.7021 72.5879L85.0254 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 71.8101L82.749 72.2028L83.4257 71.8101L82.749 71.4209L82.0723 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 73.3698L82.4404 72.9806L81.7637 72.5879V73.3698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 74.149H82.0723L82.749 73.7598L83.4257 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 72.9806L83.7333 73.3698L84.41 72.9806L83.7333 72.5879L83.0566 72.9806Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask36_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M93.5761 81.1611H81.7637V90.5122H93.5761V81.1611Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask36_6587_35280)">
                        <path
                            d="M93.2675 88.1731L92.5908 88.5658L91.9141 88.1731H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 90.5119L92.5908 90.9011L93.2675 90.5119L92.5908 90.1192L91.9141 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 88.1731L90.622 88.5658L89.9453 88.1731H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 90.5119L90.622 90.9011L91.2987 90.5119L90.622 90.1192L89.9453 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 89.3413L91.6064 89.734L92.2831 89.3413L91.6064 88.9521L90.9297 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 88.1729L88.6533 88.5656L87.9766 88.1729H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 90.5118L88.6533 90.901L89.33 90.5118L88.6533 90.1191L87.9766 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 89.3413L89.6396 89.734L90.3163 89.3413L89.6396 88.9521L88.9629 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 88.1731L86.6865 88.5658L86.0098 88.1731H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 90.5119L86.6865 90.9011L87.3632 90.5119L86.6865 90.1192L86.0098 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 89.3413L87.6689 89.734L88.3456 89.3413L87.6689 88.9521L86.9922 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 88.1729L84.7197 88.5656L84.043 88.1729H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 90.5118L84.7158 90.901L85.3925 90.5118L84.7158 90.1191L84.0391 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 89.3413L85.7021 89.734L86.3788 89.3413L85.7021 88.9521L85.0254 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 88.1729L82.749 88.5656L82.0723 88.1729H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 90.5118L82.749 90.901L83.4257 90.5118L82.749 90.1191L82.0723 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 89.3413L83.7333 89.734L84.41 89.3413L83.7333 88.9521L83.0566 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 89.734L82.4404 89.3413L81.7637 88.9521V89.734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 83.499L92.5908 83.8882L91.9141 83.499H93.2675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 85.8344L92.5908 86.2271L93.2675 85.8344L92.5908 85.4453L91.9141 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 88.1734H91.9141L92.5908 87.7842L93.2675 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 83.499L90.622 83.8882L89.9453 83.499H91.2987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 85.8344L90.622 86.2271L91.2987 85.8344L90.622 85.4453L89.9453 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 84.6675L91.6064 85.0567L92.2831 84.6675L91.6064 84.2783L90.9297 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 88.1734H89.9453L90.622 87.7842L91.2987 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 87.006L91.6064 87.3952L92.2831 87.006L91.6064 86.6133L90.9297 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 83.499L88.6533 83.8882L87.9766 83.499H89.33Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 85.8345L88.6533 86.2272L89.33 85.8345L88.6533 85.4453L87.9766 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 84.6675L89.6396 85.0567L90.3163 84.6675L89.6396 84.2783L88.9629 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 88.1734H87.9766L88.6533 87.7842L89.33 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 87.006L89.6396 87.3952L90.3163 87.006L89.6396 86.6133L88.9629 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 83.499L86.6865 83.8882L86.0098 83.499H87.3632Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 85.8344L86.6865 86.2271L87.3632 85.8344L86.6865 85.4453L86.0098 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 84.6675L87.6689 85.0567L88.3456 84.6675L87.6689 84.2783L86.9922 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 88.1734H86.0098L86.6865 87.7842L87.3632 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 87.006L87.6689 87.3952L88.3456 87.006L87.6689 86.6133L86.9922 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3964 83.499L84.7197 83.8882L84.043 83.499H85.3964Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 85.8345L84.7158 86.2272L85.3925 85.8345L84.7158 85.4453L84.0391 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 84.6675L85.7021 85.0567L86.3788 84.6675L85.7021 84.2783L85.0254 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 88.1734H84.0391L84.7158 87.7842L85.3925 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 87.006L85.7021 87.3952L86.3788 87.006L85.7021 86.6133L85.0254 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 83.499L82.749 83.8882L82.0723 83.499H83.4257Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 85.8345L82.749 86.2272L83.4257 85.8345L82.749 85.4453L82.0723 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 84.6675L83.7333 85.0567L84.41 84.6675L83.7333 84.2783L83.0566 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 85.0567L82.4404 84.6675L81.7637 84.2783V85.0567Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 87.3952L82.4404 87.006L81.7637 86.6133V87.3952Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 88.1734H82.0723L82.749 87.7842L83.4257 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 87.006L83.7333 87.3952L84.41 87.006L83.7333 86.6133L83.0566 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.9141 81.1607L92.5908 81.5534L93.2675 81.1607L92.5908 80.7715L91.9141 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M93.2675 83.4991H91.9141L92.5908 83.1064L93.2675 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 81.1607L90.622 81.5534L91.2987 81.1607L90.622 80.7715L89.9453 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M91.2987 83.4991H89.9453L90.622 83.1064L91.2987 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 82.3286L91.6064 82.7213L92.2831 82.3286L91.6064 81.9395L90.9297 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 81.1607L88.6533 81.5534L89.33 81.1607L88.6533 80.7715L87.9766 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.33 83.4991H87.9766L88.6533 83.1064L89.33 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 82.3286L89.6396 82.7213L90.3163 82.3286L89.6396 81.9395L88.9629 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 81.1607L86.6865 81.5534L87.3632 81.1607L86.6865 80.7715L86.0098 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.3632 83.4991H86.0098L86.6865 83.1064L87.3632 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 82.3286L87.6689 82.7213L88.3456 82.3286L87.6689 81.9395L86.9922 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 81.1607L84.7158 81.5534L85.3925 81.1607L84.7158 80.7715L84.0391 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.3925 83.4991H84.0391L84.7158 83.1064L85.3925 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 82.3286L85.7021 82.7213L86.3788 82.3286L85.7021 81.9395L85.0254 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 81.1607L82.749 81.5534L83.4257 81.1607L82.749 80.7715L82.0723 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 82.7213L82.4404 82.3286L81.7637 81.9395V82.7213Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.4257 83.4991H82.0723L82.749 83.1064L83.4257 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 82.3286L83.7333 82.7213L84.41 82.3286L83.7333 81.9395L83.0566 82.3286Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask37_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="81"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M93.5761 90.5127H81.7637V99.8638H93.5761V90.5127Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask37_6587_35280)">
                        <path
                            d="M91.9141 90.5127L92.5908 90.9019L93.2675 90.5127L92.5908 90.12L91.9141 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M89.9453 90.5127L90.622 90.9019L91.2987 90.5127L90.622 90.12L89.9453 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M90.9297 91.6801L91.6064 92.0693L92.2831 91.6801L91.6064 91.2909L90.9297 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M87.9766 90.5127L88.6533 90.9019L89.33 90.5127L88.6533 90.12L87.9766 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M88.9629 91.6801L89.6396 92.0693L90.3163 91.6801L89.6396 91.2909L88.9629 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.0098 90.5127L86.6865 90.9019L87.3632 90.5127L86.6865 90.12L86.0098 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M86.9922 91.6801L87.6689 92.0693L88.3456 91.6801L87.6689 91.2909L86.9922 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M84.0391 90.5127L84.7158 90.9019L85.3925 90.5127L84.7158 90.12L84.0391 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M85.0254 91.6801L85.7021 92.0693L86.3788 91.6801L85.7021 91.2909L85.0254 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M82.0723 90.5127L82.749 90.9019L83.4257 90.5127L82.749 90.12L82.0723 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7637 92.0728L82.4404 91.6801L81.7637 91.2909V92.0728Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M83.0566 91.6801L83.7333 92.0693L84.41 91.6801L83.7333 91.2909L83.0566 91.6801Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask38_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="34"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M81.7617 34.4131H69.9492V43.7642H81.7617V34.4131Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask38_6587_35280)">
                        <path
                            d="M81.453 41.4254L80.7763 41.8146L80.0996 41.4254H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 43.7646L80.7744 44.1538L81.4511 43.7646L80.7744 43.3719L80.0977 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 42.204V42.9859L81.082 42.5932L81.7587 42.204Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 41.4254L78.8056 41.8146L78.1289 41.4254H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 43.7646L78.8056 44.1538L79.4823 43.7646L78.8056 43.3719L78.1289 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 42.5933L79.7922 42.986L80.4689 42.5933L79.7922 42.2041L79.1155 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 41.4257L76.8388 41.8148L76.1621 41.4257H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 43.7646L76.8388 44.1538L77.5155 43.7646L76.8388 43.3719L76.1621 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 42.5933L77.8212 42.986L78.4979 42.5933L77.8212 42.2041L77.1445 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 41.4257L74.8701 41.8148L74.1934 41.4257H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 43.7646L74.8701 44.1538L75.5468 43.7646L74.8701 43.3719L74.1934 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 42.5932L75.8525 42.9859L76.5292 42.5932L75.8525 42.204L75.1758 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 41.4257L72.8994 41.8148L72.2227 41.4257H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 43.7646L72.8994 44.1538L73.5761 43.7646L72.8994 43.3719L72.2227 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 42.5932L73.8837 42.9859L74.5604 42.5932L73.8837 42.204L73.207 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 41.4254L70.9326 41.8146L70.2559 41.4254H71.6093Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 43.7646L70.9306 44.1538L71.6073 43.7646L70.9306 43.3719L70.2539 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 42.5933L71.9169 42.986L72.5936 42.5933L71.9169 42.2041L71.2402 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 42.986L70.6259 42.5933L69.9492 42.2041V42.986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 39.0863L80.7744 39.479L81.4511 39.0863L80.7744 38.6971L80.0977 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 37.5302V38.3085L81.082 37.9193L81.7587 37.5302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 41.4252H80.0977L80.7744 41.036L81.4511 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 39.8651V40.647L81.082 40.2578L81.7587 39.8651Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 39.0863L78.8056 39.479L79.4823 39.0863L78.8056 38.6971L78.1289 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 37.9199L79.7919 38.3091L80.4686 37.9199L79.7919 37.5272L79.1152 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 41.4252H78.1289L78.8056 41.036L79.4823 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 40.258L79.7922 40.6472L80.4689 40.258L79.7922 39.8653L79.1155 40.258Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 39.0863L76.8388 39.479L77.5155 39.0863L76.8388 38.6971L76.1621 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 37.9199L77.8212 38.3091L78.4979 37.9199L77.8212 37.5272L77.1445 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 41.4252H76.1621L76.8388 41.036L77.5155 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 40.2581L77.8212 40.6472L78.4979 40.2581L77.8212 39.8654L77.1445 40.2581Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 39.0863L74.8701 39.479L75.5468 39.0863L74.8701 38.6971L74.1934 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 37.9199L75.8544 38.3091L76.5311 37.9199L75.8544 37.5272L75.1777 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 41.4252H74.1934L74.8701 41.036L75.5468 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 40.2578L75.8525 40.647L76.5292 40.2578L75.8525 39.8651L75.1758 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 39.0863L72.8994 39.479L73.5761 39.0863L72.8994 38.6971L72.2227 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 37.9199L73.8837 38.3091L74.5604 37.9199L73.8837 37.5272L73.207 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 41.4252H72.2227L72.8994 41.036L73.5761 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 40.2578L73.8837 40.647L74.5604 40.2578L73.8837 39.8651L73.207 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 39.0863L70.9306 39.479L71.6073 39.0863L70.9306 38.6971L70.2539 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 37.9199L71.915 38.3091L72.5917 37.9199L71.915 37.5272L71.2383 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 38.3091L70.6259 37.9199L69.9492 37.5272V38.3091Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 40.6472L70.6259 40.258L69.9492 39.8653V40.6472Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 41.4252H70.2539L70.9306 41.036L71.6073 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 40.2581L71.9169 40.6472L72.5936 40.2581L71.9169 39.8654L71.2402 40.2581Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask39_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="43"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M81.7617 43.7646H69.9492V53.1157H81.7617V43.7646Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask39_6587_35280)">
                        <path
                            d="M81.453 50.7772L80.7763 51.1664L80.0996 50.7772H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 53.1127L80.7744 53.5054L81.4511 53.1127L80.7744 52.7235L80.0977 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 51.5555V52.3339L81.082 51.9447L81.7587 51.5555Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 50.7772L78.8056 51.1664L78.1289 50.7772H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 53.1127L78.8056 53.5054L79.4823 53.1127L78.8056 52.7235L78.1289 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 51.9453L79.7919 52.3345L80.4686 51.9453L79.7919 51.5526L79.1152 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 50.7772L76.8388 51.1664L76.1621 50.7772H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 53.1127L76.8388 53.5054L77.5155 53.1127L76.8388 52.7235L76.1621 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 51.9453L77.8212 52.3345L78.4979 51.9453L77.8212 51.5526L77.1445 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 50.7772L74.8701 51.1664L74.1934 50.7772H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 53.1127L74.8701 53.5054L75.5468 53.1127L74.8701 52.7235L74.1934 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 51.9453L75.8544 52.3345L76.5311 51.9453L75.8544 51.5526L75.1777 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 50.7772L72.8994 51.1664L72.2227 50.7772H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 53.1127L72.8994 53.5054L73.5761 53.1127L72.8994 52.7235L72.2227 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 51.9453L73.8837 52.3345L74.5604 51.9453L73.8837 51.5526L73.207 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 50.7772L70.9306 51.1664L70.2539 50.7772H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 53.1127L70.9306 53.5054L71.6073 53.1127L70.9306 52.7235L70.2539 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 51.9453L71.915 52.3345L72.5917 51.9453L71.915 51.5526L71.2383 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 52.3345L70.6259 51.9453L69.9492 51.5526V52.3345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.453 46.0995L80.7763 46.4922L80.0996 46.0995H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 48.4385L80.7744 48.8277L81.4511 48.4385L80.7744 48.0458L80.0977 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 46.8788V47.6607L81.082 47.2715L81.7587 46.8788Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 50.7773H80.0977L80.7744 50.3846L81.4511 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 49.2167V49.9986L81.082 49.6059L81.7587 49.2167Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 46.0995L78.8056 46.4922L78.1289 46.0995H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 48.4383L78.8056 48.8275L79.4823 48.4383L78.8056 48.0457L78.1289 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 47.2715L79.7919 47.6607L80.4686 47.2715L79.7919 46.8788L79.1152 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 50.7773H78.1289L78.8056 50.3846L79.4823 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 49.606L79.7922 49.9987L80.4689 49.606L79.7922 49.2169L79.1155 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 46.0995L76.8388 46.4922L76.1621 46.0995H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 48.4385L76.8388 48.8277L77.5155 48.4385L76.8388 48.0458L76.1621 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 47.2715L77.8212 47.6607L78.4979 47.2715L77.8212 46.8788L77.1445 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 50.7773H76.1621L76.8388 50.3846L77.5155 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 49.606L77.8212 49.9987L78.4979 49.606L77.8212 49.2168L77.1445 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 46.0995L74.8701 46.4922L74.1934 46.0995H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 48.4385L74.8701 48.8277L75.5468 48.4385L74.8701 48.0458L74.1934 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 47.2715L75.8544 47.6607L76.5311 47.2715L75.8544 46.8788L75.1777 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 50.7773H74.1934L74.8701 50.3846L75.5468 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 49.6059L75.8525 49.9986L76.5292 49.6059L75.8525 49.2167L75.1758 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 46.0995L72.8994 46.4922L72.2227 46.0995H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 48.4385L72.8994 48.8277L73.5761 48.4385L72.8994 48.0458L72.2227 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 47.2715L73.8837 47.6607L74.5604 47.2715L73.8837 46.8788L73.207 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 50.7773H72.2227L72.8994 50.3846L73.5761 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 49.6059L73.8837 49.9986L74.5604 49.6059L73.8837 49.2167L73.207 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 46.0995L70.9306 46.4922L70.2539 46.0995H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 48.4383L70.9326 48.8275L71.6093 48.4383L70.9326 48.0457L70.2559 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 47.2715L71.915 47.6607L72.5917 47.2715L71.915 46.8788L71.2383 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 47.6607L70.6259 47.2715L69.9492 46.8788V47.6607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 49.9987L70.6259 49.606L69.9492 49.2169V49.9987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 50.7773H70.2539L70.9306 50.3846L71.6073 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 49.606L71.9169 49.9987L72.5936 49.606L71.9169 49.2168L71.2402 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 43.7646L80.7744 44.1538L81.4511 43.7646L80.7744 43.3719L80.0977 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 46.1H80.0977L80.7744 45.7108L81.4511 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 44.5428V45.3212L81.082 44.932L81.7587 44.5428Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 43.7646L78.8056 44.1538L79.4823 43.7646L78.8056 43.3719L78.1289 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 46.0999H78.1289L78.8056 45.7107L79.4823 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 44.9316L79.7919 45.3208L80.4686 44.9316L79.7919 44.5389L79.1152 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 43.7646L76.8388 44.1538L77.5155 43.7646L76.8388 43.3719L76.1621 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 46.1H76.1621L76.8388 45.7108L77.5155 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 44.9316L77.8212 45.3208L78.4979 44.9316L77.8212 44.5389L77.1445 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 43.7646L74.8701 44.1538L75.5468 43.7646L74.8701 43.3719L74.1934 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 46.1H74.1934L74.8701 45.7108L75.5468 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 44.9316L75.8544 45.3208L76.5311 44.9316L75.8544 44.5389L75.1777 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 43.7646L72.8994 44.1538L73.5761 43.7646L72.8994 43.3719L72.2227 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 46.1H72.2227L72.8994 45.7108L73.5761 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 44.9316L73.8837 45.3208L74.5604 44.9316L73.8837 44.5389L73.207 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 43.7646L70.9326 44.1538L71.6093 43.7646L70.9326 43.3719L70.2559 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 45.3208L70.6259 44.9316L69.9492 44.5389V45.3208Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 46.0999H70.2559L70.9326 45.7107L71.6093 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 44.9316L71.915 45.3208L72.5917 44.9316L71.915 44.5389L71.2383 44.9316Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask40_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="53"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M81.7617 53.1123H69.9492V62.4634H81.7617V53.1123Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask40_6587_35280)">
                        <path
                            d="M81.453 60.1251L80.7763 60.5178L80.0996 60.1251H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 62.464L80.7744 62.8532L81.4511 62.464L80.7744 62.0713L80.0977 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 60.9043V61.6862L81.082 61.297L81.7587 60.9043Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 60.1251L78.8056 60.5178L78.1289 60.1251H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 62.464L78.8056 62.8532L79.4823 62.464L78.8056 62.0713L78.1289 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 61.2935L79.7919 61.6862L80.4686 61.2935L79.7919 60.9043L79.1152 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 60.125L76.8388 60.5177L76.1621 60.125H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 62.464L76.8388 62.8532L77.5155 62.464L76.8388 62.0713L76.1621 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 61.2935L77.8212 61.6862L78.4979 61.2935L77.8212 60.9043L77.1445 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 60.125L74.8701 60.5177L74.1934 60.125H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 62.464L74.8701 62.8532L75.5468 62.464L74.8701 62.0713L74.1934 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 61.2935L75.8544 61.6862L76.5311 61.2935L75.8544 60.9043L75.1777 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 60.125L72.8994 60.5177L72.2227 60.125H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 62.464L72.8994 62.8532L73.5761 62.464L72.8994 62.0713L72.2227 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 61.2935L73.8837 61.6862L74.5604 61.2935L73.8837 60.9043L73.207 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 60.1251L70.9326 60.5178L70.2559 60.1251H71.6093Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 62.464L70.9326 62.8532L71.6093 62.464L70.9326 62.0713L70.2559 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 61.2935L71.915 61.6862L72.5917 61.2935L71.915 60.9043L71.2383 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 61.6862L70.6259 61.2935L69.9492 60.9043V61.6862Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.453 55.4512L80.7763 55.8404L80.0996 55.4512H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 57.7902L80.7744 58.1793L81.4511 57.7902L80.7744 57.3975L80.0977 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 56.2305V57.0124L81.082 56.6197L81.7587 56.2305Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 60.1255H80.0977L80.7744 59.7363L81.4511 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 58.5684V59.3467L81.082 58.9575L81.7587 58.5684Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 55.4512L78.8056 55.8404L78.1289 55.4512H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 57.79L78.8056 58.1792L79.4823 57.79L78.8056 57.3973L78.1289 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 56.6197L79.7919 57.0124L80.4686 56.6197L79.7919 56.2305L79.1152 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 60.1255H78.1289L78.8056 59.7363L79.4823 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 58.9571L79.7919 59.3463L80.4686 58.9571L79.7919 58.5645L79.1152 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 55.4512L76.8388 55.8404L76.1621 55.4512H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 57.7902L76.8388 58.1793L77.5155 57.7902L76.8388 57.3975L76.1621 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 56.6197L77.8212 57.0124L78.4979 56.6197L77.8212 56.2305L77.1445 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 60.1255H76.1621L76.8388 59.7363L77.5155 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 58.9571L77.8212 59.3463L78.4979 58.9571L77.8212 58.5645L77.1445 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 55.4512L74.8701 55.8404L74.1934 55.4512H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 57.7902L74.8701 58.1793L75.5468 57.7902L74.8701 57.3975L74.1934 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 56.6197L75.8544 57.0124L76.5311 56.6197L75.8544 56.2305L75.1777 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 60.1255H74.1934L74.8701 59.7363L75.5468 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 58.9571L75.8544 59.3463L76.5311 58.9571L75.8544 58.5645L75.1777 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 55.4512L72.8994 55.8404L72.2227 55.4512H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 57.7902L72.8994 58.1793L73.5761 57.7902L72.8994 57.3975L72.2227 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 56.6197L73.8837 57.0124L74.5604 56.6197L73.8837 56.2305L73.207 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 60.1255H72.2227L72.8994 59.7363L73.5761 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 58.9571L73.8837 59.3463L74.5604 58.9571L73.8837 58.5645L73.207 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 55.4512L70.9306 55.8404L70.2539 55.4512H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 57.79L70.9326 58.1792L71.6093 57.79L70.9326 57.3973L70.2559 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 56.6197L71.915 57.0124L72.5917 56.6197L71.915 56.2305L71.2383 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 57.0124L70.6259 56.6197L69.9492 56.2305V57.0124Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 59.3463L70.6259 58.9571L69.9492 58.5645V59.3463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 60.1255H70.2539L70.9306 59.7363L71.6073 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 58.9571L71.915 59.3463L72.5917 58.9571L71.915 58.5645L71.2383 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 53.1128L80.7744 53.5055L81.4511 53.1128L80.7744 52.7236L80.0977 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 55.4517H80.0977L80.7744 55.0625L81.4511 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 53.8906V54.6725L81.082 54.2833L81.7587 53.8906Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 53.1128L78.8056 53.5055L79.4823 53.1128L78.8056 52.7236L78.1289 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 55.4516H78.1289L78.8056 55.0624L79.4823 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 54.2833L79.7919 54.6725L80.4686 54.2833L79.7919 53.8906L79.1152 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 53.1128L76.8388 53.5055L77.5155 53.1128L76.8388 52.7236L76.1621 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 55.4517H76.1621L76.8388 55.0625L77.5155 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 54.2833L77.8212 54.6725L78.4979 54.2833L77.8212 53.8906L77.1445 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 53.1128L74.8701 53.5055L75.5468 53.1128L74.8701 52.7236L74.1934 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 55.4517H74.1934L74.8701 55.0625L75.5468 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 54.2833L75.8544 54.6725L76.5311 54.2833L75.8544 53.8906L75.1777 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 53.1128L72.8994 53.5055L73.5761 53.1128L72.8994 52.7236L72.2227 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 55.4517H72.2227L72.8994 55.0625L73.5761 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 54.2833L73.8837 54.6725L74.5604 54.2833L73.8837 53.8906L73.207 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 53.1128L70.9326 53.5055L71.6093 53.1128L70.9326 52.7236L70.2559 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 54.6725L70.6259 54.2833L69.9492 53.8906V54.6725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 55.4516H70.2559L70.9326 55.0624L71.6093 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 54.2833L71.915 54.6725L72.5917 54.2833L71.915 53.8906L71.2383 54.2833Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask41_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="62"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M81.7617 62.4629H69.9492V71.814H81.7617V62.4629Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask41_6587_35280)">
                        <path
                            d="M81.453 69.4756L80.7763 69.8648L80.0996 69.4756H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 71.8111L80.7744 72.2038L81.4511 71.8111L80.7744 71.4219L80.0977 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 70.2539V71.0358L81.082 70.6431L81.7587 70.2539Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 69.4756L78.8056 69.8648L78.1289 69.4756H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 71.8111L78.8056 72.2038L79.4823 71.8111L78.8056 71.4219L78.1289 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 70.6432L79.7922 71.0359L80.4689 70.6432L79.7922 70.254L79.1155 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 69.4756L76.8388 69.8648L76.1621 69.4756H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 71.8111L76.8388 72.2038L77.5155 71.8111L76.8388 71.4219L76.1621 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 70.6432L77.8212 71.0359L78.4979 70.6432L77.8212 70.254L77.1445 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 69.4756L74.8701 69.8648L74.1934 69.4756H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 71.8111L74.8701 72.2038L75.5468 71.8111L74.8701 71.4219L74.1934 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 70.6431L75.8525 71.0358L76.5292 70.6431L75.8525 70.2539L75.1758 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 69.4756L72.8994 69.8648L72.2227 69.4756H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 71.8111L72.8994 72.2038L73.5761 71.8111L72.8994 71.4219L72.2227 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 70.6431L73.8837 71.0358L74.5604 70.6431L73.8837 70.2539L73.207 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 69.4756L70.9306 69.8648L70.2539 69.4756H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 71.8111L70.9306 72.2038L71.6073 71.8111L70.9306 71.4219L70.2539 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 70.6432L71.9169 71.0359L72.5936 70.6432L71.9169 70.254L71.2402 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 71.0359L70.6259 70.6432L69.9492 70.254V71.0359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.453 64.8018L80.7763 65.1909L80.0996 64.8018H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 67.1363L80.7744 67.529L81.4511 67.1363L80.7744 66.7471L80.0977 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 65.5771V66.359L81.082 65.9698L81.7587 65.5771Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 69.4751H80.0977L80.7744 69.0859L81.4511 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 67.915V68.6969L81.082 68.3077L81.7587 67.915Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 64.8018L78.8056 65.1909L78.1289 64.8018H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 67.1363L78.8056 67.529L79.4823 67.1363L78.8056 66.7471L78.1289 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 65.9698L79.7919 66.359L80.4686 65.9698L79.7919 65.5771L79.1152 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 69.4751H78.1289L78.8056 69.0859L79.4823 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 68.3044L79.7922 68.6971L80.4689 68.3044L79.7922 67.9152L79.1155 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 64.8018L76.8388 65.1909L76.1621 64.8018H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 67.1363L76.8388 67.529L77.5155 67.1363L76.8388 66.7471L76.1621 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 65.9698L77.8212 66.359L78.4979 65.9698L77.8212 65.5771L77.1445 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 69.4751H76.1621L76.8388 69.0859L77.5155 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 68.3044L77.8212 68.697L78.4979 68.3044L77.8212 67.9152L77.1445 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 64.8018L74.8701 65.1909L74.1934 64.8018H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 67.1363L74.8701 67.529L75.5468 67.1363L74.8701 66.7471L74.1934 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 65.9698L75.8544 66.359L76.5311 65.9698L75.8544 65.5771L75.1777 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 69.4751H74.1934L74.8701 69.0859L75.5468 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 68.3042L75.8525 68.6969L76.5292 68.3042L75.8525 67.915L75.1758 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 64.8018L72.8994 65.1909L72.2227 64.8018H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 67.1363L72.8994 67.529L73.5761 67.1363L72.8994 66.7471L72.2227 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 65.9698L73.8837 66.359L74.5604 65.9698L73.8837 65.5771L73.207 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 69.4751H72.2227L72.8994 69.0859L73.5761 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 68.3042L73.8837 68.6969L74.5604 68.3042L73.8837 67.915L73.207 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 64.8018L70.9326 65.1909L70.2559 64.8018H71.6093Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 67.1363L70.9306 67.529L71.6073 67.1363L70.9306 66.7471L70.2539 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 65.9698L71.915 66.359L72.5917 65.9698L71.915 65.5771L71.2383 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 66.359L70.6259 65.9698L69.9492 65.5771V66.359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 68.6971L70.6259 68.3044L69.9492 67.9152V68.6971Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 69.4751H70.2539L70.9306 69.0859L71.6073 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 68.3044L71.9169 68.697L72.5936 68.3044L71.9169 67.9152L71.2402 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 62.463L80.7744 62.8522L81.4511 62.463L80.7744 62.0703L80.0977 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 64.8019H80.0977L80.7744 64.4092L81.4511 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 63.2412V64.0231L81.082 63.6304L81.7587 63.2412Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 62.463L78.8056 62.8522L79.4823 62.463L78.8056 62.0703L78.1289 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 64.8018H78.1289L78.8056 64.4091L79.4823 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 63.6304L79.7919 64.0231L80.4686 63.6304L79.7919 63.2412L79.1152 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 62.463L76.8388 62.8522L77.5155 62.463L76.8388 62.0703L76.1621 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 64.8019H76.1621L76.8388 64.4092L77.5155 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 63.6304L77.8212 64.0231L78.4979 63.6304L77.8212 63.2412L77.1445 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 62.463L74.8701 62.8522L75.5468 62.463L74.8701 62.0703L74.1934 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 64.8019H74.1934L74.8701 64.4092L75.5468 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 63.6304L75.8544 64.0231L76.5311 63.6304L75.8544 63.2412L75.1777 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 62.463L72.8994 62.8522L73.5761 62.463L72.8994 62.0703L72.2227 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 64.8019H72.2227L72.8994 64.4092L73.5761 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 63.6304L73.8837 64.0231L74.5604 63.6304L73.8837 63.2412L73.207 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 62.463L70.9326 62.8522L71.6093 62.463L70.9326 62.0703L70.2559 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 64.0231L70.6259 63.6304L69.9492 63.2412V64.0231Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 64.8018H70.2559L70.9326 64.4091L71.6093 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 63.6304L71.915 64.0231L72.5917 63.6304L71.915 63.2412L71.2383 63.6304Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask42_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M81.7617 71.8135H69.9492V81.1646H81.7617V71.8135Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask42_6587_35280)">
                        <path
                            d="M81.453 78.8262L80.7763 79.2154L80.0996 78.8262H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 81.1617L80.7744 81.5543L81.4511 81.1617L80.7744 80.7725L80.0977 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 79.6016V80.3834L81.082 79.9943L81.7587 79.6016Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 78.8262L78.8056 79.2154L78.1289 78.8262H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 81.1617L78.8056 81.5543L79.4823 81.1617L78.8056 80.7725L78.1289 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 79.9943L79.7919 80.3834L80.4686 79.9943L79.7919 79.6016L79.1152 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 78.8262L76.8388 79.2154L76.1621 78.8262H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 81.1617L76.8388 81.5543L77.5155 81.1617L76.8388 80.7725L76.1621 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 79.9943L77.8212 80.3834L78.4979 79.9943L77.8212 79.6016L77.1445 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 78.8262L74.8701 79.2154L74.1934 78.8262H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 81.1617L74.8701 81.5543L75.5468 81.1617L74.8701 80.7725L74.1934 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 79.9943L75.8544 80.3834L76.5311 79.9943L75.8544 79.6016L75.1777 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 78.8262L72.8994 79.2154L72.2227 78.8262H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 81.1617L72.8994 81.5543L73.5761 81.1617L72.8994 80.7725L72.2227 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 79.9943L73.8837 80.3834L74.5604 79.9943L73.8837 79.6016L73.207 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 78.8262L70.9306 79.2154L70.2539 78.8262H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2539 81.1617L70.9306 81.5543L71.6073 81.1617L70.9306 80.7725L70.2539 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 79.9943L71.915 80.3834L72.5917 79.9943L71.915 79.6016L71.2383 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 80.3834L70.6259 79.9943L69.9492 79.6016V80.3834Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.453 74.1484L80.7763 74.5411L80.0996 74.1484H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 76.4874L80.7744 76.8766L81.4511 76.4874L80.7744 76.0947L80.0977 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 74.9277V75.7096L81.082 75.3204L81.7587 74.9277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 78.8263H80.0977L80.7744 78.4336L81.4511 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 77.2656V78.0475L81.082 77.6548L81.7587 77.2656Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 74.1484L78.8056 74.5411L78.1289 74.1484H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 76.4873L78.8056 76.8765L79.4823 76.4873L78.8056 76.0946L78.1289 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 75.3169L79.7919 75.7096L80.4686 75.3169L79.7919 74.9277L79.1152 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 78.8263H78.1289L78.8056 78.4336L79.4823 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1155 77.655L79.7922 78.0477L80.4689 77.655L79.7922 77.2658L79.1155 77.655Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 74.1484L76.8388 74.5411L76.1621 74.1484H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 76.4874L76.8388 76.8766L77.5155 76.4874L76.8388 76.0947L76.1621 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 75.3169L77.8212 75.7096L78.4979 75.3169L77.8212 74.9277L77.1445 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 78.8263H76.1621L76.8388 78.4336L77.5155 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 77.6549L77.8212 78.0476L78.4979 77.6549L77.8212 77.2657L77.1445 77.6549Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 74.1484L74.8701 74.5411L74.1934 74.1484H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 76.4874L74.8701 76.8766L75.5468 76.4874L74.8701 76.0947L74.1934 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 75.3169L75.8544 75.7096L76.5311 75.3169L75.8544 74.9277L75.1777 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 78.8263H74.1934L74.8701 78.4336L75.5468 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1758 77.6548L75.8525 78.0475L76.5292 77.6548L75.8525 77.2656L75.1758 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 74.1484L72.8994 74.5411L72.2227 74.1484H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 76.4874L72.8994 76.8766L73.5761 76.4874L72.8994 76.0947L72.2227 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 75.3169L73.8837 75.7096L74.5604 75.3169L73.8837 74.9277L73.207 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 78.8263H72.2227L72.8994 78.4336L73.5761 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 77.6548L73.8837 78.0475L74.5604 77.6548L73.8837 77.2656L73.207 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 74.1484L70.9306 74.5411L70.2539 74.1484H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 76.4873L70.9326 76.8765L71.6093 76.4873L70.9326 76.0946L70.2559 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 75.3169L71.915 75.7096L72.5917 75.3169L71.915 74.9277L71.2383 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 75.7096L70.6259 75.3169L69.9492 74.9277V75.7096Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 78.0477L70.6259 77.655L69.9492 77.2658V78.0477Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 78.8263H70.2539L70.9306 78.4336L71.6073 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2402 77.6549L71.9169 78.0476L72.5936 77.6549L71.9169 77.2657L71.2402 77.6549Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 71.8101L80.7744 72.2028L81.4511 71.8101L80.7744 71.4209L80.0977 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 74.149H80.0977L80.7744 73.7598L81.4511 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 72.5879V73.3698L81.082 72.9806L81.7587 72.5879Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 71.8101L78.8056 72.2028L79.4823 71.8101L78.8056 71.4209L78.1289 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 74.1488H78.1289L78.8056 73.7596L79.4823 74.1488Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 72.9806L79.7919 73.3698L80.4686 72.9806L79.7919 72.5879L79.1152 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 71.8101L76.8388 72.2028L77.5155 71.8101L76.8388 71.4209L76.1621 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 74.149H76.1621L76.8388 73.7598L77.5155 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 72.9806L77.8212 73.3698L78.4979 72.9806L77.8212 72.5879L77.1445 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 71.8101L74.8701 72.2028L75.5468 71.8101L74.8701 71.4209L74.1934 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 74.149H74.1934L74.8701 73.7598L75.5468 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 72.9806L75.8544 73.3698L76.5311 72.9806L75.8544 72.5879L75.1777 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 71.8101L72.8994 72.2028L73.5761 71.8101L72.8994 71.4209L72.2227 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 74.149H72.2227L72.8994 73.7598L73.5761 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 72.9806L73.8837 73.3698L74.5604 72.9806L73.8837 72.5879L73.207 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 71.8101L70.9326 72.2028L71.6093 71.8101L70.9326 71.4209L70.2559 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 73.3698L70.6259 72.9806L69.9492 72.5879V73.3698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 74.1488H70.2559L70.9326 73.7596L71.6093 74.1488Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 72.9806L71.915 73.3698L72.5917 72.9806L71.915 72.5879L71.2383 72.9806Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask43_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M81.7617 81.1611H69.9492V90.5122H81.7617V81.1611Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask43_6587_35280)">
                        <path
                            d="M81.453 88.1731L80.7763 88.5658L80.0996 88.1731H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 90.5118L80.7744 90.901L81.4511 90.5118L80.7744 90.1191L80.0977 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 88.9521V89.734L81.082 89.3413L81.7587 88.9521Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 88.1731L78.8056 88.5658L78.1289 88.1731H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 90.512L78.8056 90.9011L79.4823 90.512L78.8056 90.1193L78.1289 90.512Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 89.3413L79.7919 89.734L80.4686 89.3413L79.7919 88.9521L79.1152 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 88.1729L76.8388 88.5656L76.1621 88.1729H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 90.5118L76.8388 90.901L77.5155 90.5118L76.8388 90.1191L76.1621 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 89.3413L77.8212 89.734L78.4979 89.3413L77.8212 88.9521L77.1445 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 88.1729L74.8701 88.5656L74.1934 88.1729H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 90.5118L74.8701 90.901L75.5468 90.5118L74.8701 90.1191L74.1934 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 89.3413L75.8544 89.734L76.5311 89.3413L75.8544 88.9521L75.1777 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 88.1729L72.8994 88.5656L72.2227 88.1729H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 90.5118L72.8994 90.901L73.5761 90.5118L72.8994 90.1191L72.2227 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 89.3413L73.8837 89.734L74.5604 89.3413L73.8837 88.9521L73.207 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6093 88.1731L70.9326 88.5658L70.2559 88.1731H71.6093Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 90.512L70.9326 90.9011L71.6093 90.512L70.9326 90.1193L70.2559 90.512Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 89.3413L71.915 89.734L72.5917 89.3413L71.915 88.9521L71.2383 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 89.734L70.6259 89.3413L69.9492 88.9521V89.734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.453 83.499L80.7763 83.8882L80.0996 83.499H81.453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 85.8345L80.7744 86.2272L81.4511 85.8345L80.7744 85.4453L80.0977 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 84.2783V85.0602L81.082 84.6675L81.7587 84.2783Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 88.1734H80.0977L80.7744 87.7842L81.4511 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 86.6133V87.3952L81.082 87.006L81.7587 86.6133Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 83.499L78.8056 83.8882L78.1289 83.499H79.4823Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 85.8344L78.8056 86.2271L79.4823 85.8344L78.8056 85.4452L78.1289 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 84.6675L79.7919 85.0567L80.4686 84.6675L79.7919 84.2783L79.1152 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 88.1734H78.1289L78.8056 87.7842L79.4823 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 87.006L79.7919 87.3952L80.4686 87.006L79.7919 86.6133L79.1152 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 83.499L76.8388 83.8882L76.1621 83.499H77.5155Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 85.8345L76.8388 86.2272L77.5155 85.8345L76.8388 85.4453L76.1621 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 84.6675L77.8212 85.0567L78.4979 84.6675L77.8212 84.2783L77.1445 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 88.1734H76.1621L76.8388 87.7842L77.5155 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 87.006L77.8212 87.3952L78.4979 87.006L77.8212 86.6133L77.1445 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 83.499L74.8701 83.8882L74.1934 83.499H75.5468Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 85.8345L74.8701 86.2272L75.5468 85.8345L74.8701 85.4453L74.1934 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 84.6675L75.8544 85.0567L76.5311 84.6675L75.8544 84.2783L75.1777 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 88.1734H74.1934L74.8701 87.7842L75.5468 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 87.006L75.8544 87.3952L76.5311 87.006L75.8544 86.6133L75.1777 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 83.499L72.8994 83.8882L72.2227 83.499H73.5761Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 85.8345L72.8994 86.2272L73.5761 85.8345L72.8994 85.4453L72.2227 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 84.6675L73.8837 85.0567L74.5604 84.6675L73.8837 84.2783L73.207 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 88.1734H72.2227L72.8994 87.7842L73.5761 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 87.006L73.8837 87.3952L74.5604 87.006L73.8837 86.6133L73.207 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 83.499L70.9306 83.8882L70.2539 83.499H71.6073Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 85.8344L70.9326 86.2271L71.6093 85.8344L70.9326 85.4452L70.2559 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 84.6675L71.915 85.0567L72.5917 84.6675L71.915 84.2783L71.2383 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 85.0567L70.6259 84.6675L69.9492 84.2783V85.0567Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 87.3952L70.6259 87.006L69.9492 86.6133V87.3952Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 88.1734H70.2539L70.9306 87.7842L71.6073 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 87.006L71.915 87.3952L72.5917 87.006L71.915 86.6133L71.2383 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M80.0977 81.1607L80.7744 81.5534L81.4511 81.1607L80.7744 80.7715L80.0977 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.4511 83.4991H80.0977L80.7744 83.1064L81.4511 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 81.9395V82.7213L81.082 82.3321L81.7587 81.9395Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 81.1607L78.8056 81.5534L79.4823 81.1607L78.8056 80.7715L78.1289 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.4823 83.4991H78.1289L78.8056 83.1064L79.4823 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 82.3286L79.7919 82.7213L80.4686 82.3286L79.7919 81.9395L79.1152 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 81.1607L76.8388 81.5534L77.5155 81.1607L76.8388 80.7715L76.1621 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.5155 83.4991H76.1621L76.8388 83.1064L77.5155 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 82.3286L77.8212 82.7213L78.4979 82.3286L77.8212 81.9395L77.1445 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 81.1607L74.8701 81.5534L75.5468 81.1607L74.8701 80.7715L74.1934 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.5468 83.4991H74.1934L74.8701 83.1064L75.5468 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 82.3286L75.8544 82.7213L76.5311 82.3286L75.8544 81.9395L75.1777 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 81.1607L72.8994 81.5534L73.5761 81.1607L72.8994 80.7715L72.2227 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.5761 83.4991H72.2227L72.8994 83.1064L73.5761 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 82.3286L73.8837 82.7213L74.5604 82.3286L73.8837 81.9395L73.207 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 81.1607L70.9326 81.5534L71.6093 81.1607L70.9326 80.7715L70.2559 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 82.7213L70.6259 82.3286L69.9492 81.9395V82.7213Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.6073 83.4991H70.2539L70.9306 83.1064L71.6073 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 82.3286L71.915 82.7213L72.5917 82.3286L71.915 81.9395L71.2383 82.3286Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask44_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="69"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M81.7617 90.5127H69.9492V99.8638H81.7617V90.5127Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask44_6587_35280)">
                        <path
                            d="M80.0977 90.5127L80.7744 90.9019L81.4511 90.5127L80.7744 90.12L80.0977 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M81.7587 91.2909V92.0728L81.082 91.6801L81.7587 91.2909Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M78.1289 90.5127L78.8056 90.9019L79.4823 90.5127L78.8056 90.12L78.1289 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M79.1152 91.6801L79.7919 92.0693L80.4686 91.6801L79.7919 91.2909L79.1152 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M76.1621 90.5127L76.8388 90.9019L77.5155 90.5127L76.8388 90.12L76.1621 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M77.1445 91.6801L77.8212 92.0693L78.4979 91.6801L77.8212 91.2909L77.1445 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M74.1934 90.5127L74.8701 90.9019L75.5468 90.5127L74.8701 90.12L74.1934 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M75.1777 91.6801L75.8544 92.0693L76.5311 91.6801L75.8544 91.2909L75.1777 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M72.2227 90.5127L72.8994 90.9019L73.5761 90.5127L72.8994 90.12L72.2227 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M73.207 91.6801L73.8837 92.0693L74.5604 91.6801L73.8837 91.2909L73.207 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M70.2559 90.5127L70.9326 90.9019L71.6093 90.5127L70.9326 90.12L70.2559 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9492 92.0728L70.6259 91.6801L69.9492 91.2909V92.0728Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M71.2383 91.6801L71.915 92.0693L72.5917 91.6801L71.915 91.2909L71.2383 91.6801Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask45_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="34"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M69.9531 34.4131H58.1406V43.7642H69.9531V34.4131Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask45_6587_35280)">
                        <path
                            d="M69.6444 41.4254L68.9677 41.8146L68.291 41.4254H69.6444Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.291 43.7646L68.9677 44.1538L69.6444 43.7646L68.9677 43.3719L68.291 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9523 42.2041V42.986L69.2756 42.5933L69.9523 42.2041Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6757 41.4254L66.999 41.8146L66.3223 41.4254H67.6757Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3203 43.7646L66.997 44.1538L67.6737 43.7646L66.997 43.3719L66.3203 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3064 42.5933L67.9831 42.986L68.6598 42.5933L67.9831 42.2041L67.3064 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.7069 41.4257L65.0302 41.8148L64.3535 41.4257H65.7069Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3535 43.7646L65.0302 44.1538L65.7069 43.7646L65.0302 43.3719L64.3535 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3398 42.5932L66.0165 42.9859L66.6932 42.5932L66.0165 42.204L65.3398 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7401 41.4254L63.0634 41.8146L62.3867 41.4254H63.7401Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3867 43.7646L63.0634 44.1538L63.7366 43.7646L63.0634 43.3719L62.3867 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 42.5932L64.0439 42.9859L64.7206 42.5932L64.0439 42.204L63.3672 42.5932Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 41.4257L61.0908 41.8148L60.4141 41.4257H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 43.7646L61.0908 44.1538L61.7675 43.7646L61.0908 43.3719L60.4141 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4023 42.5933L62.079 42.986L62.7557 42.5933L62.079 42.2041L61.4023 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8026 41.4257L59.1259 41.8148L58.4492 41.4257H59.8026Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4492 43.7646L59.1259 44.1538L59.8026 43.7646L59.1259 43.3719L58.4492 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4316 42.5933L60.1083 42.986L60.785 42.5933L60.1083 42.2041L59.4316 42.5933Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1406 42.986L58.8173 42.5933L58.1406 42.2041V42.986Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.291 39.0863L68.9677 39.479L69.6444 39.0863L68.9677 38.6971L68.291 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9523 37.5302V38.3085L69.2756 37.9193L69.9523 37.5302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6444 41.4252H68.291L68.9677 41.036L69.6444 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9523 39.8653V40.6472L69.2756 40.258L69.9523 39.8653Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3203 39.0863L66.997 39.479L67.6737 39.0863L66.997 38.6971L66.3203 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3066 37.9199L67.9833 38.3091L68.66 37.9199L67.9833 37.5272L67.3066 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 41.4252H66.3203L66.997 41.036L67.6737 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3064 40.258L67.9831 40.6472L68.6598 40.258L67.9831 39.8653L67.3064 40.258Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3535 39.0863L65.0302 39.479L65.7069 39.0863L65.0302 38.6971L64.3535 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3398 37.9199L66.0165 38.3091L66.6932 37.9199L66.0165 37.5272L65.3398 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.7069 41.4252H64.3535L65.0302 41.036L65.7069 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3398 40.2578L66.0165 40.647L66.6932 40.2578L66.0165 39.8651L65.3398 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3867 39.0863L63.0634 39.479L63.7366 39.0863L63.0634 38.6971L62.3867 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 37.9199L64.0439 38.3091L64.7206 37.9199L64.0439 37.5272L63.3672 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7401 41.4252H62.3867L63.0634 41.036L63.7401 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 40.2578L64.0439 40.647L64.7206 40.2578L64.0439 39.8651L63.3672 40.2578Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 39.0863L61.0908 39.479L61.7675 39.0863L61.0908 38.6971L60.4141 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4023 37.9199L62.079 38.3091L62.7557 37.9199L62.079 37.5272L61.4023 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 41.4252H60.4141L61.0908 41.036L61.7675 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4023 40.258L62.079 40.6472L62.7557 40.258L62.079 39.8653L61.4023 40.258Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4492 39.0863L59.1259 39.479L59.8026 39.0863L59.1259 38.6971L58.4492 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 37.9199L60.1064 38.3091L60.7831 37.9199L60.1064 37.5272L59.4297 37.9199Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1406 38.3091L58.8173 37.9199L58.1406 37.5272V38.3091Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1406 40.6472L58.8173 40.258L58.1406 39.8653V40.6472Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8026 41.4252H58.4492L59.1259 41.036L59.8026 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4316 40.2581L60.1083 40.6472L60.785 40.2581L60.1083 39.8654L59.4316 40.2581Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask46_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="43"
                        width="12"
                        height="11"
                    >
                        <path
                            d="M69.9511 43.7646H58.1387V53.1157H69.9511V43.7646Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask46_6587_35280)">
                        <path
                            d="M69.6425 50.7772L68.9658 51.1664L68.2891 50.7772H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 53.1127L68.9658 53.5054L69.6425 53.1127L68.9658 52.7235L68.2891 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 51.5557V52.334L69.2737 51.9449L69.9504 51.5557Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 50.7772L66.997 51.1664L66.3203 50.7772H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3164 53.1127L66.9931 53.5054L67.6698 53.1127L66.9931 52.7235L66.3164 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 51.9453L67.9814 52.3345L68.6581 51.9453L67.9814 51.5526L67.3047 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 50.7772L65.0283 51.1664L64.3516 50.7772H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 53.1127L65.0283 53.5054L65.705 53.1127L65.0283 52.7235L64.3516 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 51.9453L66.0146 52.3345L66.6913 51.9453L66.0146 51.5526L65.3379 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 50.7772L63.0615 51.1664L62.3848 50.7772H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 53.1127L63.0615 53.5054L63.7347 53.1127L63.0615 52.7235L62.3848 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 51.9453L64.0439 52.3345L64.7206 51.9453L64.0439 51.5526L63.3672 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 50.7772L61.0908 51.1664L60.4141 50.7772H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 53.1127L61.0908 53.5054L61.7675 53.1127L61.0908 52.7235L60.4141 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 51.9453L62.0771 52.3345L62.7538 51.9453L62.0771 51.5526L61.4004 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 50.7772L59.124 51.1664L58.4473 50.7772H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4473 53.1127L59.124 53.5054L59.8007 53.1127L59.124 52.7235L58.4473 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 51.9453L60.1064 52.3345L60.7831 51.9453L60.1064 51.5526L59.4297 51.9453Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 52.3345L58.8154 51.9453L58.1387 51.5526V52.3345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 46.0995L68.9658 46.4922L68.2891 46.0995H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 48.4383L68.9658 48.8275L69.6425 48.4383L68.9658 48.0456L68.2891 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 46.8788V47.6607L69.2734 47.2715L69.9501 46.8788Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 50.7773H68.2891L68.9658 50.3846L69.6425 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 49.2169V49.9987L69.2737 49.606L69.9504 49.2169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 46.0995L66.997 46.4922L66.3203 46.0995H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 48.4383L66.9951 48.8275L67.6718 48.4383L66.9951 48.0457L66.3184 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 47.2715L67.9814 47.6607L68.6581 47.2715L67.9814 46.8788L67.3047 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 50.7773H66.3164L66.9931 50.3846L67.6698 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3044 49.606L67.9811 49.9987L68.6578 49.606L67.9811 49.2169L67.3044 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 46.0995L65.0283 46.4922L64.3516 46.0995H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 48.4385L65.0283 48.8277L65.705 48.4385L65.0283 48.0458L64.3516 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 47.2715L66.0146 47.6607L66.6913 47.2715L66.0146 46.8788L65.3379 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 50.7773H64.3516L65.0283 50.3846L65.705 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 49.6059L66.0146 49.9986L66.6913 49.6059L66.0146 49.2167L65.3379 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 46.0995L63.0615 46.4922L62.3848 46.0995H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 48.4383L63.0615 48.8275L63.7347 48.4383L63.0615 48.0456L62.3848 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 47.2715L64.0439 47.6607L64.7206 47.2715L64.0439 46.8788L63.3672 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 50.7773H62.3848L63.0615 50.3846L63.7382 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 49.6059L64.0439 49.9986L64.7206 49.6059L64.0439 49.2167L63.3672 49.6059Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 46.0995L61.0908 46.4922L60.4141 46.0995H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 48.4385L61.0908 48.8277L61.7675 48.4385L61.0908 48.0458L60.4141 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 47.2715L62.0771 47.6607L62.7538 47.2715L62.0771 46.8788L61.4004 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 50.7773H60.4141L61.0908 50.3846L61.7675 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 49.606L62.0771 49.9987L62.7538 49.606L62.0771 49.2169L61.4004 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 46.0995L59.124 46.4922L58.4473 46.0995H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 48.4385L59.122 48.8277L59.7987 48.4385L59.122 48.0458L58.4453 48.4385Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 47.2715L60.1064 47.6607L60.7831 47.2715L60.1064 46.8788L59.4297 47.2715Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 47.6607L58.8154 47.2715L58.1387 46.8788V47.6607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 49.9987L58.8154 49.606L58.1387 49.2169V49.9987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 50.7773H58.4473L59.124 50.3846L59.8007 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 49.606L60.1064 49.9987L60.7831 49.606L60.1064 49.2168L59.4297 49.606Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 43.7646L68.9658 44.1538L69.6425 43.7646L68.9658 43.3719L68.2891 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 46.0999H68.2891L68.9658 45.7108L69.6425 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 44.5428V45.3212L69.2734 44.932L69.9501 44.5428Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 43.7646L66.9951 44.1538L67.6718 43.7646L66.9951 43.3719L66.3184 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6718 46.0999H66.3184L66.9951 45.7107L67.6718 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 44.9316L67.9814 45.3208L68.6581 44.9316L67.9814 44.5389L67.3047 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 43.7646L65.0283 44.1538L65.705 43.7646L65.0283 43.3719L64.3516 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 46.1H64.3516L65.0283 45.7108L65.705 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 44.9316L66.0146 45.3208L66.6913 44.9316L66.0146 44.5389L65.3379 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 43.7646L63.0615 44.1538L63.7347 43.7646L63.0615 43.3719L62.3848 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 46.0999H62.3848L63.0615 45.7108L63.7382 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 44.9316L64.0439 45.3208L64.7206 44.9316L64.0439 44.5389L63.3672 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 43.7646L61.0908 44.1538L61.7675 43.7646L61.0908 43.3719L60.4141 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 46.1H60.4141L61.0908 45.7108L61.7675 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 44.9316L62.0771 45.3208L62.7538 44.9316L62.0771 44.5389L61.4004 44.9316Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 43.7646L59.122 44.1538L59.7987 43.7646L59.122 43.3719L58.4453 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 45.3208L58.8154 44.9316L58.1387 44.5389V45.3208Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 46.1H58.4453L59.122 45.7108L59.7987 46.1Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 44.9316L60.1064 45.3208L60.7831 44.9316L60.1064 44.5389L59.4297 44.9316Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask47_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="53"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M69.9511 53.1123H58.1387V62.4634H69.9511V53.1123Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask47_6587_35280)">
                        <path
                            d="M69.6425 60.1251L68.9658 60.5178L68.2891 60.1251H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 62.464L68.9658 62.8532L69.6425 62.464L68.9658 62.0713L68.2891 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 60.9043V61.6862L69.2734 61.297L69.9501 60.9043Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 60.1251L66.997 60.5178L66.3203 60.1251H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 62.464L66.9951 62.8532L67.6718 62.464L66.9951 62.0713L66.3184 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 61.2935L67.9814 61.6862L68.6581 61.2935L67.9814 60.9043L67.3047 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 60.125L65.0283 60.5177L64.3516 60.125H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 62.464L65.0283 62.8532L65.705 62.464L65.0283 62.0713L64.3516 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 61.2935L66.0146 61.6862L66.6913 61.2935L66.0146 60.9043L65.3379 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 60.1251L63.0615 60.5178L62.3848 60.1251H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 62.464L63.0615 62.8532L63.7347 62.464L63.0615 62.0713L62.3848 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 61.2935L64.0439 61.6862L64.7206 61.2935L64.0439 60.9043L63.3672 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 60.125L61.0908 60.5177L60.4141 60.125H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 62.464L61.0908 62.8532L61.7675 62.464L61.0908 62.0713L60.4141 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 61.2935L62.0771 61.6862L62.7538 61.2935L62.0771 60.9043L61.4004 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 60.125L59.122 60.5177L58.4453 60.125H59.7987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 62.464L59.122 62.8532L59.7987 62.464L59.122 62.0713L58.4453 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 61.2935L60.1064 61.6862L60.7831 61.2935L60.1064 60.9043L59.4297 61.2935Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 61.6862L58.8154 61.2935L58.1387 60.9043V61.6862Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 55.4512L68.9658 55.8404L68.2891 55.4512H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 57.79L68.9658 58.1792L69.6425 57.79L68.9658 57.3973L68.2891 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 56.2305V57.0124L69.2734 56.6197L69.9501 56.2305Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 60.1255H68.2891L68.9658 59.7363L69.6425 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 58.5685V59.3469L69.2737 58.9577L69.9504 58.5685Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 55.4512L66.997 55.8404L66.3203 55.4512H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 57.79L66.9951 58.1792L67.6718 57.79L66.9951 57.3973L66.3184 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 56.6197L67.9814 57.0124L68.6581 56.6197L67.9814 56.2305L67.3047 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 60.1255H66.3164L66.9931 59.7363L67.6698 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 58.9571L67.9814 59.3463L68.6581 58.9571L67.9814 58.5645L67.3047 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 55.4512L65.0283 55.8404L64.3516 55.4512H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 57.7902L65.0283 58.1793L65.705 57.7902L65.0283 57.3975L64.3516 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 56.6197L66.0146 57.0124L66.6913 56.6197L66.0146 56.2305L65.3379 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 60.1255H64.3516L65.0283 59.7363L65.705 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 58.9571L66.0146 59.3463L66.6913 58.9571L66.0146 58.5645L65.3379 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 55.4512L63.0615 55.8404L62.3848 55.4512H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 57.79L63.0615 58.1792L63.7347 57.79L63.0615 57.3973L62.3848 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 56.6197L64.0439 57.0124L64.7206 56.6197L64.0439 56.2305L63.3672 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 60.1255H62.3848L63.0615 59.7363L63.7382 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 58.9571L64.0439 59.3463L64.7206 58.9571L64.0439 58.5645L63.3672 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 55.4512L61.0908 55.8404L60.4141 55.4512H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 57.7902L61.0908 58.1793L61.7675 57.7902L61.0908 57.3975L60.4141 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 56.6197L62.0771 57.0124L62.7538 56.6197L62.0771 56.2305L61.4004 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 60.1255H60.4141L61.0908 59.7363L61.7675 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 58.9571L62.0771 59.3463L62.7538 58.9571L62.0771 58.5645L61.4004 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 55.4512L59.124 55.8404L58.4473 55.4512H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 57.7902L59.122 58.1793L59.7987 57.7902L59.122 57.3975L58.4453 57.7902Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 56.6197L60.1064 57.0124L60.7831 56.6197L60.1064 56.2305L59.4297 56.6197Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 57.0124L58.8154 56.6197L58.1387 56.2305V57.0124Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 59.3463L58.8154 58.9571L58.1387 58.5645V59.3463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 60.1255H58.4473L59.124 59.7363L59.8007 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 58.9571L60.1064 59.3463L60.7831 58.9571L60.1064 58.5645L59.4297 58.9571Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 53.1128L68.9658 53.5055L69.6425 53.1128L68.9658 52.7236L68.2891 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 55.4516H68.2891L68.9658 55.0624L69.6425 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 53.8906V54.6725L69.2734 54.2833L69.9501 53.8906Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 53.1128L66.9951 53.5055L67.6718 53.1128L66.9951 52.7236L66.3184 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6718 55.4516H66.3184L66.9951 55.0624L67.6718 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 54.2833L67.9814 54.6725L68.6581 54.2833L67.9814 53.8906L67.3047 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 53.1128L65.0283 53.5055L65.705 53.1128L65.0283 52.7236L64.3516 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 55.4517H64.3516L65.0283 55.0625L65.705 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 54.2833L66.0146 54.6725L66.6913 54.2833L66.0146 53.8906L65.3379 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 53.1128L63.0615 53.5055L63.7347 53.1128L63.0615 52.7236L62.3848 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 55.4516H62.3848L63.0615 55.0624L63.7382 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 54.2833L64.0439 54.6725L64.7206 54.2833L64.0439 53.8906L63.3672 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 53.1128L61.0908 53.5055L61.7675 53.1128L61.0908 52.7236L60.4141 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 55.4517H60.4141L61.0908 55.0625L61.7675 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 54.2833L62.0771 54.6725L62.7538 54.2833L62.0771 53.8906L61.4004 54.2833Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 53.1128L59.122 53.5055L59.7987 53.1128L59.122 52.7236L58.4453 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 54.6725L58.8154 54.2833L58.1387 53.8906V54.6725Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 55.4517H58.4453L59.122 55.0625L59.7987 55.4517Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 54.2833L60.1064 54.6725L60.7831 54.2833L60.1064 53.8906L59.4297 54.2833Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask48_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="62"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M69.9511 62.4629H58.1387V71.814H69.9511V62.4629Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask48_6587_35280)">
                        <path
                            d="M69.6425 69.4756L68.9658 69.8648L68.2891 69.4756H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 71.8111L68.9658 72.2038L69.6425 71.8111L68.9658 71.4219L68.2891 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 70.254V71.0359L69.2737 70.6432L69.9504 70.254Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 69.4756L66.997 69.8648L66.3203 69.4756H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3164 71.8111L66.9931 72.2038L67.6698 71.8111L66.9931 71.4219L66.3164 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3044 70.6432L67.9811 71.0359L68.6578 70.6432L67.9811 70.254L67.3044 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 69.4756L65.0283 69.8648L64.3516 69.4756H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 71.8111L65.0283 72.2038L65.705 71.8111L65.0283 71.4219L64.3516 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 70.6431L66.0146 71.0358L66.6913 70.6431L66.0146 70.2539L65.3379 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 69.4756L63.0615 69.8648L62.3848 69.4756H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 71.8111L63.0615 72.2038L63.7347 71.8111L63.0615 71.4219L62.3848 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 70.6431L64.0439 71.0358L64.7206 70.6431L64.0439 70.2539L63.3672 70.6431Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 69.4756L61.0908 69.8648L60.4141 69.4756H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 71.8111L61.0908 72.2038L61.7675 71.8111L61.0908 71.4219L60.4141 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 70.6432L62.0771 71.0359L62.7538 70.6432L62.0771 70.254L61.4004 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 69.4756L59.124 69.8648L58.4473 69.4756H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4473 71.8111L59.124 72.2038L59.8007 71.8111L59.124 71.4219L58.4473 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 70.6432L60.1064 71.0359L60.7831 70.6432L60.1064 70.254L59.4297 70.6432Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 71.0359L58.8154 70.6432L58.1387 70.254V71.0359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 64.8018L68.9658 65.1909L68.2891 64.8018H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 67.1363L68.9658 67.529L69.6425 67.1363L68.9658 66.7471L68.2891 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 65.5771V66.359L69.2734 65.9698L69.9501 65.5771Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 69.4751H68.2891L68.9658 69.0859L69.6425 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 67.9152V68.6971L69.2737 68.3079L69.9504 67.9152Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 64.8018L66.997 65.1909L66.3203 64.8018H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3164 67.1363L66.9931 67.529L67.6698 67.1363L66.9931 66.7471L66.3164 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 65.9698L67.9814 66.359L68.6581 65.9698L67.9814 65.5771L67.3047 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 69.4751H66.3164L66.9931 69.0859L67.6698 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3044 68.3044L67.9811 68.6971L68.6578 68.3044L67.9811 67.9152L67.3044 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 64.8018L65.0283 65.1909L64.3516 64.8018H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 67.1363L65.0283 67.529L65.705 67.1363L65.0283 66.7471L64.3516 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 65.9698L66.0146 66.359L66.6913 65.9698L66.0146 65.5771L65.3379 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 69.4751H64.3516L65.0283 69.0859L65.705 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 68.3042L66.0146 68.6969L66.6913 68.3042L66.0146 67.915L65.3379 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 64.8018L63.0615 65.1909L62.3848 64.8018H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 67.1363L63.0615 67.529L63.7347 67.1363L63.0615 66.7471L62.3848 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 65.9698L64.0439 66.359L64.7206 65.9698L64.0439 65.5771L63.3672 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 69.4751H62.3848L63.0615 69.0859L63.7382 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 68.3042L64.0439 68.6969L64.7206 68.3042L64.0439 67.915L63.3672 68.3042Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 64.8018L61.0908 65.1909L60.4141 64.8018H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 67.1363L61.0908 67.529L61.7675 67.1363L61.0908 66.7471L60.4141 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 65.9698L62.0771 66.359L62.7538 65.9698L62.0771 65.5771L61.4004 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 69.4751H60.4141L61.0908 69.0859L61.7675 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 68.3044L62.0771 68.6971L62.7538 68.3044L62.0771 67.9152L61.4004 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 64.8018L59.122 65.1909L58.4453 64.8018H59.7987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4473 67.1363L59.124 67.529L59.8007 67.1363L59.124 66.7471L58.4473 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 65.9698L60.1064 66.359L60.7831 65.9698L60.1064 65.5771L59.4297 65.9698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 66.359L58.8154 65.9698L58.1387 65.5771V66.359Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 68.6971L58.8154 68.3044L58.1387 67.9152V68.6971Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 69.4751H58.4473L59.124 69.0859L59.8007 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 68.3044L60.1064 68.697L60.7831 68.3044L60.1064 67.9152L59.4297 68.3044Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 62.463L68.9658 62.8522L69.6425 62.463L68.9658 62.0703L68.2891 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 64.8018H68.2891L68.9658 64.4091L69.6425 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 63.2412V64.0231L69.2734 63.6304L69.9501 63.2412Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 62.463L66.9951 62.8522L67.6718 62.463L66.9951 62.0703L66.3184 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6718 64.8018H66.3184L66.9951 64.4091L67.6718 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 63.6304L67.9814 64.0231L68.6581 63.6304L67.9814 63.2412L67.3047 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 62.463L65.0283 62.8522L65.705 62.463L65.0283 62.0703L64.3516 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 64.8019H64.3516L65.0283 64.4092L65.705 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 63.6304L66.0146 64.0231L66.6913 63.6304L66.0146 63.2412L65.3379 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 62.463L63.0615 62.8522L63.7347 62.463L63.0615 62.0703L62.3848 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 64.8018H62.3848L63.0615 64.4091L63.7382 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 63.6304L64.0439 64.0231L64.7206 63.6304L64.0439 63.2412L63.3672 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 62.463L61.0908 62.8522L61.7675 62.463L61.0908 62.0703L60.4141 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 64.8019H60.4141L61.0908 64.4092L61.7675 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 63.6304L62.0771 64.0231L62.7538 63.6304L62.0771 63.2412L61.4004 63.6304Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 62.463L59.122 62.8522L59.7987 62.463L59.122 62.0703L58.4453 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 64.0231L58.8154 63.6304L58.1387 63.2412V64.0231Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 64.8019H58.4453L59.122 64.4092L59.7987 64.8019Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 63.6304L60.1064 64.0231L60.7831 63.6304L60.1064 63.2412L59.4297 63.6304Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask49_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="71"
                        width="12"
                        height="11"
                    >
                        <path
                            d="M69.9511 71.8135H58.1387V81.1646H69.9511V71.8135Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask49_6587_35280)">
                        <path
                            d="M69.6425 78.8262L68.9658 79.2154L68.2891 78.8262H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 81.1617L68.9658 81.5543L69.6425 81.1617L68.9658 80.7725L68.2891 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 79.6016V80.3834L69.2734 79.9943L69.9501 79.6016Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 78.8262L66.997 79.2154L66.3203 78.8262H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3164 81.1617L66.9931 81.5543L67.6698 81.1617L66.9931 80.7725L66.3164 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 79.9943L67.9814 80.3834L68.6581 79.9943L67.9814 79.6016L67.3047 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 78.8262L65.0283 79.2154L64.3516 78.8262H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 81.1617L65.0283 81.5543L65.705 81.1617L65.0283 80.7725L64.3516 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 79.9943L66.0146 80.3834L66.6913 79.9943L66.0146 79.6016L65.3379 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 78.8262L63.0615 79.2154L62.3848 78.8262H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 81.1617L63.0615 81.5543L63.7347 81.1617L63.0615 80.7725L62.3848 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 79.9943L64.0439 80.3834L64.7206 79.9943L64.0439 79.6016L63.3672 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 78.8262L61.0908 79.2154L60.4141 78.8262H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 81.1617L61.0908 81.5543L61.7675 81.1617L61.0908 80.7725L60.4141 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 79.9943L62.0771 80.3834L62.7538 79.9943L62.0771 79.6016L61.4004 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 78.8262L59.124 79.2154L58.4473 78.8262H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4473 81.1617L59.124 81.5543L59.8007 81.1617L59.124 80.7725L58.4473 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 79.9943L60.1064 80.3834L60.7831 79.9943L60.1064 79.6016L59.4297 79.9943Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 80.3834L58.8154 79.9943L58.1387 79.6016V80.3834Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 74.1484L68.9658 74.5411L68.2891 74.1484H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 76.4873L68.9658 76.8765L69.6425 76.4873L68.9658 76.0946L68.2891 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 74.9277V75.7096L69.2734 75.3204L69.9501 74.9277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 78.8263H68.2891L68.9658 78.4336L69.6425 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9504 77.2658V78.0477L69.2737 77.655L69.9504 77.2658Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 74.1484L66.997 74.5411L66.3203 74.1484H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 76.4873L66.9951 76.8765L67.6718 76.4873L66.9951 76.0946L66.3184 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 75.3169L67.9814 75.7096L68.6581 75.3169L67.9814 74.9277L67.3047 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 78.8263H66.3164L66.9931 78.4336L67.6698 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3044 77.655L67.9811 78.0477L68.6578 77.655L67.9811 77.2658L67.3044 77.655Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 74.1484L65.0283 74.5411L64.3516 74.1484H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 76.4874L65.0283 76.8766L65.705 76.4874L65.0283 76.0947L64.3516 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 75.3169L66.0146 75.7096L66.6913 75.3169L66.0146 74.9277L65.3379 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 78.8263H64.3516L65.0283 78.4336L65.705 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 77.6548L66.0146 78.0475L66.6913 77.6548L66.0146 77.2656L65.3379 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 74.1484L63.0615 74.5411L62.3848 74.1484H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 76.4873L63.0615 76.8765L63.7347 76.4873L63.0615 76.0946L62.3848 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 75.3169L64.0439 75.7096L64.7206 75.3169L64.0439 74.9277L63.3672 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 78.8263H62.3848L63.0615 78.4336L63.7382 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 77.6548L64.0439 78.0475L64.7206 77.6548L64.0439 77.2656L63.3672 77.6548Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 74.1484L61.0908 74.5411L60.4141 74.1484H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 76.4874L61.0908 76.8766L61.7675 76.4874L61.0908 76.0947L60.4141 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 75.3169L62.0771 75.7096L62.7538 75.3169L62.0771 74.9277L61.4004 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 78.8263H60.4141L61.0908 78.4336L61.7675 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 77.655L62.0771 78.0477L62.7538 77.655L62.0771 77.2658L61.4004 77.655Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 74.1484L59.124 74.5411L58.4473 74.1484H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 76.4874L59.122 76.8766L59.7987 76.4874L59.122 76.0947L58.4453 76.4874Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 75.3169L60.1064 75.7096L60.7831 75.3169L60.1064 74.9277L59.4297 75.3169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 75.7096L58.8154 75.3169L58.1387 74.9277V75.7096Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 78.0477L58.8154 77.655L58.1387 77.2658V78.0477Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 78.8263H58.4473L59.124 78.4336L59.8007 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 77.6549L60.1064 78.0476L60.7831 77.6549L60.1064 77.2657L59.4297 77.6549Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 71.8101L68.9658 72.2028L69.6425 71.8101L68.9658 71.4209L68.2891 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 74.1489H68.2891L68.9658 73.7597L69.6425 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 72.5879V73.3698L69.2734 72.9806L69.9501 72.5879Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 71.8101L66.9951 72.2028L67.6718 71.8101L66.9951 71.4209L66.3184 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6718 74.1488H66.3184L66.9951 73.7596L67.6718 74.1488Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 72.9806L67.9814 73.3698L68.6581 72.9806L67.9814 72.5879L67.3047 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 71.8101L65.0283 72.2028L65.705 71.8101L65.0283 71.4209L64.3516 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 74.149H64.3516L65.0283 73.7598L65.705 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 72.9806L66.0146 73.3698L66.6913 72.9806L66.0146 72.5879L65.3379 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 71.8101L63.0615 72.2028L63.7347 71.8101L63.0615 71.4209L62.3848 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 74.1489H62.3848L63.0615 73.7597L63.7382 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 72.9806L64.0439 73.3698L64.7206 72.9806L64.0439 72.5879L63.3672 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 71.8101L61.0908 72.2028L61.7675 71.8101L61.0908 71.4209L60.4141 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 74.149H60.4141L61.0908 73.7598L61.7675 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 72.9806L62.0771 73.3698L62.7538 72.9806L62.0771 72.5879L61.4004 72.9806Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 71.8101L59.122 72.2028L59.7987 71.8101L59.122 71.4209L58.4453 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 73.3698L58.8154 72.9806L58.1387 72.5879V73.3698Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 74.149H58.4453L59.122 73.7598L59.7987 74.149Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 72.9806L60.1064 73.3698L60.7831 72.9806L60.1064 72.5879L59.4297 72.9806Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask50_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="81"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M69.9511 81.1611H58.1387V90.5122H69.9511V81.1611Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask50_6587_35280)">
                        <path
                            d="M69.6425 88.1731L68.9658 88.5658L68.2891 88.1731H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 90.5119L68.9658 90.9011L69.6425 90.5119L68.9658 90.1192L68.2891 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 88.9521V89.734L69.2734 89.3413L69.9501 88.9521Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 88.1731L66.997 88.5658L66.3203 88.1731H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 90.512L66.9951 90.9011L67.6718 90.512L66.9951 90.1193L66.3184 90.512Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 89.3413L67.9814 89.734L68.6581 89.3413L67.9814 88.9521L67.3047 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 88.1729L65.0283 88.5656L64.3516 88.1729H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 90.5118L65.0283 90.901L65.705 90.5118L65.0283 90.1191L64.3516 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 89.3413L66.0146 89.734L66.6913 89.3413L66.0146 88.9521L65.3379 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 88.1731L63.0615 88.5658L62.3848 88.1731H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 90.5119L63.0615 90.9011L63.7347 90.5119L63.0615 90.1192L62.3848 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 89.3413L64.0439 89.734L64.7206 89.3413L64.0439 88.9521L63.3672 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 88.1729L61.0908 88.5656L60.4141 88.1729H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 90.5118L61.0908 90.901L61.7675 90.5118L61.0908 90.1191L60.4141 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 89.3413L62.0771 89.734L62.7538 89.3413L62.0771 88.9521L61.4004 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.7987 88.1729L59.122 88.5656L58.4453 88.1729H59.7987Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 90.5118L59.122 90.901L59.7987 90.5118L59.122 90.1191L58.4453 90.5118Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 89.3413L60.1064 89.734L60.7831 89.3413L60.1064 88.9521L59.4297 89.3413Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 89.734L58.8154 89.3413L58.1387 88.9521V89.734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 83.499L68.9658 83.8882L68.2891 83.499H69.6425Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 85.8344L68.9658 86.2271L69.6425 85.8344L68.9658 85.4453L68.2891 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 84.2783V85.0602L69.2734 84.6675L69.9501 84.2783Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 88.1734H68.2891L68.9658 87.7842L69.6425 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 86.6133V87.3952L69.2734 87.006L69.9501 86.6133Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6737 83.499L66.997 83.8882L66.3203 83.499H67.6737Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 85.8344L66.9951 86.2271L67.6718 85.8344L66.9951 85.4452L66.3184 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 84.6675L67.9814 85.0567L68.6581 84.6675L67.9814 84.2783L67.3047 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 88.1734H66.3164L66.9931 87.7842L67.6698 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 87.006L67.9814 87.3952L68.6581 87.006L67.9814 86.6133L67.3047 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 83.499L65.0283 83.8882L64.3516 83.499H65.705Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 85.8345L65.0283 86.2272L65.705 85.8345L65.0283 85.4453L64.3516 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 84.6675L66.0146 85.0567L66.6913 84.6675L66.0146 84.2783L65.3379 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 88.1734H64.3516L65.0283 87.7842L65.705 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 87.006L66.0146 87.3952L66.6913 87.006L66.0146 86.6133L65.3379 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 83.499L63.0615 83.8882L62.3848 83.499H63.7382Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 85.8344L63.0615 86.2271L63.7347 85.8344L63.0615 85.4453L62.3848 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 84.6675L64.0439 85.0567L64.7206 84.6675L64.0439 84.2783L63.3672 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 88.1734H62.3848L63.0615 87.7842L63.7382 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 87.006L64.0439 87.3952L64.7206 87.006L64.0439 86.6133L63.3672 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 83.499L61.0908 83.8882L60.4141 83.499H61.7675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 85.8345L61.0908 86.2272L61.7675 85.8345L61.0908 85.4453L60.4141 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 84.6675L62.0771 85.0567L62.7538 84.6675L62.0771 84.2783L61.4004 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 88.1734H60.4141L61.0908 87.7842L61.7675 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 87.006L62.0771 87.3952L62.7538 87.006L62.0771 86.6133L61.4004 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 83.499L59.124 83.8882L58.4473 83.499H59.8007Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 85.8345L59.122 86.2272L59.7987 85.8345L59.122 85.4453L58.4453 85.8345Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 84.6675L60.1064 85.0567L60.7831 84.6675L60.1064 84.2783L59.4297 84.6675Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 85.0567L58.8154 84.6675L58.1387 84.2783V85.0567Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 87.3952L58.8154 87.006L58.1387 86.6133V87.3952Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 88.1734H58.4473L59.124 87.7842L59.8007 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 87.006L60.1064 87.3952L60.7831 87.006L60.1064 86.6133L59.4297 87.006Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M68.2891 81.1607L68.9658 81.5534L69.6425 81.1607L68.9658 80.7715L68.2891 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.6425 83.4991H68.2891L68.9658 83.1064L69.6425 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 81.9395V82.7213L69.2734 82.3321L69.9501 81.9395Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 81.1607L66.9951 81.5534L67.6718 81.1607L66.9951 80.7715L66.3184 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.6698 83.4991H66.3164L66.9931 83.1064L67.6698 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 82.3286L67.9814 82.7213L68.6581 82.3286L67.9814 81.9395L67.3047 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 81.1607L65.0283 81.5534L65.705 81.1607L65.0283 80.7715L64.3516 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.705 83.4991H64.3516L65.0283 83.1064L65.705 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 82.3286L66.0146 82.7213L66.6913 82.3286L66.0146 81.9395L65.3379 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 81.1607L63.0615 81.5534L63.7347 81.1607L63.0615 80.7715L62.3848 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.7382 83.4991H62.3848L63.0615 83.1064L63.7382 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 82.3286L64.0439 82.7213L64.7206 82.3286L64.0439 81.9395L63.3672 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 81.1607L61.0908 81.5534L61.7675 81.1607L61.0908 80.7715L60.4141 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.7675 83.4991H60.4141L61.0908 83.1064L61.7675 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 82.3286L62.0771 82.7213L62.7538 82.3286L62.0771 81.9395L61.4004 82.3286Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 81.1607L59.122 81.5534L59.7987 81.1607L59.122 80.7715L58.4453 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 82.7213L58.8154 82.3286L58.1387 81.9395V82.7213Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.8007 83.4991H58.4473L59.124 83.1064L59.8007 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 82.3286L60.1064 82.7213L60.7831 82.3286L60.1064 81.9395L59.4297 82.3286Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask51_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="58"
                        y="90"
                        width="12"
                        height="10"
                    >
                        <path
                            d="M69.9511 90.5127H58.1387V99.8638H69.9511V90.5127Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask51_6587_35280)">
                        <path
                            d="M68.2891 90.5127L68.9658 90.9019L69.6425 90.5127L68.9658 90.12L68.2891 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M69.9501 91.2909V92.0728L69.2734 91.6801L69.9501 91.2909Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M66.3184 90.5127L66.9951 90.9019L67.6718 90.5127L66.9951 90.12L66.3184 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M67.3047 91.6801L67.9814 92.0693L68.6581 91.6801L67.9814 91.2909L67.3047 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M64.3516 90.5127L65.0283 90.9019L65.705 90.5127L65.0283 90.12L64.3516 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M65.3379 91.6801L66.0146 92.0693L66.6913 91.6801L66.0146 91.2909L65.3379 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M62.3848 90.5127L63.0615 90.9019L63.7347 90.5127L63.0615 90.12L62.3848 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M63.3672 91.6801L64.0439 92.0693L64.7206 91.6801L64.0439 91.2909L63.3672 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M60.4141 90.5127L61.0908 90.9019L61.7675 90.5127L61.0908 90.12L60.4141 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M61.4004 91.6801L62.0771 92.0693L62.7538 91.6801L62.0771 91.2909L61.4004 91.6801Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.4453 90.5127L59.122 90.9019L59.7987 90.5127L59.122 90.12L58.4453 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1387 92.0728L58.8154 91.6801L58.1387 91.2909V92.0728Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M59.4297 91.6801L60.1064 92.0693L60.7831 91.6801L60.1064 91.2909L59.4297 91.6801Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask52_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="34"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 34.4131H46.3262V43.7642H58.1386V34.4131Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask52_6587_35280)">
                        <path
                            d="M57.8305 41.4254L57.1537 41.8146L56.4771 41.4254H57.8305Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 43.7646L57.1535 44.1538L57.8302 43.7646L57.1535 43.3719L56.4768 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 42.2041V42.986L57.4614 42.5933L58.1381 42.2041Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 39.0863L57.1535 39.479L57.8302 39.0863L57.1535 38.6971L56.4768 39.0863Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 37.5302V38.3085L57.4614 37.9193L58.1381 37.5302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 41.4252H56.4768L57.1535 41.036L57.8302 41.4252Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 39.8653V40.6472L57.4614 40.258L58.1381 39.8653Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask53_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="43"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M58.1386 43.7646H46.3262V53.1157H58.1386V43.7646Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask53_6587_35280)">
                        <path
                            d="M57.8302 50.7772L57.1535 51.1664L56.4768 50.7772H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 53.1127L57.1535 53.5054L57.8302 53.1127L57.1535 52.7235L56.4768 53.1127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 51.5557V52.334L57.4614 51.9449L58.1381 51.5557Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 46.0995L57.1535 46.4922L56.4768 46.0995H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4771 48.4383L57.1537 48.8275L57.8305 48.4383L57.1537 48.0456L56.4771 48.4383Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 46.8788V47.6607L57.4612 47.2715L58.1379 46.8788Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 50.7773H56.4768L57.1535 50.3846L57.8302 50.7773Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 49.2169V49.9987L57.4614 49.606L58.1381 49.2169Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4771 43.7646L57.1537 44.1538L57.8305 43.7646L57.1537 43.3719L56.4771 43.7646Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8305 46.0999H56.4771L57.1537 45.7108L57.8305 46.0999Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 44.5428V45.3212L57.4612 44.932L58.1379 44.5428Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask54_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="53"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 53.1123H46.3262V62.4634H58.1386V53.1123Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask54_6587_35280)">
                        <path
                            d="M57.8302 60.1251L57.1535 60.5178L56.4768 60.1251H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 62.464L57.1535 62.8532L57.8302 62.464L57.1535 62.0713L56.4768 62.464Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 60.9043V61.6862L57.4609 61.297L58.1376 60.9043Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 55.4512L57.1533 55.8404L56.4766 55.4512H57.83Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 57.79L57.1535 58.1792L57.8302 57.79L57.1535 57.3973L56.4768 57.79Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 56.2305V57.0124L57.4609 56.6197L58.1376 56.2305Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 60.1255H56.4766L57.1533 59.7363L57.83 60.1255Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 58.5685V59.3469L57.4612 58.9577L58.1379 58.5685Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 53.1128L57.1535 53.5055L57.8302 53.1128L57.1535 52.7236L56.4768 53.1128Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 55.4516H56.4768L57.1535 55.0624L57.8302 55.4516Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 53.8906V54.6725L57.4609 54.2833L58.1376 53.8906Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask55_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="62"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 62.4629H46.3262V71.814H58.1386V62.4629Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask55_6587_35280)">
                        <path
                            d="M57.83 69.4756L57.1533 69.8648L56.4766 69.4756H57.83Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4766 71.8111L57.1533 72.2038L57.83 71.8111L57.1533 71.4219L56.4766 71.8111Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 70.254V71.0359L57.4612 70.6432L58.1379 70.254Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 64.8018L57.1535 65.1909L56.4768 64.8018H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4766 67.1363L57.1533 67.529L57.83 67.1363L57.1533 66.7471L56.4766 67.1363Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 65.5771V66.359L57.4609 65.9698L58.1376 65.5771Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 69.4751H56.4766L57.1533 69.0859L57.83 69.4751Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 67.9152V68.6971L57.4612 68.3079L58.1379 67.9152Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 62.463L57.1535 62.8522L57.8302 62.463L57.1535 62.0703L56.4768 62.463Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 64.8018H56.4768L57.1535 64.4091L57.8302 64.8018Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 63.2412V64.0231L57.4609 63.6304L58.1376 63.2412Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask56_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="71"
                        width="13"
                        height="11"
                    >
                        <path
                            d="M58.1386 71.8135H46.3262V81.1646H58.1386V71.8135Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask56_6587_35280)">
                        <path
                            d="M57.8302 78.8262L57.1535 79.2154L56.4768 78.8262H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 81.1617L57.1535 81.5543L57.8302 81.1617L57.1535 80.7725L56.4768 81.1617Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 79.6016V80.3834L57.4612 79.9943L58.1379 79.6016Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 74.1484L57.1535 74.5411L56.4768 74.1484H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4771 76.4873L57.1537 76.8765L57.8305 76.4873L57.1537 76.0946L56.4771 76.4873Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 74.9277V75.7096L57.4612 75.3204L58.1379 74.9277Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8302 78.8263H56.4768L57.1535 78.4336L57.8302 78.8263Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1381 77.2658V78.0477L57.4614 77.655L58.1381 77.2658Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4771 71.8101L57.1537 72.2028L57.8305 71.8101L57.1537 71.4209L56.4771 71.8101Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.8305 74.1489H56.4771L57.1537 73.7597L57.8305 74.1489Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 72.5879V73.3698L57.4612 72.9806L58.1379 72.5879Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask57_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="81"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 81.1611H46.3262V90.5122H58.1386V81.1611Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask57_6587_35280)">
                        <path
                            d="M57.8302 88.1731L57.1535 88.5658L56.4768 88.1731H57.8302Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 90.5119L57.1535 90.9011L57.8302 90.5119L57.1535 90.1192L56.4768 90.5119Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 88.9521V89.734L57.4609 89.3413L58.1376 88.9521Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 83.499L57.1533 83.8882L56.4766 83.499H57.83Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 85.8344L57.1535 86.2271L57.8302 85.8344L57.1535 85.4453L56.4768 85.8344Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 84.2783V85.0602L57.4609 84.6675L58.1376 84.2783Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 88.1734H56.4766L57.1533 87.7842L57.83 88.1734Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 86.6133V87.3952L57.4609 87.006L58.1376 86.6133Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M56.4768 81.1607L57.1535 81.5534L57.8302 81.1607L57.1535 80.7715L56.4768 81.1607Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M57.83 83.4991H56.4766L57.1533 83.1064L57.83 83.4991Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1376 81.9395V82.7213L57.4609 82.3321L58.1376 81.9395Z"
                            fill="#1D3260"
                        />
                    </g>
                    <mask
                        id="mask58_6587_35280"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="46"
                        y="90"
                        width="13"
                        height="10"
                    >
                        <path
                            d="M58.1386 90.5127H46.3262V99.8638H58.1386V90.5127Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask58_6587_35280)">
                        <path
                            d="M56.4771 90.5127L57.1537 90.9019L57.8305 90.5127L57.1537 90.12L56.4771 90.5127Z"
                            fill="#1D3260"
                        />
                        <path
                            d="M58.1379 91.2909V92.0728L57.4612 91.6801L58.1379 91.2909Z"
                            fill="#1D3260"
                        />
                    </g>
                </g>
            </g>
            <path
                d="M2.31016 56.531C2.00512 56.7519 1.86136 57.1306 1.9385 57.4987L6.35984 78.4308V78.4449C6.40542 78.6552 6.50009 78.8025 6.63332 78.8796L32.1235 93.5952C32.0113 93.5181 31.9342 93.3884 31.8921 93.2166C31.8816 93.1885 31.8781 93.157 31.8711 93.1254L27.2849 71.6568C27.0711 70.6716 26.4575 69.8196 25.5844 69.3147L3.34449 56.4749C3.01842 56.2856 2.61169 56.3101 2.30665 56.531H2.31016Z"
                fill="#DAE0E5"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.4199 68.0667L25.5833 69.3149C26.4563 69.8198 27.0734 70.6683 27.2838 71.657L28.2199 76.0258L41.214 83.5291C41.4524 83.6658 41.4243 83.9042 41.1473 84.0655L32.8481 88.855C32.5747 89.0163 32.1609 89.0303 31.926 88.8936L30.8356 88.2625L31.8769 93.1291C31.8664 93.0625 31.8629 92.9924 31.8629 92.9152C31.8629 92.2701 32.2556 91.5232 32.7394 91.2428L51.0629 80.6645C51.5468 80.384 51.7817 80.0194 51.7817 79.6512C51.7817 79.2831 51.5433 78.9149 51.0629 78.6379L29.4576 66.1663C28.6126 65.6789 27.5678 65.6789 26.7228 66.1663L23.4269 68.0667H23.4199ZM29.0544 73.9291C28.9177 73.8484 28.9352 73.7152 29.093 73.624L33.8264 70.8927C33.9841 70.8015 34.2191 70.7945 34.3558 70.8716L41.4138 74.9459C41.5506 75.0265 41.5295 75.1597 41.3752 75.2509L36.6419 77.9858C36.4841 78.0769 36.2492 78.0839 36.1159 78.0068L29.0579 73.9361V73.9291H29.0544Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.2223 76.0256L28.629 77.9259L33.699 80.8501L32.2789 81.6706L29.0322 79.7983L29.4319 81.6706L29.8211 81.4462L31.2446 82.2666L29.8211 83.0871L29.7229 83.0275L30.0104 84.3844L31.2341 85.0926L30.2769 85.6431L30.8344 88.2552L31.9248 88.8864C32.1632 89.0231 32.577 89.0056 32.847 88.8478L41.1462 84.0583C41.4197 83.897 41.4512 83.6621 41.2128 83.5219L28.2188 76.0186L28.2223 76.0256ZM32.2509 87.3296L30.8309 86.5091L32.2544 85.6887L33.6779 86.5091L32.2509 87.3296ZM32.2614 84.5036L30.8379 83.6831L32.2614 82.8627L33.6849 83.6831L32.2614 84.5036ZM34.7052 85.9131L33.2852 85.0926L34.7052 84.2722L36.1288 85.0926L34.7052 85.9131ZM34.7193 83.0871L33.2922 82.2666L34.7158 81.4462L36.1393 82.2666L34.7193 83.0871ZM37.1631 84.4931L35.7396 83.6726L37.1631 82.8522L38.5831 83.6726L37.1631 84.4931Z"
                fill="#EBEDEF"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.6289 77.9258L29.0286 79.7981L32.2754 81.6739L33.6954 80.85L28.6289 77.9258Z"
                fill="var(--secondary-color)"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.0941 73.6237C28.9364 73.7149 28.9223 73.8516 29.0556 73.9288L36.1136 77.9995C36.2503 78.0801 36.4817 78.0696 36.6395 77.9785L41.3729 75.2436C41.5307 75.156 41.5447 75.0192 41.4115 74.9386L34.3535 70.8643C34.2167 70.7872 33.9818 70.7977 33.824 70.8854L29.0906 73.6167V73.6237H29.0941Z"
                fill="#EBEDEF"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
            />
            <path
                d="M29.4277 81.6707L29.7152 83.0346L29.8169 83.0907L31.2404 82.2667L29.8169 81.4463L29.4277 81.6707Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.0059 84.3916L30.2758 85.6468L31.233 85.0964L30.0059 84.3916Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.832 86.5089L32.252 87.3294L33.6791 86.5089L32.2556 85.6885L30.832 86.5089Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.8379 83.6798L32.2614 84.5038L33.6884 83.6798L32.2614 82.8594L30.8379 83.6798Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.8594 92.9085C31.8594 92.9857 31.8664 93.0558 31.8734 93.1224L31.8944 93.2136C31.933 93.3854 32.0172 93.5151 32.1258 93.5923H32.1329L32.1609 93.6133C32.3152 93.7045 32.515 93.6939 32.7324 93.5677L50.7439 83.1683C51.382 82.8001 51.7817 82.1164 51.7817 81.3766V79.6445C51.7817 80.0092 51.5433 80.3773 51.0629 80.6578L32.7394 91.2361C32.2556 91.5166 31.8629 92.2634 31.8629 92.9085H31.8594Z"
                fill="varI--light-blue);"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.2852 85.0935L34.7052 85.9139L36.1287 85.0935L34.7052 84.2695L33.2852 85.0935Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.291 82.2633L34.718 83.0873L36.1416 82.2633L34.718 81.4393L33.291 82.2633Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.7383 83.6732L37.1618 84.4937L38.5853 83.6732L37.1618 82.8528L35.7383 83.6732Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M59.574 88.5893L59.5459 88.5543C57.316 90.0409 57.053 90.3214 56.4955 90.3214H56.485C56.1484 90.3214 55.9731 89.9147 56.1975 89.6622C56.2921 89.557 56.3868 89.4518 56.485 89.3467C56.1168 89.5255 55.7522 89.6657 55.5453 89.6657C55.1842 89.6657 54.9493 89.529 54.9493 89.1784C54.9493 89.0451 55.1491 88.7401 55.4892 88.3228C55.4366 88.3334 55.391 88.3369 55.349 88.3369C55.0054 88.3369 54.6758 88.1545 54.6758 87.832C54.6758 87.6742 55.1176 87.1342 55.7662 86.4225C56.3307 85.8054 57.0565 85.0586 57.7823 84.3293C57.5193 84.4169 57.2704 84.473 57.046 84.473C56.9408 84.473 56.8426 84.4625 56.755 84.452C56.3798 84.3889 56.2431 83.9296 56.513 83.6631C57.1967 82.9969 57.8103 82.2466 58.3433 81.5208C58.7816 80.9247 59.3811 80.4724 60.0578 80.1884C60.17 80.1428 60.2892 80.1008 60.4084 80.0622C60.9309 79.9009 61.4358 79.7221 61.9266 79.5293C64.963 78.3582 67.4454 76.6927 69.7455 74.6346C72.4698 72.1978 74.9417 69.207 77.7712 65.82C78.1534 65.3641 78.4023 64.8277 78.511 64.2632C78.6232 63.6917 78.5847 63.0921 78.3918 62.5206C78.3918 62.5206 77.3259 59.2809 76.8035 57.668C77.2944 57.8047 77.8168 57.8679 78.3497 57.8679C80.9689 57.8679 83.5459 56.6757 85.3762 54.1162C86.2492 58.0151 87.0732 61.4442 87.6552 64.3684C88.1181 66.707 87.2941 69.1123 85.4954 70.6726C83.9281 72.0365 81.877 73.7405 79.7768 75.42C76.2881 78.2144 72.6837 80.9423 70.9902 81.917C69.8437 82.5762 68.7988 83.4352 67.9363 84.4415C67.1649 85.3391 66.3129 86.2752 64.7491 87.0781C62.3053 88.3299 60.0473 90.076 58.9849 90.1987C58.771 90.2232 58.5992 90.1811 58.494 90.0549C58.2767 89.799 58.7921 89.2274 59.5634 88.5858L59.574 88.5893Z"
                fill="#E0E3E5"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
            />
            <path
                d="M76.8062 57.671C77.3251 59.2873 78.3945 62.5236 78.3945 62.5236C78.5874 63.0951 78.6224 63.6946 78.5137 64.2662C73.8119 67.2605 67.2132 68.4105 61.1054 65.6581C60.7968 61.2613 56.9961 60.0096 56.9961 54.3295C56.9961 51.0197 58.7422 47.9097 58.7422 44.5858C58.7422 44.1615 58.7282 43.7688 58.6966 43.3972C60.3551 40.7254 62.8234 37.5488 63.8227 34.9928C65.993 33.8182 67.648 31.6934 69.8359 29.751C76.4205 33.4255 82.2479 38.5165 83.6924 46.0549C84.2429 48.9265 84.8214 51.6157 85.3824 54.1122C83.5522 56.6717 80.9751 57.8638 78.356 57.8638C77.823 57.8638 77.3006 57.8007 76.8097 57.664L76.8062 57.671Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M54.9862 31.7566C54.6811 31.6514 54.3831 31.5147 54.0991 31.3429C52.4687 30.3857 51.1679 28.4538 50.2493 26.2028C49.8741 25.2876 49.5621 24.3199 49.3201 23.3417C48.9029 21.6727 48.682 19.9792 48.668 18.4786C49.555 18.8467 50.635 18.987 51.529 18.987C55.5822 18.987 57.2442 16.8271 58.8255 16.8271C60.6417 16.8271 61.101 18.994 61.1396 20.8488C61.5288 20.3088 62.0021 19.8951 62.5421 19.8951C63.5834 19.8951 63.934 21.2871 63.934 22.3109C63.934 22.8193 63.7973 23.2961 63.6009 23.7589C64.1584 23.9342 64.7545 24.0289 65.347 24.0745V24.2989C65.347 24.8318 65.4031 25.3613 65.5083 25.8872C65.7327 27.0302 66.476 28.005 67.5244 28.5309C68.3028 28.9201 69.0776 29.3303 69.842 29.7546C67.6541 31.697 65.9992 33.8218 63.8288 34.9964C62.784 35.5609 61.6164 35.908 60.2104 35.908C57.0794 35.908 55.456 34.4283 54.9862 31.7531V31.7566Z"
                fill="#E0E3E5"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
            />
            <path
                d="M66.5463 15.7263C66.5463 19.2711 65.4067 21.2802 65.3506 24.0816C64.7581 24.036 64.162 23.9414 63.6045 23.7661C63.7974 23.3032 63.9376 22.8264 63.9376 22.318C63.9376 21.2872 63.587 19.9022 62.5457 19.9022C62.0057 19.9022 61.5324 20.3159 61.1432 20.8559C61.1046 19.0046 60.6488 16.8343 58.8291 16.8343C57.2478 16.8343 55.5858 18.9941 51.5326 18.9941C50.6421 18.9941 49.5586 18.8574 48.6716 18.4857C47.6968 18.0825 46.957 17.3988 46.957 16.3259C46.957 14.1766 55.5928 6.97479 59.7372 6.97479C63.0576 6.97479 66.5463 10.2145 66.5463 15.7298V15.7263Z"
                fill="#1D3260"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M63.8246 34.9998C62.8254 37.5523 60.357 40.7289 58.6985 43.4042C58.2112 37.2788 53.9336 37.9204 53.9336 32.8715C53.9336 32.2088 54.0037 31.732 54.0984 31.3428C54.3859 31.5111 54.6804 31.6513 54.9855 31.7565C55.4518 34.4317 57.0787 35.9114 60.2097 35.9114C61.6157 35.9114 62.7798 35.5643 63.8281 34.9998H63.8246Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.2223 45.0103C48.8086 46.1253 48.3282 47.405 47.8058 48.7725C45.9931 48.0326 42.6762 46.5109 39.9238 44.3371L42.5149 35.3507C43.5913 31.613 46.1158 28.4645 49.5273 26.6027L50.2461 26.21C51.1648 28.461 52.4656 30.3929 54.0959 31.3501C54.0013 31.7358 53.9312 32.2126 53.9312 32.8788C53.9312 37.9277 58.2052 37.2861 58.6961 43.4115C58.7277 43.7796 58.7417 44.1758 58.7417 44.6001C58.7417 47.924 56.9956 51.034 56.9956 54.3438C56.9956 60.0169 60.7963 61.2721 61.1049 65.6724C58.7873 64.6276 56.5363 63.0182 54.4851 60.7357C53.307 59.4208 52.4866 57.836 52.0483 56.1285L49.2223 45.0173V45.0103Z"
                fill="white"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.9096 42.2157C22.402 43.2816 20.5331 44.5298 19.6811 44.8033C18.1384 45.2976 12.83 46.1426 12.4513 45.1819C12.3566 44.9435 12.5881 44.7366 12.9913 44.5648C11.3749 44.6665 9.99697 44.5263 9.38338 44.4491C9.29572 44.4351 9.21859 44.3755 9.18703 44.2879C9.15548 44.2002 9.17651 44.1055 9.23962 44.0389C9.76205 43.4814 11.1856 43.3307 13.2788 43.3692C13.5067 43.3692 13.7276 43.3307 13.938 43.243L14.3727 43.0607C12.567 43.1799 10.0916 42.7101 8.86446 42.4436C8.55241 42.377 8.47527 41.9562 8.75226 41.7879C9.14145 41.553 9.66037 41.3356 10.1898 41.3917C10.309 41.4057 10.4282 41.4198 10.5509 41.4373C9.74803 40.9044 9.03626 40.396 8.59448 40.0734C8.3771 39.9121 8.45073 39.572 8.71369 39.5089C9.12392 39.4212 9.69894 39.3967 10.281 39.7228C10.8735 40.0523 11.4556 40.2838 11.8658 40.424C12.2164 40.5432 12.5916 40.5572 12.9492 40.4556C15.7998 39.6316 17.9035 39.0145 20.2141 37.6681C20.2141 37.6681 17.9385 35.3505 17.5143 34.204C17.3285 33.7026 17.5143 33.0574 17.7773 32.4894C17.8369 32.3667 17.9491 32.2756 18.0858 32.251C18.2225 32.2265 18.3593 32.2685 18.4575 32.3632C20.6909 34.5195 21.9461 35.2979 23.3276 35.7362C24.0604 35.9711 24.7301 36.3743 25.2771 36.9108C25.9327 37.5524 26.5708 38.0503 27.4719 38.5938C27.6297 38.6884 27.777 38.8041 27.9067 38.9304L38.4815 49.3824L39.933 44.3369C42.6854 46.5108 46.0023 48.0325 47.815 48.7723C46.5247 52.1523 44.9715 56.0512 43.5444 59.2419C42.8116 60.8828 40.8867 61.6331 39.2493 60.8933C33.9129 58.4845 29.0392 51.9139 23.9131 42.2192L23.9096 42.2157Z"
                fill="#E0E3E5"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
            />
            <path
                d="M56.8201 26.9249C56.5642 26.9249 56.168 26.4551 56.168 25.831C56.168 25.7574 56.168 25.6872 56.182 25.6136C56.1995 25.4769 56.2556 25.3577 56.3152 25.3156C56.3503 25.291 56.3818 25.2805 56.4169 25.2805C56.6097 25.2805 57.048 25.6802 57.048 26.3429C57.048 26.5848 56.9779 26.9249 56.8201 26.9249Z"
                fill="#1D3260"
            />
            <path
                d="M56.4153 25.438C56.5345 25.445 56.8922 25.7781 56.8922 26.3461C56.8922 26.56 56.8396 26.7248 56.8045 26.7704C56.6643 26.7423 56.3207 26.3812 56.3207 25.8307C56.3207 25.7501 56.3242 25.6905 56.3312 25.6379V25.6133C56.3522 25.5222 56.3838 25.4591 56.4013 25.445C56.4083 25.438 56.4118 25.438 56.4153 25.438ZM56.4153 25.1295C56.3522 25.1295 56.2856 25.147 56.226 25.1926C56.1068 25.2767 56.0472 25.4556 56.0297 25.5888C56.0156 25.6729 56.0156 25.7501 56.0156 25.8307C56.0156 26.5285 56.4714 27.0789 56.8221 27.0789C57.1271 27.0789 57.2042 26.6161 57.2042 26.3426C57.2042 25.6239 56.7274 25.126 56.4188 25.126L56.4153 25.1295Z"
                fill="#1D3260"
            />
            <path
                d="M51.1761 26.4312C51.141 26.4312 51.1059 26.4207 51.0779 26.4032C50.7483 26.1928 50.6992 25.7896 50.6992 25.5687C50.6992 25.439 50.7132 25.3092 50.7413 25.1865V25.169L50.7448 25.1514C50.7483 25.1514 50.7799 25.1234 50.836 25.1094C51.0183 25.1094 51.397 25.4951 51.397 26C51.397 26.1402 51.3654 26.2629 51.3058 26.3576C51.2777 26.4032 51.2287 26.4277 51.1726 26.4277L51.1761 26.4312Z"
                fill="#1D3260"
            />
            <path
                d="M50.88 25.2701C50.9957 25.3367 51.2446 25.6242 51.2446 25.9994C51.2446 26.1081 51.2201 26.2063 51.1745 26.2764C51.1675 26.2764 51.164 26.2764 51.1605 26.2764C50.8905 26.1046 50.8519 25.761 50.8519 25.5717C50.8519 25.4735 50.8624 25.3718 50.88 25.2771M50.8624 24.951C50.8414 24.951 50.8204 24.9511 50.7993 24.9616C50.6941 24.9896 50.6135 25.0492 50.5925 25.1474C50.5609 25.2841 50.5469 25.4279 50.5469 25.5646C50.5469 25.9538 50.6801 26.329 50.9992 26.5288C51.0553 26.5639 51.1184 26.5814 51.178 26.5814C51.2832 26.5814 51.3849 26.5324 51.441 26.4377C51.5216 26.308 51.5532 26.1537 51.5532 25.9994C51.5532 25.4349 51.1254 24.951 50.8624 24.951Z"
                fill="#1D3260"
            />
            <path
                d="M49.3203 23.3418C49.3203 23.3418 53.5558 23.6118 52.3076 27.7035C52.3076 27.7035 51.6239 28.9167 54.1238 28.4959"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M55.4102 23.8852C55.4102 23.8852 56.7425 23.7064 57.4367 23.7344C58.131 23.7625 58.4045 23.9518 58.4045 23.9518"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M57.7852 84.3319L60.2395 81.874"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M55.4922 88.3258C55.4922 88.3258 58.5391 84.9633 61.3581 82.9473"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M56.4844 89.3494C56.4844 89.3494 58.5425 87.0634 62.2556 84.332"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M59.543 88.554L63.505 85.8262"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M61.4829 29.3234C61.4829 29.3234 58.152 33.0996 54.9824 31.7567"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M62.6061 21.5811C62.6061 21.5811 63.0619 22.0369 62.9988 22.391C62.9357 22.7451 62.2099 24.2633 62.2309 25.2345C62.252 26.2058 62.2449 27.4505 63.0058 29.0107C63.7701 30.571 64.247 31.4546 64.268 32.5275C64.2891 33.6004 63.8262 34.9958 63.8262 34.9958"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M47.8069 39.6282L49.2199 45.0102"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M73.9375 49.2489L76.8091 57.6708"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M61.1015 65.6621C61.1015 65.6621 61.8904 67.857 58.7032 71.8646C55.5126 75.8722 57.4305 78.7087 60.0636 80.1919"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M47.125 84.0381C47.125 84.0381 49.5408 87.0008 55.7643 86.4293"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.9102 42.2157L26.1156 40.6309"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.543 41.434L12.6362 41.7285L15.0975 41.1465"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M14.3652 43.0598L16.1429 42.2148"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M12.9883 44.5614C12.9883 44.5614 13.935 44.1126 15.4777 44.0986C17.0169 44.0846 17.7427 43.3132 17.7427 43.3132"
                stroke="#1D3260"
                strokeWidth="0.6"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
}

export default LaptopBubbleSVG;
