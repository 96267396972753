import ArrowSVG from "../SVGs/ArrowSVG";
import styles from "./ArrowSubtitle.module.css";

function ArrowSubtitle({ style, text, darkMode, arrowClassName }) {
    return (
        <div style={style} className={styles.arrow_subtitle}>
            <ArrowSVG className={arrowClassName} />{" "}
            <div
                className={styles.text}
                style={{ color: darkMode ? "white" : undefined }}
            >
                {text}
            </div>
        </div>
    );
}

export default ArrowSubtitle;
