function InterfaceTargetSVG({ className }) {
    return (
        <svg
            width="84"
            height="88"
            viewBox="0 0 84 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M74.2464 38.0583C74.2409 37.9576 74.2409 37.8595 74.2464 37.7588L74.5078 37.9086L74.2491 38.0583H74.2464Z"
                fill="#969A9E"
            />
            <path
                d="M76.248 33.7696L76.0792 33.8677C76.1201 33.8268 76.1582 33.7832 76.2018 33.7451L76.248 33.7723V33.7696Z"
                fill="#969A9E"
            />
            <path
                d="M76.248 35.839L75.6489 36.1849L75.0498 35.839L75.6489 35.4932L76.248 35.839Z"
                fill="#969A9E"
            />
            <path
                d="M75.377 34.8045L75.2217 34.8944C75.2517 34.8508 75.2789 34.8072 75.3089 34.7637L75.377 34.8045Z"
                fill="#969A9E"
            />
            <path
                d="M76.248 37.9084L75.6489 38.2542L75.0498 37.9084L75.6489 37.5625L76.248 37.9084Z"
                fill="#969A9E"
            />
            <path
                d="M74.7778 36.5281L75.377 36.874L74.7778 37.2198L74.3285 36.9584C74.3421 36.893 74.3585 36.8277 74.3748 36.7596L74.7806 36.5254L74.7778 36.5281Z"
                fill="#969A9E"
            />
            <path
                d="M77.9902 33.7697L77.3911 34.1155L76.792 33.7697L77.3911 33.4238L77.9902 33.7697Z"
                fill="#969A9E"
            />
            <path
                d="M77.9902 35.839L77.3911 36.1849L76.792 35.839L77.3911 35.4932L77.9902 35.839Z"
                fill="#969A9E"
            />
            <path
                d="M77.1191 34.8048L76.52 35.1507L75.9209 34.8048L76.52 34.459L77.1191 34.8048Z"
                fill="#969A9E"
            />
            <path
                d="M77.9902 37.9084L77.3911 38.2542L76.792 37.9084L77.3911 37.5625L77.9902 37.9084Z"
                fill="#969A9E"
            />
            <path
                d="M77.1191 36.8742L76.52 37.22L75.9209 36.8742L76.52 36.5283L77.1191 36.8742Z"
                fill="#969A9E"
            />
            <path
                d="M79.7344 31.7003L79.1353 32.0461L78.8602 31.8882C79.0726 31.7792 79.2905 31.673 79.5111 31.5723L79.7317 31.7003H79.7344Z"
                fill="#969A9E"
            />
            <path
                d="M79.7344 33.7697L79.1353 34.1155L78.5362 33.7697L79.1353 33.4238L79.7344 33.7697Z"
                fill="#969A9E"
            />
            <path
                d="M78.2642 32.3887L78.8633 32.7345L78.2642 33.0804L77.6651 32.7345L78.2642 32.3887Z"
                fill="#969A9E"
            />
            <path
                d="M79.7344 35.839L79.1353 36.1849L78.5362 35.839L79.1353 35.4932L79.7344 35.839Z"
                fill="#969A9E"
            />
            <path
                d="M78.8633 34.8048L78.2642 35.1507L77.6651 34.8048L78.2642 34.459L78.8633 34.8048Z"
                fill="#969A9E"
            />
            <path
                d="M79.7344 37.9084L79.1353 38.2542L78.5362 37.9084L79.1353 37.5625L79.7344 37.9084Z"
                fill="#969A9E"
            />
            <path
                d="M78.8633 36.8742L78.2642 37.22L77.6651 36.8742L78.2642 36.5283L78.8633 36.8742Z"
                fill="#969A9E"
            />
            <path
                d="M79.7344 39.9787L79.1353 40.3245L78.5362 39.9787L79.1353 39.6328L79.7344 39.9787Z"
                fill="#969A9E"
            />
            <path
                d="M79.1353 41.7021L79.7344 42.048L79.1353 42.3939L78.5362 42.048L79.1353 41.7021Z"
                fill="#969A9E"
            />
            <path
                d="M79.4746 43.9676C79.3303 43.924 79.1887 43.875 79.0471 43.8232L79.1342 43.7715L79.4746 43.9676Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 31.6994L80.8775 32.0452L80.2783 31.6994L80.8775 31.3535L81.4766 31.6994Z"
                fill="#969A9E"
            />
            <path
                d="M80.8775 34.1155L80.2783 33.7697L80.8775 33.4238L81.4766 33.7697L80.8775 34.1155Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 32.7345L80.0064 33.0804L79.4072 32.7345L80.0064 32.3887L80.6055 32.7345Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 35.839L80.8775 36.1849L80.2783 35.839L80.8775 35.4932L81.4766 35.839Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 34.8048L80.0064 35.1507L79.4072 34.8048L80.0064 34.459L80.6055 34.8048Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 37.9084L80.8775 38.2542L80.2783 37.9084L80.8775 37.5625L81.4766 37.9084Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 36.8742L80.0064 37.22L79.4072 36.8742L80.0064 36.5283L80.6055 36.8742Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 39.9787L80.8775 40.3245L80.2783 39.9787L80.8775 39.6328L81.4766 39.9787Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 38.9435L80.0064 39.2894L79.4072 38.9435L80.0064 38.5977L80.6055 38.9435Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 42.048L80.8775 42.3939L80.2783 42.048L80.8775 41.7021L81.4766 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 41.0138L80.0064 41.3597L79.4072 41.0138L80.0064 40.668L80.6055 41.0138Z"
                fill="#969A9E"
            />
            <path
                d="M81.4766 44.1173L81.1607 44.2998C80.9074 44.2726 80.6541 44.2372 80.4063 44.1909L80.2783 44.1173L80.8775 43.7715L81.4766 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M80.6055 43.0832L80.0064 43.429L79.4072 43.0832L80.0064 42.7373L80.6055 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M82.6196 32.0452L82.0205 31.6994L82.6196 31.3535L83.2188 31.6994L82.6196 32.0452Z"
                fill="#969A9E"
            />
            <path
                d="M81.7485 31.0108L81.4408 30.8338C81.7022 30.7521 81.9664 30.6731 82.2333 30.5996L82.3477 30.665L81.7485 31.0108Z"
                fill="#969A9E"
            />
            <path
                d="M83.2188 33.7697L82.6196 34.1155L82.0205 33.7697L82.6196 33.4238L83.2188 33.7697Z"
                fill="#969A9E"
            />
            <path
                d="M81.7485 33.0804L81.1494 32.7345L81.7485 32.3887L82.3477 32.7345L81.7485 33.0804Z"
                fill="#969A9E"
            />
            <path
                d="M83.2188 35.839L82.6196 36.1849L82.0205 35.839L82.6196 35.4932L83.2188 35.839Z"
                fill="#969A9E"
            />
            <path
                d="M82.3477 34.8048L81.7485 35.1507L81.1494 34.8048L81.7485 34.459L82.3477 34.8048Z"
                fill="#969A9E"
            />
            <path
                d="M83.2188 37.9084L82.6196 38.2542L82.0205 37.9084L82.6196 37.5625L83.2188 37.9084Z"
                fill="#969A9E"
            />
            <path
                d="M82.3477 36.8742L81.7485 37.22L81.1494 36.8742L81.7485 36.5283L82.3477 36.8742Z"
                fill="#969A9E"
            />
            <path
                d="M83.2188 39.9787L82.6196 40.3245L82.0205 39.9787L82.6196 39.6328L83.2188 39.9787Z"
                fill="#969A9E"
            />
            <path
                d="M82.3477 38.9435L81.7485 39.2894L81.1494 38.9435L81.7485 38.5977L82.3477 38.9435Z"
                fill="#969A9E"
            />
            <path
                d="M83.2188 42.048L82.6196 42.3939L82.0205 42.048L82.6196 41.7021L83.2188 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M82.3477 41.0138L81.7485 41.3597L81.1494 41.0138L81.7485 40.668L82.3477 41.0138Z"
                fill="#969A9E"
            />
            <path
                d="M83.2199 44.1173L82.7543 44.3869C82.6617 44.3869 82.5718 44.3842 82.4819 44.3815L82.0244 44.1173L82.6235 43.7715L83.2227 44.1173H83.2199Z"
                fill="#969A9E"
            />
            <path
                d="M82.3477 43.0832L81.7485 43.429L81.1494 43.0832L81.7485 42.7373L82.3477 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M26.8489 12.7275L26.8848 12.7496C26.8654 12.7469 26.8461 12.7441 26.8295 12.7414L26.8489 12.7303V12.7275Z"
                fill="#969A9E"
            />
            <path
                d="M28.9121 12.9127C28.7051 12.9127 28.5036 12.9045 28.2994 12.8937L28.588 12.7275L28.9094 12.9127H28.9121Z"
                fill="#969A9E"
            />
            <path
                d="M30.5527 12.8528C30.3866 12.8664 30.2205 12.8773 30.0571 12.8882L30.3349 12.7275L30.5527 12.8528Z"
                fill="#969A9E"
            />
            <path
                d="M67.5332 13.0732L67.2391 13.242C66.9668 13.1767 66.6863 13.1086 66.3976 13.0351L66.9341 12.7246L67.5332 13.0705V13.0732Z"
                fill="#969A9E"
            />
            <path
                d="M11.049 54.7461C11.147 54.5719 11.2478 54.4003 11.3567 54.2314L11.7598 54.4656L11.1607 54.8115L11.0463 54.7461H11.049Z"
                fill="#969A9E"
            />
            <path
                d="M11.1626 56.1904L11.7617 56.5363L11.1626 56.8821L10.5635 56.5363L11.1626 56.1904Z"
                fill="#969A9E"
            />
            <path
                d="M10.8906 55.5014L10.6101 55.662C10.6455 55.5749 10.6837 55.4877 10.7218 55.4033L10.8906 55.5014Z"
                fill="#969A9E"
            />
            <path
                d="M11.7617 58.6056L11.1626 58.9515L10.5635 58.6056L11.1626 58.2598L11.7617 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M10.2915 57.2246L10.8906 57.5705L10.2915 57.9163L10.0982 57.8047C10.1091 57.6385 10.1281 57.4697 10.1526 57.3036L10.2915 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M10.6098 60.6477L11.1626 60.3291L11.7617 60.6749L11.1626 61.0208L10.6452 60.7212C10.6343 60.6967 10.6207 60.6722 10.6098 60.6477Z"
                fill="#969A9E"
            />
            <path
                d="M10.1853 59.3539L10.2915 59.2939L10.8906 59.6398L10.3405 59.9584C10.2779 59.7596 10.2262 59.5581 10.188 59.3539H10.1853Z"
                fill="#969A9E"
            />
            <path
                d="M13.218 52.2305L13.5039 52.3966L12.9048 52.7424L12.6897 52.6172C12.6897 52.6172 12.7169 52.5899 12.7278 52.579C12.883 52.432 13.0491 52.3176 13.218 52.2305Z"
                fill="#969A9E"
            />
            <path
                d="M12.9048 54.1201L13.5039 54.466L12.9048 54.8118L12.3057 54.466L12.9048 54.1201Z"
                fill="#969A9E"
            />
            <path
                d="M12.6328 53.4309L12.0337 53.7768L11.7832 53.6325C11.9057 53.4718 12.0364 53.3166 12.1699 53.1641L12.6328 53.4309Z"
                fill="#969A9E"
            />
            <path
                d="M12.9067 56.1904L13.5059 56.5363L12.9067 56.8821L12.3076 56.5363L12.9067 56.1904Z"
                fill="#969A9E"
            />
            <path
                d="M12.6328 55.5011L12.0337 55.847L11.4346 55.5011L12.0337 55.1553L12.6328 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M12.9048 58.2598L13.5039 58.6056L12.9048 58.9515L12.3057 58.6056L12.9048 58.2598Z"
                fill="#969A9E"
            />
            <path
                d="M12.0337 57.2246L12.6328 57.5705L12.0337 57.9163L11.4346 57.5705L12.0337 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M12.9048 60.3291L13.5039 60.6749L12.9048 61.0208L12.3057 60.6749L12.9048 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M12.0337 59.2939L12.6328 59.6398L12.0337 59.9856L11.4346 59.6398L12.0337 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M12.9067 62.3994L13.5059 62.7453L12.9067 63.0911L12.3076 62.7453L12.9067 62.3994Z"
                fill="#969A9E"
            />
            <path
                d="M12.0337 61.3643L12.6328 61.7101L12.0337 62.056L11.4346 61.7101L12.0337 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M14.6489 50.6722L14.4338 50.5469C14.4501 50.3835 14.4719 50.2228 14.5019 50.0649L14.6489 49.9805L15.248 50.3263L14.6489 50.6722Z"
                fill="#969A9E"
            />
            <path
                d="M15.248 52.3966L14.6489 52.7425L14.0498 52.3966L14.6489 52.0508L15.248 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M15.248 54.466L14.6489 54.8118L14.0498 54.466L14.6489 54.1201L15.248 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M14.377 53.4308L13.7778 53.7767L13.1787 53.4308L13.7778 53.085L14.377 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M14.6489 56.1904L15.248 56.5363L14.6489 56.8821L14.0498 56.5363L14.6489 56.1904Z"
                fill="#969A9E"
            />
            <path
                d="M13.7778 55.1553L14.377 55.5011L13.7778 55.847L13.1787 55.5011L13.7778 55.1553Z"
                fill="#969A9E"
            />
            <path
                d="M14.6489 58.2598L15.248 58.6056L14.6489 58.9515L14.0498 58.6056L14.6489 58.2598Z"
                fill="#969A9E"
            />
            <path
                d="M13.7778 57.2246L14.377 57.5705L13.7778 57.9163L13.1787 57.5705L13.7778 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M14.6489 60.3291L15.248 60.6749L14.6489 61.0208L14.0498 60.6749L14.6489 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M13.7778 59.2939L14.377 59.6398L13.7778 59.9856L13.1787 59.6398L13.7778 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M14.6489 62.3994L15.248 62.7453L14.6489 63.0911L14.0498 62.7453L14.6489 62.3994Z"
                fill="#969A9E"
            />
            <path
                d="M13.7778 61.3643L14.377 61.7101L13.7778 62.056L13.1787 61.7101L13.7778 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M15.248 64.8146L15.0955 64.9017C14.8968 64.7874 14.7007 64.6703 14.5046 64.5505L14.6489 64.4688L15.248 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M13.7778 63.4334L14.377 63.7792L13.8187 64.1006C13.639 63.9753 13.4592 63.85 13.2849 63.7166L13.7778 63.4307V63.4334Z"
                fill="#969A9E"
            />
            <path
                d="M16.9902 48.257L16.3911 48.6028L15.792 48.257L16.3911 47.9111L16.9902 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 49.9805L16.9902 50.3263L16.3911 50.6722L15.792 50.3263L16.3911 49.9805Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 48.9463L16.1191 49.2921L15.52 49.638L14.9209 49.2921L15.52 48.9463Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 52.0508L16.9902 52.3966L16.3911 52.7425L15.792 52.3966L16.3911 52.0508Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 51.0156L16.1191 51.3615L15.52 51.7073L14.9209 51.3615L15.52 51.0156Z"
                fill="#969A9E"
            />
            <path
                d="M16.9902 54.466L16.3911 54.8118L15.792 54.466L16.3911 54.1201L16.9902 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M16.1191 53.4308L15.52 53.7767L14.9209 53.4308L15.52 53.085L16.1191 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M16.9902 56.5363L16.3911 56.8821L15.792 56.5363L16.3911 56.1904L16.9902 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M16.1191 55.5011L15.52 55.847L14.9209 55.5011L15.52 55.1553L16.1191 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 58.2598L16.9902 58.6056L16.3911 58.9515L15.792 58.6056L16.3911 58.2598Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 57.2246L16.1191 57.5705L15.52 57.9163L14.9209 57.5705L15.52 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 60.3291L16.9902 60.6749L16.3911 61.0208L15.792 60.6749L16.3911 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 59.2939L16.1191 59.6398L15.52 59.9856L14.9209 59.6398L15.52 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 62.3994L16.9902 62.7453L16.3911 63.0911L15.792 62.7453L16.3911 62.3994Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 61.3643L16.1191 61.7101L15.52 62.056L14.9209 61.7101L15.52 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M16.3911 64.4688L16.9902 64.8146L16.3911 65.1605L15.792 64.8146L16.3911 64.4688Z"
                fill="#969A9E"
            />
            <path
                d="M15.52 63.4336L16.1191 63.7794L15.52 64.1253L14.9209 63.7794L15.52 63.4336Z"
                fill="#969A9E"
            />
            <path
                d="M18.6191 46.2529L18.1344 46.5334L17.9002 46.4C18.0527 46.3591 18.2079 46.3237 18.3686 46.2965L18.6164 46.2556L18.6191 46.2529Z"
                fill="#969A9E"
            />
            <path
                d="M18.7344 48.257L18.1353 48.6028L17.5362 48.257L18.1353 47.9111L18.7344 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M17.8613 47.2228L17.2622 47.5687L16.6631 47.2228L17.2622 46.877L17.8613 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M18.7344 50.3263L18.1353 50.6722L17.5362 50.3263L18.1353 49.9805L18.7344 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M17.8613 49.2921L17.2622 49.638L16.6631 49.2921L17.2622 48.9463L17.8613 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M18.1353 52.0508L18.7344 52.3966L18.1353 52.7425L17.5362 52.3966L18.1353 52.0508Z"
                fill="#969A9E"
            />
            <path
                d="M17.2622 51.0156L17.8613 51.3615L17.2622 51.7073L16.6631 51.3615L17.2622 51.0156Z"
                fill="#969A9E"
            />
            <path
                d="M18.1353 54.1201L18.7344 54.466L18.1353 54.8118L17.5362 54.466L18.1353 54.1201Z"
                fill="#969A9E"
            />
            <path
                d="M17.2622 53.085L17.8613 53.4308L17.2622 53.7767L16.6631 53.4308L17.2622 53.085Z"
                fill="#969A9E"
            />
            <path
                d="M18.7344 56.5363L18.1353 56.8821L17.5362 56.5363L18.1353 56.1904L18.7344 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M17.8613 55.5011L17.2622 55.847L16.6631 55.5011L17.2622 55.1553L17.8613 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M18.7344 58.6056L18.1353 58.9515L17.5362 58.6056L18.1353 58.2598L18.7344 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M17.8613 57.5705L17.2622 57.9163L16.6631 57.5705L17.2622 57.2246L17.8613 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M18.1353 60.3291L18.7344 60.6749L18.1353 61.0208L17.5362 60.6749L18.1353 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M17.2622 59.2939L17.8613 59.6398L17.2622 59.9856L16.6631 59.6398L17.2622 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M18.1353 62.3994L18.7344 62.7453L18.1353 63.0911L17.5362 62.7453L18.1353 62.3994Z"
                fill="#969A9E"
            />
            <path
                d="M17.2622 61.3643L17.8613 61.7101L17.2622 62.056L16.6631 61.7101L17.2622 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M18.1353 64.4688L18.7344 64.8146L18.1353 65.1605L17.5362 64.8146L18.1353 64.4688Z"
                fill="#969A9E"
            />
            <path
                d="M17.2622 63.4336L17.8613 63.7794L17.2622 64.1253L16.6631 63.7794L17.2622 63.4336Z"
                fill="#969A9E"
            />
            <path
                d="M17.8613 65.849L17.4202 66.105C17.205 66.0015 16.9872 65.8953 16.772 65.7891L17.2622 65.5059L17.8613 65.8517V65.849Z"
                fill="#969A9E"
            />
            <path
                d="M20.1498 46L20.4766 46.1879L19.8774 46.5338L19.2783 46.1879L19.3845 46.1253L20.1498 46Z"
                fill="#969A9E"
            />
            <path
                d="M20.4766 48.257L19.8774 48.6028L19.2783 48.257L19.8774 47.9111L20.4766 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M19.6055 47.2228L19.0064 47.5687L18.4072 47.2228L19.0064 46.877L19.6055 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M20.4766 50.3263L19.8774 50.6722L19.2783 50.3263L19.8774 49.9805L20.4766 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M19.6055 49.2921L19.0064 49.638L18.4072 49.2921L19.0064 48.9463L19.6055 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M20.4766 52.3966L19.8774 52.7425L19.2783 52.3966L19.8774 52.0508L20.4766 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M19.6055 51.3615L19.0064 51.7073L18.4072 51.3615L19.0064 51.0156L19.6055 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M19.8774 54.1201L20.4766 54.466L19.8774 54.8118L19.2783 54.466L19.8774 54.1201Z"
                fill="#969A9E"
            />
            <path
                d="M19.0064 53.085L19.6055 53.4308L19.0064 53.7767L18.4072 53.4308L19.0064 53.085Z"
                fill="#969A9E"
            />
            <path
                d="M19.8774 56.1904L20.4766 56.5363L19.8774 56.8821L19.2783 56.5363L19.8774 56.1904Z"
                fill="#969A9E"
            />
            <path
                d="M19.0064 55.1553L19.6055 55.5011L19.0064 55.847L18.4072 55.5011L19.0064 55.1553Z"
                fill="#969A9E"
            />
            <path
                d="M20.4766 58.6056L19.8774 58.9515L19.2783 58.6056L19.8774 58.2598L20.4766 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M19.6055 57.5705L19.0064 57.9163L18.4072 57.5705L19.0064 57.2246L19.6055 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M20.4766 60.6749L19.8774 61.0208L19.2783 60.6749L19.8774 60.3291L20.4766 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M19.6055 59.6398L19.0064 59.9856L18.4072 59.6398L19.0064 59.2939L19.6055 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M19.8774 62.3994L20.4766 62.7453L19.8774 63.0911L19.2783 62.7453L19.8774 62.3994Z"
                fill="#969A9E"
            />
            <path
                d="M19.0064 61.3643L19.6055 61.7101L19.0064 62.056L18.4072 61.7101L19.0064 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M19.8774 64.4688L20.4766 64.8146L19.8774 65.1605L19.2783 64.8146L19.8774 64.4688Z"
                fill="#969A9E"
            />
            <path
                d="M19.0064 63.4336L19.6055 63.7794L19.0064 64.1253L18.4072 63.7794L19.0064 63.4336Z"
                fill="#969A9E"
            />
            <path
                d="M19.8774 66.5381L20.4766 66.8839L19.8774 67.2298L19.2783 66.8839L19.8774 66.5381Z"
                fill="#969A9E"
            />
            <path
                d="M19.0064 65.5029L19.6055 65.8488L19.0064 66.1946L18.4072 65.8488L19.0064 65.5029Z"
                fill="#969A9E"
            />
            <path
                d="M21.6196 45.8418L22.2188 46.1876L21.6196 46.5335L21.0205 46.1876L21.6196 45.8418Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 48.257L21.6196 48.6028L21.0205 48.257L21.6196 47.9111L22.2188 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 47.2228L20.7485 47.5687L20.1494 47.2228L20.7485 46.877L21.3477 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 50.3263L21.6196 50.6722L21.0205 50.3263L21.6196 49.9805L22.2188 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 49.2921L20.7485 49.638L20.1494 49.2921L20.7485 48.9463L21.3477 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 52.3966L21.6196 52.7425L21.0205 52.3966L21.6196 52.0508L22.2188 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 51.3615L20.7485 51.7073L20.1494 51.3615L20.7485 51.0156L21.3477 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 54.466L21.6196 54.8118L21.0205 54.466L21.6196 54.1201L22.2188 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 53.4308L20.7485 53.7767L20.1494 53.4308L20.7485 53.085L21.3477 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M21.6196 56.1904L22.2188 56.5363L21.6196 56.8821L21.0205 56.5363L21.6196 56.1904Z"
                fill="#969A9E"
            />
            <path
                d="M20.7485 55.1553L21.3477 55.5011L20.7485 55.847L20.1494 55.5011L20.7485 55.1553Z"
                fill="#969A9E"
            />
            <path
                d="M21.6196 58.2598L22.2188 58.6056L21.6196 58.9515L21.0205 58.6056L21.6196 58.2598Z"
                fill="#969A9E"
            />
            <path
                d="M20.7485 57.2246L21.3477 57.5705L20.7485 57.9163L20.1494 57.5705L20.7485 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 60.6749L21.6196 61.0208L21.0205 60.6749L21.6196 60.3291L22.2188 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 59.6398L20.7485 59.9856L20.1494 59.6398L20.7485 59.2939L21.3477 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 62.7453L21.6196 63.0911L21.0205 62.7453L21.6196 62.3994L22.2188 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M21.3477 61.7101L20.7485 62.056L20.1494 61.7101L20.7485 61.3643L21.3477 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M21.6196 64.4688L22.2188 64.8146L21.6196 65.1605L21.0205 64.8146L21.6196 64.4688Z"
                fill="#969A9E"
            />
            <path
                d="M20.7485 63.4336L21.3477 63.7794L20.7485 64.1253L20.1494 63.7794L20.7485 63.4336Z"
                fill="#969A9E"
            />
            <path
                d="M22.2188 66.8839L21.6196 67.2298L21.0205 66.8839L21.6196 66.5381L22.2188 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M20.7485 65.5029L21.3477 65.8488L20.7485 66.1946L20.1494 65.8488L20.7485 65.5029Z"
                fill="#969A9E"
            />
            <path
                d="M20.9141 67.6683C20.8487 67.6411 20.7861 67.6139 20.7207 67.5867L20.7479 67.5703L20.9141 67.6656V67.6683Z"
                fill="#969A9E"
            />
            <path
                d="M23.3638 45.8418L23.9629 46.1876L23.3638 46.5335L22.7647 46.1876L23.3638 45.8418Z"
                fill="#969A9E"
            />
            <path
                d="M23.3638 47.9111L23.9629 48.257L23.3638 48.6028L22.7647 48.257L23.3638 47.9111Z"
                fill="#969A9E"
            />
            <path
                d="M22.4907 46.877L23.0898 47.2228L22.4907 47.5687L21.8916 47.2228L22.4907 46.877Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 50.3263L23.3638 50.6722L22.7647 50.3263L23.3638 49.9805L23.9629 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 49.2921L22.4907 49.638L21.8916 49.2921L22.4907 48.9463L23.0898 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 52.3966L23.3638 52.7425L22.7647 52.3966L23.3638 52.0508L23.9629 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 51.3615L22.4907 51.7073L21.8916 51.3615L22.4907 51.0156L23.0898 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 54.466L23.3638 54.8118L22.7647 54.466L23.3638 54.1201L23.9629 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 53.4308L22.4907 53.7767L21.8916 53.4308L22.4907 53.085L23.0898 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 56.5363L23.3638 56.8821L22.7647 56.5363L23.3638 56.1904L23.9629 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 55.5011L22.4907 55.847L21.8916 55.5011L22.4907 55.1553L23.0898 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M23.3638 58.2598L23.9629 58.6056L23.3638 58.9515L22.7647 58.6056L23.3638 58.2598Z"
                fill="#969A9E"
            />
            <path
                d="M22.4907 57.2246L23.0898 57.5705L22.4907 57.9163L21.8916 57.5705L22.4907 57.2246Z"
                fill="#969A9E"
            />
            <path
                d="M23.3638 60.3291L23.9629 60.6749L23.3638 61.0208L22.7647 60.6749L23.3638 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M22.4907 59.2939L23.0898 59.6398L22.4907 59.9856L21.8916 59.6398L22.4907 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 62.7453L23.3638 63.0911L22.7647 62.7453L23.3638 62.3994L23.9629 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 61.7101L22.4907 62.056L21.8916 61.7101L22.4907 61.3643L23.0898 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M23.9629 64.8146L23.3638 65.1605L22.7647 64.8146L23.3638 64.4688L23.9629 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 63.7794L22.4907 64.1253L21.8916 63.7794L22.4907 63.4336L23.0898 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M22.7647 66.8839L23.3638 66.5381L23.9629 66.8839L23.3638 67.2298L22.7647 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M22.4907 65.5029L23.0898 65.8488L22.4907 66.1946L21.8916 65.8488L22.4907 65.5029Z"
                fill="#969A9E"
            />
            <path
                d="M23.0898 67.9191L22.4907 68.2649L21.8916 67.9191L22.4907 67.5732L23.0898 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M25.106 46.5335L24.5069 46.1876L25.106 45.8418L25.7051 46.1876L25.106 46.5335Z"
                fill="#969A9E"
            />
            <path
                d="M24.2342 45.4984L23.9918 45.3595L24.6699 45.2451L24.2342 45.4984Z"
                fill="#969A9E"
            />
            <path
                d="M25.106 47.9111L25.7051 48.257L25.106 48.6028L24.5069 48.257L25.106 47.9111Z"
                fill="#969A9E"
            />
            <path
                d="M24.2349 46.877L24.834 47.2228L24.2349 47.5687L23.6358 47.2228L24.2349 46.877Z"
                fill="#969A9E"
            />
            <path
                d="M25.106 49.9805L25.7051 50.3263L25.106 50.6722L24.5069 50.3263L25.106 49.9805Z"
                fill="#969A9E"
            />
            <path
                d="M24.2349 48.9463L24.834 49.2921L24.2349 49.638L23.6358 49.2921L24.2349 48.9463Z"
                fill="#969A9E"
            />
            <path
                d="M25.7051 52.3966L25.106 52.7425L24.5069 52.3966L25.106 52.0508L25.7051 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M24.834 51.3615L24.2349 51.7073L23.6358 51.3615L24.2349 51.0156L24.834 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M25.7051 54.466L25.106 54.8118L24.5069 54.466L25.106 54.1201L25.7051 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M24.834 53.4308L24.2349 53.7767L23.6358 53.4308L24.2349 53.085L24.834 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M25.7051 56.5363L25.106 56.8821L24.5069 56.5363L25.106 56.1904L25.7051 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M24.834 55.5011L24.2349 55.847L23.6358 55.5011L24.2349 55.1553L24.834 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M25.7051 58.6056L25.106 58.9515L24.5069 58.6056L25.106 58.2598L25.7051 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M24.834 57.5705L24.2349 57.9163L23.6358 57.5705L24.2349 57.2246L24.834 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M25.106 60.3291L25.7051 60.6749L25.106 61.0208L24.5069 60.6749L25.106 60.3291Z"
                fill="#969A9E"
            />
            <path
                d="M24.2349 59.2939L24.834 59.6398L24.2349 59.9856L23.6358 59.6398L24.2349 59.2939Z"
                fill="#969A9E"
            />
            <path
                d="M24.5069 62.7453L25.106 62.3994L25.7051 62.7453L25.106 63.0911L24.5069 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M24.2349 61.3643L24.834 61.7101L24.2349 62.056L23.6358 61.7101L24.2349 61.3643Z"
                fill="#969A9E"
            />
            <path
                d="M24.5069 64.8146L25.106 64.4688L25.7051 64.8146L25.106 65.1605L24.5069 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M24.834 63.7794L24.2349 64.1253L23.6358 63.7794L24.2349 63.4336L24.834 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M24.5069 66.8839L25.106 66.5381L25.7051 66.8839L25.106 67.2298L24.5069 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M23.6358 65.8488L24.2349 65.5029L24.834 65.8488L24.2349 66.1946L23.6358 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M24.7247 69.0785L24.5069 68.9533L25.106 68.6074L25.7051 68.9533L25.2095 69.2392C25.0488 69.1875 24.8854 69.1303 24.7247 69.0785Z"
                fill="#969A9E"
            />
            <path
                d="M23.6358 67.9191L24.2349 67.5732L24.834 67.9191L24.2349 68.2649L23.6358 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M26.8482 46.5335L26.249 46.1876L26.8482 45.8418L27.4473 46.1876L26.8482 46.5335Z"
                fill="#969A9E"
            />
            <path
                d="M25.9771 45.4987L25.378 45.1528L25.4351 45.1201L26.2766 44.9785L26.5762 45.1528L25.9771 45.4987Z"
                fill="#969A9E"
            />
            <path
                d="M27.4473 48.257L26.8482 48.6028L26.249 48.257L26.8482 47.9111L27.4473 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M26.5762 47.2228L25.9771 47.5687L25.378 47.2228L25.9771 46.877L26.5762 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M27.4473 50.3263L26.8482 50.6722L26.249 50.3263L26.8482 49.9805L27.4473 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M25.9771 48.9463L26.5762 49.2921L25.9771 49.638L25.378 49.2921L25.9771 48.9463Z"
                fill="#969A9E"
            />
            <path
                d="M26.8482 52.0508L27.4473 52.3966L26.8482 52.7425L26.249 52.3966L26.8482 52.0508Z"
                fill="#969A9E"
            />
            <path
                d="M25.9771 51.0156L26.5762 51.3615L25.9771 51.7073L25.378 51.3615L25.9771 51.0156Z"
                fill="#969A9E"
            />
            <path
                d="M27.4473 54.466L26.8482 54.8118L26.249 54.466L26.8482 54.1201L27.4473 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M26.5762 53.4308L25.9771 53.7767L25.378 53.4308L25.9771 53.085L26.5762 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M27.4473 56.5363L26.8482 56.8821L26.249 56.5363L26.8482 56.1904L27.4473 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M26.5762 55.5011L25.9771 55.847L25.378 55.5011L25.9771 55.1553L26.5762 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M27.4473 58.6056L26.8482 58.9515L26.249 58.6056L26.8482 58.2598L27.4473 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M26.5762 57.5705L25.9771 57.9163L25.378 57.5705L25.9771 57.2246L26.5762 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M26.249 60.6749L26.8482 60.3291L27.4473 60.6749L26.8482 61.0208L26.249 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M26.5762 59.6398L25.9771 59.9856L25.378 59.6398L25.9771 59.2939L26.5762 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M26.249 62.7453L26.8482 62.3994L27.4473 62.7453L26.8482 63.0911L26.249 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M25.378 61.7101L25.9771 61.3643L26.5762 61.7101L25.9771 62.056L25.378 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M26.249 64.8146L26.8482 64.4688L27.4473 64.8146L26.8482 65.1605L26.249 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M25.378 63.7794L25.9771 63.4336L26.5762 63.7794L25.9771 64.1253L25.378 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M26.249 66.8839L26.8482 66.5381L27.4473 66.8839L26.8482 67.2298L26.249 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M25.378 65.8488L25.9771 65.5029L26.5762 65.8488L25.9771 66.1946L25.378 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M26.249 68.9533L26.8482 68.6074L27.4473 68.9533L26.8482 69.2991L26.249 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M25.378 67.9191L25.9771 67.5732L26.5762 67.9191L25.9771 68.2649L25.378 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 46.1876L28.5923 46.5335L27.9932 46.1876L28.5923 45.8418L29.1914 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M28.3184 45.1525L27.7192 45.4983L27.1201 45.1525L27.7192 44.8066L28.3184 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 48.257L28.5923 48.6028L27.9932 48.257L28.5923 47.9111L29.1914 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M28.3203 47.2228L27.7212 47.5687L27.1221 47.2228L27.7212 46.877L28.3203 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 50.3263L28.5923 50.6722L27.9932 50.3263L28.5923 49.9805L29.1914 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M28.3203 49.2921L27.7212 49.638L27.1221 49.2921L27.7212 48.9463L28.3203 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 52.3966L28.5923 52.7425L27.9932 52.3966L28.5923 52.0508L29.1914 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M28.3184 51.3615L27.7192 51.7073L27.1201 51.3615L27.7192 51.0156L28.3184 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 54.466L28.5923 54.8118L27.9932 54.466L28.5923 54.1201L29.1914 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M27.7212 53.085L28.3203 53.4308L27.7212 53.7767L27.1221 53.4308L27.7212 53.085Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 56.5363L28.5923 56.8821L27.9932 56.5363L28.5923 56.1904L29.1914 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M28.3184 55.5011L27.7192 55.847L27.1201 55.5011L27.7192 55.1553L28.3184 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M29.1914 58.6056L28.5923 58.9515L27.9932 58.6056L28.5923 58.2598L29.1914 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M28.3184 57.5705L27.7192 57.9163L27.1201 57.5705L27.7192 57.2246L28.3184 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M27.9932 60.6749L28.5923 60.3291L29.1914 60.6749L28.5923 61.0208L27.9932 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M27.1221 59.6398L27.7212 59.2939L28.3203 59.6398L27.7212 59.9856L27.1221 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M27.9932 62.7453L28.5923 62.3994L29.1914 62.7453L28.5923 63.0911L27.9932 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M27.1201 61.7101L27.7192 61.3643L28.3184 61.7101L27.7192 62.056L27.1201 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M27.9932 64.8146L28.5923 64.4688L29.1914 64.8146L28.5923 65.1605L27.9932 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M27.1201 63.7794L27.7192 63.4336L28.3184 63.7794L27.7192 64.1253L27.1201 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M27.9932 66.8839L28.5923 66.5381L29.1914 66.8839L28.5923 67.2298L27.9932 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M27.1221 65.8488L27.7212 65.5029L28.3203 65.8488L27.7212 66.1946L27.1221 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M27.9932 68.9533L28.5923 68.6074L29.1914 68.9533L28.5923 69.2991L27.9932 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M27.1201 67.9191L27.7192 67.5732L28.3184 67.9191L27.7192 68.2649L27.1201 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M27.3053 69.8795L27.7192 69.6426L28.3184 69.9884L28.1114 70.1082C27.8418 70.0347 27.5749 69.9585 27.3053 69.8822V69.8795Z"
                fill="#969A9E"
            />
            <path
                d="M30.334 44.4635L30.1189 44.341L30.7207 44.2402L30.334 44.4635Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 46.1876L30.3345 46.5335L29.7354 46.1876L30.3345 45.8418L30.9336 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 45.1525L29.4634 45.4983L28.8643 45.1525L29.4634 44.8066L30.0625 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 48.257L30.3345 48.6028L29.7354 48.257L30.3345 47.9111L30.9336 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 47.2228L29.4634 47.5687L28.8643 47.2228L29.4634 46.877L30.0625 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 50.3263L30.3345 50.6722L29.7354 50.3263L30.3345 49.9805L30.9336 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 49.2921L29.4634 49.638L28.8643 49.2921L29.4634 48.9463L30.0625 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 52.3966L30.3345 52.7425L29.7354 52.3966L30.3345 52.0508L30.9336 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 51.3615L29.4634 51.7073L28.8643 51.3615L29.4634 51.0156L30.0625 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 54.466L30.3345 54.8118L29.7354 54.466L30.3345 54.1201L30.9336 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 53.4308L29.4634 53.7767L28.8643 53.4308L29.4634 53.085L30.0625 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M30.9336 56.5363L30.3345 56.8821L29.7354 56.5363L30.3345 56.1904L30.9336 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 55.5011L29.4634 55.847L28.8643 55.5011L29.4634 55.1553L30.0625 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M29.7354 58.6056L30.3345 58.2598L30.9336 58.6056L30.3345 58.9515L29.7354 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M30.0625 57.5705L29.4634 57.9163L28.8643 57.5705L29.4634 57.2246L30.0625 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M29.7354 60.6749L30.3345 60.3291L30.9336 60.6749L30.3345 61.0208L29.7354 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M28.8643 59.6398L29.4634 59.2939L30.0625 59.6398L29.4634 59.9856L28.8643 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M29.7354 62.7453L30.3345 62.3994L30.9336 62.7453L30.3345 63.0911L29.7354 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M28.8643 61.7101L29.4634 61.3643L30.0625 61.7101L29.4634 62.056L28.8643 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M29.7354 64.8146L30.3345 64.4688L30.9336 64.8146L30.3345 65.1605L29.7354 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M28.8643 63.7794L29.4634 63.4336L30.0625 63.7794L29.4634 64.1253L28.8643 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M29.7354 66.8839L30.3345 66.5381L30.9336 66.8839L30.3345 67.2298L29.7354 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M28.8643 65.8488L29.4634 65.5029L30.0625 65.8488L29.4634 66.1946L28.8643 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M30.3345 68.6074L30.9336 68.9533L30.3345 69.2991L29.7354 68.9533L30.3345 68.6074Z"
                fill="#969A9E"
            />
            <path
                d="M28.8643 67.9191L29.4634 67.5732L30.0625 67.9191L29.4634 68.2649L28.8643 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M30.3594 70.6913L30.3241 70.6832L30.3349 70.6777L30.3594 70.6913Z"
                fill="#969A9E"
            />
            <path
                d="M29.4634 70.3343L28.8643 69.9884L29.4634 69.6426L30.0625 69.9884L29.4634 70.3343Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 44.1179L32.0767 44.4638L31.4776 44.1179L31.4857 44.1125L32.4035 43.96L32.6758 44.1179Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 46.1876L32.0767 46.5335L31.4776 46.1876L32.0767 45.8418L32.6758 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 45.1525L31.2056 45.4983L30.6065 45.1525L31.2056 44.8066L31.8047 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 48.257L32.0767 48.6028L31.4776 48.257L32.0767 47.9111L32.6758 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 47.2228L31.2056 47.5687L30.6065 47.2228L31.2056 46.877L31.8047 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 50.3263L32.0767 50.6722L31.4776 50.3263L32.0767 49.9805L32.6758 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 49.2921L31.2056 49.638L30.6065 49.2921L31.2056 48.9463L31.8047 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 52.3966L32.0767 52.7425L31.4776 52.3966L32.0767 52.0508L32.6758 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 51.3615L31.2056 51.7073L30.6065 51.3615L31.2056 51.0156L31.8047 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 54.466L32.0767 54.8118L31.4776 54.466L32.0767 54.1201L32.6758 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 53.4308L31.2056 53.7767L30.6065 53.4308L31.2056 53.085L31.8047 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 56.5363L32.0767 56.8821L31.4776 56.5363L32.0767 56.1904L32.6758 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 55.5011L31.2056 55.847L30.6065 55.5011L31.2056 55.1553L31.8047 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M32.6758 58.6056L32.0767 58.9515L31.4776 58.6056L32.0767 58.2598L32.6758 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M31.8047 57.5705L31.2056 57.9163L30.6065 57.5705L31.2056 57.2246L31.8047 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M31.4776 60.6749L32.0767 60.3291L32.6758 60.6749L32.0767 61.0208L31.4776 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 59.6398L31.2056 59.2939L31.8047 59.6398L31.2056 59.9856L30.6065 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M31.4776 62.7453L32.0767 62.3994L32.6758 62.7453L32.0767 63.0911L31.4776 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 61.7101L31.2056 61.3643L31.8047 61.7101L31.2056 62.056L30.6065 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M31.4776 64.8146L32.0767 64.4688L32.6758 64.8146L32.0767 65.1605L31.4776 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 63.7794L31.2056 63.4336L31.8047 63.7794L31.2056 64.1253L30.6065 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M31.4776 66.8839L32.0767 66.5381L32.6758 66.8839L32.0767 67.2298L31.4776 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 65.8488L31.2056 65.5029L31.8047 65.8488L31.2056 66.1946L30.6065 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M31.4776 68.9533L32.0767 68.6074L32.6758 68.9533L32.0767 69.2991L31.4776 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 67.9191L31.2056 67.5732L31.8047 67.9191L31.2056 68.2649L30.6065 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M31.562 70.9746L32.0767 70.6777L32.6758 71.0236L32.428 71.1652C32.1393 71.1026 31.8506 71.0399 31.562 70.9746Z"
                fill="#969A9E"
            />
            <path
                d="M30.6065 69.9884L31.2056 69.6426L31.8047 69.9884L31.2056 70.3343L30.6065 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 44.1173L33.8208 44.4632L33.2217 44.1173L33.8208 43.7715L34.4199 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 46.1876L33.8208 46.5335L33.2217 46.1876L33.8208 45.8418L34.4199 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 45.1525L32.9497 45.4983L32.3506 45.1525L32.9497 44.8066L33.5488 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 48.257L33.8208 48.6028L33.2217 48.257L33.8208 47.9111L34.4199 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 47.2228L32.9497 47.5687L32.3506 47.2228L32.9497 46.877L33.5488 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 50.3263L33.8208 50.6722L33.2217 50.3263L33.8208 49.9805L34.4199 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 49.2921L32.9497 49.638L32.3506 49.2921L32.9497 48.9463L33.5488 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 52.3966L33.8208 52.7425L33.2217 52.3966L33.8208 52.0508L34.4199 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 51.3615L32.9497 51.7073L32.3506 51.3615L32.9497 51.0156L33.5488 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 54.466L33.8208 54.8118L33.2217 54.466L33.8208 54.1201L34.4199 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 53.4308L32.9497 53.7767L32.3506 53.4308L32.9497 53.085L33.5488 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M34.4199 56.5363L33.8208 56.8821L33.2217 56.5363L33.8208 56.1904L34.4199 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 55.5011L32.9497 55.847L32.3506 55.5011L32.9497 55.1553L33.5488 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 58.6056L33.8208 58.2598L34.4199 58.6056L33.8208 58.9515L33.2217 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M33.5488 57.5705L32.9497 57.9163L32.3506 57.5705L32.9497 57.2246L33.5488 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 60.6749L33.8208 60.3291L34.4199 60.6749L33.8208 61.0208L33.2217 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 59.6398L32.9497 59.2939L33.5488 59.6398L32.9497 59.9856L32.3506 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 62.7453L33.8208 62.3994L34.4199 62.7453L33.8208 63.0911L33.2217 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 61.7101L32.9497 61.3643L33.5488 61.7101L32.9497 62.056L32.3506 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 64.8146L33.8208 64.4688L34.4199 64.8146L33.8208 65.1605L33.2217 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 63.7794L32.9497 63.4336L33.5488 63.7794L32.9497 64.1253L32.3506 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 66.8839L33.8208 66.5381L34.4199 66.8839L33.8208 67.2298L33.2217 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 65.8488L32.9497 65.5029L33.5488 65.8488L32.9497 66.1946L32.3506 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 68.9533L33.8208 68.6074L34.4199 68.9533L33.8208 69.2991L33.2217 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 67.9191L32.9497 67.5732L33.5488 67.9191L32.9497 68.2649L32.3506 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M33.2217 71.0236L33.8208 70.6777L34.4199 71.0236L33.8208 71.3694L33.2217 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M32.3506 69.9884L32.9497 69.6426L33.5488 69.9884L32.9497 70.3343L32.3506 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 44.1173L35.563 44.4632L34.9639 44.1173L35.563 43.7715L36.1621 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 46.1876L35.563 46.5335L34.9639 46.1876L35.563 45.8418L36.1621 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 45.1525L34.6919 45.4983L34.0928 45.1525L34.6919 44.8066L35.291 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 48.257L35.563 48.6028L34.9639 48.257L35.563 47.9111L36.1621 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 47.2228L34.6919 47.5687L34.0928 47.2228L34.6919 46.877L35.291 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 50.3263L35.563 50.6722L34.9639 50.3263L35.563 49.9805L36.1621 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 49.2921L34.6919 49.638L34.0928 49.2921L34.6919 48.9463L35.291 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 52.3966L35.563 52.7425L34.9639 52.3966L35.563 52.0508L36.1621 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 51.3615L34.6919 51.7073L34.0928 51.3615L34.6919 51.0156L35.291 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 54.466L35.563 54.8118L34.9639 54.466L35.563 54.1201L36.1621 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 53.4308L34.6919 53.7767L34.0928 53.4308L34.6919 53.085L35.291 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 56.5363L35.563 56.8821L34.9639 56.5363L35.563 56.1904L36.1621 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M35.291 55.5011L34.6919 55.847L34.0928 55.5011L34.6919 55.1553L35.291 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M36.1621 58.6056L35.563 58.9515L34.9639 58.6056L35.563 58.2598L36.1621 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 57.5705L34.6919 57.2246L35.291 57.5705L34.6919 57.9163L34.0928 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 60.6749L35.563 60.3291L36.1621 60.6749L35.563 61.0208L34.9639 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 59.6398L34.6919 59.2939L35.291 59.6398L34.6919 59.9856L34.0928 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 62.7453L35.563 62.3994L36.1621 62.7453L35.563 63.0911L34.9639 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 61.7101L34.6919 61.3643L35.291 61.7101L34.6919 62.056L34.0928 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 64.8146L35.563 64.4688L36.1621 64.8146L35.563 65.1605L34.9639 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 63.7794L34.6919 63.4336L35.291 63.7794L34.6919 64.1253L34.0928 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 66.8839L35.563 66.5381L36.1621 66.8839L35.563 67.2298L34.9639 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 65.8488L34.6919 65.5029L35.291 65.8488L34.6919 66.1946L34.0928 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 68.9533L35.563 68.6074L36.1621 68.9533L35.563 69.2991L34.9639 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 67.9191L34.6919 67.5732L35.291 67.9191L34.6919 68.2649L34.0928 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M34.9639 71.0236L35.563 70.6777L36.1621 71.0236L35.563 71.3694L34.9639 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M34.0928 69.9884L34.6919 69.6426L35.291 69.9884L34.6919 70.3343L34.0928 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 44.1173L37.3052 44.4632L36.7061 44.1173L37.3052 43.7715L37.9043 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M36.4338 43.4285L36.2459 43.3196L36.7715 43.2324L36.4338 43.4285Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 46.1876L37.3052 46.5335L36.7061 46.1876L37.3052 45.8418L37.9043 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 45.1525L36.4341 45.4983L35.835 45.1525L36.4341 44.8066L37.0332 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 48.257L37.3052 48.6028L36.7061 48.257L37.3052 47.9111L37.9043 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 47.2228L36.4341 47.5687L35.835 47.2228L36.4341 46.877L37.0332 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 50.3263L37.3052 50.6722L36.7061 50.3263L37.3052 49.9805L37.9043 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 49.2921L36.4341 49.638L35.835 49.2921L36.4341 48.9463L37.0332 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 52.3966L37.3052 52.7425L36.7061 52.3966L37.3052 52.0508L37.9043 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 51.3615L36.4341 51.7073L35.835 51.3615L36.4341 51.0156L37.0332 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 54.466L37.3052 54.8118L36.7061 54.466L37.3052 54.1201L37.9043 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 53.4308L36.4341 53.7767L35.835 53.4308L36.4341 53.085L37.0332 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M37.9043 56.5363L37.3052 56.8821L36.7061 56.5363L37.3052 56.1904L37.9043 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 55.5011L36.4341 55.847L35.835 55.5011L36.4341 55.1553L37.0332 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 58.6056L37.3052 58.2598L37.9043 58.6056L37.3052 58.9515L36.7061 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M37.0332 57.5705L36.4341 57.9163L35.835 57.5705L36.4341 57.2246L37.0332 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 60.6749L37.3052 60.3291L37.9043 60.6749L37.3052 61.0208L36.7061 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 59.6398L36.4341 59.2939L37.0332 59.6398L36.4341 59.9856L35.835 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 62.7453L37.3052 62.3994L37.9043 62.7453L37.3052 63.0911L36.7061 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 61.7101L36.4341 61.3643L37.0332 61.7101L36.4341 62.056L35.835 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 64.8146L37.3052 64.4688L37.9043 64.8146L37.3052 65.1605L36.7061 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 63.7794L36.4341 63.4336L37.0332 63.7794L36.4341 64.1253L35.835 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 66.8839L37.3052 66.5381L37.9043 66.8839L37.3052 67.2298L36.7061 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 65.8488L36.4341 65.5029L37.0332 65.8488L36.4341 66.1946L35.835 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 68.9533L37.3052 68.6074L37.9043 68.9533L37.3052 69.2991L36.7061 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 67.9191L36.4341 67.5732L37.0332 67.9191L36.4341 68.2649L35.835 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M36.7061 71.0236L37.3052 70.6777L37.9043 71.0236L37.3052 71.3694L36.7061 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M35.835 69.9884L36.4341 69.6426L37.0332 69.9884L36.4341 70.3343L35.835 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M36.4345 71.7119L36.9492 72.0087C36.6823 71.9652 36.4155 71.9189 36.1513 71.8753L36.4345 71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 44.1173L39.0493 44.4632L38.4502 44.1173L39.0493 43.7715L39.6484 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M38.7754 43.083L38.1763 43.4289L37.599 43.0966L38.5303 42.9414L38.7754 43.083Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 46.1876L39.0493 46.5335L38.4502 46.1876L39.0493 45.8418L39.6484 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M38.7754 45.1525L38.1763 45.4983L37.5772 45.1525L38.1763 44.8066L38.7754 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 48.257L39.0493 48.6028L38.4502 48.257L39.0493 47.9111L39.6484 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M38.7773 47.2228L38.1782 47.5687L37.5791 47.2228L38.1782 46.877L38.7773 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 50.3263L39.0493 50.6722L38.4502 50.3263L39.0493 49.9805L39.6484 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M38.7773 49.2921L38.1782 49.638L37.5791 49.2921L38.1782 48.9463L38.7773 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 52.3966L39.0493 52.7425L38.4502 52.3966L39.0493 52.0508L39.6484 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M38.7754 51.3615L38.1763 51.7073L37.5772 51.3615L38.1763 51.0156L38.7754 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 54.466L39.0493 54.8118L38.4502 54.466L39.0493 54.1201L39.6484 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M38.7773 53.4308L38.1782 53.7767L37.5791 53.4308L38.1782 53.085L38.7773 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M39.6484 56.5363L39.0493 56.8821L38.4502 56.5363L39.0493 56.1904L39.6484 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M38.7754 55.5011L38.1763 55.847L37.5772 55.5011L38.1763 55.1553L38.7754 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 58.6056L39.0493 58.2598L39.6484 58.6056L39.0493 58.9515L38.4502 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M38.7754 57.5705L38.1763 57.9163L37.5772 57.5705L38.1763 57.2246L38.7754 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 60.6749L39.0493 60.3291L39.6484 60.6749L39.0493 61.0208L38.4502 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M37.5791 59.6398L38.1782 59.2939L38.7773 59.6398L38.1782 59.9856L37.5791 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 62.7453L39.0493 62.3994L39.6484 62.7453L39.0493 63.0911L38.4502 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M37.5772 61.7101L38.1763 61.3643L38.7754 61.7101L38.1763 62.056L37.5772 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 64.8146L39.0493 64.4688L39.6484 64.8146L39.0493 65.1605L38.4502 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M37.5772 63.7794L38.1763 63.4336L38.7754 63.7794L38.1763 64.1253L37.5772 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 66.8839L39.0493 66.5381L39.6484 66.8839L39.0493 67.2298L38.4502 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M37.5791 65.8488L38.1782 65.5029L38.7773 65.8488L38.1782 66.1946L37.5791 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 68.9533L39.0493 68.6074L39.6484 68.9533L39.0493 69.2991L38.4502 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M37.5772 67.9191L38.1763 67.5732L38.7754 67.9191L38.1763 68.2649L37.5772 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M38.4502 71.0236L39.0493 70.6777L39.6484 71.0236L39.0493 71.3694L38.4502 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M37.5772 69.9884L38.1763 69.6426L38.7754 69.9884L38.1763 70.3343L37.5772 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M37.7126 72.134L37.5791 72.0578L38.1782 71.7119L38.7773 72.0578L38.4533 72.2457C38.2055 72.2103 37.9576 72.1749 37.7126 72.1367V72.134Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 44.1173L40.7915 44.4632L40.1924 44.1173L40.7915 43.7715L41.3906 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 43.0832L39.9204 43.429L39.3213 43.0832L39.9204 42.7373L40.5195 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 46.1876L40.7915 46.5335L40.1924 46.1876L40.7915 45.8418L41.3906 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 45.1525L39.9204 45.4983L39.3213 45.1525L39.9204 44.8066L40.5195 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 48.257L40.7915 48.6028L40.1924 48.257L40.7915 47.9111L41.3906 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 47.2228L39.9204 47.5687L39.3213 47.2228L39.9204 46.877L40.5195 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 50.3263L40.7915 50.6722L40.1924 50.3263L40.7915 49.9805L41.3906 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 49.2921L39.9204 49.638L39.3213 49.2921L39.9204 48.9463L40.5195 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 52.3966L40.7915 52.7425L40.1924 52.3966L40.7915 52.0508L41.3906 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 51.3615L39.9204 51.7073L39.3213 51.3615L39.9204 51.0156L40.5195 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 54.466L40.7915 54.8118L40.1924 54.466L40.7915 54.1201L41.3906 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 53.4308L39.9204 53.7767L39.3213 53.4308L39.9204 53.085L40.5195 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 56.5363L40.7915 56.8821L40.1924 56.5363L40.7915 56.1904L41.3906 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 55.5011L39.9204 55.847L39.3213 55.5011L39.9204 55.1553L40.5195 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M41.3906 58.6056L40.7915 58.9515L40.1924 58.6056L40.7915 58.2598L41.3906 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M40.5195 57.5705L39.9204 57.9163L39.3213 57.5705L39.9204 57.2246L40.5195 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M40.1924 60.6749L40.7915 60.3291L41.3906 60.6749L40.7915 61.0208L40.1924 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 59.6398L39.9204 59.2939L40.5195 59.6398L39.9204 59.9856L39.3213 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M40.1924 62.7453L40.7915 62.3994L41.3906 62.7453L40.7915 63.0911L40.1924 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 61.7101L39.9204 61.3643L40.5195 61.7101L39.9204 62.056L39.3213 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M40.1924 64.8146L40.7915 64.4688L41.3906 64.8146L40.7915 65.1605L40.1924 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 63.7794L39.9204 63.4336L40.5195 63.7794L39.9204 64.1253L39.3213 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M40.1924 66.8839L40.7915 66.5381L41.3906 66.8839L40.7915 67.2298L40.1924 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 65.8488L39.9204 65.5029L40.5195 65.8488L39.9204 66.1946L39.3213 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M40.1924 68.9533L40.7915 68.6074L41.3906 68.9533L40.7915 69.2991L40.1924 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 67.9191L39.9204 67.5732L40.5195 67.9191L39.9204 68.2649L39.3213 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M40.7915 70.6777L41.3906 71.0236L40.7915 71.3694L40.1924 71.0236L40.7915 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M39.3213 69.9884L39.9204 69.6426L40.5195 69.9884L39.9204 70.3343L39.3213 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M39.9204 71.7119L40.5195 72.0578L39.9204 72.4036L39.3213 72.0578L39.9204 71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M42.8242 42.2275L42.5356 42.3937L42.3749 42.3011L42.8242 42.2275Z"
                fill="#969A9E"
            />
            <path
                d="M42.5337 44.4632L41.9346 44.1173L42.5337 43.7715L43.1328 44.1173L42.5337 44.4632Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 43.0832L41.6626 43.429L41.0635 43.0832L41.6626 42.7373L42.2617 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 46.1876L42.5337 46.5335L41.9346 46.1876L42.5337 45.8418L43.1328 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 45.1525L41.6626 45.4983L41.0635 45.1525L41.6626 44.8066L42.2617 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 48.257L42.5337 48.6028L41.9346 48.257L42.5337 47.9111L43.1328 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 47.2228L41.6626 47.5687L41.0635 47.2228L41.6626 46.877L42.2617 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 50.3263L42.5337 50.6722L41.9346 50.3263L42.5337 49.9805L43.1328 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 49.2921L41.6626 49.638L41.0635 49.2921L41.6626 48.9463L42.2617 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 52.3966L42.5337 52.7425L41.9346 52.3966L42.5337 52.0508L43.1328 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 51.3615L41.6626 51.7073L41.0635 51.3615L41.6626 51.0156L42.2617 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 54.466L42.5337 54.8118L41.9346 54.466L42.5337 54.1201L43.1328 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 53.4308L41.6626 53.7767L41.0635 53.4308L41.6626 53.085L42.2617 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 56.5363L42.5337 56.8821L41.9346 56.5363L42.5337 56.1904L43.1328 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 55.5011L41.6626 55.847L41.0635 55.5011L41.6626 55.1553L42.2617 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 58.6056L42.5337 58.9515L41.9346 58.6056L42.5337 58.2598L43.1328 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M42.2617 57.5705L41.6626 57.9163L41.0635 57.5705L41.6626 57.2246L42.2617 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M43.1328 60.6749L42.5337 61.0208L41.9346 60.6749L42.5337 60.3291L43.1328 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 59.6398L41.6626 59.2939L42.2617 59.6398L41.6626 59.9856L41.0635 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M41.9346 62.7453L42.5337 62.3994L43.1328 62.7453L42.5337 63.0911L41.9346 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 61.7101L41.6626 61.3643L42.2617 61.7101L41.6626 62.056L41.0635 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M41.9346 64.8146L42.5337 64.4688L43.1328 64.8146L42.5337 65.1605L41.9346 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 63.7794L41.6626 63.4336L42.2617 63.7794L41.6626 64.1253L41.0635 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M41.9346 66.8839L42.5337 66.5381L43.1328 66.8839L42.5337 67.2298L41.9346 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 65.8488L41.6626 65.5029L42.2617 65.8488L41.6626 66.1946L41.0635 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M42.5337 68.6074L43.1328 68.9533L42.5337 69.2991L41.9346 68.9533L42.5337 68.6074Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 67.9191L41.6626 67.5732L42.2617 67.9191L41.6626 68.2649L41.0635 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M41.9346 71.0236L42.5337 70.6777L43.1328 71.0236L42.5337 71.3694L41.9346 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M41.6626 69.6426L42.2617 69.9884L41.6626 70.3343L41.0635 69.9884L41.6626 69.6426Z"
                fill="#969A9E"
            />
            <path
                d="M41.0635 72.0578L41.6626 71.7119L42.2617 72.0578L41.6626 72.4036L41.0635 72.0578Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 42.0481L44.2778 42.394L43.7278 42.0754L44.6591 41.9229L44.877 42.0481Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 44.1173L44.2778 44.4632L43.6787 44.1173L44.2778 43.7715L44.877 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 43.0832L43.4067 43.429L42.8076 43.0832L43.4067 42.7373L44.0059 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 46.1876L44.2778 46.5335L43.6787 46.1876L44.2778 45.8418L44.877 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 45.1525L43.4067 45.4983L42.8076 45.1525L43.4067 44.8066L44.0059 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 48.257L44.2778 48.6028L43.6787 48.257L44.2778 47.9111L44.877 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 47.2228L43.4067 47.5687L42.8076 47.2228L43.4067 46.877L44.0059 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 50.3263L44.2778 50.6722L43.6787 50.3263L44.2778 49.9805L44.877 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 49.2921L43.4067 49.638L42.8076 49.2921L43.4067 48.9463L44.0059 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 52.3966L44.2778 52.7425L43.6787 52.3966L44.2778 52.0508L44.877 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 51.3615L43.4067 51.7073L42.8076 51.3615L43.4067 51.0156L44.0059 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 54.466L44.2778 54.8118L43.6787 54.466L44.2778 54.1201L44.877 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 53.4308L43.4067 53.7767L42.8076 53.4308L43.4067 53.085L44.0059 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 56.5363L44.2778 56.8821L43.6787 56.5363L44.2778 56.1904L44.877 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 55.5011L43.4067 55.847L42.8076 55.5011L43.4067 55.1553L44.0059 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 58.6056L44.2778 58.9515L43.6787 58.6056L44.2778 58.2598L44.877 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 57.5705L43.4067 57.9163L42.8076 57.5705L43.4067 57.2246L44.0059 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 60.6749L44.2778 61.0208L43.6787 60.6749L44.2778 60.3291L44.877 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 59.6398L43.4067 59.9856L42.8076 59.6398L43.4067 59.2939L44.0059 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M44.877 62.7453L44.2778 63.0911L43.6787 62.7453L44.2778 62.3994L44.877 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M44.0059 61.7101L43.4067 62.056L42.8076 61.7101L43.4067 61.3643L44.0059 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M43.6787 64.8146L44.2778 64.4688L44.877 64.8146L44.2778 65.1605L43.6787 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M42.8076 63.7794L43.4067 63.4336L44.0059 63.7794L43.4067 64.1253L42.8076 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M44.2778 66.5381L44.877 66.8839L44.2778 67.2298L43.6787 66.8839L44.2778 66.5381Z"
                fill="#969A9E"
            />
            <path
                d="M42.8076 65.8488L43.4067 65.5029L44.0059 65.8488L43.4067 66.1946L42.8076 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M43.6787 68.9533L44.2778 68.6074L44.877 68.9533L44.2778 69.2991L43.6787 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M43.4067 67.5732L44.0059 67.9191L43.4067 68.2649L42.8076 67.9191L43.4067 67.5732Z"
                fill="#969A9E"
            />
            <path
                d="M44.2778 70.6777L44.877 71.0236L44.2778 71.3694L43.6787 71.0236L44.2778 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M42.8076 69.9884L43.4067 69.6426L44.0059 69.9884L43.4067 70.3343L42.8076 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M43.4067 71.7119L44.0059 72.0578L43.4067 72.4036L42.8076 72.0578L43.4067 71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 42.048L46.02 42.3939L45.4209 42.048L46.02 41.7021L46.6191 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M46.02 44.4632L45.4209 44.1173L46.02 43.7715L46.6191 44.1173L46.02 44.4632Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 43.0832L45.1489 43.429L44.5498 43.0832L45.1489 42.7373L45.748 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 46.1876L46.02 46.5335L45.4209 46.1876L46.02 45.8418L46.6191 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 45.1525L45.1489 45.4983L44.5498 45.1525L45.1489 44.8066L45.748 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 48.257L46.02 48.6028L45.4209 48.257L46.02 47.9111L46.6191 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 47.2228L45.1489 47.5687L44.5498 47.2228L45.1489 46.877L45.748 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 50.3263L46.02 50.6722L45.4209 50.3263L46.02 49.9805L46.6191 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 49.2921L45.1489 49.638L44.5498 49.2921L45.1489 48.9463L45.748 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 52.3966L46.02 52.7425L45.4209 52.3966L46.02 52.0508L46.6191 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 51.3615L45.1489 51.7073L44.5498 51.3615L45.1489 51.0156L45.748 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 54.466L46.02 54.8118L45.4209 54.466L46.02 54.1201L46.6191 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 53.4308L45.1489 53.7767L44.5498 53.4308L45.1489 53.085L45.748 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 56.5363L46.02 56.8821L45.4209 56.5363L46.02 56.1904L46.6191 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 55.5011L45.1489 55.847L44.5498 55.5011L45.1489 55.1553L45.748 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 58.6056L46.02 58.9515L45.4209 58.6056L46.02 58.2598L46.6191 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 57.5705L45.1489 57.9163L44.5498 57.5705L45.1489 57.2246L45.748 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 60.6749L46.02 61.0208L45.4209 60.6749L46.02 60.3291L46.6191 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 59.6398L45.1489 59.9856L44.5498 59.6398L45.1489 59.2939L45.748 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 62.7453L46.02 63.0911L45.4209 62.7453L46.02 62.3994L46.6191 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 61.7101L45.1489 62.056L44.5498 61.7101L45.1489 61.3643L45.748 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M46.6191 64.8146L46.02 65.1605L45.4209 64.8146L46.02 64.4688L46.6191 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M45.748 63.7794L45.1489 64.1253L44.5498 63.7794L45.1489 63.4336L45.748 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M45.4209 66.8839L46.02 66.5381L46.6191 66.8839L46.02 67.2298L45.4209 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M45.1489 65.5029L45.748 65.8488L45.1489 66.1946L44.5498 65.8488L45.1489 65.5029Z"
                fill="#969A9E"
            />
            <path
                d="M45.4209 68.9533L46.02 68.6074L46.6191 68.9533L46.02 69.2991L45.4209 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M44.5498 67.9191L45.1489 67.5732L45.748 67.9191L45.1489 68.2649L44.5498 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M46.02 70.6777L46.6191 71.0236L46.02 71.3694L45.4209 71.0236L46.02 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M44.5498 69.9884L45.1489 69.6426L45.748 69.9884L45.1489 70.3343L44.5498 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M44.7324 71.9521C44.6997 72.0039 44.6589 72.0529 44.6153 72.0965L44.5472 72.0584L44.7324 71.9521Z"
                fill="#969A9E"
            />
            <path
                d="M47.7622 42.3939L47.1631 42.048L47.7622 41.7021L48.3613 42.048L47.7622 42.3939Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 44.1173L47.7622 44.4632L47.1631 44.1173L47.7622 43.7715L48.3613 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M46.8911 43.429L46.292 43.0832L46.8911 42.7373L47.4902 43.0832L46.8911 43.429Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 46.1876L47.7622 46.5335L47.1631 46.1876L47.7622 45.8418L48.3613 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 45.1525L46.8911 45.4983L46.292 45.1525L46.8911 44.8066L47.4902 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 48.257L47.7622 48.6028L47.1631 48.257L47.7622 47.9111L48.3613 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 47.2228L46.8911 47.5687L46.292 47.2228L46.8911 46.877L47.4902 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 50.3263L47.7622 50.6722L47.1631 50.3263L47.7622 49.9805L48.3613 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 49.2921L46.8911 49.638L46.292 49.2921L46.8911 48.9463L47.4902 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 52.3966L47.7622 52.7425L47.1631 52.3966L47.7622 52.0508L48.3613 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 51.3615L46.8911 51.7073L46.292 51.3615L46.8911 51.0156L47.4902 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 54.466L47.7622 54.8118L47.1631 54.466L47.7622 54.1201L48.3613 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 53.4308L46.8911 53.7767L46.292 53.4308L46.8911 53.085L47.4902 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 56.5363L47.7622 56.8821L47.1631 56.5363L47.7622 56.1904L48.3613 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 55.5011L46.8911 55.847L46.292 55.5011L46.8911 55.1553L47.4902 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 58.6056L47.7622 58.9515L47.1631 58.6056L47.7622 58.2598L48.3613 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 57.5705L46.8911 57.9163L46.292 57.5705L46.8911 57.2246L47.4902 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 60.6749L47.7622 61.0208L47.1631 60.6749L47.7622 60.3291L48.3613 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 59.6398L46.8911 59.9856L46.292 59.6398L46.8911 59.2939L47.4902 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 62.7453L47.7622 63.0911L47.1631 62.7453L47.7622 62.3994L48.3613 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 61.7101L46.8911 62.056L46.292 61.7101L46.8911 61.3643L47.4902 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 64.8146L47.7622 65.1605L47.1631 64.8146L47.7622 64.4688L48.3613 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 63.7794L46.8911 64.1253L46.292 63.7794L46.8911 63.4336L47.4902 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M48.3613 66.8839L47.7622 67.2298L47.1631 66.8839L47.7622 66.5381L48.3613 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M47.4902 65.8488L46.8911 66.1946L46.292 65.8488L46.8911 65.5029L47.4902 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M47.7622 68.6074L48.3613 68.9533L47.7622 69.2991L47.1631 68.9533L47.7622 68.6074Z"
                fill="#969A9E"
            />
            <path
                d="M46.292 67.9191L46.8911 67.5732L47.4902 67.9191L46.8911 68.2649L46.292 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M47.7622 70.6777L48.3613 71.0236L47.7622 71.3694L47.1631 71.0236L47.7622 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M46.8911 69.6426L47.4902 69.9884L46.8911 70.3343L46.292 69.9884L46.8911 69.6426Z"
                fill="#969A9E"
            />
            <path
                d="M46.8911 71.7119L47.4902 72.0578L46.9674 72.36C46.8775 72.3355 46.7849 72.3165 46.6951 72.292L46.292 72.0605L46.8911 71.7146V71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M49.5064 42.3939L48.9072 42.048L49.5064 41.7021L50.1055 42.048L49.5064 42.3939Z"
                fill="#969A9E"
            />
            <path
                d="M48.5019 41.2833L48.875 41.2207L48.6354 41.3596L48.5019 41.2833Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 44.1173L49.5064 44.4632L48.9072 44.1173L49.5064 43.7715L50.1055 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M48.6353 43.429L48.0362 43.0832L48.6353 42.7373L49.2344 43.0832L48.6353 43.429Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 46.1876L49.5064 46.5335L48.9072 46.1876L49.5064 45.8418L50.1055 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 45.1525L48.6353 45.4983L48.0362 45.1525L48.6353 44.8066L49.2344 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 48.257L49.5064 48.6028L48.9072 48.257L49.5064 47.9111L50.1055 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 47.2228L48.6353 47.5687L48.0362 47.2228L48.6353 46.877L49.2344 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 50.3263L49.5064 50.6722L48.9072 50.3263L49.5064 49.9805L50.1055 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 49.2921L48.6353 49.638L48.0362 49.2921L48.6353 48.9463L49.2344 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 52.3966L49.5064 52.7425L48.9072 52.3966L49.5064 52.0508L50.1055 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 51.3615L48.6353 51.7073L48.0362 51.3615L48.6353 51.0156L49.2344 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 54.466L49.5064 54.8118L48.9072 54.466L49.5064 54.1201L50.1055 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 53.4308L48.6353 53.7767L48.0362 53.4308L48.6353 53.085L49.2344 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 56.5363L49.5064 56.8821L48.9072 56.5363L49.5064 56.1904L50.1055 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 55.5011L48.6353 55.847L48.0362 55.5011L48.6353 55.1553L49.2344 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 58.6056L49.5064 58.9515L48.9072 58.6056L49.5064 58.2598L50.1055 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 57.5705L48.6353 57.9163L48.0362 57.5705L48.6353 57.2246L49.2344 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 60.6749L49.5064 61.0208L48.9072 60.6749L49.5064 60.3291L50.1055 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 59.6398L48.6353 59.9856L48.0362 59.6398L48.6353 59.2939L49.2344 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 62.7453L49.5064 63.0911L48.9072 62.7453L49.5064 62.3994L50.1055 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 61.7101L48.6353 62.056L48.0362 61.7101L48.6353 61.3643L49.2344 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 64.8146L49.5064 65.1605L48.9072 64.8146L49.5064 64.4688L50.1055 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 63.7794L48.6353 64.1253L48.0362 63.7794L48.6353 63.4336L49.2344 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 66.8839L49.5064 67.2298L48.9072 66.8839L49.5064 66.5381L50.1055 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 65.8488L48.6353 66.1946L48.0362 65.8488L48.6353 65.5029L49.2344 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M50.1055 68.9533L49.5064 69.2991L48.9072 68.9533L49.5064 68.6074L50.1055 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M49.2344 67.9191L48.6353 68.2649L48.0362 67.9191L48.6353 67.5732L49.2344 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M49.5064 70.6777L50.1055 71.0236L49.5064 71.3694L48.9072 71.0236L49.5064 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M48.6353 69.6426L49.2344 69.9884L48.6353 70.3343L48.0362 69.9884L48.6353 69.6426Z"
                fill="#969A9E"
            />
            <path
                d="M48.6353 71.7119L49.2344 72.0578L48.6353 72.4036L48.0362 72.0578L48.6353 71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 42.048L51.2485 42.3939L50.6494 42.048L51.2485 41.7021L51.8477 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M50.3774 41.3589L49.8546 41.0566L50.7859 40.9014L50.9766 41.013L50.3774 41.3589Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 44.1173L51.2485 44.4632L50.6494 44.1173L51.2485 43.7715L51.8477 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 43.0832L50.3774 43.429L49.7783 43.0832L50.3774 42.7373L50.9766 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 46.1876L51.2485 46.5335L50.6494 46.1876L51.2485 45.8418L51.8477 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 45.1525L50.3774 45.4983L49.7783 45.1525L50.3774 44.8066L50.9766 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 48.257L51.2485 48.6028L50.6494 48.257L51.2485 47.9111L51.8477 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 47.2228L50.3774 47.5687L49.7783 47.2228L50.3774 46.877L50.9766 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 50.3263L51.2485 50.6722L50.6494 50.3263L51.2485 49.9805L51.8477 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 49.2921L50.3774 49.638L49.7783 49.2921L50.3774 48.9463L50.9766 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 52.3966L51.2485 52.7425L50.6494 52.3966L51.2485 52.0508L51.8477 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 51.3615L50.3774 51.7073L49.7783 51.3615L50.3774 51.0156L50.9766 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 54.466L51.2485 54.8118L50.6494 54.466L51.2485 54.1201L51.8477 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 53.4308L50.3774 53.7767L49.7783 53.4308L50.3774 53.085L50.9766 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 56.5363L51.2485 56.8821L50.6494 56.5363L51.2485 56.1904L51.8477 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 55.5011L50.3774 55.847L49.7783 55.5011L50.3774 55.1553L50.9766 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 58.6056L51.2485 58.9515L50.6494 58.6056L51.2485 58.2598L51.8477 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 57.5705L50.3774 57.9163L49.7783 57.5705L50.3774 57.2246L50.9766 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 60.6749L51.2485 61.0208L50.6494 60.6749L51.2485 60.3291L51.8477 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 59.6398L50.3774 59.9856L49.7783 59.6398L50.3774 59.2939L50.9766 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 62.7453L51.2485 63.0911L50.6494 62.7453L51.2485 62.3994L51.8477 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 61.7101L50.3774 62.056L49.7783 61.7101L50.3774 61.3643L50.9766 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 64.8146L51.2485 65.1605L50.6494 64.8146L51.2485 64.4688L51.8477 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 63.7794L50.3774 64.1253L49.7783 63.7794L50.3774 63.4336L50.9766 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 66.8839L51.2485 67.2298L50.6494 66.8839L51.2485 66.5381L51.8477 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 65.8488L50.3774 66.1946L49.7783 65.8488L50.3774 65.5029L50.9766 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 68.9533L51.2485 69.2991L50.6494 68.9533L51.2485 68.6074L51.8477 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 67.9191L50.3774 68.2649L49.7783 67.9191L50.3774 67.5732L50.9766 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M51.8477 71.0236L51.2485 71.3694L50.6494 71.0236L51.2485 70.6777L51.8477 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 69.9884L50.3774 70.3343L49.7783 69.9884L50.3774 69.6426L50.9766 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M50.9766 72.0578L50.3774 72.4036L49.7783 72.0578L50.3774 71.7119L50.9766 72.0578Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 42.048L52.9927 42.3939L52.3936 42.048L52.9927 41.7021L53.5918 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M52.1387 40.6787L52.7188 41.0137L52.1196 41.3595L51.5205 41.0137L52.0842 40.6869L52.1387 40.6787Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 44.1173L52.9927 44.4632L52.3936 44.1173L52.9927 43.7715L53.5918 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 43.0832L52.1196 43.429L51.5205 43.0832L52.1196 42.7373L52.7188 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 46.1876L52.9927 46.5335L52.3936 46.1876L52.9927 45.8418L53.5918 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 45.1525L52.1196 45.4983L51.5205 45.1525L52.1196 44.8066L52.7188 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 48.257L52.9927 48.6028L52.3936 48.257L52.9927 47.9111L53.5918 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 47.2228L52.1196 47.5687L51.5205 47.2228L52.1196 46.877L52.7188 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 50.3263L52.9927 50.6722L52.3936 50.3263L52.9927 49.9805L53.5918 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 49.2921L52.1196 49.638L51.5205 49.2921L52.1196 48.9463L52.7188 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 52.3966L52.9927 52.7425L52.3936 52.3966L52.9927 52.0508L53.5918 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 51.3615L52.1196 51.7073L51.5205 51.3615L52.1196 51.0156L52.7188 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 54.466L52.9927 54.8118L52.3936 54.466L52.9927 54.1201L53.5918 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 53.4308L52.1196 53.7767L51.5205 53.4308L52.1196 53.085L52.7188 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 56.5363L52.9927 56.8821L52.3936 56.5363L52.9927 56.1904L53.5918 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 55.5011L52.1196 55.847L51.5205 55.5011L52.1196 55.1553L52.7188 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 58.6056L52.9927 58.9515L52.3936 58.6056L52.9927 58.2598L53.5918 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 57.5705L52.1196 57.9163L51.5205 57.5705L52.1196 57.2246L52.7188 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 60.6749L52.9927 61.0208L52.3936 60.6749L52.9927 60.3291L53.5918 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 59.6398L52.1196 59.9856L51.5205 59.6398L52.1196 59.2939L52.7188 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 62.7453L52.9927 63.0911L52.3936 62.7453L52.9927 62.3994L53.5918 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 61.7101L52.1196 62.056L51.5205 61.7101L52.1196 61.3643L52.7188 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 64.8146L52.9927 65.1605L52.3936 64.8146L52.9927 64.4688L53.5918 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 63.7794L52.1196 64.1253L51.5205 63.7794L52.1196 63.4336L52.7188 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 66.8839L52.9927 67.2298L52.3936 66.8839L52.9927 66.5381L53.5918 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 65.8488L52.1196 66.1946L51.5205 65.8488L52.1196 65.5029L52.7188 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 68.9533L52.9927 69.2991L52.3936 68.9533L52.9927 68.6074L53.5918 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 67.9191L52.1196 68.2649L51.5205 67.9191L52.1196 67.5732L52.7188 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M53.5918 71.0236L52.9927 71.3694L52.3936 71.0236L52.9927 70.6777L53.5918 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 69.9884L52.1196 70.3343L51.5205 69.9884L52.1196 69.6426L52.7188 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M52.7188 72.0578L52.1196 72.4036L51.5205 72.0578L52.1196 71.7119L52.7188 72.0578Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 42.048L54.7349 42.3939L54.1358 42.048L54.7349 41.7021L55.334 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 41.0138L53.8638 41.3597L53.2647 41.0138L53.8638 40.668L54.4629 41.0138Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 44.1173L54.7349 44.4632L54.1358 44.1173L54.7349 43.7715L55.334 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 43.0832L53.8638 43.429L53.2647 43.0832L53.8638 42.7373L54.4629 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 46.1876L54.7349 46.5335L54.1358 46.1876L54.7349 45.8418L55.334 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 45.1525L53.8638 45.4983L53.2647 45.1525L53.8638 44.8066L54.4629 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 48.257L54.7349 48.6028L54.1358 48.257L54.7349 47.9111L55.334 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 47.2228L53.8638 47.5687L53.2647 47.2228L53.8638 46.877L54.4629 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 50.3263L54.7349 50.6722L54.1358 50.3263L54.7349 49.9805L55.334 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 49.2921L53.8638 49.638L53.2647 49.2921L53.8638 48.9463L54.4629 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 52.3966L54.7349 52.7425L54.1358 52.3966L54.7349 52.0508L55.334 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 51.3615L53.8638 51.7073L53.2647 51.3615L53.8638 51.0156L54.4629 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 54.466L54.7349 54.8118L54.1358 54.466L54.7349 54.1201L55.334 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 53.4308L53.8638 53.7767L53.2647 53.4308L53.8638 53.085L54.4629 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 56.5363L54.7349 56.8821L54.1358 56.5363L54.7349 56.1904L55.334 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 55.5011L53.8638 55.847L53.2647 55.5011L53.8638 55.1553L54.4629 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 58.6056L54.7349 58.9515L54.1358 58.6056L54.7349 58.2598L55.334 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 57.5705L53.8638 57.9163L53.2647 57.5705L53.8638 57.2246L54.4629 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 60.6749L54.7349 61.0208L54.1358 60.6749L54.7349 60.3291L55.334 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 59.6398L53.8638 59.9856L53.2647 59.6398L53.8638 59.2939L54.4629 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 62.7453L54.7349 63.0911L54.1358 62.7453L54.7349 62.3994L55.334 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 61.7101L53.8638 62.056L53.2647 61.7101L53.8638 61.3643L54.4629 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 64.8146L54.7349 65.1605L54.1358 64.8146L54.7349 64.4688L55.334 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 63.7794L53.8638 64.1253L53.2647 63.7794L53.8638 63.4336L54.4629 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 66.8839L54.7349 67.2298L54.1358 66.8839L54.7349 66.5381L55.334 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 65.8488L53.8638 66.1946L53.2647 65.8488L53.8638 65.5029L54.4629 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 68.9533L54.7349 69.2991L54.1358 68.9533L54.7349 68.6074L55.334 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 67.9191L53.8638 68.2649L53.2647 67.9191L53.8638 67.5732L54.4629 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M55.334 71.0236L54.7349 71.3694L54.1358 71.0236L54.7349 70.6777L55.334 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 69.9884L53.8638 70.3343L53.2647 69.9884L53.8638 69.6426L54.4629 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M54.4629 72.0578L54.177 72.2239L53.635 72.2702L53.2647 72.0578L53.8638 71.7119L54.4629 72.0578Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 44.1173L56.4771 44.4632L55.878 44.1173L56.4771 43.7715L57.0762 44.1173Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 43.0832L55.606 43.429L55.0069 43.0832L55.606 42.7373L56.2051 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 46.1876L56.4771 46.5335L55.878 46.1876L56.4771 45.8418L57.0762 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 45.1525L55.606 45.4983L55.0069 45.1525L55.606 44.8066L56.2051 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 48.257L56.4771 48.6028L55.878 48.257L56.4771 47.9111L57.0762 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 47.2228L55.606 47.5687L55.0069 47.2228L55.606 46.877L56.2051 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 50.3263L56.4771 50.6722L55.878 50.3263L56.4771 49.9805L57.0762 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 49.2921L55.606 49.638L55.0069 49.2921L55.606 48.9463L56.2051 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 52.3966L56.4771 52.7425L55.878 52.3966L56.4771 52.0508L57.0762 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 51.3615L55.606 51.7073L55.0069 51.3615L55.606 51.0156L56.2051 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 54.466L56.4771 54.8118L55.878 54.466L56.4771 54.1201L57.0762 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 53.4308L55.606 53.7767L55.0069 53.4308L55.606 53.085L56.2051 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 56.5363L56.4771 56.8821L55.878 56.5363L56.4771 56.1904L57.0762 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 55.5011L55.606 55.847L55.0069 55.5011L55.606 55.1553L56.2051 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 58.6056L56.4771 58.9515L55.878 58.6056L56.4771 58.2598L57.0762 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 57.5705L55.606 57.9163L55.0069 57.5705L55.606 57.2246L56.2051 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 60.6749L56.4771 61.0208L55.878 60.6749L56.4771 60.3291L57.0762 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 59.6398L55.606 59.9856L55.0069 59.6398L55.606 59.2939L56.2051 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 62.7453L56.4771 63.0911L55.878 62.7453L56.4771 62.3994L57.0762 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 61.7101L55.606 62.056L55.0069 61.7101L55.606 61.3643L56.2051 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 64.8146L56.4771 65.1605L55.878 64.8146L56.4771 64.4688L57.0762 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 63.7794L55.606 64.1253L55.0069 63.7794L55.606 63.4336L56.2051 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 66.8839L56.4771 67.2298L55.878 66.8839L56.4771 66.5381L57.0762 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 65.8488L55.606 66.1946L55.0069 65.8488L55.606 65.5029L56.2051 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 68.9533L56.4771 69.2991L55.878 68.9533L56.4771 68.6074L57.0762 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 67.9191L55.606 68.2649L55.0069 67.9191L55.606 67.5732L56.2051 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M57.0762 71.0236L56.4771 71.3694L55.878 71.0236L56.4771 70.6777L57.0762 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M56.2051 69.9884L55.606 70.3343L55.0069 69.9884L55.606 69.6426L56.2051 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M56.1777 72.0414L55.1429 72.134L55.0067 72.0578L55.6059 71.7119L56.1777 72.0414Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 46.1876L58.2212 46.5335L57.6221 46.1876L58.2212 45.8418L58.8203 46.1876Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 45.1525L57.3482 45.4983L56.749 45.1525L57.3482 44.8066L57.9473 45.1525Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 48.257L58.2212 48.6028L57.6221 48.257L58.2212 47.9111L58.8203 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 47.2228L57.3482 47.5687L56.749 47.2228L57.3482 46.877L57.9473 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 50.3263L58.2212 50.6722L57.6221 50.3263L58.2212 49.9805L58.8203 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 49.2921L57.3482 49.638L56.749 49.2921L57.3482 48.9463L57.9473 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 52.3966L58.2212 52.7425L57.6221 52.3966L58.2212 52.0508L58.8203 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 51.3615L57.3482 51.7073L56.749 51.3615L57.3482 51.0156L57.9473 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 54.466L58.2212 54.8118L57.6221 54.466L58.2212 54.1201L58.8203 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 53.4308L57.3482 53.7767L56.749 53.4308L57.3482 53.085L57.9473 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 56.5363L58.2212 56.8821L57.6221 56.5363L58.2212 56.1904L58.8203 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 55.5011L57.3482 55.847L56.749 55.5011L57.3482 55.1553L57.9473 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 58.6056L58.2212 58.9515L57.6221 58.6056L58.2212 58.2598L58.8203 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 57.5705L57.3482 57.9163L56.749 57.5705L57.3482 57.2246L57.9473 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 60.6749L58.2212 61.0208L57.6221 60.6749L58.2212 60.3291L58.8203 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 59.6398L57.3482 59.9856L56.749 59.6398L57.3482 59.2939L57.9473 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 62.7453L58.2212 63.0911L57.6221 62.7453L58.2212 62.3994L58.8203 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 61.7101L57.3482 62.056L56.749 61.7101L57.3482 61.3643L57.9473 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 64.8146L58.2212 65.1605L57.6221 64.8146L58.2212 64.4688L58.8203 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 63.7794L57.3482 64.1253L56.749 63.7794L57.3482 63.4336L57.9473 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 66.8839L58.2212 67.2298L57.6221 66.8839L58.2212 66.5381L58.8203 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 65.8488L57.3482 66.1946L56.749 65.8488L57.3482 65.5029L57.9473 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 68.9533L58.2212 69.2991L57.6221 68.9533L58.2212 68.6074L58.8203 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 67.9191L57.3482 68.2649L56.749 67.9191L57.3482 67.5732L57.9473 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M58.8203 71.0236L58.2212 71.3694L57.6221 71.0236L58.2212 70.6777L58.8203 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M57.9473 69.9884L57.3482 70.3343L56.749 69.9884L57.3482 69.6426L57.9473 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M57.6836 71.9053L56.8884 71.9761L57.3486 71.7119L57.6836 71.9053Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 48.257L59.9634 48.6028L59.3643 48.257L59.9634 47.9111L60.5625 48.257Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 47.2228L59.0923 47.5687L58.4932 47.2228L59.0923 46.877L59.6914 47.2228Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 50.3263L59.9634 50.6722L59.3643 50.3263L59.9634 49.9805L60.5625 50.3263Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 49.2921L59.0923 49.638L58.4932 49.2921L59.0923 48.9463L59.6914 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 52.3966L59.9634 52.7425L59.3643 52.3966L59.9634 52.0508L60.5625 52.3966Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 51.3615L59.0923 51.7073L58.4932 51.3615L59.0923 51.0156L59.6914 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 54.466L59.9634 54.8118L59.3643 54.466L59.9634 54.1201L60.5625 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 53.4308L59.0923 53.7767L58.4932 53.4308L59.0923 53.085L59.6914 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 56.5363L59.9634 56.8821L59.3643 56.5363L59.9634 56.1904L60.5625 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 55.5011L59.0923 55.847L58.4932 55.5011L59.0923 55.1553L59.6914 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 58.6056L59.9634 58.9515L59.3643 58.6056L59.9634 58.2598L60.5625 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 57.5705L59.0923 57.9163L58.4932 57.5705L59.0923 57.2246L59.6914 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 60.6749L59.9634 61.0208L59.3643 60.6749L59.9634 60.3291L60.5625 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 59.6398L59.0923 59.9856L58.4932 59.6398L59.0923 59.2939L59.6914 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 62.7453L59.9634 63.0911L59.3643 62.7453L59.9634 62.3994L60.5625 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 61.7101L59.0923 62.056L58.4932 61.7101L59.0923 61.3643L59.6914 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 64.8146L59.9634 65.1605L59.3643 64.8146L59.9634 64.4688L60.5625 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 63.7794L59.0923 64.1253L58.4932 63.7794L59.0923 63.4336L59.6914 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 66.8839L59.9634 67.2298L59.3643 66.8839L59.9634 66.5381L60.5625 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 65.8488L59.0923 66.1946L58.4932 65.8488L59.0923 65.5029L59.6914 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M60.5625 68.9533L59.9634 69.2991L59.3643 68.9533L59.9634 68.6074L60.5625 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 67.9191L59.0923 68.2649L58.4932 67.9191L59.0923 67.5732L59.6914 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M59.3643 71.0236L59.9634 70.6777L60.5625 71.0236L59.9634 71.3694L59.3643 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M59.6914 69.9884L59.0923 70.3343L58.4932 69.9884L59.0923 69.6426L59.6914 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M59.0914 71.7119L59.1895 71.7691L58.9553 71.7882L59.0914 71.7119Z"
                fill="#969A9E"
            />
            <path
                d="M62.2517 50.2936L62.2871 50.3372L61.7071 50.6722L61.1079 50.3263L61.7071 49.9805L62.2517 50.2936Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 49.2921L60.8345 49.638L60.2354 49.2921L60.8345 48.9463L61.4336 49.2921Z"
                fill="#969A9E"
            />
            <path
                d="M61.7056 52.7425L61.1065 52.3966L61.7056 52.0508L62.3047 52.3966L61.7056 52.7425Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 51.3615L60.8345 51.7073L60.2354 51.3615L60.8345 51.0156L61.4336 51.3615Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 54.466L61.7056 54.8118L61.1065 54.466L61.7056 54.1201L62.3047 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 53.4308L60.8345 53.7767L60.2354 53.4308L60.8345 53.085L61.4336 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 56.5363L61.7056 56.8821L61.1065 56.5363L61.7056 56.1904L62.3047 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 55.5011L60.8345 55.847L60.2354 55.5011L60.8345 55.1553L61.4336 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 58.6056L61.7056 58.9515L61.1065 58.6056L61.7056 58.2598L62.3047 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 57.5705L60.8345 57.9163L60.2354 57.5705L60.8345 57.2246L61.4336 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 60.6749L61.7056 61.0208L61.1065 60.6749L61.7056 60.3291L62.3047 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 59.6398L60.8345 59.9856L60.2354 59.6398L60.8345 59.2939L61.4336 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 62.7453L61.7056 63.0911L61.1065 62.7453L61.7056 62.3994L62.3047 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 61.7101L60.8345 62.056L60.2354 61.7101L60.8345 61.3643L61.4336 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 64.8146L61.7056 65.1605L61.1065 64.8146L61.7056 64.4688L62.3047 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 63.7794L60.8345 64.1253L60.2354 63.7794L60.8345 63.4336L61.4336 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 66.8839L61.7056 67.2298L61.1065 66.8839L61.7056 66.5381L62.3047 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 65.8488L60.8345 66.1946L60.2354 65.8488L60.8345 65.5029L61.4336 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M61.7056 69.2991L61.1065 68.9533L61.7056 68.6074L62.3047 68.9533L61.7056 69.2991Z"
                fill="#969A9E"
            />
            <path
                d="M61.4336 67.9191L60.8345 68.2649L60.2354 67.9191L60.8345 67.5732L61.4336 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M62.3047 71.0236L61.7056 71.3694L61.1065 71.0236L61.7056 70.6777L62.3047 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M60.8345 70.3343L60.2354 69.9884L60.8345 69.6426L61.4336 69.9884L60.8345 70.3343Z"
                fill="#969A9E"
            />
            <path
                d="M63.8794 52.2986L63.9883 52.432L63.4491 52.7425L62.85 52.3966L63.4491 52.0508L63.8794 52.2986Z"
                fill="#969A9E"
            />
            <path
                d="M63.064 51.2961L63.1348 51.3833L62.5765 51.7073L61.9774 51.3615L62.5765 51.0156L63.064 51.2961Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 54.466L63.4497 54.8118L62.8506 54.466L63.4497 54.1201L64.0488 54.466Z"
                fill="#969A9E"
            />
            <path
                d="M63.1777 53.4308L62.5786 53.7767L61.9795 53.4308L62.5786 53.085L63.1777 53.4308Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 56.5363L63.4497 56.8821L62.8506 56.5363L63.4497 56.1904L64.0488 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M63.1758 55.5011L62.5767 55.847L61.9776 55.5011L62.5767 55.1553L63.1758 55.5011Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 58.6056L63.4497 58.9515L62.8506 58.6056L63.4497 58.2598L64.0488 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M63.1758 57.5705L62.5767 57.9163L61.9776 57.5705L62.5767 57.2246L63.1758 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 60.6749L63.4497 61.0208L62.8506 60.6749L63.4497 60.3291L64.0488 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M63.1777 59.6398L62.5786 59.9856L61.9795 59.6398L62.5786 59.2939L63.1777 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M63.4497 63.0911L62.8506 62.7453L63.4497 62.3994L64.0488 62.7453L63.4497 63.0911Z"
                fill="#969A9E"
            />
            <path
                d="M63.1758 61.7101L62.5767 62.056L61.9776 61.7101L62.5767 61.3643L63.1758 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 64.8146L63.4497 65.1605L62.8506 64.8146L63.4497 64.4688L64.0488 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M63.1758 63.7794L62.5767 64.1253L61.9776 63.7794L62.5767 63.4336L63.1758 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M63.4497 67.2298L62.8506 66.8839L63.4497 66.5381L64.0488 66.8839L63.4497 67.2298Z"
                fill="#969A9E"
            />
            <path
                d="M63.1777 65.8488L62.5786 66.1946L61.9795 65.8488L62.5786 65.5029L63.1777 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 68.9533L63.4497 69.2991L62.8506 68.9533L63.4497 68.6074L64.0488 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M62.5767 68.2649L61.9776 67.9191L62.5767 67.5732L63.1758 67.9191L62.5767 68.2649Z"
                fill="#969A9E"
            />
            <path
                d="M64.0488 71.0236L63.4497 71.3694L62.8506 71.0236L63.4497 70.6777L64.0488 71.0236Z"
                fill="#969A9E"
            />
            <path
                d="M63.1758 69.9884L62.5767 70.3343L61.9776 69.9884L62.5767 69.6426L63.1758 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M65.5078 54.3026L65.6875 54.5232L65.1919 54.8118L64.5928 54.466L65.1919 54.1201L65.5078 54.3026Z"
                fill="#969A9E"
            />
            <path
                d="M64.6936 53.3001L64.8379 53.4771L64.3205 53.7767L63.7214 53.4308L64.3205 53.085L64.6936 53.3001Z"
                fill="#969A9E"
            />
            <path
                d="M65.791 56.5363L65.1919 56.8821L64.5928 56.5363L65.1919 56.1904L65.791 56.5363Z"
                fill="#969A9E"
            />
            <path
                d="M64.3208 55.847L63.7217 55.5011L64.3208 55.1553L64.9199 55.5011L64.3208 55.847Z"
                fill="#969A9E"
            />
            <path
                d="M65.791 58.6056L65.1919 58.9515L64.5928 58.6056L65.1919 58.2598L65.791 58.6056Z"
                fill="#969A9E"
            />
            <path
                d="M64.9199 57.5705L64.3208 57.9163L63.7217 57.5705L64.3208 57.2246L64.9199 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M65.1919 61.0208L64.5928 60.6749L65.1919 60.3291L65.791 60.6749L65.1919 61.0208Z"
                fill="#969A9E"
            />
            <path
                d="M64.9199 59.6398L64.3208 59.9856L63.7217 59.6398L64.3208 59.2939L64.9199 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M65.791 62.7453L65.1919 63.0911L64.5928 62.7453L65.1919 62.3994L65.791 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M64.3208 62.056L63.7217 61.7101L64.3208 61.3643L64.9199 61.7101L64.3208 62.056Z"
                fill="#969A9E"
            />
            <path
                d="M65.1919 65.1605L64.5928 64.8146L65.1919 64.4688L65.791 64.8146L65.1919 65.1605Z"
                fill="#969A9E"
            />
            <path
                d="M64.9199 63.7794L64.3208 64.1253L63.7217 63.7794L64.3208 63.4336L64.9199 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M65.791 66.8839L65.1919 67.2298L64.5928 66.8839L65.1919 66.5381L65.791 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M64.3208 66.1946L63.7217 65.8488L64.3208 65.5029L64.9199 65.8488L64.3208 66.1946Z"
                fill="#969A9E"
            />
            <path
                d="M65.791 68.9533L65.1919 69.2991L64.5928 68.9533L65.1919 68.6074L65.791 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M64.9199 67.9191L64.3208 68.2649L63.7217 67.9191L64.3208 67.5732L64.9199 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M65.4996 71.1897L64.9686 71.2387L64.5928 71.0236L65.1919 70.6777L65.791 71.0236L65.4996 71.1897Z"
                fill="#969A9E"
            />
            <path
                d="M64.9199 69.9884L64.3208 70.3343L63.7217 69.9884L64.3208 69.6426L64.9199 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M67.1354 56.3075L67.3887 56.618L66.9339 56.8821L66.3348 56.5363L66.9339 56.1904L67.1354 56.3075Z"
                fill="#969A9E"
            />
            <path
                d="M66.3212 55.305L66.5391 55.5719L66.0625 55.847L65.4634 55.5011L66.0625 55.1553L66.3212 55.305Z"
                fill="#969A9E"
            />
            <path
                d="M66.9341 58.9515L66.335 58.6056L66.9341 58.2598L67.5332 58.6056L66.9341 58.9515Z"
                fill="#969A9E"
            />
            <path
                d="M66.6621 57.5705L66.063 57.9163L65.4639 57.5705L66.063 57.2246L66.6621 57.5705Z"
                fill="#969A9E"
            />
            <path
                d="M67.5332 60.6749L66.9341 61.0208L66.335 60.6749L66.9341 60.3291L67.5332 60.6749Z"
                fill="#969A9E"
            />
            <path
                d="M66.063 59.9856L65.4639 59.6398L66.063 59.2939L66.6621 59.6398L66.063 59.9856Z"
                fill="#969A9E"
            />
            <path
                d="M66.9341 63.0911L66.335 62.7453L66.9341 62.3994L67.5332 62.7453L66.9341 63.0911Z"
                fill="#969A9E"
            />
            <path
                d="M66.6621 61.7101L66.063 62.056L65.4639 61.7101L66.063 61.3643L66.6621 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M67.5332 64.8146L66.9341 65.1605L66.335 64.8146L66.9341 64.4688L67.5332 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M66.063 64.1253L65.4639 63.7794L66.063 63.4336L66.6621 63.7794L66.063 64.1253Z"
                fill="#969A9E"
            />
            <path
                d="M67.5332 66.8839L66.9341 67.2298L66.335 66.8839L66.9341 66.5381L67.5332 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M66.6621 65.8488L66.063 66.1946L65.4639 65.8488L66.063 65.5029L66.6621 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M66.9341 69.2991L66.335 68.9533L66.9341 68.6074L67.5332 68.9533L66.9341 69.2991Z"
                fill="#969A9E"
            />
            <path
                d="M66.6621 67.9191L66.063 68.2649L65.4639 67.9191L66.063 67.5732L66.6621 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M67.502 71.0045L66.4698 71.0998L66.3364 71.0236L66.9355 70.6777L67.502 71.0045Z"
                fill="#969A9E"
            />
            <path
                d="M66.063 70.3343L65.4639 69.9884L66.063 69.6426L66.6621 69.9884L66.063 70.3343Z"
                fill="#969A9E"
            />
            <path
                d="M68.765 58.3088L69.0918 58.7118L68.6779 58.9515L68.0788 58.6056L68.6779 58.2598L68.765 58.3088Z"
                fill="#969A9E"
            />
            <path
                d="M67.9508 57.3063L68.2422 57.6658L67.8065 57.9163L67.2074 57.5705L67.8065 57.2246L67.9508 57.3063Z"
                fill="#969A9E"
            />
            <path
                d="M68.6782 61.0208L68.0791 60.6749L68.6782 60.3291L69.2773 60.6749L68.6782 61.0208Z"
                fill="#969A9E"
            />
            <path
                d="M68.4062 59.6398L67.8071 59.9856L67.208 59.6398L67.8071 59.2939L68.4062 59.6398Z"
                fill="#969A9E"
            />
            <path
                d="M69.2773 62.7453L68.6782 63.0911L68.0791 62.7453L68.6782 62.3994L69.2773 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M67.8071 62.056L67.208 61.7101L67.8071 61.3643L68.4062 61.7101L67.8071 62.056Z"
                fill="#969A9E"
            />
            <path
                d="M69.2773 64.8146L68.6782 65.1605L68.0791 64.8146L68.6782 64.4688L69.2773 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M68.4062 63.7794L67.8071 64.1253L67.208 63.7794L67.8071 63.4336L68.4062 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M68.6782 67.2298L68.0791 66.8839L68.6782 66.5381L69.2773 66.8839L68.6782 67.2298Z"
                fill="#969A9E"
            />
            <path
                d="M68.4062 65.8488L67.8071 66.1946L67.208 65.8488L67.8071 65.5029L68.4062 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M69.2773 68.9533L68.6782 69.2991L68.0791 68.9533L68.6782 68.6074L69.2773 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M67.8071 68.2649L67.208 67.9191L67.8071 67.5732L68.4062 67.9191L67.8071 68.2649Z"
                fill="#969A9E"
            />
            <path
                d="M69.0039 70.8656L68.2251 70.9364L68.6771 70.6777L69.0039 70.8656Z"
                fill="#969A9E"
            />
            <path
                d="M68.4062 69.9884L67.8071 70.3343L67.208 69.9884L67.8071 69.6426L68.4062 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M70.793 60.8061L70.4199 61.0212L69.8208 60.6754L70.409 60.335L70.793 60.8061Z"
                fill="#969A9E"
            />
            <path
                d="M69.5792 59.3103L69.9414 59.7569L69.5493 59.9856L68.9501 59.6398L69.5493 59.2939L69.5792 59.3103Z"
                fill="#969A9E"
            />
            <path
                d="M71.0195 62.7453L70.4204 63.0911L69.8213 62.7453L70.4204 62.3994L71.0195 62.7453Z"
                fill="#969A9E"
            />
            <path
                d="M70.1484 61.7101L69.5493 62.056L68.9502 61.7101L69.5493 61.3643L70.1484 61.7101Z"
                fill="#969A9E"
            />
            <path
                d="M70.4204 65.1605L69.8213 64.8146L70.4204 64.4688L71.0195 64.8146L70.4204 65.1605Z"
                fill="#969A9E"
            />
            <path
                d="M70.1484 63.7794L69.5493 64.1253L68.9502 63.7794L69.5493 63.4336L70.1484 63.7794Z"
                fill="#969A9E"
            />
            <path
                d="M71.0195 66.8839L70.4204 67.2298L69.8213 66.8839L70.4204 66.5381L71.0195 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M69.5493 66.1946L68.9502 65.8488L69.5493 65.5029L70.1484 65.8488L69.5493 66.1946Z"
                fill="#969A9E"
            />
            <path
                d="M71.0195 68.9533L70.4204 69.2991L69.8213 68.9533L70.4204 68.6074L71.0195 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M70.1484 67.9191L69.5493 68.2649L68.9502 67.9191L69.5493 67.5732L70.1484 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M70.4203 70.6777L70.502 70.724L70.3059 70.7431L70.4203 70.6777Z"
                fill="#969A9E"
            />
            <path
                d="M70.1484 69.9884L69.5493 70.3343L68.9502 69.9884L69.5493 69.6426L70.1484 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M72.4961 62.8998L72.1639 63.0905L71.5647 62.7446L72.1121 62.4287L72.4961 62.8998Z"
                fill="#969A9E"
            />
            <path
                d="M71.6426 61.852L71.2913 62.0562L70.6922 61.7104L71.2613 61.3809L71.6426 61.852Z"
                fill="#969A9E"
            />
            <path
                d="M72.7617 64.8146L72.1626 65.1605L71.5635 64.8146L72.1626 64.4688L72.7617 64.8146Z"
                fill="#969A9E"
            />
            <path
                d="M71.2915 64.1253L70.6924 63.7794L71.2915 63.4336L71.8906 63.7794L71.2915 64.1253Z"
                fill="#969A9E"
            />
            <path
                d="M72.7617 66.8839L72.1626 67.2298L71.5635 66.8839L72.1626 66.5381L72.7617 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M71.8906 65.8488L71.2915 66.1946L70.6924 65.8488L71.2915 65.5029L71.8906 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M72.7617 68.9533L72.1626 69.2991L71.5635 68.9533L72.1626 68.6074L72.7617 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M71.8906 67.9191L71.2915 68.2649L70.6924 67.9191L71.2915 67.5732L71.8906 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M71.8906 69.9884L71.2915 70.3343L70.6924 69.9884L71.2915 69.6426L71.8906 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M74.1953 64.9916L73.9067 65.1605L73.3075 64.8146L73.8141 64.5205L74.1953 64.9916Z"
                fill="#969A9E"
            />
            <path
                d="M73.3457 63.9457L73.0353 64.1255L72.4361 63.7796L72.9645 63.4746L73.3457 63.9457Z"
                fill="#969A9E"
            />
            <path
                d="M74.5059 66.8839L73.9067 67.2298L73.3076 66.8839L73.9067 66.5381L74.5059 66.8839Z"
                fill="#969A9E"
            />
            <path
                d="M73.6348 65.8488L73.0357 66.1946L72.4365 65.8488L73.0357 65.5029L73.6348 65.8488Z"
                fill="#969A9E"
            />
            <path
                d="M74.5059 68.9533L73.9067 69.2991L73.3076 68.9533L73.9067 68.6074L74.5059 68.9533Z"
                fill="#969A9E"
            />
            <path
                d="M73.6328 67.9191L73.0337 68.2649L72.4346 67.9191L73.0337 67.5732L73.6328 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M73.6328 69.9884L73.0337 70.3343L72.4346 69.9884L73.0337 69.6426L73.6328 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M76.248 39.9787L75.6489 40.3245L75.0498 39.9787L75.6489 39.6328L76.248 39.9787Z"
                fill="#969A9E"
            />
            <path
                d="M74.4102 39.0769C74.3884 39.0007 74.3694 38.9217 74.3503 38.8455L74.7778 38.5977L75.377 38.9435L74.7778 39.2894L74.4102 39.0769Z"
                fill="#969A9E"
            />
            <path
                d="M75.377 41.0129H75.3744C75.3744 41.0129 75.3719 41.0103 75.3694 41.0078L75.377 41.0129Z"
                fill="#969A9E"
            />
            <path
                d="M75.8965 67.0854L75.6487 67.2297L75.0496 66.8839L75.5152 66.6143L75.8965 67.0854Z"
                fill="#969A9E"
            />
            <path
                d="M75.0469 66.0395L74.7773 66.1947L74.1782 65.8489L74.6629 65.5684L75.0469 66.0395Z"
                fill="#969A9E"
            />
            <path
                d="M75.6489 69.2991L75.0498 68.9533L75.6489 68.6074L76.248 68.9533L75.6489 69.2991Z"
                fill="#969A9E"
            />
            <path
                d="M75.377 67.9191L74.7778 68.2649L74.1787 67.9191L74.7778 67.5732L75.377 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M75.377 69.9884L74.8132 70.3125L74.7533 70.3207L74.1787 69.9884L74.7778 69.6426L75.377 69.9884Z"
                fill="#969A9E"
            />
            <path
                d="M77.3911 39.6328L77.9902 39.9787L77.3911 40.3245L76.792 39.9787L77.3911 39.6328Z"
                fill="#969A9E"
            />
            <path
                d="M76.52 38.5977L77.1191 38.9435L76.52 39.2894L75.9209 38.9435L76.52 38.5977Z"
                fill="#969A9E"
            />
            <path
                d="M78.8633 38.9435L78.2642 39.2894L77.6651 38.9435L78.2642 38.5977L78.8633 38.9435Z"
                fill="#969A9E"
            />
            <path
                d="M77.9902 42.048L77.3911 42.3939L76.792 42.048L77.3911 41.7021L77.9902 42.048Z"
                fill="#969A9E"
            />
            <path
                d="M77.1191 41.0138L76.52 41.3597L75.9209 41.0138L76.52 40.668L77.1191 41.0138Z"
                fill="#969A9E"
            />
            <path
                d="M78.2642 40.668L78.8633 41.0138L78.2642 41.3597L77.6651 41.0138L78.2642 40.668Z"
                fill="#969A9E"
            />
            <path
                d="M78.8633 43.0832L78.2642 43.429L77.6651 43.0832L78.2642 42.7373L78.8633 43.0832Z"
                fill="#969A9E"
            />
            <path
                d="M76.7935 68.9541L77.2184 68.709L77.5996 69.1801L77.3926 69.2999L76.7935 68.9541Z"
                fill="#969A9E"
            />
            <path
                d="M75.9221 67.9191L76.366 67.6631L76.75 68.1315L76.5212 68.2649L75.9221 67.9191Z"
                fill="#969A9E"
            />
            <path
                d="M76.9095 70.1082L76.2423 70.1736L75.9209 69.9884L76.52 69.6426L77.1191 69.9884L76.9095 70.1082Z"
                fill="#969A9E"
            />
            <path
                d="M78.0671 69.7539L78.2441 69.9745L77.7294 70.0262L77.6641 69.9881L78.0671 69.7539Z"
                fill="#969A9E"
            />
            <path
                d="M39.3715 3.68317C38.6962 4.06987 38.2741 4.97126 38.2741 6.25118V8.32628L77.4805 30.9618V28.8867C77.4805 26.5474 76.0562 23.8296 74.3025 22.8166L41.4521 3.84929C40.6814 3.40268 39.9761 3.36183 39.426 3.65049L39.3688 3.68045L39.3715 3.68317Z"
                fill="#DAE0E5"
            />
            <path
                d="M44.6723 0.621958L39.4274 3.65292C39.9775 3.36698 40.6828 3.4051 41.4535 3.85171L74.3038 22.819C76.0576 23.8293 77.4818 26.5471 77.4818 28.8891V30.9642L82.7812 27.9033V25.8282C82.7812 23.4889 81.3624 20.7712 79.6087 19.7581L46.7583 0.788076C45.9631 0.333296 45.2333 0.300617 44.6778 0.621958H44.6723Z"
                fill="#C2C7CC"
            />
            <path
                d="M44.9641 39.8477V43.5567L54.6289 49.1366V45.8933L51.9084 41.163L48.5316 43.1101L46.4211 39.2812L44.9641 39.8477Z"
                fill="#969A9E"
            />
            <path
                d="M44.9641 32.4023V39.8477L46.4211 39.284L48.5316 43.1128L51.9084 41.1657L54.6289 45.896V37.9822L44.9641 32.4023ZM48.488 39.2268C47.8807 38.8755 47.3878 38.0231 47.3878 37.3205C47.3878 36.6179 47.8807 36.332 48.488 36.6833C49.0926 37.0346 49.5855 37.8869 49.5855 38.5868C49.5855 39.2867 49.0926 39.5753 48.488 39.2268Z"
                fill="white"
            />
            <path
                d="M47.3844 37.3207C47.3844 38.0233 47.8773 38.8756 48.4846 39.2269C49.0891 39.5755 49.582 39.2923 49.582 38.587C49.582 37.8817 49.0891 37.0347 48.4846 36.6834C47.8773 36.3321 47.3844 36.6181 47.3844 37.3207Z"
                fill="white"
            />
            <path
                d="M59.6735 45.2833L62.503 49.6622L61.6098 53.2896L64.7442 52.8375L67.8786 56.9087L66.9909 52.2547L69.8203 51.1436L66.1331 48.7118L64.7469 43.957L63.3581 47.1105L59.6735 45.2833Z"
                fill="var(--secondary-color)"
            />
            <path
                d="M60.4083 26.7646V37.9245L70.0703 43.5016V32.3446L60.4083 26.7646ZM66.8433 39.523L62.2601 33.4202L66.8433 32.6033V39.5257V39.523Z"
                fill="#1D3160"
            />
            <path
                d="M62.2625 33.4178L66.8457 39.5233V32.6008L62.2625 33.4178Z"
                fill="white"
            />
            <path
                d="M76.8323 68.9347L76.9059 68.8911C77.2572 68.6378 77.4805 68.1204 77.4805 67.4124V30.9646L38.2741 8.3291V44.7796C38.2741 46.1657 39.121 47.7724 40.1558 48.3742L75.6014 68.8394C76.0698 69.109 76.5028 69.1253 76.8296 68.9374L76.8323 68.9347ZM44.9651 43.5568V32.3998L54.6298 37.9797V49.1368L44.9651 43.5568ZM62.5054 49.6623L59.676 45.2834L63.3605 47.1107L64.7493 43.9572L66.1355 48.7119L69.8227 51.1438L66.9933 52.2549L67.8838 56.9116L64.7466 52.8404L61.6149 53.2924L62.5081 49.6623H62.5054ZM60.4112 37.9252V26.7654L70.0733 32.3453V43.5024L60.4112 37.9252Z"
                fill="white"
            />
            <path
                d="M76.9041 68.8907L82.13 65.8734C82.5276 65.6446 82.7754 65.1081 82.7754 64.3538V27.9033L77.476 30.9642V67.412C77.476 68.12 77.2554 68.6374 76.9014 68.888L76.9041 68.8907Z"
                fill="#DAE0E5"
            />
            <path
                d="M76.9036 69.2941C76.9853 69.2941 77.067 69.2696 77.1406 69.2178C77.6226 68.8774 77.8867 68.2347 77.8867 67.4096V28.8867C77.8867 26.3786 76.4026 23.5574 74.5045 22.4626L41.6541 3.49531C40.7936 2.99696 39.933 2.92344 39.2359 3.28835C39.0371 3.39183 38.9581 3.63964 39.0643 3.83844C39.1678 4.03724 39.4156 4.11621 39.6144 4.01001C40.061 3.77581 40.6247 3.84116 41.2456 4.20063L74.096 23.1679C75.7354 24.1129 77.067 26.6782 77.067 28.884V67.4069C77.067 67.9434 76.9172 68.3709 76.664 68.5506C76.4788 68.6814 76.4352 68.9346 76.5659 69.1198C76.6449 69.2314 76.7729 69.2914 76.9009 69.2914L76.9036 69.2941Z"
                fill="#1D3160"
            />
            <path
                d="M76.3452 69.4713C76.5902 69.4713 76.8217 69.4114 77.0341 69.2889C77.2302 69.1745 77.2956 68.9239 77.1839 68.7306C77.0695 68.5345 76.8217 68.4692 76.6256 68.5835C76.416 68.7034 76.1246 68.6707 75.8032 68.4855L40.3576 48.0231C39.4481 47.4948 38.6801 46.0107 38.6801 44.7825V6.25691C38.6801 5.17578 39.0069 4.36971 39.5733 4.04292C39.7694 3.93127 39.8348 3.68073 39.7231 3.48466C39.6115 3.28858 39.3609 3.2205 39.1649 3.33488C38.337 3.81144 37.8604 4.87623 37.8604 6.25691V44.7825C37.8604 46.3157 38.7754 48.0504 39.9464 48.7312L75.3947 69.1963C75.7188 69.3815 76.0429 69.4768 76.3452 69.4768V69.4713Z"
                fill="#1D3160"
            />
            <path
                d="M77.4783 31.3706C77.6199 31.3706 77.7561 31.2971 77.8323 31.1664C77.944 30.9703 77.8786 30.7225 77.6826 30.6081L38.4762 7.97257C38.2801 7.8582 38.0296 7.92628 37.9179 8.12235C37.8063 8.31842 37.8716 8.56624 38.0677 8.68061L77.2741 31.3161C77.3394 31.3543 77.4102 31.3706 77.4783 31.3706Z"
                fill="#1D3160"
            />
            <path
                d="M76.8315 69.3431C76.8996 69.3431 76.9704 69.3241 77.0357 69.2887L82.3379 66.2277C82.8771 65.9173 83.1875 65.2338 83.1875 64.3542V25.8286C83.1875 23.3178 81.7033 20.4965 79.8107 19.4045L46.9603 0.434435C46.0589 -0.0802563 45.1766 -0.140168 44.4713 0.268317L39.1692 3.32923C38.9731 3.44089 38.905 3.69142 39.0194 3.88749C39.131 4.08357 39.3816 4.15165 39.5776 4.03727L44.8798 0.97636C45.3237 0.720376 45.9173 0.777564 46.5546 1.14248L79.4022 20.1125C81.0389 21.0575 82.3705 23.62 82.3705 25.8286V64.3542C82.3705 64.926 82.2044 65.3618 81.9294 65.5197L76.6272 68.5806C76.4312 68.6923 76.3658 68.9428 76.4775 69.1389C76.5537 69.2696 76.6899 69.3431 76.8315 69.3431Z"
                fill="#1D3160"
            />
            <path
                d="M77.4797 31.37C77.5478 31.37 77.6186 31.3536 77.6839 31.3155L82.9834 28.2546C83.1794 28.1429 83.2448 27.8924 83.1331 27.6963C83.0215 27.5003 82.7709 27.4349 82.5749 27.5466L77.2755 30.6075C77.0794 30.7191 77.014 30.9697 77.1257 31.1657C77.2019 31.2965 77.3381 31.37 77.4797 31.37Z"
                fill="#1D3160"
            />
            <path
                d="M70.072 43.9111C70.1428 43.9111 70.2136 43.892 70.2762 43.8566C70.4015 43.7831 70.4805 43.6497 70.4805 43.5026V32.3455C70.4805 32.1985 70.4015 32.065 70.2762 31.9915L60.6142 26.4116C60.4889 26.3381 60.331 26.3381 60.2057 26.4116C60.0804 26.4851 60.0015 26.6186 60.0015 26.7656V37.9254C60.0015 38.0725 60.0804 38.2059 60.2057 38.2794L69.8677 43.8566C69.9304 43.892 70.0012 43.9111 70.072 43.9111ZM69.6635 32.5824V42.7946L60.8184 37.6885V27.4737L69.6635 32.5824Z"
                fill="#1D3160"
            />
            <path
                d="M54.6286 49.5449C54.6994 49.5449 54.7702 49.5258 54.8329 49.4904C54.9581 49.4169 55.0371 49.2834 55.0371 49.1364V37.9793C55.0371 37.8323 54.9581 37.6988 54.8329 37.6253L45.1681 32.0454C45.0428 31.9719 44.8849 31.9719 44.7596 32.0454C44.6344 32.1189 44.5554 32.2523 44.5554 32.3994V43.5565C44.5554 43.7035 44.6344 43.837 44.7596 43.9105L54.4244 49.4904C54.487 49.5258 54.5578 49.5449 54.6286 49.5449ZM54.2201 38.2162V48.4284L45.3723 43.3196V33.1074L54.2201 38.2162Z"
                fill="#1D3160"
            />
            <path
                d="M66.8454 39.9322C66.889 39.9322 66.9326 39.9268 66.9734 39.9105C67.1395 39.856 67.2539 39.698 67.2539 39.5238V32.6013C67.2539 32.4815 67.1994 32.3644 67.1069 32.2881C67.0143 32.2091 66.8917 32.1765 66.7719 32.1983L62.1887 33.0152C62.0498 33.0397 61.9327 33.135 61.881 33.2685C61.8292 33.3992 61.8483 33.549 61.9354 33.6634L66.5186 39.7661C66.5976 39.8696 66.7202 39.9295 66.8454 39.9295V39.9322ZM66.4369 33.0888V38.2983L62.9866 33.7042L66.4369 33.0888Z"
                fill="#1D3160"
            />
            <path
                d="M54.1502 24.5C54.2918 24.5 54.428 24.4265 54.5042 24.2958C54.6159 24.0997 54.5505 23.8519 54.3544 23.7375L45.6483 18.7077C45.4522 18.596 45.2017 18.6614 45.09 18.8575C44.9784 19.0535 45.0437 19.3014 45.2398 19.4157L53.946 24.4455C54.0113 24.4837 54.0794 24.5 54.1502 24.5Z"
                fill="#1D3160"
            />
            <path
                d="M54.1502 27.4709C54.2918 27.4709 54.428 27.3974 54.5042 27.2667C54.6159 27.0706 54.5505 26.8228 54.3544 26.7084L45.6483 21.6813C45.4522 21.5697 45.2017 21.635 45.09 21.8311C44.9784 22.0272 45.0437 22.275 45.2398 22.3894L53.946 27.4165C54.0113 27.4546 54.0794 27.4709 54.1502 27.4709Z"
                fill="#1D3160"
            />
            <path
                d="M54.1502 30.4445C54.2918 30.4445 54.428 30.371 54.5042 30.2403C54.6159 30.0442 54.5505 29.7964 54.3544 29.682L45.6483 24.655C45.4522 24.5433 45.2017 24.6087 45.09 24.8047C44.9784 25.0008 45.0437 25.2486 45.2398 25.363L53.946 30.3901C54.0113 30.4282 54.0794 30.4445 54.1502 30.4445Z"
                fill="#1D3160"
            />
            <path
                d="M54.1502 33.4155C54.2918 33.4155 54.428 33.342 54.5042 33.2112C54.6159 33.0152 54.5505 32.7674 54.3544 32.653L45.6483 27.6259C45.4522 27.5115 45.2017 27.5796 45.09 27.7757C44.9784 27.9717 45.0437 28.2196 45.2398 28.3339L53.946 33.361C54.0113 33.3991 54.0794 33.4155 54.1502 33.4155Z"
                fill="#1D3160"
            />
            <path
                d="M67.8795 57.3175C67.9367 57.3175 67.9939 57.3067 68.0457 57.2822C68.2172 57.2059 68.3153 57.018 68.2799 56.8328L67.4547 52.511L69.9683 51.5252C70.1099 51.468 70.2106 51.3401 70.2242 51.1876C70.2406 51.0351 70.1698 50.888 70.0418 50.8036L66.4798 48.4562L65.1372 43.8457C65.0882 43.6796 64.9439 43.5625 64.7723 43.5516C64.6008 43.5407 64.4401 43.6388 64.372 43.794L63.1547 46.5581L59.8514 44.9187C59.6881 44.837 59.4865 44.8751 59.364 45.0113C59.2414 45.1474 59.2251 45.3517 59.3259 45.5042L62.06 49.7333L61.2103 53.1918C61.1777 53.3226 61.2131 53.4642 61.3057 53.5649C61.3982 53.6657 61.5317 53.7147 61.6678 53.6956L64.5654 53.2763L67.5555 57.1596C67.6344 57.2631 67.7543 57.3175 67.8795 57.3175ZM68.9389 51.0514L66.842 51.8738C66.6595 51.9446 66.5533 52.138 66.5887 52.3313L67.1552 55.3024L65.0664 52.59C64.9766 52.4729 64.8322 52.413 64.6852 52.4348L62.1526 52.7997L62.9015 49.7579C62.9287 49.6489 62.9096 49.5318 62.847 49.4392L60.8236 46.3102L63.1765 47.4785C63.2773 47.5275 63.3916 47.5357 63.4979 47.4976C63.6041 47.4594 63.6885 47.3805 63.7321 47.277L64.6688 45.1501L65.7391 48.8292C65.7663 48.9218 65.8262 49.0035 65.9052 49.0553L68.9362 51.0514H68.9389Z"
                fill="#1D3160"
            />
            <path
                d="M54.6295 46.3049C54.6975 46.3049 54.7683 46.2886 54.8337 46.2505C55.0298 46.1388 55.0979 45.8883 54.9835 45.6922L52.263 40.9619C52.2085 40.8694 52.1186 40.7986 52.0152 40.7713C51.9117 40.7441 51.8 40.7577 51.7047 40.8122L48.6901 42.5523L46.7811 39.0884C46.6831 38.9086 46.4652 38.8297 46.2746 38.9059L44.8177 39.4696C44.608 39.5513 44.5018 39.7882 44.5835 39.9979C44.6651 40.2076 44.9021 40.3138 45.1118 40.2321L46.2365 39.7964L48.1727 43.3121C48.2244 43.4074 48.3143 43.4782 48.4205 43.5082C48.5267 43.5381 48.6383 43.5245 48.7337 43.4673L51.7564 41.7245L54.2727 46.1007C54.349 46.2314 54.4851 46.3049 54.6267 46.3049H54.6295Z"
                fill="#1D3160"
            />
            <path
                d="M48.9784 39.7825C49.1527 39.7825 49.3188 39.7416 49.4658 39.6545C49.8008 39.4611 49.9941 39.0717 49.9941 38.5843C49.9941 37.7428 49.4223 36.7515 48.6924 36.3267C48.2731 36.0843 47.8401 36.0544 47.5051 36.2477C47.1702 36.4411 46.9768 36.8305 46.9768 37.3152C46.9768 38.1594 47.5514 39.1507 48.2812 39.5755C48.5154 39.7117 48.7551 39.7798 48.9784 39.7798V39.7825ZM47.998 36.9394C48.0661 36.9394 48.1614 36.9639 48.284 37.0347C48.7605 37.3125 49.1799 38.0369 49.1799 38.5843C49.1799 38.7994 49.1173 38.9138 49.0601 38.9465C49.0029 38.9791 48.8776 38.9764 48.6924 38.8702C48.205 38.5897 47.7938 37.8762 47.7938 37.318C47.7938 37.1028 47.8564 36.9912 47.9136 36.9585C47.9327 36.9476 47.9626 36.9394 47.998 36.9394Z"
                fill="#1D3160"
            />
            <path
                d="M48.8828 66.8431L49.4084 67.1481C49.3512 66.9792 49.294 66.8104 49.2287 66.6443L48.8828 66.8431Z"
                fill="#969A9E"
            />
            <path
                d="M49.4819 68.5664L48.8828 68.9123L49.4819 69.2581L49.8441 69.0484C49.8305 68.9477 49.8169 68.8496 49.8006 68.7516L49.4819 68.5664Z"
                fill="#969A9E"
            />
            <path
                d="M48.8828 70.9826L49.4819 71.3284L49.9367 71.0643C49.9367 71.0098 49.9367 70.9554 49.9395 70.9009L49.4819 70.6367L48.8828 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M49.9255 69.8467L49.7539 69.9474L49.9337 70.0509C49.931 69.9828 49.931 69.9148 49.9255 69.8467Z"
                fill="#969A9E"
            />
            <path
                d="M48.8828 73.052L49.4819 73.3979L49.6181 73.3189C49.6562 73.161 49.6916 73.003 49.7216 72.8451L49.4819 72.7062L48.8828 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M49.8485 72.0712C49.8539 72.0331 49.8594 71.9922 49.8648 71.9541L49.7559 72.0167L49.8485 72.0712Z"
                fill="#969A9E"
            />
            <path
                d="M49.0081 75.1953C49.0353 75.1299 49.0625 75.0673 49.0898 75.002L48.8828 75.1218L49.0081 75.1953Z"
                fill="#969A9E"
            />
            <path
                d="M48.1408 64.6592L47.7378 64.4277L47.1387 64.7736L47.7378 65.1194L48.2552 64.8226C48.217 64.7681 48.1817 64.7137 48.1408 64.6619V64.6592Z"
                fill="#969A9E"
            />
            <path
                d="M47.1387 66.8429L47.7378 67.1888L48.3369 66.8429L47.7378 66.4971L47.1387 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M48.6108 65.4627L48.0117 65.8085L48.6108 66.1544L48.9295 65.9692C48.8532 65.814 48.7742 65.6588 48.6898 65.5063L48.6108 65.46V65.4627Z"
                fill="#969A9E"
            />
            <path
                d="M47.7378 68.5664L47.1387 68.9123L47.7378 69.2581L48.3369 68.9123L47.7378 68.5664Z"
                fill="#969A9E"
            />
            <path
                d="M49.208 67.8778L48.6089 67.532L48.0098 67.8778L48.6089 68.2237L49.208 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M48.3369 70.9826L47.7378 70.6367L47.1387 70.9826L47.7378 71.3284L48.3369 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M48.6089 69.6017L48.0098 69.9475L48.6089 70.2934L49.208 69.9475L48.6089 69.6017Z"
                fill="#969A9E"
            />
            <path
                d="M47.7378 72.7061L47.1387 73.0519L47.7378 73.3977L48.3369 73.0519L47.7378 72.7061Z"
                fill="#969A9E"
            />
            <path
                d="M49.2099 72.0168L48.6108 71.6709L48.0117 72.0168L48.6108 72.3626L49.2099 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M48.3369 75.1212L47.7378 74.7754L47.1387 75.1212L47.7378 75.4671L48.3369 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M48.0098 74.0869L48.6089 74.4328L49.208 74.0869L48.6089 73.7411L48.0098 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M47.1387 77.1916L47.7378 77.5374C47.8494 77.3713 47.9611 77.2052 48.0645 77.0336L47.7378 76.8457L47.1387 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M48.0098 76.1565L48.4319 76.3989C48.5272 76.2219 48.6198 76.0449 48.7069 75.8652L48.6116 75.8107L48.0125 76.1565H48.0098Z"
                fill="#969A9E"
            />
            <path
                d="M45.7558 62.4968L45.3964 62.7038L45.9955 63.0496L46.3168 62.8645C46.1344 62.7337 45.9465 62.6112 45.7531 62.4968H45.7558Z"
                fill="#969A9E"
            />
            <path
                d="M45.9955 64.4276L45.3964 64.7735L45.9955 65.1193L46.5946 64.7735L45.9955 64.4276Z"
                fill="#969A9E"
            />
            <path
                d="M47.2071 63.5886L46.8667 63.3926L46.2676 63.7384L46.8667 64.0843L47.3922 63.7793C47.3296 63.7167 47.2724 63.6513 47.2071 63.5886Z"
                fill="#969A9E"
            />
            <path
                d="M45.9955 66.4971L45.3964 66.843L45.9955 67.1888L46.5946 66.843L45.9955 66.4971Z"
                fill="#969A9E"
            />
            <path
                d="M46.8667 65.4619L46.2676 65.8078L46.8667 66.1536L47.4658 65.8078L46.8667 65.4619Z"
                fill="#969A9E"
            />
            <path
                d="M46.5947 68.9123L45.9956 68.5664L45.3965 68.9123L45.9956 69.2581L46.5947 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M46.8667 67.5322L46.2676 67.878L46.8667 68.2239L47.4658 67.878L46.8667 67.5322Z"
                fill="#969A9E"
            />
            <path
                d="M45.9956 70.6367L45.3965 70.9826L45.9956 71.3284L46.5947 70.9826L45.9956 70.6367Z"
                fill="#969A9E"
            />
            <path
                d="M47.4658 69.9474L46.8667 69.6016L46.2676 69.9474L46.8667 70.2932L47.4658 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M46.5946 73.0519L45.9955 72.7061L45.3964 73.0519L45.9955 73.3978L46.5946 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M46.8667 71.6709L46.2676 72.0168L46.8667 72.3626L47.4658 72.0168L46.8667 71.6709Z"
                fill="#969A9E"
            />
            <path
                d="M46.5947 75.1212L45.9956 74.7754L45.3965 75.1212L45.9956 75.4671L46.5947 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M47.4658 74.0871L46.8667 73.7412L46.2676 74.0871L46.8667 74.4329L47.4658 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M46.5946 77.1913L45.9955 76.8455L45.3964 77.1913L45.9955 77.5372L46.5946 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M47.4658 76.1564L46.8667 75.8105L46.2676 76.1564L46.8667 76.5023L47.4658 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M45.3965 79.2607L45.9956 79.6065L46.0665 79.5657C46.1944 79.4377 46.3197 79.3043 46.4422 79.1708L45.9956 78.9121L45.3965 79.258V79.2607Z"
                fill="#969A9E"
            />
            <path
                d="M46.2676 78.2267L46.8667 78.5725L47.0546 78.4636C47.1417 78.3547 47.2261 78.2458 47.3105 78.1368L46.8667 77.8809L46.2676 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M44.2514 62.3584L43.6523 62.7042L44.2514 63.0501L44.8505 62.7042L44.2514 62.3584Z"
                fill="#969A9E"
            />
            <path
                d="M44.2534 64.4276L43.6543 64.7735L44.2534 65.1193L44.8525 64.7735L44.2534 64.4276Z"
                fill="#969A9E"
            />
            <path
                d="M45.1225 63.3926L44.5234 63.7384L45.1225 64.0843L45.7217 63.7384L45.1225 63.3926Z"
                fill="#969A9E"
            />
            <path
                d="M44.8525 66.843L44.2534 66.4972L43.6543 66.843L44.2534 67.1889L44.8525 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M45.1245 65.4619L44.5254 65.8078L45.1245 66.1536L45.7237 65.8078L45.1245 65.4619Z"
                fill="#969A9E"
            />
            <path
                d="M44.2514 68.5664L43.6523 68.9123L44.2514 69.2581L44.8505 68.9123L44.2514 68.5664Z"
                fill="#969A9E"
            />
            <path
                d="M45.7217 67.878L45.1225 67.5322L44.5234 67.878L45.1225 68.2239L45.7217 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M44.8505 70.9826L44.2514 70.6367L43.6523 70.9826L44.2514 71.3284L44.8505 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M45.1225 69.6016L44.5234 69.9474L45.1225 70.2932L45.7217 69.9474L45.1225 69.6016Z"
                fill="#969A9E"
            />
            <path
                d="M44.8525 73.052L44.2534 72.7062L43.6543 73.052L44.2534 73.3979L44.8525 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M45.7237 72.0168L45.1245 71.6709L44.5254 72.0168L45.1245 72.3626L45.7237 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M44.8505 75.1212L44.2514 74.7754L43.6523 75.1212L44.2514 75.4671L44.8505 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M45.7217 74.0871L45.1225 73.7412L44.5234 74.0871L45.1225 74.4329L45.7217 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M44.8525 77.1913L44.2534 76.8455L43.6543 77.1913L44.2534 77.5372L44.8525 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M45.7217 76.1564L45.1225 75.8105L44.5234 76.1564L45.1225 76.5023L45.7217 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M44.8525 79.261L44.2534 78.9152L43.6543 79.261L44.2534 79.6069L44.8525 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M45.7237 78.2267L45.1245 77.8809L44.5254 78.2267L45.1245 78.5725L45.7237 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M43.6523 81.3304L43.8239 81.4312C43.9737 81.3277 44.1262 81.2243 44.2732 81.1153C44.3032 81.0935 44.3305 81.0717 44.3604 81.0499L44.2514 80.9873L43.6523 81.3332V81.3304Z"
                fill="#969A9E"
            />
            <path
                d="M44.5262 80.2963L44.981 80.5604C45.1444 80.4242 45.305 80.2881 45.4602 80.1465L45.1225 79.9531L44.5234 80.299L44.5262 80.2963Z"
                fill="#969A9E"
            />
            <path
                d="M42.5093 62.3584L41.9102 62.7042L42.5093 63.0501L43.1084 62.7042L42.5093 62.3584Z"
                fill="#969A9E"
            />
            <path
                d="M43.9589 61.6555C43.7165 61.5683 43.4714 61.4866 43.2236 61.4131L42.7852 61.6664L43.3843 62.0122L43.9834 61.6664L43.9615 61.6527L43.9589 61.6555Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 64.7736L42.5093 64.4277L41.9102 64.7736L42.5093 65.1194L43.1084 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M43.3803 63.3926L42.7812 63.7384L43.3803 64.0843L43.9794 63.7384L43.3803 63.3926Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 66.8429L42.5093 66.4971L41.9102 66.8429L42.5093 67.1888L43.1084 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 65.8078L43.3803 65.4619L42.7812 65.8078L43.3803 66.1536L43.9794 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 68.9123L42.5093 68.5664L41.9102 68.9123L42.5093 69.2581L43.1084 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 67.878L43.3803 67.5322L42.7812 67.878L43.3803 68.2239L43.9794 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 70.9826L42.5093 70.6367L41.9102 70.9826L42.5093 71.3284L43.1084 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 69.9474L43.3803 69.6016L42.7812 69.9474L43.3803 70.2932L43.9794 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 73.0519L42.5093 72.7061L41.9102 73.0519L42.5093 73.3977L43.1084 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 72.0168L43.3803 71.6709L42.7812 72.0168L43.3803 72.3626L43.9794 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 75.1212L42.5093 74.7754L41.9102 75.1212L42.5093 75.4671L43.1084 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 74.0871L43.3803 73.7412L42.7812 74.0871L43.3803 74.4329L43.9794 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 77.1916L42.5093 76.8457L41.9102 77.1916L42.5093 77.5374L43.1084 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 76.1564L43.3803 75.8105L42.7812 76.1564L43.3803 76.5023L43.9794 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M43.1084 79.2609L42.5093 78.915L41.9102 79.2609L42.5093 79.6068L43.1084 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M43.9794 78.2267L43.3803 77.8809L42.7812 78.2267L43.3803 78.5725L43.9794 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M41.9102 81.3302L42.5093 81.6761L43.1084 81.3302L42.5093 80.9844L41.9102 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M43.3803 79.9502L42.7812 80.2961L43.3803 80.6419L43.9794 80.2961L43.3803 79.9502Z"
                fill="#969A9E"
            />
            <path
                d="M40.7654 60.9799L40.9043 60.9009C40.779 60.8819 40.6565 60.8628 40.5312 60.8438L40.7681 60.9799H40.7654Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 62.7042L40.7671 62.3584L40.168 62.7042L40.7671 63.0501L41.3662 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M41.6383 61.3231L41.0392 61.6689L41.6383 62.0148L42.2374 61.6689L41.6383 61.3231Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 64.7736L40.7671 64.4277L40.168 64.7736L40.7671 65.1194L41.3662 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 63.7386L41.6383 63.3927L41.0392 63.7386L41.6383 64.0844L42.2374 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 66.8429L40.7671 66.4971L40.168 66.8429L40.7671 67.1888L41.3662 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M42.2373 65.8078L41.6382 65.4619L41.0391 65.8078L41.6382 66.1536L42.2373 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 68.9123L40.7671 68.5664L40.168 68.9123L40.7671 69.2581L41.3662 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 67.8779L41.6383 67.532L41.0392 67.8779L41.6383 68.2237L42.2374 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 70.9826L40.7671 70.6367L40.168 70.9826L40.7671 71.3284L41.3662 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 69.9475L41.6383 69.6017L41.0392 69.9475L41.6383 70.2934L42.2374 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 73.0519L40.7671 72.7061L40.168 73.0519L40.7671 73.3977L41.3662 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M42.2373 72.0168L41.6382 71.6709L41.0391 72.0168L41.6382 72.3626L42.2373 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 75.1212L40.7671 74.7754L40.168 75.1212L40.7671 75.4671L41.3662 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 74.0869L41.6383 73.741L41.0392 74.0869L41.6383 74.4327L42.2374 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 77.1916L40.7671 76.8457L40.168 77.1916L40.7671 77.5374L41.3662 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 76.1566L41.6383 75.8107L41.0392 76.1566L41.6383 76.5024L42.2374 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 79.2609L40.7671 78.915L40.168 79.2609L40.7671 79.6068L41.3662 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M42.2373 78.2267L41.6382 77.8809L41.0391 78.2267L41.6382 78.5725L42.2373 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M41.3662 81.3302L40.7671 80.9844L40.168 81.3302L40.7671 81.6761L41.3662 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M42.2374 80.2959L41.6383 79.95L41.0392 80.2959L41.6383 80.6417L42.2374 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M40.4121 83.2587C40.5483 83.2042 40.6844 83.1443 40.8206 83.0844L40.7661 83.0544L40.4121 83.2587Z"
                fill="#969A9E"
            />
            <path
                d="M41.0392 82.3654L41.6383 82.7112C41.6383 82.7112 41.6465 82.7085 41.6492 82.7058L42.2374 82.3654L41.6383 82.0195L41.0392 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M39.4978 60.7076C39.1601 60.6695 38.8225 60.6314 38.4875 60.5987L38.4276 60.6341L39.0267 60.98L39.4978 60.7076Z"
                fill="#969A9E"
            />
            <path
                d="M39.624 62.7042L39.0249 62.3584L38.4258 62.7042L39.0249 63.0501L39.624 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 61.6691L39.896 61.3232L39.2969 61.6691L39.896 62.015L40.4951 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M39.622 64.7735L39.0229 64.4276L38.4238 64.7735L39.0229 65.1193L39.622 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 63.7384L39.896 63.3926L39.2969 63.7384L39.896 64.0843L40.4951 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M39.622 66.843L39.0229 66.4972L38.4238 66.843L39.0229 67.1889L39.622 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 65.8078L39.896 65.4619L39.2969 65.8078L39.896 66.1536L40.4951 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M39.624 68.9123L39.0249 68.5664L38.4258 68.9123L39.0249 69.2581L39.624 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 67.878L39.896 67.5322L39.2969 67.878L39.896 68.2239L40.4951 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M39.624 70.9826L39.0249 70.6367L38.4258 70.9826L39.0249 71.3284L39.624 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 69.9474L39.896 69.6016L39.2969 69.9474L39.896 70.2932L40.4951 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M39.622 73.052L39.0229 72.7062L38.4238 73.052L39.0229 73.3979L39.622 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 72.0168L39.896 71.6709L39.2969 72.0168L39.896 72.3626L40.4951 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M39.624 75.1212L39.0249 74.7754L38.4258 75.1212L39.0249 75.4671L39.624 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 74.0871L39.896 73.7412L39.2969 74.0871L39.896 74.4329L40.4951 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M39.622 77.1913L39.0229 76.8455L38.4238 77.1913L39.0229 77.5372L39.622 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 76.1564L39.896 75.8105L39.2969 76.1564L39.896 76.5023L40.4951 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M39.622 79.261L39.0229 78.9152L38.4238 79.261L39.0229 79.6069L39.622 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 78.2267L39.896 77.8809L39.2969 78.2267L39.896 78.5725L40.4951 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M39.624 81.3302L39.0249 80.9844L38.4258 81.3302L39.0249 81.6761L39.624 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M40.4951 80.2961L39.896 79.9502L39.2969 80.2961L39.896 80.6419L40.4951 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M38.4238 83.4003L39.0229 83.7461L39.622 83.4003L39.0229 83.0544L38.4238 83.4003Z"
                fill="#969A9E"
            />
            <path
                d="M39.896 82.0195L39.2969 82.3654L39.896 82.7112L40.4951 82.3654L39.896 82.0195Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 60.6343L37.6893 60.5226L36.9839 60.46L36.6816 60.6343L37.2808 60.9801L37.8799 60.6343Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 62.7042L37.2808 62.3584L36.6816 62.7042L37.2808 63.0501L37.8799 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 61.6689L38.1518 61.323L37.5527 61.6689L38.1518 62.0147L38.7509 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 64.7736L37.2808 64.4277L36.6816 64.7736L37.2808 65.1194L37.8799 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 63.7386L38.1518 63.3927L37.5527 63.7386L38.1518 64.0844L38.7509 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 66.8429L37.2808 66.4971L36.6816 66.8429L37.2808 67.1888L37.8799 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M38.7529 65.8078L38.1538 65.4619L37.5547 65.8078L38.1538 66.1536L38.7529 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 68.9123L37.2808 68.5664L36.6816 68.9123L37.2808 69.2581L37.8799 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 67.8778L38.1518 67.532L37.5527 67.8778L38.1518 68.2237L38.7509 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 70.9826L37.2808 70.6367L36.6816 70.9826L37.2808 71.3284L37.8799 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 69.9475L38.1518 69.6017L37.5527 69.9475L38.1518 70.2934L38.7509 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 73.0519L37.2808 72.7061L36.6816 73.0519L37.2808 73.3977L37.8799 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M38.7529 72.0168L38.1538 71.6709L37.5547 72.0168L38.1538 72.3626L38.7529 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 75.1212L37.2808 74.7754L36.6816 75.1212L37.2808 75.4671L37.8799 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 74.0869L38.1518 73.7411L37.5527 74.0869L38.1518 74.4328L38.7509 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 77.1916L37.2808 76.8457L36.6816 77.1916L37.2808 77.5374L37.8799 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 76.1565L38.1518 75.8107L37.5527 76.1565L38.1518 76.5024L38.7509 76.1565Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 79.2609L37.2808 78.915L36.6816 79.2609L37.2808 79.6068L37.8799 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M38.7529 78.2267L38.1538 77.8809L37.5547 78.2267L38.1538 78.5725L38.7529 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M37.8799 81.3302L37.2808 80.9844L36.6816 81.3302L37.2808 81.6761L37.8799 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M38.7509 80.2959L38.1518 79.9501L37.5527 80.2959L38.1518 80.6418L38.7509 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M36.6816 83.4005L37.2808 83.7464L37.8799 83.4005L37.2808 83.0547L36.6816 83.4005Z"
                fill="#969A9E"
            />
            <path
                d="M38.1518 82.0195L37.5527 82.3654L38.1518 82.7112L38.7509 82.3654L38.1518 82.0195Z"
                fill="#969A9E"
            />
            <path
                d="M36.1369 60.634L35.6059 60.3263C35.5677 60.3236 35.5296 60.3182 35.4915 60.3154L34.9414 60.634L35.5405 60.9799L36.1396 60.634H36.1369Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 62.7042L35.5386 62.3584L34.9395 62.7042L35.5386 63.0501L36.1376 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 61.6689L36.4096 61.3231L35.8105 61.6689L36.4096 62.0148L37.0088 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 64.7736L35.5386 64.4277L34.9395 64.7736L35.5386 65.1194L36.1376 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 63.7386L36.4096 63.3927L35.8105 63.7386L36.4096 64.0844L37.0088 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 66.8429L35.5386 66.4971L34.9395 66.8429L35.5386 67.1888L36.1376 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 65.8078L36.4096 65.4619L35.8105 65.8078L36.4096 66.1536L37.0088 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 68.9123L35.5386 68.5664L34.9395 68.9123L35.5386 69.2581L36.1376 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 67.8779L36.4096 67.532L35.8105 67.8779L36.4096 68.2237L37.0088 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 70.9826L35.5386 70.6367L34.9395 70.9826L35.5386 71.3284L36.1376 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 69.9475L36.4096 69.6017L35.8105 69.9475L36.4096 70.2934L37.0088 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 73.0519L35.5386 72.7061L34.9395 73.0519L35.5386 73.3977L36.1376 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 72.0168L36.4096 71.6709L35.8105 72.0168L36.4096 72.3626L37.0088 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 75.1212L35.5386 74.7754L34.9395 75.1212L35.5386 75.4671L36.1376 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 74.0869L36.4096 73.741L35.8105 74.0869L36.4096 74.4327L37.0088 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 77.1916L35.5386 76.8457L34.9395 77.1916L35.5386 77.5374L36.1376 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 76.1566L36.4096 75.8107L35.8105 76.1566L36.4096 76.5024L37.0088 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 79.2609L35.5386 78.915L34.9395 79.2609L35.5386 79.6068L36.1376 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 78.2267L36.4096 77.8809L35.8105 78.2267L36.4096 78.5725L37.0088 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M36.1376 81.3302L35.5386 80.9844L34.9395 81.3302L35.5386 81.6761L36.1376 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 80.2959L36.4096 79.95L35.8105 80.2959L36.4096 80.6417L37.0088 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M35.5386 83.0547L34.9395 83.4005L35.5386 83.7464L36.1376 83.4005L35.5386 83.0547Z"
                fill="#969A9E"
            />
            <path
                d="M37.0088 82.3654L36.4096 82.0195L35.8105 82.3654L36.4096 82.7112L37.0088 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M35.8105 84.4355L35.9494 84.5145C36.249 84.4627 36.5486 84.4055 36.8454 84.3402L36.4096 84.0869L35.8105 84.4328V84.4355Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 60.6339L33.7964 60.2881L33.1973 60.6339L33.7964 60.9798L34.3955 60.6339Z"
                fill="#969A9E"
            />
            <path
                d="M34.3935 62.7042L33.7944 62.3584L33.1953 62.7042L33.7944 63.0501L34.3935 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 61.6689L34.6675 61.323L34.0684 61.6689L34.6675 62.0147L35.2666 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 64.7735L33.7964 64.4276L33.1973 64.7735L33.7964 65.1193L34.3955 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 63.7386L34.6675 63.3927L34.0684 63.7386L34.6675 64.0844L35.2666 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 66.843L33.7964 66.4972L33.1973 66.843L33.7964 67.1889L34.3955 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M35.2646 65.8078L34.6655 65.4619L34.0664 65.8078L34.6655 66.1536L35.2646 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M34.3935 68.9123L33.7944 68.5664L33.1953 68.9123L33.7944 69.2581L34.3935 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 67.8778L34.6675 67.532L34.0684 67.8778L34.6675 68.2237L35.2666 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M34.3935 70.9826L33.7944 70.6367L33.1953 70.9826L33.7944 71.3284L34.3935 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 69.9475L34.6675 69.6017L34.0684 69.9475L34.6675 70.2934L35.2666 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 73.052L33.7964 72.7062L33.1973 73.052L33.7964 73.3979L34.3955 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M35.2646 72.0168L34.6655 71.6709L34.0664 72.0168L34.6655 72.3626L35.2646 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M34.3935 75.1212L33.7944 74.7754L33.1953 75.1212L33.7944 75.4671L34.3935 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 74.0869L34.6675 73.7411L34.0684 74.0869L34.6675 74.4328L35.2666 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 77.1913L33.7964 76.8455L33.1973 77.1913L33.7964 77.5372L34.3955 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 76.1565L34.6675 75.8107L34.0684 76.1565L34.6675 76.5024L35.2666 76.1565Z"
                fill="#969A9E"
            />
            <path
                d="M34.3955 79.261L33.7964 78.9152L33.1973 79.261L33.7964 79.6069L34.3955 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M35.2646 78.2267L34.6655 77.8809L34.0664 78.2267L34.6655 78.5725L35.2646 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M34.3935 81.3302L33.7944 80.9844L33.1953 81.3302L33.7944 81.6761L34.3935 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 80.2959L34.6675 79.9501L34.0684 80.2959L34.6675 80.6418L35.2666 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M33.7964 83.0544L33.1973 83.4003L33.7964 83.7461L34.3955 83.4003L33.7964 83.0544Z"
                fill="#969A9E"
            />
            <path
                d="M35.2666 82.3654L34.6675 82.0195L34.0684 82.3654L34.6675 82.7112L35.2666 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M34.0664 84.4347L34.5647 84.7234C34.6519 84.7125 34.7391 84.6989 34.8262 84.688L35.2646 84.4347L34.6655 84.0889L34.0664 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 60.634L32.0525 60.2881L31.4534 60.634L32.0525 60.9799L32.6516 60.634Z"
                fill="#969A9E"
            />
            <path
                d="M32.6514 62.7042L32.0522 62.3584L31.4531 62.7042L32.0522 63.0501L32.6514 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 61.6691L32.9234 61.3232L32.3242 61.6691L32.9234 62.015L33.5225 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 64.7735L32.0525 64.4276L31.4534 64.7735L32.0525 65.1193L32.6516 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 63.7384L32.9234 63.3926L32.3242 63.7384L32.9234 64.0843L33.5225 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 66.843L32.0525 66.4971L31.4534 66.843L32.0525 67.1888L32.6516 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 65.8078L32.9234 65.4619L32.3242 65.8078L32.9234 66.1536L33.5225 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M32.6514 68.9123L32.0522 68.5664L31.4531 68.9123L32.0522 69.2581L32.6514 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 67.878L32.9234 67.5322L32.3242 67.878L32.9234 68.2239L33.5225 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M32.6514 70.9826L32.0522 70.6367L31.4531 70.9826L32.0522 71.3284L32.6514 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 69.9474L32.9234 69.6016L32.3242 69.9474L32.9234 70.2932L33.5225 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 73.0519L32.0525 72.7061L31.4534 73.0519L32.0525 73.3978L32.6516 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 72.0168L32.9234 71.6709L32.3242 72.0168L32.9234 72.3626L33.5225 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M32.6514 75.1212L32.0522 74.7754L31.4531 75.1212L32.0522 75.4671L32.6514 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 74.0871L32.9234 73.7412L32.3242 74.0871L32.9234 74.4329L33.5225 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 77.1913L32.0525 76.8455L31.4534 77.1913L32.0525 77.5372L32.6516 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 76.1564L32.9234 75.8105L32.3242 76.1564L32.9234 76.5023L33.5225 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M32.6516 79.261L32.0525 78.9152L31.4534 79.261L32.0525 79.6069L32.6516 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 78.2267L32.9234 77.8809L32.3242 78.2267L32.9234 78.5725L33.5225 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M32.6514 81.3302L32.0522 80.9844L31.4531 81.3302L32.0522 81.6761L32.6514 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 80.2961L32.9234 79.9502L32.3242 80.2961L32.9234 80.6419L33.5225 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M32.0525 83.0544L31.4534 83.4003L32.0525 83.7461L32.6516 83.4003L32.0525 83.0544Z"
                fill="#969A9E"
            />
            <path
                d="M33.5225 82.3654L32.9234 82.0195L32.3242 82.3654L32.9234 82.7112L33.5225 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M32.3242 84.4347L32.9234 84.7806L33.5225 84.4347L32.9234 84.0889L32.3242 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 60.6339L30.31 60.2881L29.7109 60.6339L30.31 60.9798L30.9091 60.6339Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 62.7042L30.31 62.3584L29.7109 62.7042L30.31 63.0501L30.9091 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 61.6689L31.1809 61.3231L30.5818 61.6689L31.1809 62.0148L31.78 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 64.7736L30.31 64.4277L29.7109 64.7736L30.31 65.1194L30.9091 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 63.7386L31.1809 63.3927L30.5818 63.7386L31.1809 64.0844L31.78 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 66.8429L30.31 66.4971L29.7109 66.8429L30.31 67.1888L30.9091 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M31.7802 65.8078L31.1811 65.4619L30.582 65.8078L31.1811 66.1536L31.7802 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 68.9123L30.31 68.5664L29.7109 68.9123L30.31 69.2581L30.9091 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 67.8779L31.1809 67.532L30.5818 67.8779L31.1809 68.2237L31.78 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 70.9826L30.31 70.6367L29.7109 70.9826L30.31 71.3284L30.9091 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 69.9475L31.1809 69.6017L30.5818 69.9475L31.1809 70.2934L31.78 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 73.0519L30.31 72.7061L29.7109 73.0519L30.31 73.3977L30.9091 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M31.7802 72.0168L31.1811 71.6709L30.582 72.0168L31.1811 72.3626L31.7802 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 75.1212L30.31 74.7754L29.7109 75.1212L30.31 75.4671L30.9091 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 74.0869L31.1809 73.741L30.5818 74.0869L31.1809 74.4327L31.78 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 77.1916L30.31 76.8457L29.7109 77.1916L30.31 77.5374L30.9091 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 76.1566L31.1809 75.8107L30.5818 76.1566L31.1809 76.5024L31.78 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 79.2609L30.31 78.915L29.7109 79.2609L30.31 79.6068L30.9091 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M31.7802 78.2267L31.1811 77.8809L30.582 78.2267L31.1811 78.5725L31.7802 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M30.9091 81.3302L30.31 80.9844L29.7109 81.3302L30.31 81.6761L30.9091 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 80.2959L31.1809 79.95L30.5818 80.2959L31.1809 80.6417L31.78 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M30.31 83.0547L29.7109 83.4005L30.31 83.7464L30.9091 83.4005L30.31 83.0547Z"
                fill="#969A9E"
            />
            <path
                d="M31.78 82.3654L31.1809 82.0195L30.5818 82.3654L31.1809 82.7112L31.78 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M30.582 84.4347L31.1811 84.7806L31.7802 84.4347L31.1811 84.0889L30.582 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 60.6339L28.566 60.2881L27.9668 60.6339L28.566 60.9798L29.1651 60.6339Z"
                fill="#969A9E"
            />
            <path
                d="M29.167 62.7042L28.5679 62.3584L27.9688 62.7042L28.5679 63.0501L29.167 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 61.6691L29.4389 61.3232L28.8398 61.6691L29.4389 62.015L30.038 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 64.7735L28.566 64.4276L27.9668 64.7735L28.566 65.1193L29.1651 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 63.7384L29.4389 63.3926L28.8398 63.7384L29.4389 64.0843L30.038 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 66.843L28.566 66.4972L27.9668 66.843L28.566 67.1889L29.1651 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 65.8078L29.4389 65.4619L28.8398 65.8078L29.4389 66.1536L30.038 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M29.167 68.9123L28.5679 68.5664L27.9688 68.9123L28.5679 69.2581L29.167 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 67.878L29.4389 67.5322L28.8398 67.878L29.4389 68.2239L30.038 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M29.167 70.9826L28.5679 70.6367L27.9688 70.9826L28.5679 71.3284L29.167 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 69.9474L29.4389 69.6016L28.8398 69.9474L29.4389 70.2932L30.038 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 73.052L28.566 72.7062L27.9668 73.052L28.566 73.3979L29.1651 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 72.0168L29.4389 71.6709L28.8398 72.0168L29.4389 72.3626L30.038 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M29.167 75.1212L28.5679 74.7754L27.9688 75.1212L28.5679 75.4671L29.167 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 74.0871L29.4389 73.7412L28.8398 74.0871L29.4389 74.4329L30.038 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 77.1913L28.566 76.8455L27.9668 77.1913L28.566 77.5372L29.1651 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 76.1564L29.4389 75.8105L28.8398 76.1564L29.4389 76.5023L30.038 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M29.1651 79.261L28.566 78.9152L27.9668 79.261L28.566 79.6069L29.1651 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 78.2267L29.4389 77.8809L28.8398 78.2267L29.4389 78.5725L30.038 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M29.167 81.3302L28.5679 80.9844L27.9688 81.3302L28.5679 81.6761L29.167 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 80.2961L29.4389 79.9502L28.8398 80.2961L29.4389 80.6419L30.038 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M28.566 83.0544L27.9668 83.4003L28.566 83.7461L29.1651 83.4003L28.566 83.0544Z"
                fill="#969A9E"
            />
            <path
                d="M30.038 82.3654L29.4389 82.0195L28.8398 82.3654L29.4389 82.7112L30.038 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M28.8398 84.4347L29.4389 84.7806L30.038 84.4347L29.4389 84.0889L28.8398 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 60.634L26.8238 60.2881L26.2247 60.634L26.8238 60.9799L27.4229 60.634Z"
                fill="#969A9E"
            />
            <path
                d="M27.4228 62.7042L26.8237 62.3584L26.2246 62.7042L26.8237 63.0501L27.4228 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 61.6691L27.6948 61.3232L27.0957 61.6691L27.6948 62.015L28.294 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 64.7735L26.8238 64.4276L26.2247 64.7735L26.8238 65.1193L27.4229 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 63.7384L27.6948 63.3926L27.0957 63.7384L27.6948 64.0843L28.294 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 66.843L26.8238 66.4971L26.2247 66.843L26.8238 67.1888L27.4229 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 65.8078L27.6948 65.4619L27.0957 65.8078L27.6948 66.1536L28.294 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M27.4228 68.9123L26.8237 68.5664L26.2246 68.9123L26.8237 69.2581L27.4228 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 67.878L27.6948 67.5322L27.0957 67.878L27.6948 68.2239L28.294 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M27.4228 70.9826L26.8237 70.6367L26.2246 70.9826L26.8237 71.3284L27.4228 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 69.9474L27.6948 69.6016L27.0957 69.9474L27.6948 70.2932L28.294 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 73.0519L26.8238 72.7061L26.2247 73.0519L26.8238 73.3978L27.4229 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 72.0168L27.6948 71.6709L27.0957 72.0168L27.6948 72.3626L28.294 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M27.4228 75.1212L26.8237 74.7754L26.2246 75.1212L26.8237 75.4671L27.4228 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 74.0871L27.6948 73.7412L27.0957 74.0871L27.6948 74.4329L28.294 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 77.1913L26.8238 76.8455L26.2247 77.1913L26.8238 77.5372L27.4229 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 76.1564L27.6948 75.8105L27.0957 76.1564L27.6948 76.5023L28.294 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M27.4229 79.261L26.8238 78.9152L26.2247 79.261L26.8238 79.6069L27.4229 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 78.2267L27.6948 77.8809L27.0957 78.2267L27.6948 78.5725L28.294 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M27.4228 81.3302L26.8237 80.9844L26.2246 81.3302L26.8237 81.6761L27.4228 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 80.2961L27.6948 79.9502L27.0957 80.2961L27.6948 80.6419L28.294 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M26.8238 83.0544L26.2247 83.4003L26.8238 83.7461L27.4229 83.4003L26.8238 83.0544Z"
                fill="#969A9E"
            />
            <path
                d="M28.294 82.3654L27.6948 82.0195L27.0957 82.3654L27.6948 82.7112L28.294 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M27.0957 84.4347L27.6948 84.7806L28.294 84.4347L27.6948 84.0889L27.0957 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 60.6339L25.1496 60.3262C25.0951 60.3316 25.0379 60.3371 24.9835 60.3453L24.4824 60.6339L25.0815 60.9798L25.6806 60.6339Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 62.7042L25.0815 62.3584L24.4824 62.7042L25.0815 63.0501L25.6806 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 61.6691L25.9526 61.3232L25.3535 61.6691L25.9526 62.015L26.5517 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 64.7736L25.0815 64.4277L24.4824 64.7736L25.0815 65.1194L25.6806 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 63.7384L25.9526 63.3926L25.3535 63.7384L25.9526 64.0843L26.5517 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 66.8429L25.0815 66.4971L24.4824 66.8429L25.0815 67.1888L25.6806 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 65.8078L25.9526 65.4619L25.3535 65.8078L25.9526 66.1536L26.5517 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 68.9123L25.0815 68.5664L24.4824 68.9123L25.0815 69.2581L25.6806 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 67.878L25.9526 67.5322L25.3535 67.878L25.9526 68.2239L26.5517 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 70.9826L25.0815 70.6367L24.4824 70.9826L25.0815 71.3284L25.6806 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 69.9474L25.9526 69.6016L25.3535 69.9474L25.9526 70.2932L26.5517 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 73.0519L25.0815 72.7061L24.4824 73.0519L25.0815 73.3977L25.6806 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 72.0168L25.9526 71.6709L25.3535 72.0168L25.9526 72.3626L26.5517 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 75.1212L25.0815 74.7754L24.4824 75.1212L25.0815 75.4671L25.6806 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 74.0871L25.9526 73.7412L25.3535 74.0871L25.9526 74.4329L26.5517 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 77.1916L25.0815 76.8457L24.4824 77.1916L25.0815 77.5374L25.6806 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 76.1564L25.9526 75.8105L25.3535 76.1564L25.9526 76.5023L26.5517 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 79.2609L25.0815 78.915L24.4824 79.2609L25.0815 79.6068L25.6806 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 78.2267L25.9526 77.8809L25.3535 78.2267L25.9526 78.5725L26.5517 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M25.6806 81.3302L25.0815 80.9844L24.4824 81.3302L25.0815 81.6761L25.6806 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 80.2961L25.9526 79.9502L25.3535 80.2961L25.9526 80.6419L26.5517 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M25.0815 83.0547L24.4824 83.4005L25.0815 83.7464L25.6806 83.4005L25.0815 83.0547Z"
                fill="#969A9E"
            />
            <path
                d="M26.5517 82.3654L25.9526 82.0195L25.3535 82.3654L25.9526 82.7112L26.5517 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M25.3535 84.4347L25.9526 84.7806L26.5517 84.4347L25.9526 84.0889L25.3535 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M23.938 60.6337L23.7364 60.5166C23.6466 60.5302 23.5567 60.5384 23.4668 60.5547C23.2435 60.5901 23.0202 60.6282 22.7969 60.6664L23.3415 60.9795L23.9407 60.6337H23.938Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 62.7042L23.3374 62.3584L22.7383 62.7042L23.3374 63.0501L23.9365 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 61.6689L24.2105 61.323L23.6113 61.6689L24.2105 62.0147L24.8096 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 64.7736L23.3374 64.4277L22.7383 64.7736L23.3374 65.1194L23.9365 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 63.7386L24.2105 63.3927L23.6113 63.7386L24.2105 64.0844L24.8096 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 66.8429L23.3374 66.4971L22.7383 66.8429L23.3374 67.1888L23.9365 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M24.8076 65.8078L24.2085 65.4619L23.6094 65.8078L24.2085 66.1536L24.8076 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 68.9123L23.3374 68.5664L22.7383 68.9123L23.3374 69.2581L23.9365 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 67.8778L24.2105 67.532L23.6113 67.8778L24.2105 68.2237L24.8096 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 70.9826L23.3374 70.6367L22.7383 70.9826L23.3374 71.3284L23.9365 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 69.9475L24.2105 69.6017L23.6113 69.9475L24.2105 70.2934L24.8096 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 73.0519L23.3374 72.7061L22.7383 73.0519L23.3374 73.3977L23.9365 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M24.8076 72.0168L24.2085 71.6709L23.6094 72.0168L24.2085 72.3626L24.8076 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 75.1212L23.3374 74.7754L22.7383 75.1212L23.3374 75.4671L23.9365 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 74.0869L24.2105 73.7411L23.6113 74.0869L24.2105 74.4328L24.8096 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 77.1916L23.3374 76.8457L22.7383 77.1916L23.3374 77.5374L23.9365 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 76.1565L24.2105 75.8107L23.6113 76.1565L24.2105 76.5024L24.8096 76.1565Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 79.2609L23.3374 78.915L22.7383 79.2609L23.3374 79.6068L23.9365 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M24.8076 78.2267L24.2085 77.8809L23.6094 78.2267L24.2085 78.5725L24.8076 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M23.9365 81.3302L23.3374 80.9844L22.7383 81.3302L23.3374 81.6761L23.9365 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 80.2959L24.2105 79.9501L23.6113 80.2959L24.2105 80.6418L24.8096 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M23.3374 83.0547L22.7383 83.4005L23.3374 83.7464L23.9365 83.4005L23.3374 83.0547Z"
                fill="#969A9E"
            />
            <path
                d="M24.8096 82.3654L24.2105 82.0195L23.6113 82.3654L24.2105 82.7112L24.8096 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M23.6094 84.4347L23.8872 84.5954C24.0696 84.6144 24.2493 84.6335 24.4318 84.6526L24.8076 84.4347L24.2085 84.0889L23.6094 84.4347Z"
                fill="#969A9E"
            />
            <path
                d="M21.833 60.8438C21.7159 60.8655 21.6015 60.8928 21.4844 60.9146L21.596 60.9799L21.833 60.8438Z"
                fill="#969A9E"
            />
            <path
                d="M22.1943 62.7042L21.5952 62.3584L20.9961 62.7042L21.5952 63.0501L22.1943 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 61.6691L22.4663 61.3232L21.8672 61.6691L22.4663 62.015L23.0654 61.6691Z"
                fill="#969A9E"
            />
            <path
                d="M22.1942 64.7735L21.5951 64.4276L20.996 64.7735L21.5951 65.1193L22.1942 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 63.7384L22.4663 63.3926L21.8672 63.7384L22.4663 64.0843L23.0654 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M22.1942 66.843L21.5951 66.4971L20.996 66.843L21.5951 67.1888L22.1942 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 65.8078L22.4663 65.4619L21.8672 65.8078L22.4663 66.1536L23.0654 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M22.1943 68.9123L21.5952 68.5664L20.9961 68.9123L21.5952 69.2581L22.1943 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 67.878L22.4663 67.5322L21.8672 67.878L22.4663 68.2239L23.0654 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M22.1943 70.9826L21.5952 70.6367L20.9961 70.9826L21.5952 71.3284L22.1943 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 69.9474L22.4663 69.6016L21.8672 69.9474L22.4663 70.2932L23.0654 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M22.1942 73.0519L21.5951 72.7061L20.996 73.0519L21.5951 73.3978L22.1942 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 72.0168L22.4663 71.6709L21.8672 72.0168L22.4663 72.3626L23.0654 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M22.1943 75.1212L21.5952 74.7754L20.9961 75.1212L21.5952 75.4671L22.1943 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 74.0871L22.4663 73.7412L21.8672 74.0871L22.4663 74.4329L23.0654 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M22.1942 77.1913L21.5951 76.8455L20.996 77.1913L21.5951 77.5372L22.1942 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 76.1564L22.4663 75.8105L21.8672 76.1564L22.4663 76.5023L23.0654 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M22.1942 79.261L21.5951 78.9152L20.996 79.261L21.5951 79.6069L22.1942 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 78.2267L22.4663 77.8809L21.8672 78.2267L22.4663 78.5725L23.0654 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M22.1943 81.3302L21.5952 80.9844L20.9961 81.3302L21.5952 81.6761L22.1943 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 80.2961L22.4663 79.9502L21.8672 80.2961L22.4663 80.6419L23.0654 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M21.5951 83.0544L20.996 83.4003L21.5951 83.7461L22.1942 83.4003L21.5951 83.0544Z"
                fill="#969A9E"
            />
            <path
                d="M23.0654 82.3654L22.4663 82.0195L21.8672 82.3654L22.4663 82.7112L23.0654 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M21.9734 84.3721C22.3029 84.4129 22.6325 84.4511 22.9647 84.4919L23.0627 84.4347L22.4636 84.0889L21.9707 84.3721H21.9734Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 62.7042L19.8531 62.3584L19.2539 62.7042L19.8531 63.0501L20.4522 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 61.6689L20.7239 61.3231L20.1248 61.6689L20.7239 62.0148L21.323 61.6689Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 64.7736L19.8531 64.4277L19.2539 64.7736L19.8531 65.1194L20.4522 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 63.7386L20.7239 63.3927L20.1248 63.7386L20.7239 64.0844L21.323 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 66.8429L19.8531 66.4971L19.2539 66.8429L19.8531 67.1888L20.4522 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M21.3232 65.8078L20.7241 65.4619L20.125 65.8078L20.7241 66.1536L21.3232 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 68.9123L19.8531 68.5664L19.2539 68.9123L19.8531 69.2581L20.4522 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 67.8779L20.7239 67.532L20.1248 67.8779L20.7239 68.2237L21.323 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 70.9826L19.8531 70.6367L19.2539 70.9826L19.8531 71.3284L20.4522 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 69.9475L20.7239 69.6017L20.1248 69.9475L20.7239 70.2934L21.323 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 73.0519L19.8531 72.7061L19.2539 73.0519L19.8531 73.3977L20.4522 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M21.3232 72.0168L20.7241 71.6709L20.125 72.0168L20.7241 72.3626L21.3232 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 75.1212L19.8531 74.7754L19.2539 75.1212L19.8531 75.4671L20.4522 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 74.0869L20.7239 73.741L20.1248 74.0869L20.7239 74.4327L21.323 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 77.1916L19.8531 76.8457L19.2539 77.1916L19.8531 77.5374L20.4522 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 76.1566L20.7239 75.8107L20.1248 76.1566L20.7239 76.5024L21.323 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 79.2609L19.8531 78.915L19.2539 79.2609L19.8531 79.6068L20.4522 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M21.3232 78.2267L20.7241 77.8809L20.125 78.2267L20.7241 78.5725L21.3232 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M20.4522 81.3302L19.8531 80.9844L19.2539 81.3302L19.8531 81.6761L20.4522 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 80.2959L20.7239 79.95L20.1248 80.2959L20.7239 80.6417L21.323 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M19.2539 83.4005L19.8531 83.7464L20.4522 83.4005L19.8531 83.0547L19.2539 83.4005Z"
                fill="#969A9E"
            />
            <path
                d="M21.323 82.3654L20.7239 82.0195L20.1248 82.3654L20.7239 82.7112L21.323 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M20.5586 84.1842C20.7056 84.206 20.8527 84.2278 20.9998 84.2468L20.7247 84.0889L20.5586 84.1842Z"
                fill="#969A9E"
            />
            <path
                d="M18.708 62.7042L18.1089 62.3584L17.5098 62.7042L18.1089 63.0501L18.708 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M19.5803 61.6688L19.199 61.4482C18.9267 61.519 18.6544 61.5926 18.3848 61.6688L18.9811 62.0147L19.5803 61.6688Z"
                fill="#969A9E"
            />
            <path
                d="M18.7099 64.7736L18.1108 64.4277L17.5117 64.7736L18.1108 65.1194L18.7099 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 63.7386L18.98 63.3927L18.3809 63.7386L18.98 64.0844L19.5791 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M18.7099 66.8429L18.1108 66.4971L17.5117 66.8429L18.1108 67.1888L18.7099 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M19.5811 65.8078L18.9819 65.4619L18.3828 65.8078L18.9819 66.1536L19.5811 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M18.708 68.9123L18.1089 68.5664L17.5098 68.9123L18.1089 69.2581L18.708 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 67.8778L18.98 67.532L18.3809 67.8778L18.98 68.2237L19.5791 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M18.708 70.9826L18.1089 70.6367L17.5098 70.9826L18.1089 71.3284L18.708 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 69.9475L18.98 69.6017L18.3809 69.9475L18.98 70.2934L19.5791 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M18.7099 73.0519L18.1108 72.7061L17.5117 73.0519L18.1108 73.3977L18.7099 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M19.5811 72.0168L18.9819 71.6709L18.3828 72.0168L18.9819 72.3626L19.5811 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M18.708 75.1212L18.1089 74.7754L17.5098 75.1212L18.1089 75.4671L18.708 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 74.0869L18.98 73.7411L18.3809 74.0869L18.98 74.4328L19.5791 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M18.7099 77.1916L18.1108 76.8457L17.5117 77.1916L18.1108 77.5374L18.7099 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 76.1565L18.98 75.8107L18.3809 76.1565L18.98 76.5024L19.5791 76.1565Z"
                fill="#969A9E"
            />
            <path
                d="M18.7099 79.2609L18.1108 78.915L17.5117 79.2609L18.1108 79.6068L18.7099 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M19.5811 78.2267L18.9819 77.8809L18.3828 78.2267L18.9819 78.5725L19.5811 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M18.708 81.3302L18.1089 80.9844L17.5098 81.3302L18.1089 81.6761L18.708 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M19.5791 80.2959L18.98 79.9501L18.3809 80.2959L18.98 80.6418L19.5791 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M17.5104 83.4003L17.9843 83.6753C18.0414 83.6889 18.0987 83.7053 18.1559 83.7189L18.7059 83.4003L18.1068 83.0544L17.5077 83.4003H17.5104Z"
                fill="#969A9E"
            />
            <path
                d="M18.98 82.0195L18.3809 82.3654L18.98 82.7112L19.5791 82.3654L18.98 82.0195Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 62.7042L16.3667 62.3584L15.7676 62.7042L16.3667 63.0501L16.9658 62.7042Z"
                fill="#969A9E"
            />
            <path
                d="M17.279 61.9932C17.2625 61.9987 17.2431 62.0042 17.2266 62.0098L17.2376 62.018L17.2763 61.9959L17.279 61.9932Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 64.7735L16.3667 64.4276L15.7676 64.7735L16.3667 65.1193L16.9658 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 63.7386L17.2379 63.3927L16.6388 63.7386L17.2379 64.0844L17.837 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 66.843L16.3667 66.4971L15.7676 66.843L16.3667 67.1888L16.9658 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M17.8369 65.8078L17.2378 65.4619L16.6387 65.8078L17.2378 66.1536L17.8369 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 68.9123L16.3667 68.5664L15.7676 68.9123L16.3667 69.2581L16.9658 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 67.8779L17.2379 67.532L16.6388 67.8779L17.2379 68.2237L17.837 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 70.9826L16.3667 70.6367L15.7676 70.9826L16.3667 71.3284L16.9658 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 69.9475L17.2379 69.6017L16.6388 69.9475L17.2379 70.2934L17.837 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 73.0519L16.3667 72.7061L15.7676 73.0519L16.3667 73.3978L16.9658 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M17.8369 72.0168L17.2378 71.6709L16.6387 72.0168L17.2378 72.3626L17.8369 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 75.1212L16.3667 74.7754L15.7676 75.1212L16.3667 75.4671L16.9658 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 74.0869L17.2379 73.741L16.6388 74.0869L17.2379 74.4327L17.837 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 77.1913L16.3667 76.8455L15.7676 77.1913L16.3667 77.5372L16.9658 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 76.1566L17.2379 75.8107L16.6388 76.1566L17.2379 76.5024L17.837 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 79.261L16.3667 78.9152L15.7676 79.261L16.3667 79.6069L16.9658 79.261Z"
                fill="#969A9E"
            />
            <path
                d="M17.8369 78.2267L17.2378 77.8809L16.6387 78.2267L17.2378 78.5725L17.8369 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M16.9658 81.3302L16.3667 80.9844L15.7676 81.3302L16.3667 81.6761L16.9658 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M17.837 80.2959L17.2379 79.95L16.6388 80.2959L17.2379 80.6417L17.837 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M16.1914 83.1552C16.4447 83.2396 16.6979 83.3186 16.9539 83.3948L16.3657 83.0544L16.1914 83.1552Z"
                fill="#969A9E"
            />
            <path
                d="M17.2379 82.0195L16.6388 82.3654L17.2379 82.7112L17.837 82.3654L17.2379 82.0195Z"
                fill="#969A9E"
            />
            <path
                d="M15.2242 62.7034L15.1833 62.6816C14.9382 62.7688 14.6904 62.8559 14.4453 62.9485L14.6223 63.052L15.2215 62.7061L15.2242 62.7034Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 64.7736L14.6225 64.4277L14.0234 64.7736L14.6225 65.1194L15.2217 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 63.7384L15.4956 63.3926L14.8965 63.7384L15.4956 64.0843L16.0947 63.7384Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 66.8429L14.6225 66.4971L14.0234 66.8429L14.6225 67.1888L15.2217 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 65.8078L15.4956 65.4619L14.8965 65.8078L15.4956 66.1536L16.0947 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 68.9123L14.6225 68.5664L14.0234 68.9123L14.6225 69.2581L15.2217 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 67.878L15.4956 67.5322L14.8965 67.878L15.4956 68.2239L16.0947 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 70.9826L14.6225 70.6367L14.0234 70.9826L14.6225 71.3284L15.2217 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 69.9474L15.4956 69.6016L14.8965 69.9474L15.4956 70.2932L16.0947 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 73.0519L14.6225 72.7061L14.0234 73.0519L14.6225 73.3977L15.2217 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 72.0168L15.4956 71.6709L14.8965 72.0168L15.4956 72.3626L16.0947 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 75.1212L14.6225 74.7754L14.0234 75.1212L14.6225 75.4671L15.2217 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 74.0871L15.4956 73.7412L14.8965 74.0871L15.4956 74.4329L16.0947 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 77.1916L14.6225 76.8457L14.0234 77.1916L14.6225 77.5374L15.2217 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 76.1564L15.4956 75.8105L14.8965 76.1564L15.4956 76.5023L16.0947 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M15.2217 79.2609L14.6225 78.915L14.0234 79.2609L14.6225 79.6068L15.2217 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 78.2267L15.4956 77.8809L14.8965 78.2267L15.4956 78.5725L16.0947 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M14.6225 80.9844L14.0234 81.3302L14.6225 81.6761L15.2217 81.3302L14.6225 80.9844Z"
                fill="#969A9E"
            />
            <path
                d="M16.0947 80.2961L15.4956 79.9502L14.8965 80.2961L15.4956 80.6419L16.0947 80.2961Z"
                fill="#969A9E"
            />
            <path
                d="M14.8965 82.3654L15.4956 82.7112L16.0947 82.3654L15.4956 82.0195L14.8965 82.3654Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 64.7736L12.8803 64.4277L12.2812 64.7736L12.8803 65.1194L13.4794 64.7736Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 63.7386L13.7514 63.3927L13.1523 63.7386L13.7514 64.0844L14.3505 63.7386Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 66.8429L12.8803 66.4971L12.2812 66.8429L12.8803 67.1888L13.4794 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 65.8078L13.7514 65.4619L13.1523 65.8078L13.7514 66.1536L14.3505 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 68.9123L12.8803 68.5664L12.2812 68.9123L12.8803 69.2581L13.4794 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 67.8778L13.7514 67.532L13.1523 67.8778L13.7514 68.2237L14.3505 67.8778Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 70.9826L12.8803 70.6367L12.2812 70.9826L12.8803 71.3284L13.4794 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 69.9475L13.7514 69.6017L13.1523 69.9475L13.7514 70.2934L14.3505 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 73.0519L12.8803 72.7061L12.2812 73.0519L12.8803 73.3977L13.4794 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 72.0168L13.7514 71.6709L13.1523 72.0168L13.7514 72.3626L14.3505 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 75.1212L12.8803 74.7754L12.2812 75.1212L12.8803 75.4671L13.4794 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 74.0869L13.7514 73.7411L13.1523 74.0869L13.7514 74.4328L14.3505 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 77.1916L12.8803 76.8457L12.2812 77.1916L12.8803 77.5374L13.4794 77.1916Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 76.1565L13.7514 75.8107L13.1523 76.1565L13.7514 76.5024L14.3505 76.1565Z"
                fill="#969A9E"
            />
            <path
                d="M13.4794 79.2609L12.8803 78.915L12.2812 79.2609L12.8803 79.6068L13.4794 79.2609Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 78.2267L13.7514 77.8809L13.1523 78.2267L13.7514 78.5725L14.3505 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M12.2812 81.3302L12.8803 81.6761L13.4794 81.3302L12.8803 80.9844L12.2812 81.3302Z"
                fill="#969A9E"
            />
            <path
                d="M14.3505 80.2959L13.7514 79.9501L13.1523 80.2959L13.7514 80.6418L14.3505 80.2959Z"
                fill="#969A9E"
            />
            <path
                d="M13.582 82.1176C13.8053 82.221 14.0314 82.3218 14.2574 82.4198L14.3527 82.3654L13.7536 82.0195L13.582 82.1176Z"
                fill="#969A9E"
            />
            <path
                d="M11.7372 64.7735L11.1381 64.4276L10.5389 64.7735L11.1381 65.1193L11.7372 64.7735Z"
                fill="#969A9E"
            />
            <path
                d="M12.6094 63.7389L12.5304 63.6953C12.2989 63.7906 12.0674 63.8887 11.8359 63.9867L12.0103 64.0875L12.6094 63.7416V63.7389Z"
                fill="#969A9E"
            />
            <path
                d="M11.7372 66.843L11.1381 66.4971L10.5389 66.843L11.1381 67.1888L11.7372 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 65.8078L12.0093 65.4619L11.4102 65.8078L12.0093 66.1536L12.6084 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M11.7373 68.9123L11.1382 68.5664L10.5391 68.9123L11.1382 69.2581L11.7373 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 67.878L12.0093 67.5322L11.4102 67.878L12.0093 68.2239L12.6084 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M11.7373 70.9826L11.1382 70.6367L10.5391 70.9826L11.1382 71.3284L11.7373 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 69.9474L12.0093 69.6016L11.4102 69.9474L12.0093 70.2932L12.6084 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M11.7372 73.0519L11.1381 72.7061L10.5389 73.0519L11.1381 73.3978L11.7372 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 72.0168L12.0093 71.6709L11.4102 72.0168L12.0093 72.3626L12.6084 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M11.7373 75.1212L11.1382 74.7754L10.5391 75.1212L11.1382 75.4671L11.7373 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 74.0871L12.0093 73.7412L11.4102 74.0871L12.0093 74.4329L12.6084 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M11.7372 77.1913L11.1381 76.8455L10.5389 77.1913L11.1381 77.5372L11.7372 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 76.1564L12.0093 75.8105L11.4102 76.1564L12.0093 76.5023L12.6084 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M11.1381 78.9152L10.5389 79.261L11.1381 79.6069L11.7372 79.261L11.1381 78.9152Z"
                fill="#969A9E"
            />
            <path
                d="M12.6084 78.2267L12.0093 77.8809L11.4102 78.2267L12.0093 78.5725L12.6084 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M12.0093 79.9502L11.4102 80.2961L12.0093 80.6419L12.6084 80.2961L12.0093 79.9502Z"
                fill="#969A9E"
            />
            <path
                d="M9.79269 64.8904C9.64564 64.9585 9.49862 65.0239 9.35156 65.092L9.39784 65.1192L9.79542 64.8904H9.79269Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 66.843L9.39597 66.4972L8.79688 66.843L9.39597 67.1889L9.99507 66.843Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 65.8078L10.2671 65.4619L9.66797 65.8078L10.2671 66.1536L10.8662 65.8078Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 68.9123L9.39597 68.5664L8.79688 68.9123L9.39597 69.2581L9.99507 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 67.878L10.2671 67.5322L9.66797 67.878L10.2671 68.2239L10.8662 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 70.9826L9.39597 70.6367L8.79688 70.9826L9.39597 71.3284L9.99507 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 69.9474L10.2671 69.6016L9.66797 69.9474L10.2671 70.2932L10.8662 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 73.052L9.39597 72.7062L8.79688 73.052L9.39597 73.3979L9.99507 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 72.0168L10.2671 71.6709L9.66797 72.0168L10.2671 72.3626L10.8662 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 75.1212L9.39597 74.7754L8.79688 75.1212L9.39597 75.4671L9.99507 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 74.0871L10.2671 73.7412L9.66797 74.0871L10.2671 74.4329L10.8662 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M9.99507 77.1913L9.39597 76.8455L8.79688 77.1913L9.39597 77.5372L9.99507 77.1913Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 76.1564L10.2671 75.8105L9.66797 76.1564L10.2671 76.5023L10.8662 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M8.82031 79.2475C8.89929 79.3019 8.9755 79.3564 9.05175 79.4109L9.39488 79.6097L9.99398 79.2638L9.39488 78.918L8.82031 79.2502V79.2475Z"
                fill="#969A9E"
            />
            <path
                d="M10.8662 78.2267L10.2671 77.8809L9.66797 78.2267L10.2671 78.5725L10.8662 78.2267Z"
                fill="#969A9E"
            />
            <path
                d="M10.032 80.0836L10.0947 80.1272C10.258 80.2389 10.4242 80.3478 10.5876 80.454L10.8626 80.2961L10.2635 79.9502L10.0293 80.0864L10.032 80.0836Z"
                fill="#969A9E"
            />
            <path
                d="M8.25288 66.8429L7.65379 66.4971L7.05469 66.8429L7.65379 67.1888L8.25288 66.8429Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 65.8086L8.54396 65.4736C8.45954 65.5145 8.37507 65.5553 8.29065 65.5962L7.92578 65.8086L8.52488 66.1544L9.12398 65.8086Z"
                fill="#969A9E"
            />
            <path
                d="M8.25093 68.9123L7.65183 68.5664L7.05273 68.9123L7.65183 69.2581L8.25093 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 67.878L8.52488 67.5322L7.92578 67.878L8.52488 68.2239L9.12398 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M8.25093 70.9826L7.65183 70.6367L7.05273 70.9826L7.65183 71.3284L8.25093 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 69.9474L8.52488 69.6016L7.92578 69.9474L8.52488 70.2932L9.12398 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M8.25288 73.0519L7.65379 72.7061L7.05469 73.0519L7.65379 73.3977L8.25288 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 72.0168L8.52488 71.6709L7.92578 72.0168L8.52488 72.3626L9.12398 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M8.25093 75.1212L7.65183 74.7754L7.05273 75.1212L7.65183 75.4671L8.25093 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 74.0871L8.52488 73.7412L7.92578 74.0871L8.52488 74.4329L9.12398 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M7.65379 76.8457L7.05469 77.1916L7.65379 77.5374L8.25288 77.1916L7.65379 76.8457Z"
                fill="#969A9E"
            />
            <path
                d="M9.12398 76.1564L8.52488 75.8105L7.92578 76.1564L8.52488 76.5023L9.12398 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M8.52488 77.8809L7.92578 78.2267L8.52488 78.5725L9.12398 78.2267L8.52488 77.8809Z"
                fill="#969A9E"
            />
            <path
                d="M5.91022 67.189L6.50939 66.8432L6.23161 66.6825C6.03282 66.7969 5.8367 66.9139 5.64062 67.0338L5.91022 67.189Z"
                fill="#969A9E"
            />
            <path
                d="M6.50881 68.9123L5.90964 68.5664L5.31055 68.9123L5.90964 69.2581L6.50881 68.9123Z"
                fill="#969A9E"
            />
            <path
                d="M7.38003 67.8779L6.78093 67.532L6.18176 67.8779L6.78093 68.2237L7.38003 67.8779Z"
                fill="#969A9E"
            />
            <path
                d="M6.50881 70.9826L5.90964 70.6367L5.31055 70.9826L5.90964 71.3284L6.50881 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M7.38003 69.9475L6.78093 69.6017L6.18176 69.9475L6.78093 70.2934L7.38003 69.9475Z"
                fill="#969A9E"
            />
            <path
                d="M6.50857 73.0519L5.9094 72.7061L5.3103 73.0519L5.9094 73.3978L6.50857 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M7.3799 72.0168L6.78081 71.6709L6.18164 72.0168L6.78081 72.3626L7.3799 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M6.50881 75.1212L5.90964 74.7754L5.31055 75.1212L5.90964 75.4671L6.50881 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M7.38003 74.0869L6.78093 73.741L6.18176 74.0869L6.78093 74.4327L7.38003 74.0869Z"
                fill="#969A9E"
            />
            <path
                d="M5.90948 76.8455L5.77332 76.9244C5.93399 77.0633 6.09739 77.1995 6.26351 77.3329L6.50864 77.1913L5.90948 76.8455Z"
                fill="#969A9E"
            />
            <path
                d="M7.38003 76.1566L6.78093 75.8107L6.18176 76.1566L6.78093 76.5024L7.38003 76.1566Z"
                fill="#969A9E"
            />
            <path
                d="M4.1655 69.2581L4.7646 68.9123L4.1655 68.5664L3.56641 68.9123L4.1655 69.2581Z"
                fill="#969A9E"
            />
            <path
                d="M5.6357 67.878L5.0366 67.5322L4.4375 67.878L5.0366 68.2239L5.6357 67.878Z"
                fill="#969A9E"
            />
            <path
                d="M4.7646 70.9826L4.1655 70.6367L3.56641 70.9826L4.1655 71.3284L4.7646 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M5.6357 69.9474L5.0366 69.6016L4.4375 69.9474L5.0366 70.2932L5.6357 69.9474Z"
                fill="#969A9E"
            />
            <path
                d="M4.7646 73.052L4.1655 72.7062L3.56641 73.052L4.1655 73.3979L4.7646 73.052Z"
                fill="#969A9E"
            />
            <path
                d="M5.6357 72.0168L5.0366 71.6709L4.4375 72.0168L5.0366 72.3626L5.6357 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M4.76625 75.1212L4.16716 74.7754L3.7832 74.996C3.91936 75.1485 4.05826 75.2982 4.19987 75.448L4.76625 75.1212Z"
                fill="#969A9E"
            />
            <path
                d="M5.6357 74.0871L5.0366 73.7412L4.4375 74.0871L5.0366 74.4329L5.6357 74.0871Z"
                fill="#969A9E"
            />
            <path
                d="M5.63767 76.1564L5.03857 75.8105L4.73633 75.9848C4.88611 76.1292 5.03859 76.2735 5.19381 76.4124L5.63767 76.1564Z"
                fill="#969A9E"
            />
            <path
                d="M3.02162 70.9826L2.42252 70.6367L1.97315 70.8954C1.94864 70.939 1.92413 70.9826 1.90234 71.0289L2.42252 71.3284L3.02162 70.9826Z"
                fill="#969A9E"
            />
            <path
                d="M3.29441 70.2934L3.89351 69.9475L3.29441 69.6017L2.69531 69.9475L3.29441 70.2934Z"
                fill="#969A9E"
            />
            <path
                d="M3.0227 73.0519L2.4236 72.7061L2.203 72.8341C2.30104 73.0084 2.40722 73.1772 2.51615 73.3461L3.0227 73.0519Z"
                fill="#969A9E"
            />
            <path
                d="M3.89351 72.0168L3.29441 71.6709L2.69531 72.0168L3.29441 72.3626L3.89351 72.0168Z"
                fill="#969A9E"
            />
            <path
                d="M2.15123 72.0177L1.77539 71.7998C1.78901 71.9251 1.81898 72.0531 1.86528 72.1811L2.15123 72.0177Z"
                fill="#969A9E"
            />
            <path
                d="M3.8949 74.0869L3.29581 73.7411L2.93359 73.9508C3.05342 74.1115 3.17599 74.2721 3.30398 74.4301L3.89763 74.0869H3.8949Z"
                fill="#969A9E"
            />
            <path
                d="M45.4715 60.7705V66.4975C45.4715 69.8253 43.2684 73.1531 38.8731 75.6938C30.0743 80.7727 15.81 80.7727 7.01127 75.6938C2.61325 73.1531 0.410156 69.8253 0.410156 66.4975V60.7705C0.410156 64.101 2.61053 67.4288 7.01127 69.9696C15.81 75.0457 30.0743 75.0457 38.8731 69.9696C43.2684 67.4261 45.4715 64.101 45.4715 60.7705Z"
                fill="#1D3160"
            />
            <path
                d="M22.9982 51.0157C18.6574 51.0075 14.3084 51.9607 10.9942 53.8724C4.3931 57.6822 4.3931 63.8612 10.9942 67.6683C17.5926 71.4808 28.2895 71.4808 34.8906 67.6683C41.4863 63.8612 41.4863 57.6822 34.8906 53.8724C31.6064 51.977 27.3063 51.0239 23.0009 51.0157H22.9982ZM22.9819 47.7669C28.7334 47.7723 34.4821 49.0441 38.8692 51.5767C43.2645 54.1147 45.4676 57.4398 45.4676 60.7703C45.4676 64.1008 43.2645 67.4286 38.8692 69.9694C30.0704 75.0455 15.8061 75.0455 7.00737 69.9694C2.60934 67.4259 0.40625 64.1008 0.40625 60.7703C0.40625 57.4398 2.60662 54.1147 7.00737 51.5767C11.419 49.0304 17.2005 47.7614 22.9819 47.7669Z"
                fill="white"
            />
            <path
                d="M23.0122 54.2699C20.1065 54.259 17.1926 54.8935 14.9732 56.1762C10.5779 58.7142 10.5779 62.8318 14.9732 65.3698C19.3739 67.9106 26.5061 67.9106 30.9068 65.3698C35.3048 62.8318 35.3048 58.7115 30.9068 56.1762C28.7255 54.9153 25.8688 54.2808 23.0122 54.2699ZM22.9985 51.0157C27.304 51.0266 31.6067 51.977 34.8882 53.8723C41.4839 57.6821 41.4839 63.8612 34.8882 67.6683C28.2871 71.4808 17.5902 71.4808 10.9919 67.6683C4.39074 63.8612 4.39074 57.6821 10.9919 53.8723C14.306 51.9579 18.655 51.0075 22.9958 51.0157H22.9985Z"
                fill="#969A9E"
            />
            <path
                d="M23.0249 57.5212C21.5516 57.5076 20.0784 57.8235 18.9564 58.4716C16.7587 59.7434 16.7587 61.7994 18.9564 63.0739C21.154 64.3457 24.7215 64.3457 26.9218 63.0739C29.1249 61.8022 29.1249 59.7434 26.9218 58.4716C25.8434 57.8507 24.4382 57.5321 23.0276 57.5212H23.0249ZM23.0112 54.2697C25.8679 54.2806 28.7246 54.9151 30.9059 56.1759C35.3039 58.714 35.3039 62.8315 30.9059 65.3696C26.5051 67.9103 19.373 67.9103 14.9723 65.3696C10.577 62.8315 10.577 58.7113 14.9723 56.1759C17.1917 54.896 20.1056 54.2588 23.0112 54.2697Z"
                fill="white"
            />
            <path
                d="M23.0241 57.5209C24.4348 57.5345 25.84 57.8504 26.9184 58.4713C29.1215 59.7431 29.1215 61.7991 26.9184 63.0736C24.7207 64.3454 21.1533 64.3454 18.9529 63.0736C16.7553 61.8019 16.7553 59.7431 18.9529 58.4713C20.0749 57.8232 21.5482 57.5073 23.0214 57.5209H23.0241Z"
                fill="var(--secondary-color)"
            />
            <path
                d="M26.961 19.5078V30.6812L22.8789 36.6152V24.6738L26.961 19.5078Z"
                fill="white"
            />
            <path
                d="M22.8788 24.6741V36.6154L22.8516 36.6917L18.7422 35.4254V24.252L22.8788 24.6741Z"
                fill="white"
            />
            <path
                d="M22.9378 64.4272C21.4209 64.4272 19.9069 64.095 18.7522 63.4278C17.5567 62.7361 16.9004 61.7939 16.9004 60.7727C16.9004 59.7514 17.5594 58.8119 18.7522 58.1202C19.9014 57.4558 21.4264 57.1072 23.0304 57.1154C24.569 57.129 26.026 57.4857 27.1261 58.1202C28.3244 58.8119 28.9833 59.7542 28.9833 60.7727C28.9833 61.7939 28.3244 62.7361 27.1261 63.4278C25.9715 64.095 24.4573 64.4272 22.9405 64.4272H22.9378ZM22.9269 57.9296C21.4972 57.9296 20.1628 58.2455 19.1607 58.8255C18.2293 59.3647 17.7174 60.0537 17.7174 60.7699C17.7174 61.4861 18.2293 62.1779 19.1607 62.7171C21.244 63.9207 24.6317 63.9207 26.7149 62.7171C27.649 62.1779 28.1637 61.4861 28.1637 60.7699C28.1637 60.0537 27.649 59.362 26.7149 58.8255C25.7346 58.2591 24.422 57.9405 23.0195 57.9296C22.9868 57.9296 22.9568 57.9296 22.9241 57.9296H22.9269Z"
                fill="#1D3160"
            />
            <path
                d="M22.9374 67.6736C19.9772 67.6736 17.0198 67.0227 14.7649 65.7237C12.5074 64.4193 11.2629 62.6601 11.2656 60.7702C11.2656 58.8802 12.5101 57.1238 14.7649 55.8221C16.9571 54.5558 19.8519 53.8613 22.9237 53.8613C22.9537 53.8613 22.9809 53.8613 23.0109 53.8613C26.0636 53.8722 28.9394 54.5694 31.1098 55.8221C33.3674 57.1238 34.6119 58.883 34.6146 60.7702C34.6146 62.6601 33.3701 64.4193 31.1098 65.7237C28.8577 67.0255 25.8975 67.6736 22.9374 67.6736ZM22.9265 54.6783C19.9935 54.6783 17.2458 55.3346 15.1762 56.5274C13.1827 57.6793 12.0853 59.1852 12.0853 60.7702C12.0853 62.3551 13.1827 63.8638 15.1762 65.0157C19.4571 67.4884 26.4231 67.4857 30.7013 65.0157C32.6974 63.8638 33.7977 62.3551 33.7977 60.7702C33.7977 59.1852 32.6974 57.6793 30.7013 56.5274C28.6534 55.3455 25.922 54.6865 23.0082 54.6783C22.9809 54.6783 22.9537 54.6783 22.9265 54.6783Z"
                fill="#1D3160"
            />
            <path
                d="M22.939 70.9257C18.5382 70.9257 14.1375 69.959 10.7879 68.0227C7.4656 66.1056 5.63554 63.5294 5.63281 60.7735C5.63281 58.0149 7.46287 55.4387 10.7879 53.5215C14.0558 51.6343 18.3912 50.6022 22.9989 50.6104C27.563 50.6213 31.8576 51.6561 35.0928 53.5215C38.4151 55.4414 40.2451 58.0149 40.2451 60.7735C40.2451 63.5321 38.4151 66.1056 35.0928 68.0227C31.7432 69.959 27.3397 70.9257 22.939 70.9257ZM22.9417 51.4246C18.4974 51.4246 14.3309 52.4186 11.1964 54.2268C8.13551 55.9942 6.44978 58.3171 6.44978 60.7735C6.44978 63.2271 8.13551 65.55 11.1964 67.3174C17.6696 71.0564 28.2057 71.0564 34.6843 67.3174C37.7425 65.5528 39.4254 63.2298 39.4282 60.7735C39.4282 58.3199 37.7452 55.9942 34.6843 54.2268C31.5717 52.4295 27.4214 51.4355 22.9961 51.4246H22.9417Z"
                fill="#1D3160"
            />
            <path
                d="M22.9405 74.1739C17.0965 74.1739 11.2551 72.8913 6.80536 70.3233C2.41823 67.788 0 64.3948 0 60.7702C0 57.1456 2.4155 53.7552 6.80536 51.2226C11.1217 48.7308 16.8514 47.3583 22.9405 47.3583H22.9841C29.0569 47.3637 34.7702 48.7362 39.0757 51.2226C43.4628 53.7552 45.8783 57.1456 45.8783 60.7702C45.8783 64.3948 43.4628 67.7853 39.0757 70.3233C34.6259 72.8913 28.7846 74.1739 22.9405 74.1739ZM22.9405 48.1752C16.993 48.1752 11.4076 49.5069 7.21384 51.9306C3.09087 54.3107 0.81697 57.4506 0.81697 60.7702C0.81697 64.0898 3.08814 67.2324 7.21384 69.618C15.8846 74.6206 29.9937 74.6206 38.6672 69.618C42.7902 67.2324 45.0613 64.0898 45.0613 60.7702C45.0613 57.4506 42.7902 54.3107 38.6672 51.9306C34.4843 49.5151 28.9153 48.1807 22.9841 48.1752H22.9432H22.9405Z"
                fill="#1D3160"
            />
            <path
                d="M22.9405 79.8978C17.0965 79.8978 11.2524 78.6152 6.80536 76.0471C2.41823 73.5118 0 70.1214 0 66.4968V60.7698C0 60.5438 0.182457 60.3613 0.408485 60.3613C0.634513 60.3613 0.81697 60.5438 0.81697 60.7698V66.4968C0.81697 69.8164 3.08814 72.9563 7.21384 75.3391C15.8846 80.3444 29.9964 80.3444 38.6672 75.3391C42.7902 72.9563 45.0613 69.8137 45.0613 66.4968V60.7698C45.0613 60.5438 45.2438 60.3613 45.4698 60.3613C45.6958 60.3613 45.8783 60.5438 45.8783 60.7698V66.4968C45.8783 70.1187 43.4628 73.5091 39.0757 76.0471C34.6259 78.6152 28.7846 79.8978 22.9405 79.8978Z"
                fill="#1D3160"
            />
            <path
                d="M22.8772 60.9501C22.6512 60.9501 22.4688 60.7676 22.4688 60.5416V24.6712C22.4688 24.4452 22.6512 24.2627 22.8772 24.2627C23.1033 24.2627 23.2857 24.4452 23.2857 24.6712V60.5416C23.2857 60.7676 23.1033 60.9501 22.8772 60.9501Z"
                fill="#1D3160"
            />
            <path
                d="M22.8511 37.0999C22.8102 37.0999 22.7721 37.0945 22.7312 37.0809L18.6246 35.8145C18.4531 35.7628 18.3359 35.6021 18.3359 35.4251V24.2517C18.3359 24.1373 18.385 24.0257 18.4721 23.9494C18.5565 23.8732 18.6709 23.8351 18.788 23.8459L22.7013 24.2463L26.6445 19.2573C26.7535 19.1211 26.9359 19.0694 27.0993 19.1239C27.2627 19.1811 27.3743 19.3363 27.3743 19.5105V30.684C27.3743 30.7657 27.3498 30.8474 27.3035 30.9155L23.2432 36.8303C23.1833 36.9965 23.028 37.1027 22.8592 37.1027L22.8511 37.0999ZM19.1502 35.1229L22.6604 36.2067L26.5492 30.556V20.6843L23.1969 24.9271C23.1097 25.036 22.9709 25.0932 22.8347 25.0796L19.1502 24.7038V35.1256V35.1229Z"
                fill="#1D3160"
            />
            <path
                d="M52.2547 75.8197L52.6615 75.5848L53.0684 75.8197L52.6615 76.0545L52.2547 75.8197Z"
                fill="#969A9E"
            />
            <path
                d="M53.0684 77.225L52.6615 77.4598L52.2547 77.225L52.6615 76.9901L53.0684 77.225Z"
                fill="#969A9E"
            />
            <path
                d="M52.0697 76.7568L51.7424 76.5682C51.7591 76.5275 51.7794 76.4868 51.7979 76.4443L52.0697 76.2871L52.4766 76.522L52.0697 76.7568Z"
                fill="#969A9E"
            />
            <path
                d="M52.6615 78.3955L53.0684 78.6304L52.6615 78.8652L52.4027 78.7154C52.3638 78.6803 52.3268 78.6451 52.2899 78.61L52.6615 78.3955Z"
                fill="#969A9E"
            />
            <path
                d="M52.4766 77.9272L52.0697 78.1621L51.8294 78.0234C51.8016 77.9753 51.7757 77.9254 51.7535 77.8755L52.0697 77.6924L52.4766 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M53.8432 76.0545L53.4364 75.8197L53.8432 75.5848L54.25 75.8197L53.8432 76.0545Z"
                fill="#969A9E"
            />
            <path
                d="M53.098 75.2633C53.2478 75.193 53.405 75.1265 53.5695 75.0654L53.6602 75.1172L53.2533 75.3521L53.0999 75.2633H53.098Z"
                fill="#969A9E"
            />
            <path
                d="M54.25 77.225L53.8432 77.4598L53.4364 77.225L53.8432 76.9901L54.25 77.225Z"
                fill="#969A9E"
            />
            <path
                d="M53.6602 76.522L53.2533 76.7568L52.8465 76.522L53.2533 76.2871L53.6602 76.522Z"
                fill="#969A9E"
            />
            <path
                d="M54.25 78.6304L53.8432 78.8652L53.4364 78.6304L53.8432 78.3955L54.25 78.6304Z"
                fill="#969A9E"
            />
            <path
                d="M53.6602 77.9272L53.2533 78.1621L52.8465 77.9272L53.2533 77.6924L53.6602 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M53.2533 79.0986L53.6602 79.3335L53.4272 79.4685C53.2959 79.3871 53.1683 79.3039 53.0444 79.2188L53.2533 79.0986Z"
                fill="#969A9E"
            />
            <path
                d="M55.0268 76.0545L54.62 75.8197L55.0268 75.5848L55.4336 75.8197L55.0268 76.0545Z"
                fill="#969A9E"
            />
            <path
                d="M54.0301 75.1167L54.4369 74.8818L54.8438 75.1167L54.4369 75.3515L54.0301 75.1167Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 77.225L55.0268 77.4598L54.62 77.225L55.0268 76.9901L55.4336 77.225Z"
                fill="#969A9E"
            />
            <path
                d="M54.8438 76.522L54.4369 76.7568L54.0301 76.522L54.4369 76.2871L54.8438 76.522Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 78.6304L55.0268 78.8652L54.62 78.6304L55.0268 78.3955L55.4336 78.6304Z"
                fill="#969A9E"
            />
            <path
                d="M54.8438 77.9272L54.4369 78.1621L54.0301 77.9272L54.4369 77.6924L54.8438 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 80.0357L55.0268 80.2706L54.62 80.0357L55.0268 79.8009L55.4336 80.0357Z"
                fill="#969A9E"
            />
            <path
                d="M54.8438 79.3335L54.4369 79.5683L54.0301 79.3335L54.4369 79.0986L54.8438 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 81.441L55.0268 81.6759L54.8992 81.6019C54.9805 81.4891 55.073 81.3856 55.1747 81.2913L55.4317 81.4392L55.4336 81.441Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 82.8462L55.0268 83.081L54.62 82.8462L55.0268 82.6113L55.4336 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M54.6531 82.0342L54.8398 82.1433L54.5495 82.3097C54.5773 82.2154 54.6124 82.1248 54.6512 82.0342H54.6531Z"
                fill="#969A9E"
            />
            <path
                d="M55.4336 84.2514L55.0268 84.4863L55.0138 84.4789C54.914 84.3883 54.8289 84.2847 54.7549 84.1738L55.0249 84.0166L55.4317 84.2514H55.4336Z"
                fill="#969A9E"
            />
            <path
                d="M54.459 83.3273L54.8418 83.5492L54.5441 83.7211C54.5034 83.5936 54.4757 83.4623 54.459 83.3273Z"
                fill="#969A9E"
            />
            <path
                d="M56.2104 76.0546L55.8036 75.8198L56.2104 75.585L56.6172 75.8198L56.2104 76.0546Z"
                fill="#969A9E"
            />
            <path
                d="M55.2137 75.1167L55.6205 74.8818L56.0273 75.1167L55.6205 75.3515L55.2137 75.1167Z"
                fill="#969A9E"
            />
            <path
                d="M56.6172 77.2251L56.2104 77.4599L55.8036 77.2251L56.2104 76.9902L56.6172 77.2251Z"
                fill="#969A9E"
            />
            <path
                d="M56.0273 76.522L55.6205 76.7568L55.2137 76.522L55.6205 76.2871L56.0273 76.522Z"
                fill="#969A9E"
            />
            <path
                d="M56.6172 78.6304L56.2104 78.8652L55.8036 78.6304L56.2104 78.3955L56.6172 78.6304Z"
                fill="#969A9E"
            />
            <path
                d="M56.0273 77.9272L55.6205 78.1621L55.2137 77.9272L55.6205 77.6924L56.0273 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M56.6172 80.0356L56.2104 80.2705L55.8036 80.0356L56.2104 79.8008L56.6172 80.0356Z"
                fill="#969A9E"
            />
            <path
                d="M56.0273 79.3335L55.6205 79.5683L55.2137 79.3335L55.6205 79.0986L56.0273 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M56.6172 81.4409L56.2104 81.6757L55.8036 81.4409L56.2104 81.2061L56.6172 81.4409Z"
                fill="#969A9E"
            />
            <path
                d="M56.0273 80.7388L55.8461 80.8442C55.7259 80.7646 55.5928 80.6833 55.4523 80.6019L55.6205 80.5039L56.0273 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M55.8036 82.8462L56.2104 82.6113L56.6172 82.8462L56.2104 83.081L55.8036 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M56.0273 82.144L55.6205 82.3789L55.2137 82.144L55.6205 81.9092L56.0273 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M55.8036 84.2514L56.2104 84.0166L56.6172 84.2514L56.2104 84.4863L55.8036 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M55.6186 83.3143L56.0254 83.5492L55.6186 83.784L55.2118 83.5492L55.6186 83.3143Z"
                fill="#969A9E"
            />
            <path
                d="M55.618 84.7197L55.9102 84.888C55.7641 84.8677 55.6291 84.8325 55.507 84.7844L55.6198 84.7197H55.618Z"
                fill="#969A9E"
            />
            <path
                d="M56.9872 75.8197L57.394 75.5848L57.8008 75.8197L57.394 76.0545L56.9872 75.8197Z"
                fill="#969A9E"
            />
            <path
                d="M56.3954 75.1167L56.8022 74.8818L57.209 75.1167L56.8022 75.3515L56.3954 75.1167Z"
                fill="#969A9E"
            />
            <path
                d="M57.8008 77.225L57.394 77.4598L56.9872 77.225L57.394 76.9901L57.8008 77.225Z"
                fill="#969A9E"
            />
            <path
                d="M57.209 76.522L56.8022 76.7568L56.3954 76.522L56.8022 76.2871L57.209 76.522Z"
                fill="#969A9E"
            />
            <path
                d="M57.8008 78.6304L57.394 78.8652L56.9872 78.6304L57.394 78.3955L57.8008 78.6304Z"
                fill="#969A9E"
            />
            <path
                d="M57.209 77.9272L56.8022 78.1621L56.3954 77.9272L56.8022 77.6924L57.209 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M57.8008 80.0357L57.394 80.2706L56.9872 80.0357L57.394 79.8009L57.8008 80.0357Z"
                fill="#969A9E"
            />
            <path
                d="M57.209 79.3335L56.8022 79.5683L56.3954 79.3335L56.8022 79.0986L57.209 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M57.8008 81.441L57.394 81.6759L56.9872 81.441L57.394 81.2062L57.8008 81.441Z"
                fill="#969A9E"
            />
            <path
                d="M57.209 80.7388L56.8022 80.9736L56.3954 80.7388L56.8022 80.5039L57.209 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M56.9872 82.8462L57.394 82.6113L57.8008 82.8462L57.394 83.081L56.9872 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M57.209 82.144L56.8022 82.3789L56.3954 82.144L56.8022 81.9092L57.209 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M56.9872 84.2514L57.394 84.0166L57.8008 84.2514L57.394 84.4863L56.9872 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M56.3973 83.5493L56.8041 83.3145L57.2109 83.5493L56.8041 83.7841L56.3973 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M56.8037 84.7196L57.0293 84.849C56.8573 84.8583 56.6835 84.8731 56.5097 84.8897L56.8037 84.7196Z"
                fill="#969A9E"
            />
            <path
                d="M58.1707 77.2251L58.5776 76.9902L58.9844 77.2251L58.5776 77.4599L58.1707 77.2251Z"
                fill="#969A9E"
            />
            <path
                d="M57.5809 76.522L57.9877 76.2871L58.3945 76.522L57.9877 76.7568L57.5809 76.522Z"
                fill="#969A9E"
            />
            <path
                d="M58.9844 78.6304L58.5776 78.8652L58.1707 78.6304L58.5776 78.3955L58.9844 78.6304Z"
                fill="#969A9E"
            />
            <path
                d="M58.3945 77.9272L57.9877 78.1621L57.5809 77.9272L57.9877 77.6924L58.3945 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M58.9844 80.0356L58.5776 80.2705L58.1707 80.0356L58.5776 79.8008L58.9844 80.0356Z"
                fill="#969A9E"
            />
            <path
                d="M58.3945 79.3335L57.9877 79.5683L57.5809 79.3335L57.9877 79.0986L58.3945 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M58.9844 81.4409L58.5776 81.6757L58.1707 81.4409L58.5776 81.2061L58.9844 81.4409Z"
                fill="#969A9E"
            />
            <path
                d="M58.3945 80.7388L57.9877 80.9736L57.5809 80.7388L57.9877 80.5039L58.3945 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M58.9844 82.8462L58.5776 83.081L58.1707 82.8462L58.5776 82.6113L58.9844 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M58.3945 82.144L57.9877 82.3789L57.5809 82.144L57.9877 81.9092L58.3945 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M58.5776 84.0166L58.9844 84.2514L58.5776 84.4863L58.1707 84.2514L58.5776 84.0166Z"
                fill="#969A9E"
            />
            <path
                d="M57.9877 83.3145L58.3945 83.5493L57.9877 83.7841L57.5809 83.5493L57.9877 83.3145Z"
                fill="#969A9E"
            />
            <path
                d="M58.1992 84.8417C58.0642 84.8361 57.9274 84.8324 57.7924 84.8306L57.9866 84.7178L58.1992 84.8417Z"
                fill="#969A9E"
            />
            <path
                d="M59.7612 78.8652L59.3543 78.6304L59.7612 78.3955L60.168 78.6304L59.7612 78.8652Z"
                fill="#969A9E"
            />
            <path
                d="M58.7625 77.9272L59.1694 77.6924L59.5762 77.9272L59.1694 78.1621L58.7625 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M60.168 80.0356L59.7612 80.2705L59.3543 80.0356L59.7612 79.8008L60.168 80.0356Z"
                fill="#969A9E"
            />
            <path
                d="M59.5762 79.3335L59.1694 79.5683L58.7625 79.3335L59.1694 79.0986L59.5762 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M60.168 81.4409L59.7612 81.6757L59.3543 81.4409L59.7612 81.2061L60.168 81.4409Z"
                fill="#969A9E"
            />
            <path
                d="M59.5762 80.7388L59.1694 80.9736L58.7625 80.7388L59.1694 80.5039L59.5762 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M60.168 82.8462L59.7612 83.081L59.3543 82.8462L59.7612 82.6113L60.168 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M59.5762 82.144L59.1694 82.3789L58.7625 82.144L59.1694 81.9092L59.5762 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M60.168 84.2514L59.7612 84.4863L59.3543 84.2514L59.7612 84.0166L60.168 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M59.5781 83.5493L59.1713 83.7841L58.7645 83.5493L59.1713 83.3145L59.5781 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M58.868 84.8954L59.1713 84.7197L59.5781 84.9546L59.5226 84.986C59.3044 84.949 59.0862 84.9176 58.868 84.8935V84.8954Z"
                fill="#969A9E"
            />
            <path
                d="M60.9447 78.8652L60.5379 78.6304L60.9447 78.3955L61.3516 78.6304L60.9447 78.8652Z"
                fill="#969A9E"
            />
            <path
                d="M59.946 77.9277L60.2179 77.7705C60.3214 77.8315 60.4231 77.8944 60.5267 77.9517C60.571 77.9221 60.6173 77.8981 60.6617 77.8722L60.7578 77.9277L60.351 78.1625L59.9442 77.9277H59.946Z"
                fill="#969A9E"
            />
            <path
                d="M61.3516 80.0356L60.9447 80.2705L60.5379 80.0356L60.9447 79.8008L61.3516 80.0356Z"
                fill="#969A9E"
            />
            <path
                d="M60.7598 79.3335L60.353 79.5683L59.9461 79.3335L60.353 79.0986L60.7598 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M61.3516 81.4409L60.9447 81.6757L60.5379 81.4409L60.9447 81.2061L61.3516 81.4409Z"
                fill="#969A9E"
            />
            <path
                d="M60.7598 80.7388L60.353 80.9736L59.9461 80.7388L60.353 80.5039L60.7598 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M61.3516 82.8462L60.9447 83.081L60.5379 82.8462L60.9447 82.6113L61.3516 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M60.7598 82.144L60.353 82.3789L59.9461 82.144L60.353 81.9092L60.7598 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M61.3516 84.2514L60.9447 84.4863L60.5379 84.2514L60.9447 84.0166L61.3516 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M60.7598 83.5493L60.353 83.7841L59.9461 83.5493L60.353 83.3145L60.7598 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M59.9461 84.9546L60.353 84.7197L60.7598 84.9546L60.3807 85.1728C60.3456 85.1635 60.3104 85.1524 60.2753 85.1432L59.9461 84.9527V84.9546Z"
                fill="#969A9E"
            />
            <path
                d="M62.1285 78.8652L61.7216 78.6304L62.1285 78.3955L62.5353 78.6304L62.1285 78.8652Z"
                fill="#969A9E"
            />
            <path
                d="M61.1297 77.9272L61.5365 77.6924L61.9434 77.9272L61.5365 78.1621L61.1297 77.9272Z"
                fill="#969A9E"
            />
            <path
                d="M62.5353 80.0357L62.1285 80.2706L61.7216 80.0357L62.1285 79.8009L62.5353 80.0357Z"
                fill="#969A9E"
            />
            <path
                d="M61.9434 79.3335L61.5365 79.5683L61.1297 79.3335L61.5365 79.0986L61.9434 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M62.5353 81.441L62.1285 81.6759L61.7216 81.441L62.1285 81.2062L62.5353 81.441Z"
                fill="#969A9E"
            />
            <path
                d="M61.9434 80.7388L61.5365 80.9736L61.1297 80.7388L61.5365 80.5039L61.9434 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M62.5352 82.8462L62.1283 83.081L61.7215 82.8462L62.1283 82.6113L62.5352 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M61.9434 82.144L61.5365 82.3789L61.1297 82.144L61.5365 81.9092L61.9434 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M62.5352 84.2514L62.1283 84.4863L61.7215 84.2514L62.1283 84.0166L62.5352 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M61.9436 83.5492L61.5368 83.784L61.13 83.5492L61.5368 83.3143L61.9436 83.5492Z"
                fill="#969A9E"
            />
            <path
                d="M62.5352 85.6567L62.1283 85.8916L61.7215 85.6567L62.1283 85.4219L62.5352 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M61.9436 84.9544L61.5368 85.1893L61.13 84.9544L61.5368 84.7196L61.9436 84.9544Z"
                fill="#969A9E"
            />
            <path
                d="M62.9045 78.6307L63.18 78.4717C63.2521 78.5826 63.3131 78.7047 63.3594 78.8378L63.3113 78.8655L62.9045 78.6307Z"
                fill="#969A9E"
            />
            <path
                d="M62.313 77.9277L62.4628 77.8408C62.5997 77.9074 62.7273 77.9925 62.8438 78.0905L62.7199 78.1626L62.313 77.9277Z"
                fill="#969A9E"
            />
            <path
                d="M62.9051 80.0357L63.3119 79.8009L63.7188 80.0357L63.3119 80.2706L62.9051 80.0357Z"
                fill="#969A9E"
            />
            <path
                d="M63.127 79.3335L62.7201 79.5683L62.3133 79.3335L62.7201 79.0986L63.127 79.3335Z"
                fill="#969A9E"
            />
            <path
                d="M63.7188 81.441L63.3119 81.6759L62.9051 81.441L63.3119 81.2062L63.7188 81.441Z"
                fill="#969A9E"
            />
            <path
                d="M62.7201 80.9736L62.3133 80.7388L62.7201 80.5039L63.127 80.7388L62.7201 80.9736Z"
                fill="#969A9E"
            />
            <path
                d="M63.7188 82.8462L63.3119 83.081L62.9051 82.8462L63.3119 82.6113L63.7188 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M63.127 82.144L62.7201 82.3789L62.3133 82.144L62.7201 81.9092L63.127 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M63.7188 84.2514L63.3119 84.4863L62.9051 84.2514L63.3119 84.0166L63.7188 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M63.127 83.5492L62.7201 83.784L62.3133 83.5492L62.7201 83.3143L63.127 83.5492Z"
                fill="#969A9E"
            />
            <path
                d="M63.7188 85.6567L63.3119 85.8916L62.9051 85.6567L63.3119 85.4219L63.7188 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M63.127 84.9544L62.7201 85.1893L62.3133 84.9544L62.7201 84.7196L63.127 84.9544Z"
                fill="#969A9E"
            />
            <path
                d="M63.127 86.3598L63.0363 86.4116C62.8995 86.334 62.7608 86.2581 62.6221 86.1805L62.7201 86.125L63.127 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M63.526 79.3163C63.5335 79.3313 63.5391 79.3482 63.5466 79.3632L63.496 79.3332L63.526 79.3163Z"
                fill="#969A9E"
            />
            <path
                d="M64.4954 81.6759L64.0886 81.441L64.4954 81.2062L64.9022 81.441L64.4954 81.6759Z"
                fill="#969A9E"
            />
            <path
                d="M63.4969 80.7388L63.9037 80.5039L64.3105 80.7388L63.9037 80.9736L63.4969 80.7388Z"
                fill="#969A9E"
            />
            <path
                d="M64.9023 82.8462L64.4955 83.081L64.0887 82.8462L64.4955 82.6113L64.9023 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M64.3105 82.144L63.9037 82.3789L63.4969 82.144L63.9037 81.9092L64.3105 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M64.9023 84.2514L64.4955 84.4863L64.0887 84.2514L64.4955 84.0166L64.9023 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M64.3104 83.5492L63.9036 83.784L63.4968 83.5492L63.9036 83.3143L64.3104 83.5492Z"
                fill="#969A9E"
            />
            <path
                d="M64.9023 85.6567L64.4955 85.8916L64.0887 85.6567L64.4955 85.4219L64.9023 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M64.3104 84.9544L63.9036 85.1893L63.4968 84.9544L63.9036 84.7196L64.3104 84.9544Z"
                fill="#969A9E"
            />
            <path
                d="M64.9023 87.063L64.5732 87.2534C64.4308 87.1813 64.2884 87.1074 64.146 87.0297L64.4955 86.8281L64.9023 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M64.3104 86.3598L63.9036 86.5947L63.4968 86.3598L63.9036 86.125L64.3104 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M65.2713 81.4416L65.6541 81.2197C65.7614 81.3177 65.8779 81.4065 65.998 81.4897L65.6763 81.6746L65.2695 81.4398L65.2713 81.4416Z"
                fill="#969A9E"
            />
            <path
                d="M64.6814 80.7383L64.8237 80.657C64.9495 80.7457 65.0752 80.829 65.1973 80.9103L65.0882 80.9732L64.6814 80.7383Z"
                fill="#969A9E"
            />
            <path
                d="M66.0859 82.8462L65.6791 83.081L65.2723 82.8462L65.6791 82.6113L66.0859 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M65.4941 82.144L65.0873 82.3789L64.6805 82.144L65.0873 81.9092L65.4941 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M66.0859 84.2514L65.6791 84.4863L65.2723 84.2514L65.6791 84.0166L66.0859 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M65.4939 83.5492L65.0871 83.784L64.6803 83.5492L65.0871 83.3143L65.4939 83.5492Z"
                fill="#969A9E"
            />
            <path
                d="M66.0859 85.6567L65.6791 85.8916L65.2723 85.6567L65.6791 85.4219L66.0859 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M65.4939 84.9544L65.0871 85.1893L64.6803 84.9544L65.0871 84.7196L65.4939 84.9544Z"
                fill="#969A9E"
            />
            <path
                d="M66.0859 87.063L65.6791 87.2978L65.2723 87.063L65.6791 86.8281L66.0859 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M65.4939 86.3598L65.0871 86.5947L64.6803 86.3598L65.0871 86.125L65.4939 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M66.8627 83.081L66.4559 82.8462L66.8627 82.6113L67.2695 82.8462L66.8627 83.081Z"
                fill="#969A9E"
            />
            <path
                d="M65.8641 82.144L66.2709 81.9092L66.6777 82.144L66.2709 82.3789L65.8641 82.144Z"
                fill="#969A9E"
            />
            <path
                d="M67.2695 84.2514L66.8627 84.4863L66.4559 84.2514L66.8627 84.0166L67.2695 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M66.6777 83.5493L66.2709 83.7841L65.8641 83.5493L66.2709 83.3145L66.6777 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M67.2695 85.6567L66.8627 85.8916L66.4559 85.6567L66.8627 85.4219L67.2695 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M66.6777 84.9546L66.2709 85.1894L65.8641 84.9546L66.2709 84.7197L66.6777 84.9546Z"
                fill="#969A9E"
            />
            <path
                d="M67.2695 87.063L66.8627 87.2978L66.4559 87.063L66.8627 86.8281L67.2695 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M66.6777 86.3598L66.2709 86.5947L65.8641 86.3598L66.2709 86.125L66.6777 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M66.6777 87.765L66.3375 87.961C66.245 87.9351 66.1526 87.9074 66.0601 87.8778L65.8623 87.7632L66.2691 87.5283L66.6759 87.7632L66.6777 87.765Z"
                fill="#969A9E"
            />
            <path
                d="M67.6395 82.8462L68.0463 82.6113L68.4531 82.8462L68.0463 83.081L67.6395 82.8462Z"
                fill="#969A9E"
            />
            <path
                d="M67.0466 82.1437L67.1335 82.0938C67.287 82.1622 67.4423 82.2287 67.5977 82.2953L67.4534 82.3785L67.0466 82.1437Z"
                fill="#969A9E"
            />
            <path
                d="M68.4531 84.2514L68.0463 84.4863L67.6395 84.2514L68.0463 84.0166L68.4531 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M67.8594 83.5493L67.4526 83.7841L67.0457 83.5493L67.4526 83.3145L67.8594 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M68.4531 85.6567L68.0463 85.8916L67.6395 85.6567L68.0463 85.4219L68.4531 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M67.8594 84.9546L67.4526 85.1894L67.0457 84.9546L67.4526 84.7197L67.8594 84.9546Z"
                fill="#969A9E"
            />
            <path
                d="M68.4531 87.063L68.0463 87.2978L67.6395 87.063L68.0463 86.8281L68.4531 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M67.8594 86.3598L67.4526 86.5947L67.0457 86.3598L67.4526 86.125L67.8594 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M67.8594 87.7651L67.4526 88L67.0457 87.7651L67.4526 87.5303L67.8594 87.7651Z"
                fill="#969A9E"
            />
            <path
                d="M68.8231 84.2514L69.2299 84.0166L69.6367 84.2514L69.2299 84.4863L68.8231 84.2514Z"
                fill="#969A9E"
            />
            <path
                d="M68.2313 83.5493L68.6381 83.3145L69.0449 83.5493L68.6381 83.7841L68.2313 83.5493Z"
                fill="#969A9E"
            />
            <path
                d="M69.6367 85.6567L69.2299 85.8916L68.8231 85.6567L69.2299 85.4219L69.6367 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M69.0449 84.9546L68.6381 85.1894L68.2313 84.9546L68.6381 84.7197L69.0449 84.9546Z"
                fill="#969A9E"
            />
            <path
                d="M69.6367 87.063L69.2299 87.2978L68.8231 87.063L69.2299 86.8281L69.6367 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M69.0449 86.3598L68.6381 86.5947L68.2313 86.3598L68.6381 86.125L69.0449 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M69.0449 87.7651L68.6381 88L68.2313 87.7651L68.6381 87.5303L69.0449 87.7651Z"
                fill="#969A9E"
            />
            <path
                d="M70.0066 84.2514C70.2026 84.2107 70.3967 84.1663 70.5909 84.1201L70.8184 84.2514L70.4115 84.4863L70.0047 84.2514H70.0066Z"
                fill="#969A9E"
            />
            <path
                d="M69.4995 83.4989C69.5457 83.5544 69.5882 83.6117 69.6289 83.6709L69.4144 83.547L69.4995 83.4971V83.4989Z"
                fill="#969A9E"
            />
            <path
                d="M70.8203 85.6567L70.4135 85.8916L70.0067 85.6567L70.4135 85.4219L70.8203 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M69.8197 85.1894L69.4129 84.9546L69.8197 84.7197L70.2266 84.9546L69.8197 85.1894Z"
                fill="#969A9E"
            />
            <path
                d="M70.8203 87.063L70.4135 87.2978L70.0067 87.063L70.4135 86.8281L70.8203 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M70.2266 86.3598L69.8197 86.5947L69.4129 86.3598L69.8197 86.125L70.2266 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M70.2266 87.765L70.1489 87.8094C70.0675 87.8279 69.988 87.8464 69.9085 87.8649C69.8364 87.8871 69.7661 87.9074 69.694 87.9259L69.4129 87.7632L69.8197 87.5283L70.2266 87.7632V87.765Z"
                fill="#969A9E"
            />
            <path
                d="M71.5971 84.4863L71.1903 84.2514L71.5971 84.0166L72.0039 84.2514L71.5971 84.4863Z"
                fill="#969A9E"
            />
            <path
                d="M72.0039 85.6567L71.5971 85.8916L71.1903 85.6567L71.5971 85.4219L72.0039 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M71.0053 85.1893L70.5985 84.9544L71.0053 84.7196L71.4121 84.9544L71.0053 85.1893Z"
                fill="#969A9E"
            />
            <path
                d="M72.0039 87.063L71.5971 87.2978L71.1903 87.063L71.5971 86.8281L72.0039 87.063Z"
                fill="#969A9E"
            />
            <path
                d="M71.4121 86.3598L71.0053 86.5947L70.5985 86.3598L71.0053 86.125L71.4121 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M71.0803 87.5748C70.986 87.6007 70.8898 87.6266 70.7955 87.6524L71.0063 87.5304L71.0822 87.5748H71.0803Z"
                fill="#969A9E"
            />
            <path
                d="M72.7807 84.0166L73.1875 84.2514L72.7807 84.4863L72.3739 84.2514L72.7807 84.0166Z"
                fill="#969A9E"
            />
            <path
                d="M72.1509 83.7617C72.1879 83.7543 72.223 83.7488 72.26 83.7414L72.1879 83.7821L72.1509 83.7599V83.7617Z"
                fill="#969A9E"
            />
            <path
                d="M73.1875 85.6567L72.7807 85.8916L72.3739 85.6567L72.7807 85.4219L73.1875 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M72.5957 84.9544L72.1889 85.1893L71.7821 84.9544L72.1889 84.7196L72.5957 84.9544Z"
                fill="#969A9E"
            />
            <path
                d="M72.9023 86.8984C72.7544 86.9724 72.6009 87.0408 72.4474 87.1055L72.3735 87.063L72.7803 86.8281L72.9023 86.8984Z"
                fill="#969A9E"
            />
            <path
                d="M72.5957 86.3598L72.1889 86.5947L71.7821 86.3598L72.1889 86.125L72.5957 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M73.9643 84.4863L73.5575 84.2514L73.9643 84.0166L74.3711 84.2514L73.9643 84.4863Z"
                fill="#969A9E"
            />
            <path
                d="M73.6855 83.6031L73.3712 83.7843L73.092 83.6234C73.2491 83.6086 73.4082 83.5994 73.5672 83.5957C73.6079 83.5957 73.6467 83.5994 73.6855 83.6031Z"
                fill="#969A9E"
            />
            <path
                d="M74.3711 85.6567L73.9643 85.8916L73.5575 85.6567L73.9643 85.4219L74.3711 85.6567Z"
                fill="#969A9E"
            />
            <path
                d="M73.3705 84.7196L73.7773 84.9544L73.3705 85.1893L72.9637 84.9544L73.3705 84.7196Z"
                fill="#969A9E"
            />
            <path
                d="M73.7773 86.3598L73.3705 86.5947L72.9637 86.3598L73.3705 86.125L73.7773 86.3598Z"
                fill="#969A9E"
            />
            <path
                d="M74.8203 84.8731C74.8203 84.9286 74.8185 84.984 74.8129 85.0395L74.554 85.1893L74.1472 84.9544L74.554 84.7196L74.8203 84.8731Z"
                fill="#969A9E"
            />
            <path
                d="M71.9041 72.9512L72.0039 73.0085L71.9004 73.0677C71.9004 73.0288 71.9004 72.99 71.9041 72.9512Z"
                fill="#969A9E"
            />
            <path
                d="M72.6382 71.7569C72.7676 71.6811 72.9119 71.6182 73.0783 71.572C73.0912 71.5701 73.1042 71.5664 73.1171 71.5646L73.1855 71.6034L72.7787 71.8383L72.6382 71.7569Z"
                fill="#969A9E"
            />
            <path
                d="M73.1875 73.0093L72.7807 73.2441L72.3739 73.0093L72.7807 72.7744L73.1875 73.0093Z"
                fill="#969A9E"
            />
            <path
                d="M72.0242 72.4471C72.076 72.3233 72.1463 72.2068 72.2332 72.0977L72.5938 72.3048L72.1869 72.5396L72.0242 72.4453V72.4471Z"
                fill="#969A9E"
            />
            <path
                d="M73.1875 74.4145L72.7844 74.6475C72.6531 74.5754 72.5347 74.4848 72.4312 74.3812L72.7807 74.1797L73.1875 74.4145Z"
                fill="#969A9E"
            />
            <path
                d="M72.1889 73.4767L72.5957 73.7115L72.1889 73.9464L72.0835 73.8854C72.0391 73.7929 72.004 73.6986 71.9762 73.6006L72.1907 73.4767H72.1889Z"
                fill="#969A9E"
            />
            <path
                d="M73.5575 71.604L73.9643 71.3691L74.3711 71.604L73.9643 71.8388L73.5575 71.604Z"
                fill="#969A9E"
            />
            <path
                d="M74.3711 73.0093L73.9643 73.2441L73.5575 73.0093L73.9643 72.7744L74.3711 73.0093Z"
                fill="#969A9E"
            />
            <path
                d="M73.3705 72.5411L72.9637 72.3063L73.3705 72.0714L73.7773 72.3063L73.3705 72.5411Z"
                fill="#969A9E"
            />
            <path
                d="M74.3711 74.4145L73.9643 74.6494L73.5575 74.4145L73.9643 74.1797L74.3711 74.4145Z"
                fill="#969A9E"
            />
            <path
                d="M73.7773 73.7115L73.3705 73.9464L72.9637 73.7115L73.3705 73.4767L73.7773 73.7115Z"
                fill="#969A9E"
            />
            <path
                d="M75.1479 71.8388L74.7411 71.604L75.1479 71.3691L75.5547 71.604L75.1479 71.8388Z"
                fill="#969A9E"
            />
            <path
                d="M75.5547 73.0093L75.1479 73.2441L74.7411 73.0093L75.1479 72.7744L75.5547 73.0093Z"
                fill="#969A9E"
            />
            <path
                d="M74.5541 72.541L74.1473 72.3061L74.5541 72.0713L74.9609 72.3061L74.5541 72.541Z"
                fill="#969A9E"
            />
            <path
                d="M75.4727 74.3665C75.2988 74.433 75.1287 74.4922 74.966 74.544L74.7404 74.4145L75.1472 74.1797L75.4727 74.3665Z"
                fill="#969A9E"
            />
            <path
                d="M74.9609 73.7114L74.5541 73.9463L74.1473 73.7114L74.5541 73.4766L74.9609 73.7114Z"
                fill="#969A9E"
            />
            <path
                d="M76.3295 71.8388L75.9227 71.604L76.3295 71.3691L76.7363 71.604L76.3295 71.8388Z"
                fill="#969A9E"
            />
            <path
                d="M75.4049 70.943C75.5916 70.8893 75.7765 70.8413 75.9633 70.7969L76.1445 70.9023L75.7377 71.1371L75.4049 70.9448V70.943Z"
                fill="#969A9E"
            />
            <path
                d="M76.7363 73.0093L76.3295 73.2441L75.9227 73.0093L76.3295 72.7744L76.7363 73.0093Z"
                fill="#969A9E"
            />
            <path
                d="M76.1445 72.3061L75.7377 72.541L75.3309 72.3061L75.7377 72.0713L76.1445 72.3061Z"
                fill="#969A9E"
            />
            <path
                d="M76.1445 73.7114L75.7377 73.9463L75.3309 73.7114L75.7377 73.4766L76.1445 73.7114Z"
                fill="#969A9E"
            />
            <path
                d="M77.5131 71.8388L77.1063 71.604L77.5131 71.3691L77.9199 71.604L77.5131 71.8388Z"
                fill="#969A9E"
            />
            <path
                d="M76.5145 70.9009L76.9213 70.666L77.3281 70.9009L76.9213 71.1357L76.5145 70.9009Z"
                fill="#969A9E"
            />
            <path
                d="M77.9199 73.0093L77.5131 73.2441L77.1063 73.0093L77.5131 72.7744L77.9199 73.0093Z"
                fill="#969A9E"
            />
            <path
                d="M77.3281 72.3061L76.9213 72.541L76.5145 72.3061L76.9213 72.0713L77.3281 72.3061Z"
                fill="#969A9E"
            />
            <path
                d="M77.1133 73.5876C76.9783 73.6671 76.8396 73.743 76.6972 73.8169L76.5142 73.7115L76.921 73.4767L77.1133 73.5876Z"
                fill="#969A9E"
            />
            <path
                d="M78.2902 71.6032L78.6619 71.3887C78.7377 71.5218 78.771 71.6586 78.7673 71.7973L78.6952 71.838L78.2884 71.6032H78.2902Z"
                fill="#969A9E"
            />
            <path
                d="M77.6969 70.9008L77.9244 70.7695C78.0779 70.849 78.2073 70.9286 78.3164 71.0118L78.1038 71.1357L77.6969 70.9008Z"
                fill="#969A9E"
            />
            <path
                d="M78.512 72.3063L78.1051 72.5411L77.6983 72.3063L78.1051 72.0714L78.512 72.3063Z"
                fill="#969A9E"
            />
            <path
                d="M55.6627 62.89L59.2926 64.9832L61.9609 63.4447L58.3329 61.3496L55.6627 62.89Z"
                fill="#5F646B"
            />
            <path
                d="M59.293 77.6275V64.9829L55.6631 62.8896V75.5342L59.293 77.6275Z"
                fill="#373A3A"
            />
            <path
                d="M65.7066 58.3058C65.5309 58.4075 65.4089 58.6109 65.3793 58.9234C65.1999 60.8003 64.7487 62.7179 63.6762 64.0364H63.6744C63.127 64.7095 62.4169 65.2272 61.4942 65.5046C60.4698 65.8134 59.4009 65.0275 59.2955 64.9831V77.6277C60.0463 78.1141 61.1576 78.5005 62.6148 80.0834C64.0368 81.6275 65.0742 82.0306 67.3394 83.3305C67.2895 82.9885 67.3468 82.6205 67.4892 82.2303L67.7776 81.474L67.6574 81.4056C67.2044 81.143 67.0824 80.6271 67.2395 80.2147L69.9467 73.0862C70.0521 72.807 70.2888 72.574 70.6346 72.4963C71.3595 72.3354 72.1065 72.3835 72.8203 72.7959L67.6889 69.8335C68.288 66.6511 69.1238 62.2889 67.3431 59.2359C66.8716 58.4334 66.1633 58.0784 65.7362 58.2929C65.7362 58.2929 65.7362 58.2947 65.7343 58.2947C65.7269 58.3003 65.7177 58.304 65.7121 58.3077L65.7066 58.3058Z"
                fill="white"
            />
            <path
                d="M63.6738 64.0379C62.8251 64.0453 62.1039 63.5108 61.9615 63.4443L59.2932 64.9828C59.3986 65.0272 60.4674 65.8131 61.4918 65.5042C62.4145 65.2269 63.1265 64.711 63.672 64.036L63.6738 64.0379Z"
                fill="#969A9E"
            />
            <path
                d="M67.7738 81.4723L69.34 82.3765C70.5568 83.0773 72.6815 83.4472 74.0517 83.1476C75.2796 82.8776 76.0155 82.3321 76.2559 81.7404L77.1602 79.3568C76.9216 79.9541 76.1893 80.4996 74.9541 80.7714C73.5839 81.0728 71.4573 80.703 70.2443 80.0003L68.5578 79.0258C68.103 78.7632 67.9809 78.2473 68.1381 77.835L67.2357 80.2111C67.0785 80.6235 67.2006 81.1394 67.6536 81.402L67.7738 81.4704V81.4723Z"
                fill="#DAE0E5"
            />
            <path
                d="M67.4853 82.228C67.3429 82.6182 67.2837 82.988 67.3355 83.3282C67.4132 83.8497 67.7442 84.299 68.4173 84.6374C73.6263 87.2448 75.2647 84.3083 75.6013 83.4632L76.2559 81.7398C76.0155 82.3334 75.2795 82.877 74.0517 83.147C72.6814 83.4466 70.5549 83.0786 69.34 82.3759L67.7737 81.4717L67.4853 82.228Z"
                fill="#DAE0E5"
            />
            <path
                d="M68.1382 77.8342C67.9811 78.2465 68.1031 78.7625 68.558 79.025L70.2444 79.9996C71.4575 80.7004 73.584 81.0721 74.9543 80.7707C76.1895 80.4988 76.9236 79.9533 77.1603 79.356L78.0664 76.9706C77.8334 77.5735 77.0974 78.1227 75.8548 78.3945C74.4846 78.6959 72.358 78.3261 71.145 77.6234L69.4586 76.6489C69.0037 76.3881 68.8816 75.8741 69.0388 75.458L68.1364 77.8342H68.1382Z"
                fill="#DAE0E5"
            />
            <path
                d="M68.2727 56.8257C68.2727 56.8257 68.2761 56.8223 68.2812 56.8223H68.2778C68.2778 56.8223 68.2761 56.824 68.2744 56.8257H68.2727Z"
                fill="white"
            />
            <path
                d="M68.2738 56.8211H68.2776L68.2852 56.8154C68.2852 56.8154 68.2776 56.8173 68.2738 56.8211Z"
                fill="white"
            />
            <path
                d="M68.3391 56.7866C68.3391 56.7866 68.3425 56.7849 68.3477 56.7832H68.3443C68.3443 56.7832 68.3425 56.7849 68.3408 56.7866H68.3391Z"
                fill="white"
            />
            <path
                d="M68.3507 56.7777L68.3472 56.7811C68.3472 56.7811 68.3559 56.7777 68.3594 56.7725H68.3576L68.3507 56.7759V56.7777Z"
                fill="white"
            />
            <path
                d="M68.36 56.7722C68.36 56.7722 68.3654 56.7704 68.3672 56.7686H68.3636L68.3618 56.7722H68.36Z"
                fill="white"
            />
            <path
                d="M70.3511 68.2873C70.9558 65.0883 71.7842 60.739 70.0053 57.6935C69.5282 56.8743 68.7978 56.5192 68.3744 56.7652C68.3725 56.767 68.3707 56.767 68.367 56.767C68.3651 56.7689 68.3633 56.7707 68.3596 56.7707C68.3559 56.7744 68.3503 56.7781 68.3466 56.78C68.3429 56.7818 68.3392 56.7837 68.3374 56.7837L68.3244 56.7929H68.3226C68.3226 56.7929 68.3189 56.7948 68.3152 56.7966C68.3115 56.7985 68.3078 56.8022 68.3059 56.8022H68.3022C68.3022 56.8022 68.2948 56.8096 68.2893 56.8133L68.2819 56.8188C68.2819 56.8188 68.2745 56.8207 68.2727 56.8225L65.7338 58.2852C66.1609 58.0707 66.8691 58.4257 67.3407 59.2283C69.1214 62.2812 68.2874 66.6434 67.6865 69.8258L72.8179 72.7882C72.1041 72.3758 71.3571 72.3259 70.6322 72.4886C70.2864 72.5663 70.0516 72.7993 69.9443 73.0785L69.9277 73.1229C69.7982 73.5297 69.9258 74.016 70.3641 74.2675L72.0505 75.242C73.2635 75.9428 75.3901 76.3145 76.7603 76.015C77.9974 75.7431 78.7352 75.1939 78.9682 74.593L78.9719 74.5819C79.2105 73.9605 78.9072 73.2856 78.0714 72.803L70.5083 68.4371L70.3493 68.2855L70.3511 68.2873Z"
                fill="#969A9E"
            />
            <path
                d="M69.0406 75.4579C68.8834 75.8739 69.0055 76.388 69.4604 76.6487L71.1468 77.6232C72.3598 78.3241 74.4864 78.6958 75.8566 78.3943C77.0974 78.1225 77.8352 77.5733 78.0682 76.9705L78.9688 74.598C78.7358 75.1971 77.9979 75.7463 76.7608 76.02C75.3906 76.3196 73.2641 75.9479 72.051 75.2471L70.3646 74.2726C69.9263 74.0211 69.7987 73.5347 69.9282 73.1279L69.0424 75.4579H69.0406Z"
                fill="#DAE0E5"
            />
            <path
                d="M59.2924 77.9056C59.4459 77.9056 59.5698 77.7817 59.5698 77.6282V65.4626C60.0339 65.7048 60.7995 66.0044 61.5724 65.7714C62.4933 65.494 63.2737 64.9707 63.8876 64.2125C63.9838 64.0942 63.9653 63.9185 63.8469 63.8224C63.7286 63.7262 63.5529 63.7447 63.4567 63.863C62.9131 64.5324 62.2252 64.9966 61.4116 65.2407C60.6368 65.4737 59.7991 64.9651 59.5236 64.7987C59.4607 64.7617 59.4182 64.7377 59.3978 64.7285C59.3128 64.6933 59.2148 64.7026 59.1371 64.7543C59.0594 64.8061 59.0132 64.893 59.0132 64.9855V77.6301C59.0132 77.7836 59.1371 77.9074 59.2906 77.9074L59.2924 77.9056Z"
                fill="#1D3160"
            />
            <path
                d="M63.6767 64.3154C63.758 64.3154 63.8376 64.2803 63.8912 64.2137C64.8509 63.0321 65.4278 61.3105 65.6553 58.951C65.6682 58.8086 65.7126 58.6237 65.8458 58.546C65.9789 58.4702 66.0233 58.3001 65.9475 58.1669C65.8698 58.0338 65.6997 57.9894 65.5684 58.0652C65.3816 58.1725 65.1505 58.4055 65.1042 58.8974C64.8879 61.1441 64.3516 62.7677 63.4622 63.8624C63.366 63.9807 63.3845 64.1564 63.5029 64.2525C63.5546 64.2951 63.6157 64.3154 63.6785 64.3154H63.6767Z"
                fill="#1D3160"
            />
            <path
                d="M74.043 73.7796C74.1392 73.7796 74.2316 73.7296 74.2834 73.6409C74.3592 73.5077 74.3148 73.3395 74.1817 73.2618L67.9925 69.6892C68.5954 66.4902 69.3739 62.1742 67.5765 59.0954C67.0495 58.1967 66.2025 57.7436 65.6053 58.0432C65.4684 58.1116 65.413 58.278 65.4814 58.4149C65.5498 58.5517 65.7162 58.6072 65.8531 58.5388C66.1267 58.4001 66.7055 58.7107 67.0957 59.3746C68.8154 62.3203 68.0073 66.6011 67.4193 69.7262L67.4101 69.7798C67.3879 69.8963 67.4415 70.0128 67.5432 70.072L73.9025 73.7444C73.9468 73.7703 73.9931 73.7814 74.0412 73.7814L74.043 73.7796Z"
                fill="#1D3160"
            />
            <path
                d="M65.7065 58.5832C65.7583 58.5832 65.8119 58.5684 65.8581 58.5388C65.8581 58.5388 65.8618 58.537 65.8655 58.5351C65.8711 58.5314 65.8748 58.5296 65.8803 58.5259C65.8822 58.5259 65.884 58.524 65.884 58.524C65.9598 58.4741 66.0098 58.3872 66.0098 58.291C66.0098 58.1376 65.8859 58.0137 65.7324 58.0137C65.6714 58.0137 65.6159 58.034 65.5697 58.0673C65.5641 58.071 65.5586 58.0747 65.5549 58.0765C65.4273 58.1598 65.3903 58.3317 65.4754 58.4612C65.529 58.5425 65.6177 58.5869 65.7084 58.5869L65.7065 58.5832Z"
                fill="#1D3160"
            />
            <path
                d="M68.274 57.0984C68.3092 57.0984 68.3461 57.091 68.3813 57.0762C68.4867 57.0374 68.5625 56.9357 68.5625 56.8155C68.5625 56.662 68.4386 56.5381 68.2851 56.5381C68.2426 56.5381 68.1927 56.551 68.1538 56.5695C68.017 56.6361 67.9578 56.8025 68.0244 56.9394C68.0725 57.0392 68.1705 57.0965 68.274 57.0965V57.0984Z"
                fill="#1D3160"
            />
            <path
                d="M68.3377 57.0647C68.3876 57.0647 68.4375 57.0517 68.4819 57.024C68.4893 57.0203 68.4986 57.0147 68.506 57.0092C68.5226 56.9999 68.5374 56.9889 68.5503 56.9759C68.641 56.8945 68.6705 56.7596 68.6132 56.6449C68.5448 56.5081 68.3802 56.4507 68.2434 56.5192C68.2415 56.5192 68.2378 56.521 68.236 56.5229C68.2249 56.5284 68.2138 56.534 68.2046 56.5414C68.2027 56.5414 68.199 56.5451 68.1972 56.5469C68.1787 56.558 68.1583 56.5728 68.1435 56.5894C68.0344 56.6985 68.0344 56.8742 68.1435 56.9815C68.1972 57.0351 68.2693 57.0628 68.3395 57.0628L68.3377 57.0647Z"
                fill="#1D3160"
            />
            <path
                d="M68.2736 57.0987H68.2773C68.4308 57.0987 68.5547 56.9748 68.5547 56.8213C68.5547 56.6678 68.4308 56.5439 68.2773 56.5439C68.1238 56.5439 67.9981 56.6678 67.9981 56.8213C67.9981 56.9748 68.1201 57.0987 68.2736 57.0987Z"
                fill="#1D3160"
            />
            <path
                d="M68.2717 57.1043C68.3327 57.1043 68.3956 57.084 68.4455 57.0433C68.5121 56.9897 68.5527 56.9083 68.5527 56.8233C68.5527 56.6698 68.4288 56.5459 68.2754 56.5459C68.1958 56.5459 68.1256 56.5792 68.0756 56.631C68.0756 56.631 68.0738 56.6328 68.072 56.6347C67.9665 56.7456 67.9702 56.9213 68.0793 57.0267C68.133 57.0785 68.2032 57.1043 68.2717 57.1043Z"
                fill="#1D3160"
            />
            <path
                d="M65.7331 58.5669C65.7793 58.5669 65.8274 58.5558 65.8718 58.5299L68.4033 57.071C68.4957 57.0266 68.5586 56.9304 68.5586 56.8213C68.5586 56.6678 68.4347 56.5439 68.2812 56.5439C68.2313 56.5439 68.1777 56.5606 68.1333 56.5865L65.5944 58.0492C65.4612 58.125 65.4169 58.2951 65.4927 58.4282C65.5445 58.517 65.6369 58.5669 65.7331 58.5669Z"
                fill="#1D3160"
            />
            <path
                d="M68.2826 57.0984C68.3344 57.0984 68.3861 57.0836 68.4342 57.0522L68.4416 57.0485C68.5692 56.9634 68.6025 56.7915 68.5174 56.6639C68.4324 56.5363 68.2604 56.503 68.1328 56.5881C68.0052 56.6731 67.9682 56.8488 68.0533 56.9764C68.1069 57.0559 68.1938 57.1003 68.2844 57.1003L68.2826 57.0984Z"
                fill="#1D3160"
            />
            <path
                d="M68.2859 57.0949C68.3376 57.0949 68.3913 57.0802 68.4375 57.0506C68.4412 57.0506 68.443 57.0469 68.4467 57.0469C68.4578 57.0413 68.4671 57.0358 68.4782 57.0302C68.4819 57.0302 68.4837 57.0265 68.4874 57.0247C68.493 57.021 68.4985 57.0173 68.5041 57.0154C68.5299 57.0025 68.5521 56.9858 68.5725 56.9655C68.6816 56.8564 68.6816 56.6807 68.5725 56.5735C68.4948 56.494 68.3802 56.4736 68.2822 56.5088C68.2581 56.5162 68.2341 56.5291 68.2119 56.5439H68.21C68.21 56.5439 68.1952 56.555 68.1878 56.5587C68.186 56.5587 68.1823 56.5605 68.1804 56.5624C68.1712 56.5679 68.162 56.5716 68.1527 56.579C68.1527 56.579 68.1379 56.5883 68.1342 56.5901C68.0066 56.6752 67.9733 56.8472 68.0584 56.9747C68.112 57.0543 68.1989 57.0968 68.2895 57.0968L68.2859 57.0949Z"
                fill="#1D3160"
            />
            <path
                d="M70.3516 68.565C70.4829 68.565 70.5994 68.4725 70.6234 68.3394L70.629 68.3135C71.2373 65.0922 72.0713 60.6801 70.2443 57.5532C69.7081 56.6342 68.8242 56.1811 68.2343 56.5251C68.1012 56.6027 68.0568 56.771 68.1344 56.9041C68.2121 57.0373 68.3822 57.0816 68.5135 57.004C68.7854 56.8468 69.366 57.1464 69.7654 57.8324C71.487 60.7818 70.6752 65.0756 70.0835 68.2099L70.0779 68.2358C70.0502 68.3856 70.1482 68.5317 70.2998 68.5594C70.3165 68.5631 70.335 68.565 70.3516 68.565Z"
                fill="#1D3160"
            />
            <path
                d="M59.2926 65.2602C59.3389 65.2602 59.3869 65.2491 59.4313 65.2232L62.0997 63.6847C62.2328 63.6089 62.2772 63.4388 62.2014 63.3056C62.1255 63.1725 61.9554 63.1281 61.8223 63.2039L59.1539 64.7424C59.0208 64.8182 58.9764 64.9884 59.0522 65.1215C59.104 65.2103 59.1965 65.2602 59.2926 65.2602Z"
                fill="#1D3160"
            />
            <path
                d="M63.6571 64.3157C63.6571 64.3157 63.6701 64.3157 63.6756 64.3157C63.8291 64.3157 63.953 64.19 63.9512 64.0365C63.9512 63.883 63.8273 63.761 63.6756 63.761C63.6756 63.761 63.6645 63.761 63.659 63.761C63.0284 63.761 62.4607 63.4189 62.2166 63.2728C62.1538 63.2358 62.1075 63.2081 62.0779 63.1952C61.9393 63.1304 61.7747 63.1896 61.71 63.3283C61.6452 63.467 61.7044 63.6316 61.8431 63.6963C61.8616 63.7055 61.8912 63.724 61.93 63.7481C62.2129 63.9182 62.8749 64.3157 63.6571 64.3157Z"
                fill="#1D3160"
            />
            <path
                d="M67.3364 83.6076C67.4325 83.6076 67.525 83.5577 67.5768 83.4689C67.6526 83.3358 67.6063 83.1657 67.4751 83.0898C67.0498 82.8458 66.667 82.6331 66.3175 82.4389C64.8363 81.6142 63.9413 81.1186 62.817 79.8963C61.6668 78.6463 60.7071 78.1137 60.0081 77.7236C59.7955 77.6052 59.6124 77.5035 59.446 77.3963L55.9418 75.3751V63.0523L58.3346 61.671L61.824 63.6866C61.9572 63.7624 62.1254 63.718 62.2031 63.5849C62.2789 63.4517 62.2345 63.2816 62.1014 63.2058L58.4733 61.1107C58.3883 61.0608 58.281 61.0608 58.196 61.1107L55.5258 62.6529C55.4407 62.7028 55.3871 62.7934 55.3871 62.8933V75.5379C55.3871 75.6377 55.4407 75.7283 55.5258 75.7782C55.5258 75.7782 59.5255 78.0934 59.7382 78.2118C60.4427 78.6038 61.3192 79.0901 62.4084 80.2754C63.5937 81.5624 64.5164 82.0765 66.0475 82.9271C66.3951 83.1213 66.7761 83.3321 67.1977 83.5743C67.2421 83.6002 67.2883 83.6113 67.3364 83.6113V83.6076Z"
                fill="#1D3160"
            />
            <path
                d="M59.293 65.2602C59.3892 65.2602 59.4816 65.2103 59.5334 65.1216C59.6092 64.9884 59.5648 64.8183 59.4317 64.7425L55.8018 62.6492C55.6705 62.5734 55.5004 62.6178 55.4227 62.7509C55.3469 62.8841 55.3913 63.0542 55.5244 63.13L59.1543 65.2233C59.1987 65.2491 59.2449 65.2602 59.293 65.2602Z"
                fill="#1D3160"
            />
            <path
                d="M78.9739 74.8635C79.0849 74.8635 79.1903 74.7951 79.2328 74.686C79.5287 73.913 79.1385 73.1013 78.2121 72.5669L70.649 68.201C70.5159 68.1252 70.3476 68.1695 70.2699 68.3027C70.1941 68.4358 70.2385 68.6041 70.3716 68.6818L77.9347 73.0476C78.6171 73.4415 78.9092 73.9796 78.7151 74.4881C78.6596 74.6305 78.7317 74.7914 78.8741 74.8469C78.9074 74.8598 78.9407 74.8654 78.9739 74.8654V74.8635Z"
                fill="#1D3160"
            />
            <path
                d="M75.6804 76.4001C76.091 76.4001 76.4793 76.365 76.8195 76.2892C78.0622 76.0155 78.9405 75.4349 79.2271 74.6971C79.2826 74.5547 79.2123 74.3938 79.0681 74.3383C78.9257 74.2828 78.7648 74.3531 78.7094 74.4974C78.4949 75.0503 77.7423 75.5181 76.6993 75.7492C75.4308 76.0266 73.3634 75.6864 72.1874 75.0077L70.5009 74.0332C70.1662 73.8409 70.1052 73.484 70.1903 73.2122C70.2365 73.0661 70.1551 72.9108 70.0109 72.8645C69.8648 72.8183 69.7095 72.8997 69.6633 73.0439C69.482 73.6153 69.7132 74.22 70.2254 74.514L71.9118 75.4867C72.8956 76.0562 74.399 76.4001 75.6804 76.4001Z"
                fill="#1D3160"
            />
            <path
                d="M69.9439 73.3613C70.0548 73.3613 70.1602 73.2929 70.2027 73.1819C70.2508 73.0562 70.3784 72.8343 70.6928 72.764C71.4195 72.6013 72.0889 72.6919 72.6769 73.034C72.8082 73.1098 72.9783 73.0654 73.056 72.9323C73.1337 72.7992 73.0874 72.6309 72.9543 72.5532C72.2405 72.1409 71.4398 72.0281 70.5689 72.2222C70.1528 72.3165 69.8311 72.5939 69.6813 72.9841C69.6277 73.1265 69.6979 73.2873 69.8422 73.3428C69.8754 73.3558 69.9087 73.3613 69.9402 73.3613H69.9439Z"
                fill="#1D3160"
            />
            <path
                d="M74.7729 78.7759C75.1852 78.7759 75.5754 78.7408 75.9175 78.665C77.162 78.3913 78.0404 77.8107 78.327 77.071C78.3825 76.9286 78.3103 76.7677 78.1679 76.7123C78.0256 76.6568 77.8647 76.7271 77.8092 76.8713C77.591 77.4353 76.8587 77.892 75.7973 78.125C74.5288 78.4043 72.4633 78.0659 71.2854 77.3854L69.5989 76.4109C69.2476 76.2093 69.1977 75.8284 69.2994 75.5584L70.2018 73.1804C70.2554 73.0361 70.1814 72.8771 70.0372 72.8253C69.8929 72.7736 69.7339 72.8457 69.6821 72.9899L68.7816 75.3605C68.5615 75.9412 68.789 76.5847 69.3234 76.8898L71.0098 77.8624C71.9936 78.4301 73.4933 78.7759 74.7729 78.7759Z"
                fill="#1D3160"
            />
            <path
                d="M73.869 81.152C74.2813 81.152 74.6715 81.1169 75.0136 81.0411C76.2673 80.7637 77.1216 80.2016 77.4175 79.4582C77.4748 79.3158 77.4046 79.155 77.2622 79.0976C77.1198 79.0422 76.9589 79.1106 76.9016 79.253C76.6797 79.8133 75.9474 80.2663 74.8934 80.4993C73.6267 80.7785 71.5594 80.4401 70.3814 79.7596L68.695 78.7851C68.3437 78.5817 68.2937 78.2008 68.3954 77.9327L69.2978 75.5565C69.3515 75.4141 69.2793 75.2532 69.137 75.1996C68.9946 75.146 68.8337 75.2162 68.7801 75.3605L67.8777 77.7366C67.6576 78.3136 67.8851 78.9571 68.4176 79.2659L70.1041 80.2404C71.0878 80.8081 72.5875 81.1539 73.8671 81.1539L73.869 81.152Z"
                fill="#1D3160"
            />
            <path
                d="M72.9701 83.528C73.3824 83.528 73.7707 83.4929 74.1128 83.4171C75.3444 83.1471 76.219 82.572 76.5149 81.8416C76.5722 81.6992 76.5038 81.5383 76.3614 81.481C76.219 81.4237 76.0582 81.4921 76.0008 81.6345C75.7789 82.1837 75.0282 82.6478 73.9945 82.8753C72.7241 83.1526 70.6567 82.8142 69.4807 82.1356L67.7942 81.1611C67.4448 80.9577 67.3948 80.5768 67.4965 80.3086L68.3989 77.9325C68.4525 77.7901 68.3804 77.6292 68.238 77.5756C68.0957 77.5219 67.9348 77.5922 67.8812 77.7364L66.9788 80.1126C66.7587 80.6896 66.9862 81.3331 67.5169 81.6419L69.2052 82.6164C70.1889 83.1841 71.6904 83.528 72.9737 83.528H72.9701Z"
                fill="#1D3160"
            />
            <path
                d="M72.1549 85.9731C72.7134 85.9731 73.2311 85.8825 73.7045 85.7032C75.0914 85.1762 75.6739 84.0334 75.8606 83.5674L79.2298 74.697C79.2834 74.5546 79.215 74.39 79.0745 74.3345C78.9321 74.279 78.7749 74.3419 78.7176 74.4824C78.7176 74.4843 75.3429 83.3658 75.3429 83.3658C75.1857 83.7597 74.6883 84.7361 73.5066 85.1854C72.2326 85.6699 70.5628 85.4036 68.5435 84.3921C67.985 84.1129 67.6818 83.7523 67.6115 83.29C67.5708 83.0108 67.6152 82.6853 67.7484 82.3266L68.035 81.5721C68.0479 81.5407 68.0535 81.5074 68.0535 81.4741C68.0535 81.3207 67.9296 81.1949 67.7761 81.1949C67.6503 81.1949 67.5431 81.2781 67.5098 81.3928L67.2287 82.1306C67.0623 82.5818 67.0087 82.9978 67.0642 83.3714C67.1622 84.0167 67.5764 84.5271 68.2976 84.8877C69.7436 85.6107 71.0343 85.975 72.1568 85.975L72.1549 85.9731Z"
                fill="#1D3160"
            />
        </svg>
    );
}

export default InterfaceTargetSVG;
