import HomeDesktop from "./HomeDesktop/HomeDesktop";
import HomeMobile from "./HomeMobile/HomeMobile";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";

function Home() {
    return (
        <div>
            <OrgThemeHelper />
            <HomeDesktop viewportName={"desktop"} />
            <HomeMobile viewportName={"mobile"} />
        </div>
    );
}

export default Home;
