import { Link } from "react-router-dom";
import { useOrgObject } from "../../hooks/useOrgObject";

function OrgLink({ to = "", style, id, className, rel, target, children }) {
    const orgObject = useOrgObject();
    const overridePrefixes = ["mailto", "http"];
    function shouldOverride() {
        return overridePrefixes.some((prefix) => to.includes(prefix));
    }

    function getOrgHref() {
        return shouldOverride()
            ? to
            : `${orgObject.key ? `/${orgObject.key}` : ""}${
                  to[0] === "/" ? to : "/" + to
              }`;
    }

    return (
        <Link
            to={getOrgHref()}
            id={id}
            className={className}
            style={style}
            rel={rel}
            target={target}
        >
            {children}
        </Link>
    );
}

export default OrgLink;
