import ArrowSubtitle from "../../components/ArrowSubtitle/ArrowSubtitle";
import Header from "../../components/Header/Header";
import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import { useOrgObject } from "../../hooks/useOrgObject";
import styles from "./CrisisSupport.module.css";

function CrisisSupport({ viewportName = "desktop" }) {
    const { region } = useOrgObject();

    function getRegionalData() {
        if (region === "ca") {
            return [
                {
                    src: "/images/global/boots-on-the-ground.png",
                    title: "Boots On The Ground",
                    content:
                        "Please visit <a href='https://www.bootsontheground.ca' target='_blank' rel='noreferrer'>www.bootsontheground.ca</a> or call us 24/7 at <strong><a href='tel:18336772668'>1-833-677-BOOT(2668)</a></strong>",
                },
                {
                    src: "/images/global/text-line.png",
                    title: "24/7 Crisis Text Line",
                    content:
                        "Text <strong><a href='sms:686868&body=TALK'>TALK to 686868</a></strong> to reach an English speaking Crisis Responder, or <strong><a href='s,s:686868&body=TEXTO'>TEXTO to 686868</a></strong> to reach a French-speaking Crisis Responder on any text/SMS enabled cell phone.",
                },
                {
                    src: "/images/global/988-talk-suicide.png",
                    title: "Talk Suicide",
                    content:
                        "Connect to a crisis responder for help in English or French without judgement. Call <strong><a href='tel:18334564566'>1-833-456-4566</a></strong> toll-free, any time - or text <strong><a href='sms:45645'>45645</a></strong> from 4 PM to midnight ET.",
                },
            ];
        } else if (region === "us") {
            return [
                {
                    src: "/images/global/copline.png",
                    title: "COPLINE",
                    content:
                        "Please visit <a href='https://www.copline.org/' target='_blank' rel='noreferrer'>www.copline.org</a> or call us 24/7 at <strong><a href='tel:18002675463'>1-800-COPLINE (267-5463)</a></strong",
                },
                {
                    src: "/images/global/text-line.png",
                    title: "24/7 Crisis Text Line",
                    content:
                        "Text <strong><a href='sms:741741&body=HOME'>HOME to 741741</a></strong> to reach the Crisis Text Line. Please visit <a href='https://www.nvfc.org/programs/share-the-load-program/'>www.nvfc.org/programs/share-the-load-program</a> to learn more.",
                },
                {
                    src: "/images/global/988-talk-suicide.png",
                    title: "Talk Suicide",
                    content:
                        "Connect to a crisis responder for help in English or Spanish without judgement. Call or text <strong><a href='tel:988'>988</a></strong> toll-free, any time.",
                },
            ];
        } else {
            return [];
        }
    }
    return (
        <main>
            <OrgThemeHelper />
            <section id={styles.content_section}>
                <div id={styles.emergency_banner}>
                    If your life or someone else’s is in danger, don’t hesitate
                    to DIAL EMERGENCY SERVICES NOW
                    <a
                        className="link-button"
                        href="tel:911"
                        aria-label="Please Call 911 to report a crisis"
                    >
                        Please Call 911
                    </a>
                </div>
                <div
                    id={styles.content_section_content}
                    className={"section-content"}
                >
                    <Header darkMode={true} viewportName={viewportName} />

                    <div className={styles.heading_container}>
                        <ArrowSubtitle text={"You are not alone"} />
                        <h1 id={styles.main_heading}>Crisis Support</h1>
                        <p>
                            If you are experiencing a crisis and/or suicidal
                            thoughts,{" "}
                            <strong>
                                help is just a text or phone call away
                            </strong>
                            .
                        </p>
                    </div>
                    <div id={styles.resources_container}>
                        {getRegionalData().map(({ title, src, content }) => (
                            <div key={title} className={styles.resource}>
                                <img
                                    className={styles.resource_image}
                                    src={src}
                                    alt=""
                                />
                                <h2 className={styles.resource_title}>
                                    {title}
                                </h2>

                                <div
                                    className={styles.resource_content}
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CrisisSupport;
