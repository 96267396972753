import styles from "./ReelsList.module.css";
import Waveform from "../Waveform/Waveform";
import OrgLink from "../OrgLink/OrgLink";

function ReelsList({ data }) {
    return (
        <div id={styles.reels_list}>
            {data.map((reel) => (
                <div className={styles.reel_container} key={reel.name}>
                    <Waveform data={reel} className={styles.left} />
                    <OrgLink
                        to={"/"}
                        className={[
                            styles.connect_button,
                            "link-button",
                            "outline",
                            "dark-mode",
                        ].join(" ")}
                    >
                        Connect
                    </OrgLink>
                </div>
            ))}
        </div>
    );
}

export default ReelsList;
