import styles from "./WellnessArticleLink.module.css";
import ArrowSubtitle from "../ArrowSubtitle/ArrowSubtitle";
import OrgLink from "../OrgLink/OrgLink";

function WellnessArticleLink({ id, alt = "", src, title }) {
    return (
        <OrgLink
            className={styles.wellness_article_link}
            to={"/wellness-hub/" + id}
        >
            <img
                draggable={false}
                src={src}
                alt={alt}
                className={styles.image}
            />
            <h3 className={styles.title}>{title}</h3>
            <ArrowSubtitle text={"Read More"} />
        </OrgLink>
    );
}

export default WellnessArticleLink;
