function PlayButtonSVG({ className }) {
    return (
        <svg
            className={className}
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="17.178"
                cy="16.8929"
                r="16.8929"
                fill="var(--secondary-color)"
            />
            <path
                d="M20.8112 16.2992C21.283 16.5547 21.283 17.2319 20.8112 17.4875L14.797 20.7451C14.3468 20.989 13.7995 20.663 13.7995 20.151L13.7995 13.6356C13.7995 13.1236 14.3468 12.7976 14.797 13.0415L20.8112 16.2992Z"
                fill="white"
            />
        </svg>
    );
}

export default PlayButtonSVG;
