import { useContext, useEffect, useRef } from "react";
import useFocusTrap from "../../hooks/useFocusTrap";
import styles from "./SignUpModal.module.css";
import SignUpOption from "../SignUpOption/SignUpOption";
import OrgLink from "../OrgLink/OrgLink";
import { SignUpModalContext } from "../SignUpModalContextProvider/SignUpModalContextProvider";
import { useOrgObject } from "../../hooks/useOrgObject";

function SignUpModal() {
    const { showModal, setShowModal, signUpOption, setSignUpOption } =
        useContext(SignUpModalContext);
    const backdropRef = useRef(null);
    const closeButtonRef = useRef(null);
    const orgObject = useOrgObject();

    function handleEscape(e) {
        if (e.keyCode === 27) {
            setShowModal(false);
        }
    }

    useEffect(() => {
        if (showModal) {
            setTimeout(() => closeButtonRef.current.focus(), 1);
        }
    }, [showModal]);

    useFocusTrap(backdropRef, showModal, {});

    return (
        <div
            ref={backdropRef}
            id={styles.modal_backdrop}
            className={[showModal ? undefined : styles.hide, "desktop"].join(
                " "
            )}
            onKeyDown={handleEscape}
        >
            <div id={styles.modal_container}>
                <div id={styles.top}>
                    <div id={styles.top_text}>
                        Please select your membership type below.
                    </div>
                    <button
                        onClick={() => setShowModal(false)}
                        id={styles.close_button}
                        ref={closeButtonRef}
                        aria-label="Close sign up modal"
                    >
                        <img
                            src="/images/global/modal-close-icon-thick.svg"
                            alt=""
                        />
                    </button>
                </div>
                <div id={styles.middle}>
                    <SignUpOption
                        name={"Peer Member"}
                        subtitle={"Seeking Assistance"}
                        checks={[
                            "Self Assessment Tools",
                            "Keel Reels",
                            "Wellness Hub",
                            "Access to Peer Supporters",
                        ]}
                        selectedOption={signUpOption}
                        setSelectedOption={setSignUpOption}
                    />

                    <SignUpOption
                        name={"Peer Supporter"}
                        subtitle={"Offering Support"}
                        checks={[
                            "Access to Peers",
                            "All Tools",
                            "Peer Support Training",
                        ]}
                        boldChecks={["International Initiative"]}
                        selectedOption={signUpOption}
                        setSelectedOption={setSignUpOption}
                    />
                </div>
                <div id={styles.bottom}>
                    <button
                        id={styles.cancel_button}
                        className={styles.bottom_button}
                        aria-label="Cancel sign up process and close modal"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <OrgLink
                        target={"_blank"}
                        rel={"noreferrer"}
                        id={styles.continue_link}
                        to={`https://app.${orgObject.region}.geta-head.com/app/gah/`}
                        className={styles.bottom_button}
                    >
                        Continue
                    </OrgLink>
                </div>
            </div>
        </div>
    );
}

export default SignUpModal;
