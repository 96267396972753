import OrgThemeHelper from "../../components/OrgThemeHelper/OrgThemeHelper";
import PeerConnectDesktop from "./PeerConnectDesktop/PeerConnectDesktop";
import PeerConnectMobile from "./PeerConnectMobile/PeerConnectMobile";

function PeerConnect() {
    return (
        <div>
            <OrgThemeHelper />
            <PeerConnectMobile viewportName={"mobile"} />
            <PeerConnectDesktop viewportName={"desktop"} />
        </div>
    );
}

export default PeerConnect;
